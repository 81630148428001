import styled, {css} from "styled-components";
import {Animation, ButtonIcon} from "../../atom";
import {breakpoints, stylesScrollbarMixin} from "../../../config";

type ModalBodyRight = {
  $isStatus?: boolean;
};

const ButtonBg = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--interface-dark);
  opacity: 0.3;
  z-index: 0;
`;

const BodyAnimation = styled(Animation)`
  position: relative;
  width: 100%;
  max-width: 640px;
  padding: 80px 64px;
  margin-left: auto;
  background-color: var(--brand-3);
  border-radius: 60px 0 0 60px;
  max-height: 100%;
  overflow: auto;
  ${stylesScrollbarMixin("4px", "4px")};

  @media (max-width: ${breakpoints.XXL}) {
    padding: 60px 70px;
  }

  @media (max-width: ${breakpoints.XL}) {
    padding: 40px 60px;
  }

  @media (max-width: ${breakpoints.LG}) {
    margin: auto 0 0 0;
    max-width: unset;
    padding: 64px 56px 70px;
    border-radius: 40px 40px 0 0;

    &::before {
      content: "";
      position: absolute;
      top: 16px;
      left: 50%;
      transform: translateX(-50%);
      width: 140px;
      height: clamp(4px, 1vw, 5px);
      background-color: var(--interface);
      border-radius: 160px;
    }
  }

  @media (max-width: ${breakpoints.MD}) {
    padding: 40px 20px 30px;

    &::before {
      width: 64px;
    }
  }
`;

const ButtonIconBody = styled(ButtonIcon)`
  position: absolute;
  top: 24px;
  right: 24px;

  &:hover {
    color: var(--interface-dark);
  }
`;

const ModalBodyRight = styled.section<ModalBodyRight>`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1000000;
  display: flex;
  overflow: auto;
  ${stylesScrollbarMixin("4px", "4px")};

  ${(props) =>
          props.$isStatus &&
          css`
            ${BodyAnimation} {
              background-color: var(--brand-1);
            }

            ${ButtonIconBody} {
              color: var(--brand-3);

              &:hover {
                color: var(--interface);
              }
            }
          `}
`;

export {ModalBodyRight, ButtonBg, BodyAnimation, ButtonIconBody};
