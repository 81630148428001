import React, { FC, ReactNode } from "react";
import * as s from "./Menu-mobile-styles";
import { Animation } from "../animation/Animation";

type MenuMobileType = {
  className?: string;
  isActive: boolean;
  list: Array<ReactNode>;
  onClickBurger: () => void;
};

export const MenuMobile: FC<MenuMobileType> = ({
  className,
  isActive,
  list,
  onClickBurger,
}) => {
  return (
    <s.MenuMobile className={className}>
      <s.Burger type={"button"} $isActive={isActive} onClick={onClickBurger}>
        <s.Span></s.Span>
      </s.Burger>

      <Animation isShow={isActive}>
        <s.List>
          {list.map((item, index) => (
            <s.Item key={index}>{item}</s.Item>
          ))}
        </s.List>
      </Animation>
    </s.MenuMobile>
  );
};
