import React, { FC, useEffect } from "react";
import * as s from "./Main-styles";
import {
  Animation,
  IconCatShape,
  IconDoc,
  IconExit,
  IconUpload,
} from "../../../shared";
import { useLocation, useNavigate } from "react-router-dom";
import heroPets1440 from "../../../shared/config/assets/images/hero-pets-1440.png";
import heroPets1440_2x from "../../../shared/config/assets/images/hero-pets-1440@2x.png";
import heroPets1440Webp from "../../../shared/config/assets/images/hero-pets-1440.webp";
import heroPets1440_2xWebp from "../../../shared/config/assets/images/hero-pets-1440@2x.webp";
import heroPets1024 from "../../../shared/config/assets/images/hero-pets-1024.png";
import heroPets1024_2x from "../../../shared/config/assets/images/hero-pets-1024@2x.png";
import heroPets1024Webp from "../../../shared/config/assets/images/hero-pets-1024.webp";
import heroPets1024_2xWebp from "../../../shared/config/assets/images/hero-pets-1024@2x.webp";
import heroPets375 from "../../../shared/config/assets/images/hero-pets-375.png";
import heroPets375_2x from "../../../shared/config/assets/images/hero-pets-375@2x.png";
import heroPets375Webp from "../../../shared/config/assets/images/hero-pets-375.webp";
import heroPets375_2xWebp from "../../../shared/config/assets/images/hero-pets-375@2x.webp";
import { A11y, Navigation, Pagination, Scrollbar } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { esAuth, esDiscount } from "../../../entities";
import {
  useAppDispatch,
  useAppSelector,
  useChangeLetter,
  useIsMobile,
  useScrollToHash,
  useScrollToTop,
} from "../../../shared/lib";
import { setModals } from "../../../widgets/modals/model";
import { PromoCodeType } from "../../../entities/discount/model";
import {
  breakpointsTs,
  Container,
  ModalsEnumType,
  ModalsProperty,
  pages,
  StatusPromoCode,
} from "../../../shared/config";
import { SwiperSlide } from "swiper/react";

type MainType = {};

export const Main: FC<MainType> = ({}) => {
  useScrollToTop();
  const { hash } = useLocation();
  useScrollToHash(hash);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isFullDesktop] = useIsMobile(breakpointsTs.XXL);
  const [isDesktop] = useIsMobile(breakpointsTs.XL);
  const [isTablet] = useIsMobile(breakpointsTs.LG);
  const [isMobile] = useIsMobile(breakpointsTs.SM);
  const { isAuthorization } = useAppSelector(esAuth.model.selectAuth);
  const {
    promoCodesTypes,
    promoCodesTypesYoung,
    promoCodesTypesAdult,
    promoCodesTypesPartner,
  } = useAppSelector(esDiscount.model.selectDiscount);
  const { user } = useAppSelector(esAuth.model.selectAuth);

  const onRedirect = () => {
    if (isAuthorization) {
      dispatch(setModals({ action: ModalsProperty.MODAL_EXIT }));
    } else {
      navigate(pages.signIn.link);
    }
  };

  const onRedirectButtonCheck = () => {
    if (isAuthorization) {
      navigate(pages.personalAreaChecks.link + "/" + "Modal");
    } else {
      const element = document.getElementById("form-registration-section");
      element?.scrollIntoView({ behavior: "smooth" });
    }
  };

  const onRedirectButtonBonusesSection = () => {
    if (isAuthorization) {
      navigate(pages.personalAreaProfile.link);
    } else {
      navigate(pages.signIn.link);
    }
  };

  const onOpenModalEnum = (actionModal: ModalsEnumType) => () => {
    dispatch(
      setModals({
        action: actionModal,
      })
    );
  };

  const onOpenModalPromoCodeType =
    (data: PromoCodeType, title?: string) => () => {
      dispatch(
        setModals({
          action: ModalsProperty.MODAL_PROMO_CODE_TYPE,
          content: { ...data, isButton: false, titleModal: title },
        })
      );
    };

  const onOpenModalQuestion = (title: string) => () => {
    dispatch(
      setModals({
        action: ModalsProperty.MODAL_FREQUENTLY_ASKED_QUESTION_QUESTION,
        content: title,
      })
    );
  };

  const onRedirectProfile = () => {
    navigate(pages.personalAreaProfile.link);
  };

  useEffect(() => {
    if (promoCodesTypes?.length === 0) {
      dispatch(esDiscount.model.getPromoCodesTypesNotAuthorizedThunk());
    }
  }, []);

  const animation = {
    initial: { opacity: 0, y: -50 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0 },
  };

  return (
    <s.Main>
      <s.HeaderMain
        isMobile={!isTablet}
        isScroll={true}
        listLeft={[
          <s.LinkDefault href="#discounts-section">
            Скидки и подарки
          </s.LinkDefault>,

          <s.LinkDefault href="#additional-royals-section">
            Дополнительные роялсы
          </s.LinkDefault>,

          <s.LinkDefault href="#duo-feeding-section">
            Смешанное кормление
          </s.LinkDefault>,

          <s.LinkDefault href="#faq-section">FAQ</s.LinkDefault>,
        ]}
        listRight={[
          <s.LinkDefault
            href={
              "https://xn----ctbfggjf0anaeki8a4dxd.xn--p1ai/media/rules/Правила_акции_Здоровый_питомец__2024.pdf"
            }
            target={"_blank"}
          >
            <IconDoc width={24} height={24} />
            {isTablet && "Полные правила акции"}
          </s.LinkDefault>,

          <s.Button type={"button"} onClick={onRedirect}>
            <IconExit width={24} height={24} />{" "}
            {isTablet && (isAuthorization ? "Выйти" : "Войти")}
          </s.Button>,

          isAuthorization && (
            <s.ButtonAvatar
              type={"button"}
              $isImage={user?.image}
              onClick={onRedirectProfile}
            >
              {user?.image ? (
                <s.ImageAvatar src={user?.image} alt={"Аватар"}></s.ImageAvatar>
              ) : (
                user?.first_name.slice(0, 1) || <s.IconAvatar />
              )}
            </s.ButtonAvatar>
          ),
        ]}
      />

      <s.Content>
        <Container>
          <Animation isShow={true} delay={0.3} variants={animation}>
            <s.HeroSection
              titleBanner={"Здоровье с\u00A0первых дней"}
              subTitleBanner={
                "Подберите рацион питомцу с учетом его индивидуальных потребностей"
              }
              extraTextBanner={
                "Регистрируйте чеки\u00A0- получайте подарки и призы"
              }
              imgBanner375Webp={heroPets375Webp}
              imgBanner375_2xWebp={heroPets375_2xWebp}
              imgBanner375={heroPets375}
              imgBanner375_2x={heroPets375_2x}
              imgBanner1024Webp={heroPets1024Webp}
              imgBanner1024_2xWebp={heroPets1024_2xWebp}
              imgBanner1024={heroPets1024}
              imgBanner1024_2x={heroPets1024_2x}
              imgBanner1440={heroPets1440}
              imgBanner1440_2x={heroPets1440_2x}
              imgBanner1440Webp={heroPets1440Webp}
              imgBanner1440_2xWebp={heroPets1440_2xWebp}
              altTextImgBanner={"\u00A0"}
              buttonText={"загрузить чек"}
              buttonTypeBonus={"button"}
              buttonIconUpload={<IconUpload />}
              onClickButtonUploadHero={onRedirectButtonCheck}
            />
          </Animation>
        </Container>

        <Container>
          <s.PartnersWrapSection />
        </Container>

        <Container>
          <s.AdditionalBonusesSection
            titleCardTarget={"Получайте бонусы за каждую покупку"}
            textCardTarget={"За каждые 50 рублей в чеке начисляем 1 роялс"}
            textButtonCard={
              isAuthorization ? "личный кабинет" : "Авторизоваться"
            }
            typeButtonCard={"button"}
            iconButtonCard={<IconUpload />}
            onClickButtonUploadCardTarget={onRedirectButtonBonusesSection}
            titleTimeline={"Дополнительные бонусы за регулярные покупки"}
            firstStepIconTimeline={<IconCatShape />}
            cardsRoyalsList={[
              { amount: null, month: "1 месяц" },
              { amount: 30, month: "3 месяца" },
              { amount: 45, month: "6 месяцев" },
              { amount: 60, month: "9 месяцев" },
            ]}
          />
        </Container>

        <s.DiscountsSection id="discounts-section">
          <s.DiscountsHeader>
            <s.DiscountsTitle>Скидки и полезные подарки</s.DiscountsTitle>
          </s.DiscountsHeader>

          <s.DiscountsList>
            <s.DiscountsItem>
              <s.DiscountsSubTitle>
                Скидки на рационы для&nbsp;котят и щенков
              </s.DiscountsSubTitle>

              <s.SwiperContainer
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                spaceBetween={!isMobile ? 8 : isDesktop ? 16 : 12}
                slidesPerView={
                  !isMobile
                    ? 2.08
                    : !isTablet
                    ? 2.65
                    : !isDesktop
                    ? 3.75
                    : isFullDesktop
                    ? 4
                    : 3.97
                }
                navigation={true}
                onSlideChange={() => {}}
                onSwiper={(swiper: any) => {}}
              >
                {promoCodesTypesYoung?.map((promoCodeYoung, index) => (
                  <SwiperSlide key={index}>
                    <s.CardSlide
                      counterRoyals={String(promoCodeYoung.price)}
                      image={promoCodeYoung.image}
                      statusPromoCode={StatusPromoCode.DEFAULT}
                      text={useChangeLetter({
                        text: promoCodeYoung.title,
                        icon: <s.RubleIcon />,
                        targetChar: "₽",
                      })}
                      onClickButton={onOpenModalPromoCodeType(
                        promoCodeYoung,
                        "Скидки на рационы для котят и щенков"
                      )}
                    />
                  </SwiperSlide>
                ))}
              </s.SwiperContainer>
            </s.DiscountsItem>

            <s.DiscountsItem>
              <s.DiscountsSubTitle>
                Скидки на рационы для&nbsp;взрослых кошек и собак
              </s.DiscountsSubTitle>

              <s.SwiperContainer
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                spaceBetween={!isMobile ? 8 : isDesktop ? 16 : 12}
                slidesPerView={
                  !isMobile
                    ? 2.08
                    : !isTablet
                    ? 2.65
                    : !isDesktop
                    ? 3.75
                    : isFullDesktop
                    ? 4
                    : 3.97
                }
                navigation={true}
                onSlideChange={() => {}}
                onSwiper={(swiper: any) => {}}
              >
                {promoCodesTypesAdult?.map((promoCodeAdult, index) => (
                  <SwiperSlide key={index}>
                    <s.CardSlide
                      counterRoyals={String(promoCodeAdult.price)}
                      image={promoCodeAdult.image}
                      statusPromoCode={StatusPromoCode.DEFAULT}
                      isBigBgImage={promoCodeAdult.assignment === "grown_up"}
                      text={useChangeLetter({
                        text: promoCodeAdult.title,
                        icon: <s.RubleIcon />,
                        targetChar: "₽",
                      })}
                      isRoyals={!!promoCodeAdult.price}
                      onClickButton={onOpenModalPromoCodeType(
                        promoCodeAdult,
                        "Скидки на рационы для взрослых кошек и собак"
                      )}
                    />
                  </SwiperSlide>
                ))}
              </s.SwiperContainer>
            </s.DiscountsItem>

            <s.DiscountsItem>
              <s.DiscountsSubTitle>Подарки от партнера</s.DiscountsSubTitle>

              <s.SwiperContainer
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                spaceBetween={!isMobile ? 8 : isDesktop ? 16 : 12}
                slidesPerView={
                  !isMobile
                    ? 2.08
                    : !isTablet
                    ? 2.65
                    : !isDesktop
                    ? 3.75
                    : isFullDesktop
                    ? 4
                    : 3.97
                }
                navigation={true}
                onSlideChange={() => {}}
                onSwiper={(swiper: any) => {}}
              >
                {promoCodesTypesPartner?.map((promoCodePartner, index) => (
                  <SwiperSlide key={index}>
                    <s.CardSlideGifts
                      counterRoyals={String(promoCodePartner.price)}
                      image={promoCodePartner.image}
                      statusPromoCode={StatusPromoCode.DEFAULT}
                      text={promoCodePartner.title}
                      isRoyals={false}
                      onClickButton={onOpenModalPromoCodeType(
                        promoCodePartner,
                        "Подарки от партнера"
                      )}
                    />
                  </SwiperSlide>
                ))}
              </s.SwiperContainer>
            </s.DiscountsItem>
          </s.DiscountsList>
        </s.DiscountsSection>

        <Container>
          <s.AdditionalRoyalsSection
            id="additional-royals-section"
            titleAdditionalRoyals={"Как получить дополнительные роялсы?"}
            isFlipped={isFullDesktop}
            onClickCardRoyals={onOpenModalEnum}
          />
        </Container>

        <Container>
          <s.DuoFeedingSection
            id="duo-feeding-section"
            titleDuoFeeding={"Важность кормления сухим и влажным рационом"}
            textDuoFeeding={
              "Сочетание сухого и влажного рационов может помочь улучшить здоровье питомца"
            }
            onClickCardFeedings={onOpenModalEnum}
          />
        </Container>

        {!isAuthorization && (
          <Container>
            <s.FormRegistrationSection id={"form-registration-section"} />
          </Container>
        )}

        <Container>
          <s.FaqSection id="faq-section" onClickButton={onOpenModalQuestion} />
        </Container>
      </s.Content>
    </s.Main>
  );
};
