import { AxiosResponse } from "axios";
import { AnimalResponseType } from "../model";
import { instance } from "../../../shared/api";

export const animalApi = {
  breed: async () => {
    const response = await instance.get("breed/");
    return response as AxiosResponse<Array<AnimalResponseType>>;
  },
};
