import styled from "styled-components";
import { Container } from "../../../shared/config";

const Root = styled.section`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-bottom: 24px;
`;

const RootContainer = styled(Container)``;

const Content = styled.div`
  flex: 1 1 auto;
`;

export { Root, RootContainer, Content };
