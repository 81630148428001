import React, { FC } from "react";

export const IconDog: FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox={"0 0 40 40"}
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M38.125 4.04a.208.208 0 0 0-.029-.091c-.062-.216-2.147.206-2.402.26l-.283.062c-.464.165-.97.177-1.441.033a4.996 4.996 0 0 1-.6-.451 2.526 2.526 0 0 0-1.043-.61 5.765 5.765 0 0 0-1.845-.077c-.72.069-1.43.23-2.109.48a4.036 4.036 0 0 0-1.398 1.052c-.168.214-.307.45-.413.702l-.043.149a2.262 2.262 0 0 0-.144 1.066c-.4.712-.7 1.474-.894 2.268-.154.686-1.143 4.083-1.335 5.03-.034.234-.26 1.19-.312 1.522-.471.994-1.667 1.71-2.44 2.498-.774.788-1.394 1.441-2.086 2.166a20.815 20.815 0 0 1-2.54 2.402c-.452.341-2.742 2.745-2.561 2.787-.422.278-2.398 3.655-2.518 4.323-.759.452-1.835.557-2.695.677a4.525 4.525 0 0 1-3.362-.792c-1.259-.884-2.402-1.999-3.78-2.738-.39-.212-.77-.313-.524.48.73 2.306 2.58 3.872 4.803 4.67 1.4.426 2.868.573 4.324.432.182 0 .687-.072 1.057-.135a.245.245 0 0 0 0 .053c0 .668 1.281 1.745 1.643 1.749.312 0 .898-.048 1.143.24-.428.245-.826.87-.38 1.215.285.144.608.194.923.144l12.365-.072c.033.008.483.005.581-.567a1.35 1.35 0 0 0-.692-1.417 5.888 5.888 0 0 0-2.05-.192 18.926 18.926 0 0 0-2.043.058c.341-.722 1.399-3.108 1.624-3.565.168-.34 2.33-1.16 2.916-1.56.324-.203.634-.428.927-.674a5.869 5.869 0 0 0 1.528-2.166c.115.235.336.437.442.663.076.178.14.361.192.548.353 1.066.622 1.826.807 2.281.308.87.308 1.975.423 2.883.115.908.053 2.344.605 3.122.025.034.058.06.096.077.029.129 1.666.363 2.364.375.25.003.5-.01.75-.039a.245.245 0 0 0 .24-.24c-.025-.437.076-.817-.217-1.162-.173-.212-.346-.553-.62-.572-.273-.02-.725.062-.96-.149l-.044-.029a6.254 6.254 0 0 1-.206-1.403c-.068-.798-.087-1.6-.058-2.401.003-1.285.078-2.568.226-3.844.216-1.921.384-4.037.269-5.9-.115-1.864-.87-2.968-1.018-3.217-.03-.053-.62-.836-.827-1.288-.812-1.739-.413-3.593.774-5.077l.586-.193a10.817 10.817 0 0 0 2.402-.96c.46-.28.871-.636 1.215-1.052.326-.389.565-.843.702-1.33a2.71 2.71 0 0 0 .052-.875 4.315 4.315 0 0 1-.01-.534 2.88 2.88 0 0 0-.057-1.095Z"
      clipRule="evenodd"
    />
  </svg>
);
