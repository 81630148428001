import React, { FC, useEffect, useState } from "react";
import * as s from "./Select-input-calendar-formik-styles";
import { CalendarListDateType, SelectInputCalendar } from "../../../shared";
import { FieldHelperProps, FieldInputProps, FieldMetaProps } from "formik";
import { monthNumber } from "../../../shared/config";

type SelectInputCalendarFormikType = FieldMetaProps<any> &
  FieldHelperProps<any> &
  FieldInputProps<any> & {
    placeholderInput: string;
    className?: string;
  };

export const SelectInputCalendarFormik: FC<SelectInputCalendarFormikType> = ({
  className,
  error,
  placeholderInput,
  touched,
  name,
  setValue,
  onBlur,
}) => {
  const getAllMonthsRussianWithDate = () => {
    const months: Array<CalendarListDateType> = [];

    const currentDate = new Date(); // Получаем текущую дату
    const currentYear = currentDate.getFullYear(); // Получаем текущий год
    const currentMonth = currentDate.getMonth() + 1; // Получаем текущий месяц

    for (let i = 0; i < 12; i++) {
      const date = new Date(2000, i, 1);
      const monthName = date.toLocaleString("ru", { month: "long" });
      const monthNumber =
        date.getMonth() + 1 >= 10
          ? date.getMonth() + 1
          : `${date.getDate() - 1}${date.getMonth() + 1}`;

      let disabled = false; // По умолчанию не заблокирован

      if (currentYear === new Date(textButtonYear).getFullYear()) {
        // Проверяем текущий год
        // Если текущий месяц больше месяца в цикле, заблокировать месяц
        if (currentMonth < i + 1) {
          disabled = true;
        }
      }

      months.push({ monthName, monthNumber: String(monthNumber), disabled });
    }

    return months;
  };

  const getAllYear = (currentYear: number) => {
    const year: Array<number> = [];

    for (let i = 0; i < 35; i++) {
      year.push(currentYear - i);
    }

    return year;
  };

  const currentDate = new Date();
  const month = currentDate.toLocaleString("default", {
    month: "long",
  });
  const year = String(currentDate.getFullYear());
  const [valueInput, setValueInput] = useState("");
  const [isShowCalendar, setIsShowCalendar] = useState(false);
  const [textButtonMonth, setTextButtonMonth] = useState<string>(month);
  const [textButtonYear, setTextButtonYear] = useState(year);
  const [isActiveButton, setIsActiveButton] = useState(false);

  const getFormatValueCalendar = (value: string) => {
    // Форматирование значения в соответствии с маской MM/YYY
    let formattedValue = value
      .replace(/\D/g, "") // Удаление всех нецифровых символов
      .slice(0, 6); // Ограничение длины ввода до 6 символов (MMYYYY)

    // Обработка первых двух символов (месяц)
    if (formattedValue.length > 2) {
      const month = parseInt(formattedValue.slice(0, 2), 10);
      // Проверка, чтобы значение месяца не превышало 12
      if (month > 12) {
        formattedValue = "12"; // Если превышает, устанавливаем 12
      } else {
        formattedValue =
          formattedValue.slice(0, 2) + "/" + formattedValue.slice(2);
      }
    }

    // Обработка года
    if (formattedValue.length > 3) {
      const currentYear = new Date().getFullYear().toString();
      const year = formattedValue.slice(3);
      if (year > currentYear) {
        formattedValue = formattedValue.slice(0, 3) + currentYear;
      }
    }

    return formattedValue;
  };

  const onShowCalendar = () => {
    setIsShowCalendar((show) => !show);
  };

  const onButtonInputShowCalendar = () => {
    setIsShowCalendar(true);

    if (!valueInput) {
      setValueInput(
        getFormatValueCalendar(
          monthNumber[textButtonMonth] + String(textButtonYear)
        )
      );
    }
  };

  const onClickButtonTimeMonth = () => {
    setIsActiveButton(true);
  };

  const onClickButtonTimeYear = () => {
    setIsActiveButton(false);
  };

  const onClickButtonDateMoth =
    (setTextButton: (value: string) => void) =>
    (date: string, text: string) =>
    () => {
      setTextButton(text);
      setValueInput(String(date) + "/" + String(textButtonYear));
    };

  const onClickButtonDateYear =
    (setTextButton: (value: string) => void) => (text: string) => () => {
      const currentYear = currentDate.getFullYear(); // Получаем текущий год
      setTextButton(text);

      if (currentYear === new Date(text).getFullYear()) {
        setTextButtonMonth("январь");
        setValueInput(monthNumber["январь"] + "/" + String(text));
      } else {
        setValueInput(monthNumber[textButtonMonth] + "/" + String(text));
      }
    };

  const setIsActiveButtonDate = (text: string, textButton: string): boolean => {
    return textButton.toLowerCase() === text.toLowerCase();
  };

  useEffect(() => {
    if (valueInput.length > 0) {
      setValue("01." + valueInput.split("/").join("."));
    } else {
      setValue("");
    }
  }, [valueInput]);

  return (
    <s.SelectInputCalendarFormik
      className={className}
      $isActive={isShowCalendar}
    >
      <SelectInputCalendar
        name={name}
        error={touched ? (error ? error : "") : ""}
        textButtonMonth={textButtonMonth}
        textButtonYear={textButtonYear}
        typeButton={"button"}
        isActiveButtonMonth={isActiveButton}
        isActiveButtonYear={!isActiveButton}
        listMonth={getAllMonthsRussianWithDate()}
        listYear={getAllYear(Number(year))}
        setIsActiveButtonDate={setIsActiveButtonDate}
        onClickButtonTimeMonth={onClickButtonTimeMonth}
        onClickButtonTimeYear={onClickButtonTimeYear}
        onClickButtonIconClose={onShowCalendar}
        onClickButtonDateMonth={onClickButtonDateMoth(setTextButtonMonth)}
        onClickButtonDateYear={onClickButtonDateYear(setTextButtonYear)}
        onClickButtonBg={onShowCalendar}
        valueInput={valueInput}
        placeholderInput={placeholderInput}
        icon={<s.IconSelectCalendar $isError={!!error && touched} />}
        onClickButtonIcon={onButtonInputShowCalendar}
        isShowCalendar={isShowCalendar}
      />
    </s.SelectInputCalendarFormik>
  );
};
