import styled from "styled-components";

const InfoBonusTooltip = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const Button = styled.button`
  display: flex;
`;

const InfoBonusTooltipWrap = styled.div`
  font: var(--font-16);
  position: absolute;
  bottom: 30px;
  left: -70px;
  display: flex;
  align-items: center;
  width: max-content;
  padding: 4px 12px 4px 12px;
  background-color: var(--brand-3);
  border-radius: 8px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05);
`;

const Span = styled.span`
  margin-right: 4px;
`;

export { InfoBonusTooltip, Button, InfoBonusTooltipWrap, Span };
