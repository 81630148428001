import React, {FC} from "react";
import * as s from "./Modal-confirmation-phone-styles";
import {useFormik} from "formik";
import {validationFormModalConfirmationPhone} from "../config";
import {ButtonProperty} from "../../../../shared/config";
import {SmsType} from "../../../../shared/model";

type ModalConfirmationPhoneType = {
  className?: string;
  onShowModal: () => void;
  onSubmit: (body: SmsType) => void;
  phone: string
};

export const ModalConfirmationPhone: FC<ModalConfirmationPhoneType> = (
  {
    className,
    onShowModal,
    onSubmit,
    phone,
  }) => {
  const formik = useFormik({
    initialValues: {
      phone: phone
        ? `+${phone.slice(0, 1)} (${phone.slice(
          1,
          4
        )}) ${phone.slice(4, 7)}-${phone.slice(
          7,
          9
        )}-${phone.slice(9, 11)}`
        : "",
    },

    validationSchema: validationFormModalConfirmationPhone,

    onSubmit: async (body: SmsType) => {
      onSubmit(body);
    },
  });

  return (
    <s.ModalConfirmationPhone className={className}>
      <s.Modal
        buttonRedirectText={"К профилю"}
        onClickButtonBg={onShowModal}
        onClickButtonIcon={onShowModal}
      >
        <s.Title>
          Подтвердите номер телефона и&nbsp;получите 2&nbsp;роялса <s.Icon/>
        </s.Title>

        <s.Form onSubmit={formik.handleSubmit}>
          <s.Text>Введите номер телефона</s.Text>

          <s.SubText>Отправим на него код подтверждения</s.SubText>

          <s.InputPhone
            label={"Телефон"}
            {...formik.getFieldProps("phone")}
            {...formik.getFieldMeta("phone")}
            {...formik.getFieldHelpers("phone")}
          />

          <s.ButtonCommon
            property={ButtonProperty.SECONDARY}
            text={"Получить код"}
            type={"submit"}
            onClickButton={() => {
            }}
          />
        </s.Form>
      </s.Modal>
    </s.ModalConfirmationPhone>
  );
};
