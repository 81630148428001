import {useState} from "react";

const useScrollToHash = (hash: string) => {
  const [hashState, setHashState] = useState(hash)
  if (hashState) {
    setTimeout(() => {
      const targetElement = document.querySelector(hashState);
      if (targetElement) {
        targetElement.scrollIntoView({behavior: "smooth"});
        setHashState("")
        const currentUrl = window.location.href.split('#')[0];
        window.history.replaceState({}, document.title, currentUrl);
      }
    }, 500);


  }
};

export {useScrollToHash};
