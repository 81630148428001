import styled, { css } from "styled-components";

type TextareaType = {
  $isError?: boolean;
};

const Textarea = styled.textarea<TextareaType>`
  display: block;
  width: 100%;
  max-width: 450px;
  min-height: 170px;
  max-height: 450px;
  border-radius: 10px;
  padding: 23px 20px;
  font: var(--font-16);
  color: var(--text);
  background-color: var(--brand-3);
  border: 1px solid transparent;
  resize: block;

  &::placeholder {
    color: var(--brand-2);
  }

  ${(props) =>
    props.$isError &&
    css`
      color: var(--error);
      background-color: var(--pink);
      border: 1px solid var(--error);

      &::placeholder {
        color: var(--error);
      }
    `};

  &:disabled {
    opacity: 0.6;
    resize: none;
  }
`;

export { Textarea };
