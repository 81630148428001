import React, { FC } from "react";
import * as s from "./Modal-confirmation-successful-styles";

type ModalConfirmationSuccessfulType = {
  className?: string;
  buttonRedirectText: string;
  onShowModal: () => void;
};

export const ModalConfirmationSuccessful: FC<
  ModalConfirmationSuccessfulType
> = ({ className, buttonRedirectText, onShowModal }) => {
  return (
    <s.ModalConfirmationSuccessful className={className}>
      <s.Modal
        buttonRedirectText={buttonRedirectText}
        onClickButtonBg={onShowModal}
        onClickButtonIcon={onShowModal}
      >
        <s.Title>Номер телефона успешно потвержден</s.Title>

        <s.Text>
          Пришлем смс на подтвержденный номер, когда начислим роялсы
        </s.Text>
      </s.Modal>
    </s.ModalConfirmationSuccessful>
  );
};
