import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  getHistoryRoyalsThunk,
  HistoryRoyalsType,
  selectRoyalsHistory,
} from "../../../../entities/discount/model";
import { Animation } from "../../../../shared";
import { useAppDispatch } from "../../../../shared/lib";
import * as s from "./Modal-history-operations-styles";

type ModalHistoryOperationsType = {
  className?: string;
  onShowModal: () => void;
};

export const ModalHistoryOperations: FC<ModalHistoryOperationsType> = ({
  className,
  onShowModal,
}) => {
  const dispatch = useAppDispatch();
  const historyList = useSelector(selectRoyalsHistory);
  const [listMaxLength, setListMaxLength] = useState<number>(5);

  const getBonusText = (sign: string, count: number): string => {
    if (count) return sign + count;
    return count.toString();
  };

  const onChangeListMaxLength = () => {
    setListMaxLength((value) => value + 5);
  };

  useEffect((): void => {
    dispatch(getHistoryRoyalsThunk());
  }, [dispatch]);

  return (
    <s.ModalHistoryOperations className={className}>
      <s.Modal onClickButtonBg={onShowModal} onClickButtonIcon={onShowModal}>
        <s.Title>История операций</s.Title>

        <s.List>
          {historyList
            .slice(0, listMaxLength)
            .map((item: HistoryRoyalsType, index: number) => (
              <s.Item key={index}>
                <Animation isShow={true}>
                  <s.Wrap>
                    <s.Text>{item.title}</s.Text>
                    <s.Royals
                      counter={getBonusText(item.plus_or_minus, item.volume)}
                    />
                  </s.Wrap>
                </Animation>
              </s.Item>
            ))}
        </s.List>

        {listMaxLength < historyList.length ? (
          <s.Button type={"button"} onClick={onChangeListMaxLength}>
            Показать еще
          </s.Button>
        ) : null}
      </s.Modal>
    </s.ModalHistoryOperations>
  );
};
