import React, { ChangeEvent, FC } from "react";
import * as s from "./field-text-formik-styles";
import { FieldInput } from "../../../shared";
import { FieldHelperProps, FieldInputProps, FieldMetaProps } from "formik";

type FieldTextFormikType = FieldMetaProps<any> &
  FieldHelperProps<any> &
  FieldInputProps<any> & {
    className?: string;
    type: "email" | "text" | "number";
    label: string;
    errorResponse?: string;
    onHandelBlurInput?: () => void;
  };

export const FieldTextFormik: FC<FieldTextFormikType> = ({
  className,
  value,
  type,
  name,
  label,
  error,
  errorResponse,
  touched,
  onChange,
  onBlur,
  setValue,
  onHandelBlurInput,
}) => {
  const errorInput = errorResponse
    ? errorResponse
    : touched
    ? errorResponse
      ? errorResponse
      : error
    : "";
  const icon = value ? <s.Icon $isError={!!errorInput} /> : "";

  const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e);
  };
  const onClickButtonIcon = () => {
    setValue("");
  };

  const onBlurInput = (e: ChangeEvent<HTMLInputElement>) => {
    if (!value) {
      onBlur(e);
    }
  };

  return (
    <s.FieldTextFormik className={className}>
      <FieldInput
        type={type}
        value={value}
        name={name}
        label={label}
        icon={icon}
        isFocus={!!value}
        error={errorInput}
        onChangeInput={onChangeInput}
        onClickButtonIcon={onClickButtonIcon}
        onBlurInput={onBlurInput}
        onHandelBlurInput={onHandelBlurInput}
      />
    </s.FieldTextFormik>
  );
};
