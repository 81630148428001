import styled, { css } from "styled-components";
import {
  FieldTextFormik,
  SelectInputCalendarFormik,
  SelectSearchFormik,
} from "../../../features";
import { Input } from "../../../shared/ui/atom/input/Input-styles";
import { breakpoints } from "../../../shared/config";
import { ButtonsTags } from "../../../shared";

type ItemInputType = {
  $isActive?: boolean;
};

const FormPet = styled.div``;

const List = styled.ul`
  display: grid;
  grid-auto-rows: var(--minmax);
  gap: 40px;
  max-width: 678px;
  margin-top: 24px;
  padding: 40px;
  margin-bottom: 24px;
  background-color: var(--background-form);
  border-radius: 20px;

  @media (max-width: ${breakpoints.MD}) {
    padding: 40px 24px;

    gap: 30px;
  }
`;

const Item = styled.li``;

const Title = styled.h4`
  font: var(--font-12);
  color: var(--brand-2-2);
`;

const Text = styled.p`
  font: var(--font-19);
  color: var(--iconography);
  margin-bottom: 12px;
`;

const ButtonsAnimal = styled(ButtonsTags)`
  margin-bottom: 24px;
`;

const ListInputs = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-columns: var(--minmax);
  gap: 12px;
  margin-bottom: 12px;

  @media (max-width: ${breakpoints.MD}) {
    gap: 8px;
  }
`;

const ItemInput = styled.li<ItemInputType>`
  ${(props) =>
    props.$isActive &&
    css`
      position: relative;
      z-index: 1000000000;
    `}
`;

const InputCss = css`
  max-width: var(--input-width);

  ${Input} {
    background-color: var(--brand-4);

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px var(--brand-4) inset;
    }
  }
`;

const InputText = styled(FieldTextFormik)`
  ${InputCss}
`;

const SelectCalendar = styled(SelectInputCalendarFormik)`
  ${InputCss}
`;

const SelectAnimal = styled(SelectSearchFormik)`
  ${InputCss}
`;

export {
  FormPet,
  Title,
  Text,
  List,
  Item,
  ButtonsAnimal,
  ListInputs,
  ItemInput,
  InputText,
  SelectCalendar,
  SelectAnimal,
};
