import {type ActionReducerMapBuilder, createSlice, type PayloadAction,} from "@reduxjs/toolkit";
import {type NoInfer} from "react-redux";
import {initialStateShared as initialState, NotificationType, SharedStateType,} from "../type/shared-type";

const sharedSlice = createSlice({
  name: "shared",
  initialState,
  reducers: {
    setError: (
      state: SharedStateType,
      action: PayloadAction<string | null>
    ) => {
      state.error = action.payload;
    },
    setLoading: (state: SharedStateType, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setNotification: (
      state: SharedStateType,
      action: PayloadAction<NotificationType>
    ) => {
      state.notifications.push(action.payload);
    },
    setDeleteNotification: (
      state: SharedStateType,
      action: PayloadAction<string>
    ) => {
      const id = action.payload;

      state.notifications = state.notifications.filter(
        (notification) => notification.id !== id
      );
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<NoInfer<any>>) => {
  },

  selectors: {
    selectShared: (state) => state,
  },
});

export const {reducer} = sharedSlice;
export const sharedReducer = reducer;
export const {
  setError,
  setLoading,
  setDeleteNotification,
  setNotification,
} = sharedSlice.actions;
export const {selectShared} = sharedSlice.selectors;
