import styled from "styled-components";

const MenuDesktop = styled.div``;

const ListLeft = styled.ul`
  display: flex;
  align-items: center;
  gap: 24px;
`;

const ItemLeft = styled.li``;

export { MenuDesktop, ListLeft, ItemLeft };
