import styled, { css } from "styled-components";
import { FieldPasswordFormik, FieldTextFormik } from "../../../features";
import { Button, InputCheckbox } from "../../../shared";
import { breakpoints } from "../../../shared/config";
import { Input } from "../../../shared/ui/atom/input/Input-styles";

const FormSignIn = styled.form``;

const InputCss = css`
  max-width: var(--input-width);

  ${Input} {
    background-color: var(--brand-4);

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px var(--brand-4) inset;
    }
  }
`;

const SubTitle = styled.h3`
  margin-bottom: 24px;
  font: var(--font-9);
  color: var(--interface-dark);
`;

const InputText = styled(FieldTextFormik)`
  ${InputCss};
`;

const InputPassword = styled(FieldPasswordFormik)`
  margin-top: 12px;
  margin-bottom: 24px;
  ${InputCss};

  @media (max-width: ${breakpoints.MD}) {
    margin-bottom: 16px;
  }

  @media (max-width: ${breakpoints.SM}) {
    margin-top: 8px;
    margin-bottom: 12px;
  }
`;

const InputCheck = styled(InputCheckbox)`
  align-items: center;
`;

const ButtonSignIn = styled(Button)`
  max-width: 450px;
  margin-top: 40px;

  @media (max-width: ${breakpoints.LG}) {
    width: 100%;
  }

  @media (max-width: ${breakpoints.SM}) {
    margin-top: 32px;
  }
`;

export {
  FormSignIn,
  SubTitle,
  ButtonSignIn,
  InputText,
  InputPassword,
  InputCheck,
};
