import React, { FC, ReactNode } from "react";
import * as s from "./Button-tag-styles";
import { ButtonTagProperty } from "../../../config";

export type ButtonTagType = {
  className?: string;
  property:
    | ButtonTagProperty.DEFAULT
    | ButtonTagProperty.SELECTED
    | ButtonTagProperty.ERROR;
  text: string;
  isActive?: boolean;
  type: "button" | "reset" | "submit";
  disabled?: boolean;
  icon: ReactNode;
  onClickButtonTag: () => void;
};

export const ButtonTag: FC<ButtonTagType> = ({
  className,
  property,
  text,
  type,
  isActive,
  disabled,
  icon,
  onClickButtonTag,
}) => {
  return (
    <s.ButtonTag
      className={className}
      onClick={disabled ? () => {} : onClickButtonTag}
      type={type}
      property={property}
      disabled={disabled}
      $isActive={isActive}
    >
      {icon}
      {text}
    </s.ButtonTag>
  );
};
