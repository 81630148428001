import styled from "styled-components";
import { IconRoyals } from "../_icons";

const Span = styled.span``;

const CounterRoyals = styled.p`
  display: flex;
  align-items: center;
  gap: 4px;
  font: var(--font-15);
  color: var(--text);
`;

const Icon = styled(IconRoyals)`
  width: 32px;
  height: 32px;
  transform: translateY(-1px);
`;

export { CounterRoyals, Span, Icon };
