import React, { FC } from "react";
import * as s from "./Menu-personal-area-mobile-styles";
import { IconPromoSmall, IconReceipt, IconSetting, IconUser } from "../../atom";
import { sd } from "../../../index";
import {Container, pages} from "../../../config";

type MenuPersonalAreaMobileType = {
  className?: string;
};

export const MenuPersonalAreaMobile: FC<MenuPersonalAreaMobileType> = ({
  className,
}) => {
  return (
    <s.MenuPersonalAreaMobile className={className}>
      <Container>
        <s.List>
          <s.Item>
            <s.Link to={pages.personalAreaProfile.link}>
              <IconUser width={40} height={40} />
              Профиль
            </s.Link>
          </s.Item>

          <s.Item>
            <s.Link to={pages.personalAreaChecks.link}>
              <IconReceipt width={40} height={40} />
              Чеки
            </s.Link>
          </s.Item>

          <s.Item>
            <s.Link to={pages.personalAreaPromoCode.link}>
              <IconPromoSmall width={40} height={40} />
              Промокоды
            </s.Link>
          </s.Item>

          <s.Item>
            <s.Link to={pages.faqAnswers.link}>
              <IconSetting width={40} height={40} />
              Помощь
            </s.Link>
          </s.Item>
        </s.List>
      </Container>
    </s.MenuPersonalAreaMobile>
  );
};
