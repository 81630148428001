import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { MenuPersonalAreaMobile } from "../../../shared";
import { breakpoints, Container } from "../../../shared/config";

const FaqAnswers = styled.section`
  padding-bottom: 145px;

  @media (max-width: ${breakpoints.XL}) {
    padding-bottom: 116px;
  }
`;

const FaqAnswersContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const LinkCss = css`
  font: var(--font-16);
  color: var(--text);
  transition: color 300ms ease, transform 300ms ease;

  &:hover {
    color: var(--hyperlink);
  }

  &:active {
    transform: scale(0.98);
  }
`;

const LinkDefault = styled.a`
  display: flex;
  align-items: center;
  gap: 4px;
  ${LinkCss};
`;

const LinkRouter = styled(Link)`
  display: block;
  ${LinkCss};
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  gap: 4px;
  ${LinkCss};
`;

const Content = styled.div`
  flex: 1 1 auto;
  padding-bottom: 80px;

  @media (max-width: ${breakpoints.XL}) {
    padding-top: 4px;
  }

  @media (max-width: ${breakpoints.LG}) {
    padding-top: 28px;
  }

  @media (max-width: ${breakpoints.MD}) {
    padding-bottom: 60px;
  }
`;

const Menu = styled(MenuPersonalAreaMobile)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
`;

export {
  FaqAnswers,
  FaqAnswersContainer,
  LinkDefault,
  LinkRouter,
  Button,
  Content,
  Menu,
};
