import React, { FC } from "react";

export const IconRub: FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox={"0 0 16 16"}
    {...props}
  >
    <path d="M12.618 1.678c.924.895 1.386 2.031 1.386 3.41s-.462 2.515-1.386 3.41c-.91.88-2.12 1.32-3.63 1.32H5.424V16H3.048V.336h5.94c1.51 0 2.72.447 3.63 1.342ZM10.88 7.024c.499-.47.748-1.115.748-1.936s-.25-1.46-.748-1.914c-.499-.47-1.173-.704-2.024-.704H5.424v5.236h3.432c.85 0 1.525-.227 2.024-.682Z" />
    <path d="M1.002 11h7v2h-7v-2Z" />
  </svg>
);
