import React, { FC } from "react";

export const IconExit: FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox={"0 0 24 24"}
    {...props}
  >
    <path
      fill="currentColor"
      d="M14.819 5.045H6.373a.497.497 0 0 0-.497.497v12.916c0 .275.223.497.497.497h8.445a.497.497 0 0 0 .497-.497v-.497a.497.497 0 0 1 .994 0v.994a.994.994 0 0 1-.994.994H5.876a.994.994 0 0 1-.993-.994V5.045c0-.549.445-.994.993-.994h9.44c.548 0 .993.445.993.994v1.242a.497.497 0 1 1-.994 0v-.745a.497.497 0 0 0-.496-.497Z"
    />
    <path
      fill="currentColor"
      d="M10.345 12h8.943v.994h-8.943a.497.497 0 0 1 0-.994Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M19.44 13.323a.994.994 0 0 0 0-1.653l-2.36-1.574a.497.497 0 1 0-.551.827l2.36 1.574-2.36 1.574a.497.497 0 1 0 .55.826l2.361-1.574Z"
      clipRule="evenodd"
    />
  </svg>
);
