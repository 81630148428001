import styled, { css } from "styled-components";
import { breakpoints } from "../../../config";

export type InputCodeType = {
  $isError?: boolean;
  $isFocus?: boolean;
  $disabled?: boolean;
};

const InputCode = styled.input<InputCodeType>`
  width: 100%;
  padding: 17px 50px 17px 30px;
  font: var(--font-3);
  color: var(--hyperlink);
  background-color: var(--brand-4);
  border-radius: 20px;
  border: 1px solid transparent;
  transition: color 300ms ease, border 300ms ease;

  ${(props) =>
    props.$isFocus &&
    css`
      border: 1px solid var(--brand-2) !important;
    `};

  ${(props) =>
    props.$isError &&
    css`
      color: var(--error) !important;
      border: 1px solid var(--error) !important;
    `};

  ${(props) =>
    props.$disabled &&
    css`
      pointer-events: none;
      opacity: 0.6;
    `};

  &::placeholder {
    color: var(--interface);
  }

  &:hover {
    border: 1px solid var(--brand-2);
  }

  &:focus {
    outline: 1px solid var(--brand-2);
  }

  @media (max-width: ${breakpoints.XL}) {
  }
`;

export {InputCode};
