import { FormikProps } from "formik";
import React, { FC, MouseEvent } from "react";
import { InitialValuesFormikSignInType } from "../../../pages";
import { ButtonProperty } from "../../../shared/config";
import * as s from "./Form-signin-styles";

type FormSignInType = {
  className?: string;
  formik: FormikProps<InitialValuesFormikSignInType>;
};

export const FormSignInUser: FC<FormSignInType> = ({ className, formik }) => {
  const onChangeRememberMe = (e: MouseEvent<HTMLButtonElement>) => {
    const element = e.target as HTMLElement;
    if (element.nodeName !== "A") {
      formik.setFieldValue(
        "isRemember",
        (formik.values.isRemember = !formik.values.isRemember)
      );
    }
  };

  return (
    <s.FormSignIn className={className} onSubmit={formik.handleSubmit}>
      <s.SubTitle>Введите данные</s.SubTitle>

      <s.InputText
        type={"text"}
        label={"E-mail"}
        {...formik.getFieldProps("email")}
        {...formik.getFieldMeta("email")}
        {...formik.getFieldHelpers("email")}
      />

      <s.InputPassword
        label={"Пароль"}
        {...formik.getFieldProps("password")}
        {...formik.getFieldMeta("password")}
        {...formik.getFieldHelpers("password")}
      />

      <s.InputCheck
        className={"input-checkbox"}
        label={`Запомнить меня`}
        checked={formik.values.isRemember}
        onClickInputChecked={onChangeRememberMe}
      />

      <s.ButtonSignIn
        property={ButtonProperty.SECONDARY}
        text={"Войти"}
        type={"submit"}
        onClickButton={() => {}}
      />
    </s.FormSignIn>
  );
};
