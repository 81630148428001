import React, { FC } from "react";
import * as s from "./Modal-promo-code-type-styles";
import { PromoCodeDescriptionType } from "../../../../entities/discount/model";
import { ButtonProperty } from "../../../../shared/config";
import { useChangeLetter } from "../../../../shared/lib";

type ModalPromoCodeType = {
  className?: string;
  title?: string;
  text: string;
  counter: string;
  isDisabledButton?: boolean;
  isButton: boolean;
  list: PromoCodeDescriptionType;
  onShowModal: () => void;
  isRoyals?: boolean;
  onClickButton?: () => void;
};

export const ModalPromoCodeType: FC<ModalPromoCodeType> = ({
  className,
  title,
  text,
  counter,
  isDisabledButton,
  isButton,
  list,
  onShowModal,
  isRoyals = true,
  onClickButton,
}) => {
  const titles = Object.keys(list);

  return (
    <s.ModalPromoCodeType className={className}>
      <s.Modal onClickButtonBg={onShowModal} onClickButtonIcon={onShowModal}>
        <s.Wrap>
          {isRoyals && <s.Royals counter={counter} />}
          <s.Title>{title}</s.Title>
        </s.Wrap>

        <s.Text>
          {useChangeLetter({
            text: text,
            icon: <s.RubleIcon />,
            targetChar: "₽",
          })}
        </s.Text>

        <s.List>
          {titles.map((title, index) => (
            <s.Item key={index}>
              <s.SubTitle>{title}</s.SubTitle>

              {Array.isArray(list[title]) && list[title].length > 1 ? (
                <s.SubList>
                  {(list[title] as string[]).map((text: string) => (
                    <s.SubItem key={text}>
                      <s.SubText>{text}</s.SubText>
                    </s.SubItem>
                  ))}
                </s.SubList>
              ) : (
                <s.SubText>{list[title]}</s.SubText>
              )}
            </s.Item>
          ))}
        </s.List>

        {isButton && (
          <s.ButtonModal
            text={"Забрать промокод"}
            property={ButtonProperty.SECONDARY}
            type={"button"}
            disabled={!isDisabledButton}
            onClickButton={
              !isDisabledButton
                ? () => {}
                : onClickButton
                ? onClickButton
                : () => {}
            }
          />
        )}
      </s.Modal>
    </s.ModalPromoCodeType>
  );
};
