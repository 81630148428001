import React, { FC } from "react";

export const IconCatShape: FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox={"0 0 80 80"}
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M3.053 17.771C4.531 11.329 6.301 6.907 6.301 6.907c3.537-9.531 17.806 19.77 17.806 19.77s5.76-1.332 12.766-.64c5.663.559 10.444 2.509 10.444 2.509s7.194-16.06 11.177-14.955c1.495.415 2.875 1.976 4.004 4.317 7.313 11.933 6.27 60.135-29.784 57.891C-7.844 73.274-.826 32.483 3.054 17.77v.002Z"
      clipRule="evenodd"
    />
  </svg>
);
