import * as React from "react";
import { FC } from "react";
import * as s from "./Faq-answers-styles";
import { Header } from "../../../widgets";
import { Faq, Footer, IconDoc, IconExit } from "../../../shared";
import {
  useAppDispatch,
  useAppSelector,
  useIsMobile,
} from "../../../shared/lib";
import { setModals } from "../../../widgets/modals/model";
import { breakpointsTs, ModalsProperty, pages } from "../../../shared/config";
import { esAuth } from "../../../entities";
import { useNavigate } from "react-router-dom";
import { useScrollToTop } from "../../../shared/lib/hooks/use-scroll-to-top";

type FaqAnswersType = {};

export const FaqAnswers: FC<FaqAnswersType> = ({}) => {
  useScrollToTop();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isMobile] = useIsMobile(breakpointsTs.LG);
  const { isAuthorization } = useAppSelector(esAuth.model.selectAuth);

  const onRedirect = () => {
    if (isAuthorization) {
      dispatch(setModals({ action: ModalsProperty.MODAL_EXIT }));
    } else {
      navigate(pages.signIn.link);
    }
  };
  const onOpenModalQuestion = () => {
    dispatch(setModals({ action: ModalsProperty.MODAL_QUESTION }));
  };

  const onOpenModalQuestionFrequently = (title: string) => () => {
    dispatch(
      setModals({
        action: ModalsProperty.MODAL_FREQUENTLY_ASKED_QUESTION_QUESTION,
        content: title,
      })
    );
  };

  return (
    <s.FaqAnswers>
      <Header
        isMobile={false}
        listLeft={[<s.LinkRouter to={pages.main.link}>Главная</s.LinkRouter>]}
        listRight={[
          <s.LinkDefault
            href={
              "https://xn----ctbfggjf0anaeki8a4dxd.xn--p1ai/media/rules/Правила_акции_Здоровый_питомец__2024.pdf"
            }
            target={"_blank"}
          >
            <IconDoc width={24} height={24} />
            {isMobile && "Полные правила акции"}
          </s.LinkDefault>,

          <s.Button type={"button"} onClick={onRedirect}>
            <IconExit width={24} height={24} />{" "}
            {isMobile && (isAuthorization ? "Выйти" : "Войти")}
          </s.Button>,
        ]}
      />

      <s.FaqAnswersContainer>
        <s.Content>
          <Faq onClickButton={onOpenModalQuestionFrequently} />
        </s.Content>

        <Footer
          links={[
            {
              title: "Корпоративная политика",
              items: [
                {
                  text: "Правила использования сайта",
                  href: "https://www.mars.com/global/policies/legal/ld-russian",
                },

                {
                  text: "Конфиденциальность",
                  href: "https://www.mars.com/global/policies/privacy/pp-russian",
                },

                {
                  text: "Доступность",
                  href: "https://www.mars.com/accessibility-russian",
                },

                {
                  text: "Cookies",
                  href: "https://www.mars.com/cookies-russia",
                },
              ],
            },

            {
              title: "Об акции",
              items: [
                {
                  text: "Пользовательское соглашение",
                  href: "https://www.royalcanin.com/ru/about-us/terms-and-conditions",
                },

                {
                  text: "Правила акции",
                  href: "https://xn----ctbfggjf0anaeki8a4dxd.xn--p1ai/media/rules/Правила_акции_Здоровый_питомец__2024.pdf",
                },
              ],
            },
          ]}
          onClickButton={onOpenModalQuestion}
        />
      </s.FaqAnswersContainer>

      <s.Menu />
    </s.FaqAnswers>
  );
};
