import styled, { css } from "styled-components";
import { FieldTextFormik } from "../../../features";
import { Button } from "../../../shared";
import { breakpoints } from "../../../shared/config";
import { Input } from "../../../shared/ui/atom/input/Input-styles";

const RecoverPasswordEmail = styled.section`
  max-width: 467px;
`;

const InputCss = css`
  max-width: var(--input-width);

  ${Input} {
    background-color: var(--brand-4);

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px var(--brand-4) inset;
    }
  }
`;

const InputText = styled(FieldTextFormik)`
  ${InputCss};
`;

const Title = styled.h2`
  margin-bottom: 40px;
  font: var(--font-3);
  color: var(--brand-1);

  @media (max-width: ${breakpoints.MD}) {
    margin-bottom: 32px;
  }
`;

const Subtitle = styled.p`
  font: var(--font-9);
  color: var(--interface-dark);
  margin-bottom: 10px;

  @media (max-width: ${breakpoints.SM}) {
    margin-bottom: 8px;
  }
`;

const InfoText = styled.p`
  font: var(--font-16);
  color: var(--text);
  letter-spacing: -0.36px;
  margin-bottom: 24px;
`;

const ButtonRecoverCode = styled(Button)`
  max-width: 100%;
  margin-top: 40px;
  margin-bottom: 24px;

  @media (max-width: ${breakpoints.SM}) {
    margin-top: 32px;
    margin-bottom: 20px;
  }
`;

const FormRecoverPasswordEmail = styled.form`
  max-width: 450px;

  @media (max-width: ${breakpoints.XL}) {
    max-width: 400px;
  }

  @media (max-width: ${breakpoints.LG}) {
    max-width: 380px;
  }
`;

const ToRegistration = styled.a`
  font: var(--font-19);
  color: var(--brand-1);

  & span {
    font: var(--font-17);
  }
`;

export {
  RecoverPasswordEmail,
  InputText,
  Title,
  Subtitle,
  InfoText,
  ButtonRecoverCode,
  FormRecoverPasswordEmail,
  ToRegistration,
};
