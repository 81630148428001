import React, { FC } from "react";
import * as s from "./Buttons-dropdown-styles";
import { Animation, ButtonDropdown } from "../../atom";

export type ButtonsDropdownItemType = {
  id: string;
  value: string;
};

type ButtonsDropdownType = {
  className?: string;
  list: Array<ButtonsDropdownItemType>;
  onClickButtonItem: (id: string, value: string) => () => void;
  isIOS?: boolean;
};

export const ButtonsDropdown: FC<ButtonsDropdownType> = ({
  className,
  list,
  onClickButtonItem,
  isIOS,
}) => {
  return (
    <s.ButtonsDropdown className={className}>
      <s.List>
        {list.map((item, index) => (
          <s.Item key={item.value + item.id}>
            <ButtonDropdown
              type={"button"}
              text={item.value}
              isIOS={isIOS}
              onClickButtonDropdown={onClickButtonItem(item.id, item.value)}
            />
          </s.Item>
        ))}
      </s.List>

      <Animation isShow={list.length === 0}>
        <s.Text>Совпадений не найдено</s.Text>
      </Animation>
    </s.ButtonsDropdown>
  );
};
