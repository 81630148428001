import { useEffect, useState } from "react";

export const useIsMobile = (breakpoint: number) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth >= breakpoint);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth >= breakpoint);
    };

    setIsMobile(window.innerWidth >= breakpoint);

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return [isMobile] as const;
};
