import React, { FC, ReactNode } from "react";
import * as s from "./Card-promo-code-styles";
import { StatusPromoCode } from "../../../config";
import { Animation } from "../../atom";

type CardPromoCodeType = {
  className?: string;
  text: ReactNode;
  image?: string;
  counterRoyals: string;
  statusPromoCode: StatusPromoCode.DEFAULT | StatusPromoCode.INVALID;
  isBigBgImage?: boolean;
  isRoyals?: boolean;
  onClickButton?: () => void;
};

export const CardPromoCode: FC<CardPromoCodeType> = ({
  className,
  text,
  image,
  counterRoyals,
  statusPromoCode,
  isBigBgImage,
  isRoyals = true,
  onClickButton,
}) => {
  return (
    <s.CardPromoCode
      className={className}
      $status={statusPromoCode}
      onClick={onClickButton}
      $isBigBgImage={isBigBgImage}
    >
      <s.Button type={"button"} onClick={onClickButton} />
      <s.BgImage src={image ? image : ""} alt={""} />

      <Animation isShow={statusPromoCode !== StatusPromoCode.DEFAULT}>
        <s.Bg>
          <s.Tag>
            {statusPromoCode === StatusPromoCode.INVALID ? "недоступен" : ""}
          </s.Tag>
        </s.Bg>
      </Animation>

      {isRoyals && <s.Royals counter={counterRoyals} />}

      <s.Text>{text}</s.Text>
    </s.CardPromoCode>
  );
};
