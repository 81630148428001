import styled from "styled-components";
import { Button, ModalBody } from "../../../../shared";
import { BodyAnimation } from "../../../../shared/ui/molecules/modal-body/Modal-body-styles";
import { breakpoints } from "../../../../shared/config";

const ModalSuccessfullyRegistered = styled.div``;

const Modal = styled(ModalBody)`
  ${BodyAnimation} {
    max-width: 620px;
  }

  @media (max-width: ${breakpoints.LG}) {
    ${BodyAnimation} {
      max-width: 100%;
    }
  }
`;

const Title = styled.h2`
  max-width: 486px;
  margin-bottom: 16px;
  font: var(--font-3);
  color: var(--interface-dark);

  @media (max-width: ${breakpoints.LG}) {
    max-width: unset;
  }
`;

const Text = styled.p`
  max-width: 310px;
  margin-bottom: 40px;
  font: var(--font-12);
  color: var(--brand-2);

  @media (max-width: ${breakpoints.XL}) {
    margin-bottom: 32px;
  }
`;

const ButtonCommon = styled(Button)`
  max-width: 400px;
`;

export { ModalSuccessfullyRegistered, Modal, Title, Text, ButtonCommon };
