import React, { FC } from "react";

export const IconEyeClosed: FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox={"0 0 24 24"}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.752 8.92a2.826 2.826 0 0 0-2.46 4.23l3.859-3.86a2.8 2.8 0 0 0-1.4-.37Zm-4.33 2.83a4.326 4.326 0 0 1 7.39-3.06.75.75 0 0 1 0 1.06l-5.06 5.06a.75.75 0 0 1-1.06 0 4.309 4.309 0 0 1-1.27-3.06Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2.007 8.757C4.398 4.999 7.902 2.73 11.75 2.73c2.25 0 4.405.784 6.271 2.191a.75.75 0 0 1-.903 1.198C15.484 4.886 13.64 4.23 11.75 4.23c-3.212 0-6.288 1.891-8.477 5.333h-.001c-.355.556-.557 1.351-.557 2.192 0 .84.202 1.636.557 2.191v.001c.754 1.183 1.627 2.196 2.567 3.004a.75.75 0 1 1-.978 1.138c-1.06-.912-2.027-2.04-2.854-3.336-.545-.854-.792-1.949-.792-2.998 0-1.049.247-2.144.792-2.998ZM19.328 7.096a.75.75 0 0 1 1.055.114c.384.477.76.99 1.108 1.538.545.854.792 1.948.792 2.997s-.247 2.144-.792 2.998c-2.391 3.758-5.895 6.027-9.743 6.027a9.964 9.964 0 0 1-3.87-.799.75.75 0 1 1 .581-1.382 8.466 8.466 0 0 0 3.29.681c3.211 0 6.287-1.891 8.476-5.333h.001c.355-.556.557-1.351.557-2.192 0-.84-.202-1.636-.557-2.191l-.001-.002c-.312-.492-.655-.96-1.011-1.402a.75.75 0 0 1 .114-1.054Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15.394 11.712a.75.75 0 0 1 .601.874 4.315 4.315 0 0 1-3.421 3.422.75.75 0 0 1-.272-1.476 2.815 2.815 0 0 0 2.218-2.218.75.75 0 0 1 .874-.602ZM9.75 13.75a.75.75 0 0 1 0 1.06l-7.47 7.47a.75.75 0 0 1-1.06-1.06l7.47-7.47a.75.75 0 0 1 1.06 0ZM22.282 1.22a.75.75 0 0 1 0 1.06l-7.47 7.47a.75.75 0 0 1-1.061-1.06l7.47-7.47a.75.75 0 0 1 1.06 0Z"
      clipRule="evenodd"
    />
  </svg>
);
