import styled from "styled-components";
import {breakpoints} from "../../../config";

const CardWallet = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  max-width: 430px;
  height: 100%;
  padding: 20px 20px 24px;
  border-radius: 20px;
  background-color: var(--brand-4);

  @media (max-width: ${breakpoints.XXL}) {
    padding: 20px 20px 24px;
    border-radius: 24px;
  }

  @media (max-width: ${breakpoints.XL}) {
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
    padding: 10px 32px 20px;
  }

  @media (max-width: ${breakpoints.LG}) {
    flex-direction: row;
    gap: 12px;
    padding: 18px;
  }
`;

const WrapIcon = styled.div`
  width: 64px;
  height: 64px;
  color: var(--brand-1);

  @media (max-width: ${breakpoints.XL}) {
    width: 40px;
    height: 40px;

    & svg {
      width: 40px;
      height: 40px;
    }
  }

  @media (max-width: ${breakpoints.LG}) {
    width: 56px;
    height: 56px;

    & svg {
      width: 56px;
      height: 56px;
    }
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;

  @media (max-width: ${breakpoints.XL}) {
    gap: 4px;
  }

  @media (max-width: ${breakpoints.LG}) {
    align-self: center;
  }
`;

const Title = styled.h2`
  font: var(--font-6);
  color: var(--interface-dark);
  text-align: left;
  letter-spacing: -0.56px;
`;

const Text = styled.p`
  font: var(--font-19);
  color: var(--brand-2);
`;

export {CardWallet, WrapIcon, Text, Content, Title};
