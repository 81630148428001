import React, {FC, ReactNode} from "react";
import * as s from "./Modal-body-right-styles";
import {IconClose} from "../../atom";
import {useIsMobile} from "../../../lib";
import {breakpointsTs} from "../../../config";

type ModalBodyRightType = {
  className?: string;
  children: ReactNode;
  isStatus?: boolean;
  onClickButtonBg: () => void;
  onClickButtonIcon: () => void;
};

export const ModalBodyRight: FC<ModalBodyRightType> = (
  {
    className,
    children,
    isStatus,
    onClickButtonBg,
    onClickButtonIcon,
  }) => {
  const [isMobile] = useIsMobile(breakpointsTs.LG);
  const variants = isMobile
    ? {
      initial: {x: 200, opacity: 0},
      animate: {x: 0, opacity: 1},
      exit: {x: 200, opacity: 0},
    }
    : {
      initial: {scale: 0.8, transformOrigin: "bottom center", opacity: 0},
      animate: {scale: 1, opacity: 1},
      exit: {scale: 0.8, opacity: 0},
    };
  return (
    <s.ModalBodyRight className={className} $isStatus={isStatus}>
      <s.ButtonBg type={"button"} onClick={onClickButtonBg}/>

      <s.BodyAnimation isShow={true} delay={0.2} variants={variants}>
        <s.ButtonIconBody
          type={"button"}
          icon={<IconClose width={24} height={24}/>}
          onClickButtonIcon={onClickButtonIcon}
        />

        {children}
      </s.BodyAnimation>
    </s.ModalBodyRight>
  );
};
