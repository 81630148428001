import styled from "styled-components";
import { Button, PopupBody } from "../../../../shared";
import { BodyAnimation } from "../../../../shared/ui/molecules/popup-body/Popup-body-styles";
import { breakpoints } from "../../../../shared/config";

const ModalExit = styled.div``;

const Modal = styled(PopupBody)`
  ${BodyAnimation} {
    padding: 50px 80px 40px 50px;
    max-width: 580px;
  }

  @media (max-width: ${breakpoints.LG}) {
    ${BodyAnimation} {
      max-width: 100%;
    }
  }

  @media (max-width: ${breakpoints.LG}) {
    ${BodyAnimation} {
      padding: 56px 76px 70px 56px;
    }
  }

  @media (max-width: ${breakpoints.MD}) {
    ${BodyAnimation} {
      padding: 56px 20px 32px 20px;
    }
  }
`;

const Title = styled.h2`
  margin-bottom: 16px;
  font: var(--font-3);
  color: var(--interface-dark);
`;

const Text = styled.p`
  font: var(--font-11);
  color: var(--brand-2);
  margin-bottom: 40px;

  @media (max-width: ${breakpoints.MD}) {
    margin-bottom: 32px;
  }
`;

const WrapButtons = styled.div`
  max-width: 450px;

  @media (max-width: ${breakpoints.LG}) {
    max-width: 100%;
  }
`;

const ButtonExit = styled(Button)`
  margin-bottom: 10px;
  max-width: 100%;
`;

const ButtonNoExit = styled(Button)`
  max-width: 100%;
`;

export { ModalExit, Modal, Title, Text, WrapButtons, ButtonExit, ButtonNoExit };
