import styled, { css } from "styled-components";
import { IconRoyals } from "../_icons";
import { breakpoints } from "../../../config";

type CardRoyalsType = {
  $isFlipped?: boolean;
};

const BodyStyle = css`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 32px;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  overflow: hidden;
  will-change: transform;
  transition: transform 500ms ease;

  @media (max-width: ${breakpoints.MD}) {
    position: relative;
  }
`;

const Body = styled.div`
  ${BodyStyle};
  transform: perspective(600px) rotateY(0);
  padding: 24px;
  background-color: var(--pink);

  @media (max-width: ${breakpoints.XXL}) {
    padding-right: 30px;
    padding-bottom: 40px;
  }

  @media (max-width: ${breakpoints.LG}) {
    padding: 24px 32px 30px;
  }

  @media (max-width: ${breakpoints.MD}) {
    min-height: 168px;
    padding: 24px;
  }

  & svg {
    z-index: -5;
  }
`;

const BodyFlipped = styled.div`
  ${BodyStyle};
  transform: perspective(600px) rotateY(180deg);
  padding: 15px 10px 24px 19px;
  background-color: var(--brand-1);
`;

const CardRoyals = styled.article<CardRoyalsType>`
  display: block;
  position: relative;
  width: 100%;
  max-width: 312px;
  min-height: 320px;
  border-radius: 32px;
  z-index: 100;
  cursor: pointer;

  @media (max-width: ${breakpoints.XL}) {
    max-width: none;
    min-height: 213px;
  }

  @media (max-width: ${breakpoints.MD}) {
    min-height: 168px;
  }

  &:hover {
    ${(props) =>
      props.$isFlipped &&
      css`
        ${Body} {
          transform: perspective(600px) rotateY(-180deg);
        }

        ${BodyFlipped} {
          transform: perspective(600px) rotateY(0);
        }
      `}
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const Title = styled.h3`
  font: var(--font-6);
  letter-spacing: -0.56px;
  color: var(--text);
  margin-top: 144px;
  text-align: start;

  @media (max-width: ${breakpoints.XXL}) {
    margin-top: 144px;
  }

  @media (max-width: ${breakpoints.XL}) {
    max-width: 88%;
    margin-top: 72px;
  }

  @media (max-width: ${breakpoints.LG}) {
    max-width: 74%;
    margin-top: 56px;
  }

  @media (max-width: ${breakpoints.MD}) {
    max-width: 80%;
    margin-top: 62px;
  }
`;

const Text = styled.p`
  margin-top: 8px;
  font: var(--font-16);
  color: var(--brand-2);
`;

const WrapCaption = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;
  display: flex;
  align-items: center;
  gap: 4px;

  @media (max-width: ${breakpoints.LG}) {
    left: 32px;
  }

  @media (max-width: ${breakpoints.MD}) {
    left: auto;
  }
`;

const Caption = styled.div`
  font: var(--font-6);
  color: var(--brand-1);
`;

const Icon = styled(IconRoyals)`
  width: 40px;
  height: 40px;
`;

const List = styled.ol`
  position: relative;
  display: grid;
  align-content: center;
  height: 100%;
  grid-auto-rows: var(--minmax);
  gap: 10px;
  z-index: 2;
`;

const ItemCss = css`
  display: flex;
  gap: 5px;
  font: var(--font-15);
  color: var(--brand-3);
`;

const Item = styled.li`
  ${ItemCss};
`;

const WrapItem = styled.div``;

const Span = styled.span``;

const SubList = styled.ul``;

const SubItem = styled.li`
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translate(-50%);
    left: -10px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: currentColor;
  }
`;

export {
  CardRoyals,
  Body,
  Content,
  Title,
  Text,
  WrapCaption,
  Caption,
  Icon,
  BodyFlipped,
  List,
  Item,
  WrapItem,
  Span,
  SubList,
  SubItem,
};
