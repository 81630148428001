import React, { FC, MouseEvent, ReactNode } from "react";
import * as s from "./Field-checkbox-styles";
import { Animation, InputCheckbox } from "../../atom";

type FieldCheckboxType = {
  className?: string;
  checked: boolean;
  label: ReactNode;
  error?: string;
  onClickInputChecked: (e: MouseEvent<HTMLButtonElement>) => void;
};

export const FieldCheckbox: FC<FieldCheckboxType> = ({
  className,
  checked,
  label,
  error,
  onClickInputChecked,
}) => {
  return (
    <s.FieldCheckbox className={className}>
      <InputCheckbox
        checked={checked}
        label={label}
        onClickInputChecked={onClickInputChecked}
      />

      <Animation isShow={!!error}>
        <s.Error>{error}</s.Error>
      </Animation>
    </s.FieldCheckbox>
  );
};
