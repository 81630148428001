import React, { FC } from "react";
import * as s from "./Calendar-styles";
import { Animation, ButtonTime, IconClose } from "../../atom";

export type CalendarListDateType = {
  monthName: string;
  monthNumber: string;
  disabled: boolean;
};

export type CalendarType = {
  className?: string;
  textButtonMonth: string;
  textButtonYear: string;
  typeButton: "button" | "reset" | "submit";
  isActiveButtonMonth: boolean;
  isActiveButtonYear: boolean;
  listMonth: Array<CalendarListDateType>;
  listYear: Array<number>;
  setIsActiveButtonDate: (text: string, textButton: string) => boolean;
  onClickButtonTimeMonth: () => void;
  onClickButtonTimeYear: () => void;
  onClickButtonIconClose: () => void;
  onClickButtonDateMonth: (date: string, text: string) => () => void;
  onClickButtonDateYear: (text: string) => () => void;
};

export const Calendar: FC<CalendarType> = ({
  className,
  typeButton,
  textButtonMonth,
  textButtonYear,
  isActiveButtonMonth,
  isActiveButtonYear,
  listMonth,
  listYear,
  setIsActiveButtonDate,
  onClickButtonTimeMonth,
  onClickButtonTimeYear,
  onClickButtonIconClose,
  onClickButtonDateMonth,
  onClickButtonDateYear,
}) => {
  return (
    <s.Calendar className={className}>
      <s.Title>Календарь</s.Title>

      <s.Header>
        <s.WrapButton>
          <ButtonTime
            text={textButtonMonth}
            type={typeButton}
            isActive={isActiveButtonMonth}
            onClickButtonTime={onClickButtonTimeMonth}
          />

          <ButtonTime
            text={textButtonYear}
            type={typeButton}
            isActive={isActiveButtonYear}
            onClickButtonTime={onClickButtonTimeYear}
          />
        </s.WrapButton>

        <s.ButtonIcon
          dataTestid={"button-icon-close"}
          icon={<IconClose />}
          type={"button"}
          onClickButtonIcon={onClickButtonIconClose}
        />
      </s.Header>

      <Animation isShow={isActiveButtonMonth}>
        <s.ContentMonth>
          <s.ListMonth data-testid={"list-month-test"}>
            {listMonth.map((el) => (
              <s.ItemMonth key={el.monthName + el.monthNumber}>
                <s.ButtonDate
                  type={"button"}
                  $isActive={setIsActiveButtonDate(
                    el.monthName,
                    textButtonMonth
                  )}
                  disabled={el.disabled}
                  onClick={
                    el.disabled
                      ? () => {}
                      : onClickButtonDateMonth(el.monthNumber, el.monthName)
                  }
                >
                  {el.monthName}
                </s.ButtonDate>
              </s.ItemMonth>
            ))}
          </s.ListMonth>
        </s.ContentMonth>
      </Animation>

      <Animation isShow={isActiveButtonYear}>
        <s.ContentYear>
          <s.ListYear data-testid={"list-year-test"}>
            {listYear.map((year) => (
              <s.ItemYear key={year}>
                <s.ButtonDate
                  type={"button"}
                  $isActive={setIsActiveButtonDate(
                    String(year),
                    textButtonYear
                  )}
                  onClick={onClickButtonDateYear(String(year))}
                >
                  {year}
                </s.ButtonDate>
              </s.ItemYear>
            ))}
          </s.ListYear>
        </s.ContentYear>
      </Animation>
    </s.Calendar>
  );
};
