import React, { FC, ReactNode } from "react";
import * as s from "./Card-promo-styles";
import { StatusProperty } from "../../../config";
import { IconInfo } from "../../atom";

type CardPromoType = {
  className?: string;
  text: string | ReactNode;
  textStatusPromo: string;
  statusPromo:
    | StatusProperty.DEFAULT
    | StatusProperty.IN_PROCESS
    | StatusProperty.SUCCESS
    | StatusProperty.COMMON;
  valueInput: string;
  onClickInput: () => void;
  onClickButtonIcon: () => void;
  onClickButtonPromoIcon: () => void;
};

export const CardPromo: FC<CardPromoType> = ({
  className,
  text,
  textStatusPromo,
  statusPromo,
  valueInput,
  onClickInput,
  onClickButtonIcon,
  onClickButtonPromoIcon,
}) => {
  return (
    <s.CardPromo className={className}>
      <s.Wrap title={textStatusPromo}>
        <s.StatusPromo text={textStatusPromo} status={statusPromo} />

        <s.ButtonIconPromo
          type={"button"}
          icon={<IconInfo />}
          onClickButtonIcon={onClickButtonPromoIcon}
        />
      </s.Wrap>

      <s.Text>{text}</s.Text>

      {statusPromo !== StatusProperty.DEFAULT &&
        statusPromo !== StatusProperty.COMMON && (
          <s.InputPromo
            type={"button"}
            value={valueInput}
            placeholder={"P48T57DMQS"}
            icon={<s.IconInput />}
            onChangeInput={() => {}}
            onClickInput={onClickInput}
            onClickButtonIcon={onClickButtonIcon}
          />
        )}
    </s.CardPromo>
  );
};
