import * as React from "react";
import { FC } from "react";

export const IconZoom: FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox={"0 0 17 16"}
    fill="currentColor"
    {...props}
  >
    <path
      fill="currentColor"
      d="M7.83 5c.35 0 .634.269.634.6v1.2h1.269c.35 0 .634.268.634.6 0 .33-.284.6-.634.6h-1.27v1.2c0 .332-.283.6-.634.6-.35 0-.634-.268-.634-.6V8h-1.27c-.35 0-.635-.27-.635-.6 0-.332.284-.6.635-.6h1.27V5.6c0-.331.284-.6.634-.6Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.393 11.62A5.895 5.895 0 0 1 7.83 12.8c-3.155 0-5.712-2.417-5.712-5.4C2.117 4.418 4.674 2 7.83 2c3.154 0 5.711 2.418 5.711 5.4a5.197 5.197 0 0 1-1.249 3.371l2.332 2.205a.577.577 0 0 1 0 .849.66.66 0 0 1-.897 0l-2.333-2.205ZM3.386 7.4c0-2.32 1.99-4.2 4.443-4.2 2.453 0 4.442 1.88 4.442 4.2a4.07 4.07 0 0 1-1.243 2.914.625.625 0 0 0-.117.111A4.565 4.565 0 0 1 7.829 11.6c-2.454 0-4.443-1.88-4.443-4.2Z"
      clipRule="evenodd"
    />
  </svg>
);
