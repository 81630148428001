import * as yup from "yup";

export const ValidationFormModalQuestion = yup.object().shape({
  name: yup.string().required("Это поле обязательно для заполнения"),
  phone: yup
    .string()
    .required("Это поле обязательно для заполнения")
    .min(18, "Минимальное количество символов 11"),
  email: yup
    .string()
    .required("Это поле обязательно для заполнения")
    .email("Введите корректную почту"),
  category: yup.string().trim().required("Это поле обязательно для заполнения"),
  text: yup
    .string()
    .required("Это поле обязательно для заполнения")
    .max(300, "Максимально допустомое количество символов 300"),
  personal_data_handling: yup
    .boolean()
    .required("Это поле обязательно для заполнения")
    .oneOf([true], "Нужно согласиться с обработкой персональных данных"),
});
