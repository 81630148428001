import styled from "styled-components";
import { ButtonIcon, IconCopy, Status } from "../../atom";
import { Input } from "../../atom/input/Input-styles";
import { FieldInput } from "../../molecules";
import image from "./../../../config/assets/images/card-promo-shape.svg";
import { breakpoints } from "../../../config";

const CardPromo = styled.article`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 265px;
  padding: 32px 40px;
  border-radius: 32px;
  border: 1px solid var(--interface);
  background: transparent url(${image}) no-repeat top right/160px 98px;

  @media (max-width: ${breakpoints.XL}) {
    padding: 30px 30px 24px;
    border-radius: 28px;
  }

  @media (max-width: ${breakpoints.MD}) {
    min-height: 235px;
    border-radius: 24px;
  }
`;

const Wrap = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 24px;

  @media (max-width: ${breakpoints.XL}) {
    margin-bottom: 16px;
  }
`;

const StatusPromo = styled(Status)`
  white-space: nowrap;
`;

const ButtonIconPromo = styled(ButtonIcon)`
  flex: 0 0 36px;
  width: 36px;
  height: 36px;
  color: var(--interface);

  @media (max-width: ${breakpoints.MD}) {
    flex: 0 0 24px;
    width: 24px;
    height: 24px;
  }

  &:hover {
    color: var(--hyperlink);
  }
`;

const Text = styled.p`
  flex: 1 0 auto;
  margin-bottom: 24px;
  font: var(--font-9);
  color: var(--interface-dark);
`;

const InputPromo = styled(FieldInput)`
  ${Input} {
    background-color: var(--brand-4);
  }
`;

const IconInput = styled(IconCopy)`
  width: 24px;
  height: 24px;
  color: var(--iconography);
  transition: ease-in-out 300ms;
`;

export {
  CardPromo,
  Wrap,
  StatusPromo,
  ButtonIconPromo,
  Text,
  InputPromo,
  IconInput,
};
