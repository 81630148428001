import React, { FC, ReactNode } from "react";
import * as s from "./Modal-about-invitation-buttons-styles";

type ModalAboutInvitationButtonsType = {
  className?: string;
  onShowModal: () => void;
  title: ReactNode;
  list: Array<{ title: string; text: string }>;
  buttons: Array<ReactNode>;
};

export const ModalAboutInvitationButtons: FC<
  ModalAboutInvitationButtonsType
> = ({ className, onShowModal, title, list, buttons }) => {
  return (
    <s.ModalAboutInvitationButtons className={className}>
      <s.Modal onClickButtonBg={onShowModal} onClickButtonIcon={onShowModal}>
        <s.Title>{title}</s.Title>
        <s.List>
          {list.map((item, index) => (
            <s.Item key={index}>
              <s.Number>{index + 1}.</s.Number>

              <s.Wrap>
                <s.SubTitle>{item.title}</s.SubTitle>
                <s.Text>{item.text}</s.Text>
              </s.Wrap>
            </s.Item>
          ))}
        </s.List>
        <s.Buttons>
          {buttons.map((button) => (
            <s.ItemButton>{button}</s.ItemButton>
          ))}
        </s.Buttons>
      </s.Modal>
    </s.ModalAboutInvitationButtons>
  );
};
