export type DataQuestionType = {
  [key: string]: {
    title: string;
    question: {
      values: Array<string>;
      list: Array<string>;
    };
    listQuestions: Array<string>;
  };
};

const dataQuestion: DataQuestionType = {
  "Что такое «Здоровый питомец»?": {
    title: "Что такое «Здоровый питомец»?",
    question: {
      values: [
        `
        «Здоровый питомец» – это программа лояльности, организованная Royal Canin и Зоозавром. 
      `,
        `
        Каждая ваша покупка рационов Royal\u00A0Canin для котят и щенков в 
        Зоозавре позволяет вам накапливать бонусы («роялсы»), чтобы получать 
        дополнительную скидку на следующую покупку Royal\u00A0Canin или подарки и призы 
        от Royal\u00A0Canin и Зоозавра. 
        `,

        `
        Для этого необходимо зарегистрироваться в 
        программе и загружать чеки с покупкой Royal\u00A0Canin в Личном кабинете.
        `,
      ],
      list: [],
    },
    listQuestions: [
      "Чеки принимаются только из Зоозавра?",
      "Какой ассортимент участвует в акции?",
      "Сколько будет действовать акция?",
      "В каких городах действует акция?",
      "Куда обратиться, если у меня есть вопросы или уточнения по программе?",
    ],
  },

  "Чеки принимаются только из Зоозавра?": {
    title: "Чеки принимаются только из Зоозавра?",
    question: {
      values: [
        `
        Да, вы можете загружать чеки из розничных 
        магазинов «Зоозавр» и интернет-магазина Zoozavr.ru.
      `,
      ],
      list: [],
    },
    listQuestions: [
      "Что такое «Здоровый питомец»?",
      "Какой ассортимент участвует в акции?",
      "Сколько будет действовать акция?",
      "В каких городах действует акция?",
      "Куда обратиться, если у меня есть вопросы или уточнения по программе?",
    ],
  },

  "Какой ассортимент участвует в акции?": {
    title: "Какой ассортимент участвует в акции?",
    question: {
      values: [
        `
        Ассортимент Royal\u00A0Canin для котят и щенков, представленный в 
        сети магазинов «Зоозавр» и на сайте Zoozavr.ru, за исключением ветеринарных диет.
      `,
      ],
      list: [],
    },
    listQuestions: [
      "Что такое «Здоровый питомец»?",
      "Чеки принимаются только из Зоозавра?",
      "Сколько будет действовать акция?",
      "В каких городах действует акция?",
      "Куда обратиться, если у меня есть вопросы или уточнения по программе?",
    ],
  },

  "Сколько будет действовать акция?": {
    title: "Сколько будет действовать акция?",
    question: {
      values: [
        `
        Срок проведения акции до 29 декабря 2024 года.
      `,
      ],
      list: [],
    },
    listQuestions: [
      "Что такое «Здоровый питомец»?",
      "Чеки принимаются только из Зоозавра?",
      "Какой ассортимент участвует в акции?",
      "В каких городах действует акция?",
      "Куда обратиться, если у меня есть вопросы или уточнения по программе?",
    ],
  },

  "В каких городах действует акция?": {
    title: "В каких городах действует акция?",
    question: {
      values: [
        `
        Акция действует на всей территории Российской Федерации, 
        в сети магазинов Зоозавр, на сайте Zoozavr.ru.
      `,
      ],
      list: [],
    },
    listQuestions: [
      "Что такое «Здоровый питомец»?",
      "Чеки принимаются только из Зоозавра?",
      "Какой ассортимент участвует в акции?",
      "Сколько будет действовать акция?",
      "Куда обратиться, если у меня есть вопросы или уточнения по программе?",
    ],
  },

  "Куда обратиться, если у меня есть вопросы или уточнения по программе?": {
    title:
      "Куда обратиться, если у меня есть вопросы или уточнения по программе?",
    question: {
      values: [
        `
        Если у вас возникли вопросы по программе или нужна помощь обращайтесь 
        в нашу службу поддержки через форму Обратной связи на сайте акции 
        здоровый-питомец.рф или пишите на email support@zdorovy-pitomets.ru.
      `,
        ` Мы обязательно свяжемся с вами в течение 24 часов и ответим на все вопросы!`,
      ],
      list: [],
    },
    listQuestions: [
      "Что такое «Здоровый питомец»?",
      "Чеки принимаются только из Зоозавра?",
      "Какой ассортимент участвует в акции?",
      "Сколько будет действовать акция?",
      "В каких городах действует акция?",
    ],
  },

  "Что такое роялс?": {
    title: "Что такое роялс?",
    question: {
      values: [
        `
        «Роялс» – внутренние бонусы программы лояльности «Здоровый питомец». 
        1 роялс начисляется за каждые 50 рублей, потраченные на рационы Royal 
        Canin в Зоозавре. Например, вы загружаете чек из «Зоозавра», в котором 
        есть рацион сухой для котят Royal\u00A0Canin Kitten 2кг стоимостью 2 057 рублей. 
        После проверки валидности чека, вам на счет будет начислено 41 роялс. 
      `,
        ` Роялсы начисляются только за каждые ровные 50 рублей. В нашем примере 
        41 роялс начислен за 2050 рублей, остаток 7 рублей в зачет не идет.`,
      ],
      list: [],
    },
    listQuestions: ["Как копить роялсы?", "Могут ли обнулиться мои роялсы?"],
  },

  "Как копить роялсы?": {
    title: "Как копить роялсы?",
    question: {
      values: [`Есть несколько вариантов накопления роялсов:`],
      list: [
        `Загружайте чеки с Royal\u00A0Canin из Зоозавра и получайте 
        1 роялс за каждые 50 рублей, потраченные на Royal\u00A0Canin в Зоозавре.`,
        `Поделитесь в социальной сети и получите по 3 роялса за каждую сеть ВКонтакте, Одноклассники`,
        `Пригласите друга, отправив ему уникальный код любым удобным вам способом, и получите по 5 
        роялсов за каждого друга, зарегистрировавшегося по вашему уникальному коду и загрузившего чеки на сумму от 250 рублей.`,
      ],
    },
    listQuestions: ["Что такое роялс?", "Могут ли обнулиться мои роялсы?"],
  },

  "Могут ли обнулиться мои роялсы?": {
    title: "Могут ли обнулиться мои роялсы?",
    question: {
      values: [
        `
        Роялсы начисляются на срок 2 месяца. Если Участник программы не 
        успевает обменять их на подарки, то спустя 2 месяца с момента получения роялсов в Личном кабинете они сгорают.
      `,
        `
        Также организатор вправе обнулить накопленные Участником роялсы, 
        если аккаунт Участника неактивен (с аккаунта не совершались результативные действия на сайте здоровый-питомец.рф в течение 4 месяцев)
        `,

        `
          Мы подготовили для вас 3 вида полезных подарков и призов от Royal\u00A0Canin и Зоозавра. 
          Покупайте Royal\u00A0Canin и загружайте ваши чеки в Личном кабинете, копите  и выбирайте в Каталоге подарок!
        `,
      ],
      list: [],
    },
    listQuestions: ["Что такое роялс?", "Как копить роялсы?"],
  },

  "Какие подарки меня ждут?": {
    title: "Какие подарки меня ждут?",
    question: {
      values: [
        `Мы подготовили для вас различные виды подарков и призы от Royal\u00A0Canin и Зоозавра.`,
        `Покупайте Royal\u00A0Canin и загружайте ваши чеки в Личном кабинете, копите  и выбирайте в Каталоге подарок!`,
      ],
      list: [],
    },
    listQuestions: [
      "Сколько чеков в день я могу загрузить?",
      "Как мне получить мой подарок?",
    ],
  },

  "Сколько чеков в день я могу загрузить?": {
    title: "Сколько чеков в день я могу загрузить?",
    question: {
      values: [
        `К начислению роялсов принимается не более 3 (трех) чеков в течение календарных суток 
        с 0 часов 0 минут до 23 часов 59 минут. Четвертый и последующие чеки, 
        загруженные пользователем в течение суток, обработке и начислению роялсов не подлежат.`,
        `Сроки регистрации покупки на Cайте: должен быть произведен в течение 72 часов с
         момента выдачи чека. Если чек загружен позднее, чем через 72 часа после выдачи, он не подлежит обработке и начислению роялсов`,
      ],
      list: [],
    },
    listQuestions: [
      "Какие подарки меня ждут?",
      "Как мне получить мой подарок?",
    ],
  },

  "Как мне получить мой подарок?": {
    title: "Как мне получить мой подарок?",
    question: {
      values: [
        `
        После обмена роялсов в Личном кабинете в разделе Мои промокоды у вас 
        появится промокод, который вы сможете использовать как в интернет-магазине Zoozavr.ru, 
        введя промокод в специальное окно при покупке, так и в магазинах Зоозавр. Обратите внимание, использовать промокод можно только один раз. 
        `,
      ],
      list: [],
    },
    listQuestions: [
      "Какие подарки меня ждут?",
      "Сколько чеков в день я могу загрузить?",
    ],
  },
};

export { dataQuestion };
