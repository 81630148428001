import React, { FC } from "react";
import * as s from "./Additional-royals-styles";
import { CardsRoyals } from "../../molecules";
import { ModalsEnumType, ModalsProperty } from "../../../config";

type AdditionalRoyalsType = {
  className?: string;
  id?: string;
  titleAdditionalRoyals: string;
  isFlipped: boolean;
  onClickCardRoyals: (modalInfo: ModalsEnumType) => () => void;
};

export const AdditionalRoyals: FC<AdditionalRoyalsType> = ({
  className,
  id,
  titleAdditionalRoyals,
  isFlipped,
  onClickCardRoyals,
}) => {
  const cardsRoyalsMock = [
    {
      title: "Регистрируйтесь и заполняйте анкету про своего питомца",
      royals: 5,
      image: <s.IconHeartPink status={1} />,
      backImage: <s.IconHeartRed status={1} />,
      listInfo: [
        {
          index: 1,
          text: "Нажмите кнопку «Зарегистрироваться» или «Загрузить чек» на Сайте.",
        },
        {
          index: 2,
          text: "При регистрации заполните обязательные личные данные, а также данные минимум одного питомца.",
        },
        {
          index: 3,
          text: "Подтвердите e-mail, указанный в форме регистрации. Нажмите кнопку «Зарегистрироваться».",
        },
        {
          index: 4,
          text: "Авторизируйтесь на сайте и зайдите в Личный кабинет.",
        },
      ],
      modal: ModalsProperty.MODAL_ADDITIONAL_ROYALS_HEART,
    },

    {
      title: "Приглашайте друзей с питомцами",
      royals: 5,
      image: <s.IconWingPink status={2} />,
      backImage: <s.IconWingRed status={2} />,
      listInfo: [
        {
          index: 1,
          text: "Зайдите в Личный кабинет и нажмите на кнопку «Пригласить друга».",
        },
        {
          index: 2,
          text: "Введите e-mail друга,на него будет отправлена ссылка для регистрации на сайте.",
        },
        {
          index: 3,
          text: "За каждого друга, который зарегистрируется по вашей ссылке и загрузит чеки в сумме на 5 роялсов, мы начислим 5 подарочных роялсов в течение 10 дней.",
        },
      ],
      modal: ModalsProperty.MODAL_ADDITIONAL_ROYALS_WINGS,
    },

    {
      title: "Рассказывайте о программе в социальных сетях",
      royals: 3,
      image: <s.IconStarPink status={3} />,
      backImage: <s.IconStarRed status={3} />,
      listInfo: [
        {
          index: 1,
          text: "Зайдите в Личный кабинет в блок «Рассказывайте о программе в соц.сетях».",
        },
        {
          index: 2,
          text: "Перейдите по ссылке в Одноклассники или Вконтакте и авторизируйтесь.",
        },
        {
          index: 3,
          text: "Опубликуйте пост с информацией о программе Здоровый питомец.",
        },
        {
          index: 4,
          text: "Получите по 3 роялса за каждую соц.сеть в течение 10 дней.",
        },
      ],
      modal: ModalsProperty.MODAL_ADDITIONAL_ROYALS_STAR,
    },

    {
      title: "Получайте бонусы за регулярную покупку Royal\u00A0Canin",
      footnote: "в течение 3/6/9 месяцев",
      royals: 60,
      image: <s.IconShapePink status={4} />,
      backImage: <s.IconShapeRed status={4} />,
      listInfo: [
        {
          index: 1,
          text: "Совершайте покупки рационов из ассортимента Royal\u00A0Canin, представленных в сети магазинов «Зоозавр» и на сайте Zoozavr.ru, кроме ветеринарных диет.",
        },
        {
          index: 2,
          text: "Загружайте чеки с Royal\u00A0Canin из Зоозавра.",
        },
        {
          index: 3,
          text: "Бонус за регулярную покупку Royal Canin каждый месяц:",
          subItems: [
            "в течение 3 мес. - 30 роялсов.",
            "в течение 6 мес. - 45 роялсов.",
            "в течение 9 мес. - 60 роялсов.",
          ],
        },
      ],
      modal: ModalsProperty.MODAL_ADDITIONAL_ROYALS_SHAPE,
    },
  ];

  return (
    <s.AdditionalRoyals className={className} id={id}>
      <s.Title>{titleAdditionalRoyals}</s.Title>

      <CardsRoyals
        cards={cardsRoyalsMock}
        isFlipped={isFlipped}
        onClickCardRoyals={onClickCardRoyals}
      />
    </s.AdditionalRoyals>
  );
};
