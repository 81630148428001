import * as React from "react";
import { FC } from "react";

export const IconPromoSmallFilled: FC<React.SVGProps<SVGSVGElement>> = (
  props,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox={"0 0 60 60"}
    fill="currentColor"
    {...props}
  >
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3.047}
      d="M45.238 31.015a5.08 5.08 0 0 1 5.078-5.079v-2.03c0-8.126-2.03-10.157-10.156-10.157H19.848c-8.125 0-10.157 2.031-10.157 10.156v1.016a5.08 5.08 0 0 1 5.079 5.078 5.08 5.08 0 0 1-5.079 5.078v1.016c0 8.125 2.032 10.156 10.157 10.156H40.16c8.125 0 10.156-2.031 10.156-10.156a5.08 5.08 0 0 1-5.078-5.079Z"
    />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3.047}
      d="M23.906 35.587 36.094 23.4"
    />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={5}
      d="M36.079 35.586h.018M23.891 24.415h.018"
    />
  </svg>
);
