import styled, { css } from "styled-components";

type ButtonTimerType = {
  $isDisabled?: boolean;
};

const ButtonTimer = styled.button<ButtonTimerType>`
  font: var(--font-19);
  color: var(--interface-dark);
  will-change: transform;
  transition: transform 300ms ease;

  ${(props) =>
    props.$isDisabled &&
    css`
      color: var(--brand-2);
      pointer-events: none;
    `};

  &:active {
    transform: scale(0.98);
  }
`;

export {ButtonTimer};
