import { AxiosResponse } from "axios";
import {
  RefreshRequest,
  RefreshResponse,
  RegistrationRequestBodyType,
  SignInRequestBodyType,
  SignInRequestInterface,
  UserType,
} from "../model";
import { instance } from "../../../shared/api";

export const authApi = {
  me: async (): Promise<AxiosResponse<UserType>> => {
    return await instance.get("auth/users/me/");
  },

  editMe: async (body: UserType): Promise<AxiosResponse<UserType>> => {
    return await instance.patch("auth/users/me/", body);
  },

  email_confirmation: async (body: {
    email: string;
    name: string;
  }): Promise<AxiosResponse<any>> => {
    return instance.post("email_confirmation/", body);
  },

  registration: async (
    body: RegistrationRequestBodyType,
    invitationToken?: string
  ): Promise<
    AxiosResponse<{
      tokens: { refresh: string; access: string };
      data: UserType;
    }>
  > => {
    return await instance.post(
      invitationToken
        ? `auth/users/?invitation_token=${invitationToken}`
        : "auth/users/",
      body
    );
  },

  sign_in: async (
    body: SignInRequestBodyType
  ): Promise<AxiosResponse<SignInRequestInterface>> => {
    return await instance.post<SignInRequestInterface>(
      "/auth/jwt/create/",
      body
    );
  },

  refreshToken: async (body: RefreshRequest) => {
    const response = await instance.post("auth/jwt/refresh/", {
      refresh: body,
    });
    return response as AxiosResponse<RefreshResponse>;
  },
};
