import styled, { css } from "styled-components";
import { IconCheck, IconTick } from "../_icons";

type WrapIconType = {
  $isChecked: boolean;
};

const WrapIcon = styled.div<WrapIconType>`
  position: relative;
  display: flex;
  align-items: center;

  ${(props) =>
    props.$isChecked
      ? css`
          color: var(--brand-1);
        `
      : css`
          color: var(--text);
        `}
`;

const InputCheckbox = styled.button`
  display: flex;
  gap: 8px;
  position: relative;
  font: var(--font-19);
  color: var(--text);
  text-align: left;
  line-height: 1;
  cursor: pointer;

  &:hover {
    ${WrapIcon} {
      color: var(--brand-1);
    }
  }

  &:active {
    ${WrapIcon} {
      transform: scale(0.95);
    }
  }
`;

const IconNoCheck = styled(IconCheck)`
  width: 20px;
  height: 20px;
`;

const IconYesCheck = styled(IconTick)`
  position: absolute;
  top: 0;
  right: 1px;
  width: 16px;
`;

export { InputCheckbox, WrapIcon, IconNoCheck, IconYesCheck };
