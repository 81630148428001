import styled from "styled-components";
import { breakpoints } from "../../../shared/config";
import closeIcon from "../../../shared/config/assets/images/close-modal.svg";
import removePhoto from "../../../shared/config/assets/images/remove-photo.svg";
import uploadIcon from "../../../shared/config/assets/images/upload-icon.svg";

const UploadButton = styled.div`
  margin-bottom: 32px;

  @media (max-width: ${breakpoints.XL}) {
    margin-bottom: 36px;
  }

  @media (max-width: ${breakpoints.LG}) {
    margin-top: 40px;
    margin-bottom: 0;
  }

  @media (max-width: ${breakpoints.MD}) {
    margin-top: 56px;
  }

  .apm-p {
    font: var(--font-10);
    color: var(--interface-dark);

    @media (max-width: ${breakpoints.MD}) {
      max-width: 90%;
      margin: 0 auto;
    }
  }

  //Modal window

  .apm-modal {
    display: flex;
    height: 100%;
    backdrop-filter: blur(3px);
    background-color: rgba(10, 44, 87, 0.3);
  }

  .apm-modal-dialog {
    margin: auto;
    max-width: none;

    @media (max-width: ${breakpoints.MD}) {
      width: 90%;
      max-width: 430px;
    }
  }

  .apm-modal-body {
    padding: 16px 64px 26px;

    .main-button {
      display: none;
    }

    @media (max-width: ${breakpoints.MD}) {
      padding: 16px 12px 24px;
    }
  }

  .apm-modal-content {
    border: none;
    width: 578px;
    border-radius: 24px;

    @media (max-width: ${breakpoints.MD}) {
      width: 100%;
    }
  }

  .apm-close {
    top: 24px;
    right: 24px;
    background-color: transparent;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      background-image: url(${closeIcon});
      background-repeat: no-repeat;
      background-size: cover;
      width: 24px;
      height: 24px;
      margin-right: 8px;
      cursor: pointer;
      transform: none;
      z-index: 20;
    }

    &:after {
      display: none;
    }
  }

  .apm-block-wrong-qr {
    margin: 30px 0;
  }

  .apm-title-manual {
    font: var(--font-8);
    color: var(--interface-dark);
    margin-bottom: 8px;
  }

  .apm-description-title {
    font: var(--font-11);

    p {
      font: var(--font-11);
      color: var(--text);
    }
  }

  //Buttons

  .red-button,
  .white-button {
    font: var(--font-7) !important;
    letter-spacing: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    transition: transform 300ms linear, background-color 300ms ease,
      color 300ms ease, border 300ms ease;
  }

  .white-button {
    margin-top: 48px;
  }

  .red-button {
    background-color: var(--brand-1);

    &:hover {
      background-color: var(--brand-1-dark);
    }

    span {
      color: var(--brand-3) !important;
    }
  }

  .apm-btn-link.white-button {
    color: var(--brand-1);
    background-color: transparent;
    border: 1px solid var(--brand-1);

    &:hover {
      color: var(--brand-1-dark);
      border: 1px solid var(--brand-1-dark);
    }

    span {
      color: var(--brand-1);
      background-color: var(--brand-3);
    }
  }

  #apm-scan-qr {
    @media (max-width: ${breakpoints.LG}) {
      position: fixed;
      bottom: 24px;
      left: 50%;
      transform: translateX(-50%);
      max-width: 90%;
      overflow: visible;

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 110%;
        height: 200%;
        background-color: #fff;
      }
    }
  }

  .apm-btn {
    background-color: unset;
  }

  .main-button,
  .apm-btn-main {
    width: 100%;
    height: 64px;
    border-radius: 0;
    box-shadow: unset;
  }

  .main-button {
    span:before {
      content: "";
      display: block;
      background-image: url(${uploadIcon});
      background-repeat: no-repeat;
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }
  }

  .upload-button,
  .manual-qr-button,
  .submit-manual-qr-button,
  .submit-photos-button {
    width: 100%;
    height: fit-content;
    margin: 0 !important;
    border-radius: 8px;
  }

  .upload-button span,
  .submit-manual-qr-button span,
  .submit-photos-button span {
    font: var(--font-9) !important;
    letter-spacing: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px 0;
    text-transform: none;
  }

  a:has(.manual-qr-button) {
    margin-bottom: 12px !important;
  }

  .manual-qr-button {
    background-color: transparent;

    span {
      font: var(--font-9) !important;
      letter-spacing: 0;
      text-transform: none;
      padding: 24px 0;
    }
  }

  a:has(.submit-manual-qr-button) {
    margin-top: 40px !important;
  }

  .add-photo-button {
    width: 100%;
    height: 72px;
    color: var(--brand-1);
    background-color: transparent;
    border: 1px solid var(--brand-1);

    &:hover {
      color: var(--brand-1-dark);
      border: 1px solid var(--brand-1-dark);
    }

    span {
      font: var(--font-9);
      color: var(--brand-1);
      background-color: var(--brand-3);
      text-transform: none;
    }

    input {
    }
  }

  //Inputs

  .apm-p-input {
    font: var(--font-16) !important;
    letter-spacing: -0.32px;
    color: var(--text);
    padding-top: 24px;
    margin-bottom: 6px;
  }

  .apm-block-qr input {
    font: var(--font-16);
    letter-spacing: -0.32px;
    width: 100%;
    height: 64px;
    padding: 23px 56px 23px 20px;
    color: var(--brand-2);
    background-color: var(--brand-4);
    border: none;
    border-radius: 10px;
    text-align: left;
  }

  //Photo

  #apm-photos-thumbnails-container {
    margin-top: 32px;
    margin-bottom: 40px;
  }

  .apm-photo-thumbnail-wrapper {
    width: 132px;
    height: 132px;
    padding: 0;
    border: none;
  }

  .apm-photo-thumbnail-image {
    border-radius: 16px;
  }

  .apm-remove-photo-button {
    width: 32px;
    height: 32px;
    max-width: 32px;
    max-height: 32px;
    background-color: var(--brand-3);
    box-shadow: 0 11px 23px 0 #0000001a;

    &:before {
      content: "";
      display: block;
      width: 18px;
      height: 18px;
      background-color: transparent;
      background-image: url(${removePhoto});
      background-repeat: no-repeat;
      background-size: cover;
      transform: translateX(-50%) translateY(-50%) rotate(0);
    }

    &:after {
      display: none;
    }
  }

  .apm-title {
    font: var(--font-3);
    letter-spacing: -1.68px;
    color: var(--brand-1);
    margin-bottom: 16px;
  }

  .apm-description-title p {
    font: var(--font-16);
    letter-spacing: -0.36px;
    color: var(--brand-2);
  }
`;

export { UploadButton };
