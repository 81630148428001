import React, { FC, ReactNode } from "react";
import * as s from "./Button-icon-styles";

type ButtonIconType = {
  className?: string;
  type: "button" | "submit" | "reset";
  dataTestid?: string;
  icon: ReactNode;
  onClickButtonIcon?: () => void;
};

export const ButtonIcon: FC<ButtonIconType> = ({
  className,
  type,
  dataTestid,
  icon,
  onClickButtonIcon,
}) => {
  return (
    <s.ButtonIcon
      className={className}
      type={type}
      onClick={onClickButtonIcon}
      data-testid={dataTestid}
    >
      {icon}
    </s.ButtonIcon>
  );
};
