import styled from "styled-components";

const NotificationsBar = styled.div`
  position: fixed;
  top: 12px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10000;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 20px;
`;

const List = styled.ul`
  display: grid;
  grid-auto-rows: var(--minmax);
  gap: 8px;
`;

const Item = styled.li``;

export { NotificationsBar, List, Item };
