import React, { FC } from "react";
import * as s from "./Checks-validations-styles";
import { CheckValidation, CheckValidationType } from "../../atom";

type ChecksValidationsType = {
  className?: string;
  title: string;
  checksValidations: Array<CheckValidationType>;
};

export const ChecksValidations: FC<ChecksValidationsType> = ({
  className,
  title,
  checksValidations,
}) => {
  return (
    <s.ChecksValidations className={className}>
      <s.Title>{title}</s.Title>

      <s.List>
        {checksValidations.map((el) => (
          <s.Item key={el.text}>
            <CheckValidation
              className={el.className}
              text={el.text}
              isStatus={el.isStatus}
            />
          </s.Item>
        ))}
      </s.List>
    </s.ChecksValidations>
  );
};
