import styled from "styled-components";
import { breakpoints } from "../../../config";

const StepRoyals = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (max-width: ${breakpoints.MD}) {
    flex-direction: row;
    align-items: center;
    gap: 12px;
  }
`;

const IndexShape = styled.div`
  position: relative;
`;

const Icon = styled.span`
  display: flex;
  width: max-content;
  color: var(--pink);
  padding-right: 15px;
  background-color: var(--background-form);

  @media (max-width: ${breakpoints.XXL}) {
    padding-right: 10px;
  }

  @media (max-width: ${breakpoints.MD}) {
    padding-right: 0;
    background-color: transparent;
  }

  & svg {
    width: 80px;
    height: 80px;

    @media (max-width: ${breakpoints.XL}) {
      width: 64px;
      height: 64px;
    }

    @media (max-width: ${breakpoints.MD}) {
      width: 56px;
      height: 56px;
      transform: translateY(4px);
    }
  }
`;

const Item = styled.div`
  color: var(--text);
`;

const Index = styled.span`
  position: absolute;
  font: var(--font-4);
  top: 11px;
  left: 30px;
  color: var(--brand-1);
  z-index: 3;

  @media only screen and (-webkit-min-device-pixel-ratio: 2) {
    top: 17px;
  }

  @media (max-width: ${breakpoints.XL}) {
    top: 10px;
    left: 24px;

    @media only screen and (-webkit-min-device-pixel-ratio: 2) {
      top: 13px;
    }
  }

  @media (max-width: ${breakpoints.MD}) {
    top: 13px;
    left: 22px;

    @media only screen and (-webkit-min-device-pixel-ratio: 2) {
      top: 17px;
    }
  }
`;

const Text = styled.p`
  font: var(--font-16);
  letter-spacing: -0.36px;
  color: var(--text);

  @media (max-width: ${breakpoints.MD}) {
    max-width: 75%;
    color: var(--interface-dark);
  }
`;

const FirstWord = styled.span`
  @media (max-width: ${breakpoints.MD}) {
    font: var(--font-25);
    display: inline-block;
    width: 100%;
    margin-bottom: 4px;
  }
`;

const Footnote = styled.p`
  font: var(--font-21);
  max-width: 110px;
  color: var(--brand-2);
  margin-top: 8px;

  @media (max-width: ${breakpoints.MD}) {
    font: var(--font-16);
    max-width: none;
    color: var(--text);
    margin-top: 0;
  }
`;

export {StepRoyals, IndexShape, Icon, Item, Index, Text, FirstWord, Footnote};
