import styled from "styled-components";
import { breakpoints } from "../../../config";

const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  margin: 0 auto;
  max-width: 293px;

  @media (max-width: ${breakpoints.XL}) {
    gap: 16px;
  }

  @media (max-width: ${breakpoints.MD}) {
    max-width: none;
    justify-content: center;
  }
`;

const Icon = styled.span`
  display: flex;
  color: var(--interface);
  transition: color 300ms;
  width: 80px;
  height: 80px;

  @media (max-width: ${breakpoints.XL}) {
    width: 64px;
    height: 64px;
  }
`;

const Text = styled.p`
  font: var(--font-8);
  color: var(--text);
  text-align: start;
`;

const ButtonCardAdd = styled.button`
  padding: 0 92px 0 45px;
  min-height: 284px;
  height: 100%;
  width: 100%;
  background: var(--background-form);
  border: 1px solid var(--interface);
  border-radius: 32px;
  cursor: pointer;
  will-change: transform;
  transition: transform 200ms;

  @media (max-width: ${breakpoints.XXL}) {
    padding: 0 45px;
  }

  @media (max-width: ${breakpoints.XL}) {
    min-height: 246px;
    padding: 0 26px;
    border-radius: 28px;
  }

  @media (max-width: ${breakpoints.LG}) {
    min-height: 240px;
    padding: 0 46px;
    border-radius: 32px;
  }

  @media (max-width: ${breakpoints.MD}) {
    min-height: unset;
    width: 100%;
    padding: 29px 58px;
    border-radius: 28px;
  }

  &:hover {
    transform: scale(1.02);

    ${Icon} {
      color: var(--iconography);
    }
  }

  &:active {
    transform: scale(0.98);
  }
`;

export { ButtonCardAdd, Content, Icon, Text };
