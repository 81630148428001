import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { discountApi } from "../../api";

export const getPromoCodesThunk = createAsyncThunk(
  "discount/get-promo-codes",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await discountApi.get_promocode();
      return response.data;
    } catch (e) {
      const error = e as AxiosError<any>;
    }
  }
);

export const getPromoCodesTypesThunk = createAsyncThunk(
  "discount/get-promo-code-type",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await discountApi.get_promocode_type();
      return response.data;
    } catch (e) {
      const error = e as AxiosError<any>;
    }
  }
);

export const sendPromoCodeThunk = createAsyncThunk(
  "discount/send-promo-code",
  async (id: number, { rejectWithValue, dispatch }) => {
    try {
      const response = await discountApi.send_promocode(id);
      dispatch(getPromoCodesThunk());
      dispatch(getPromoCodesTypesThunk());
      return response.data;
    } catch (e) {
      const error = e as AxiosError<any>;
    }
  }
);

export const getPromoCodesTypesNotAuthorizedThunk = createAsyncThunk(
  "discount/get-promo-code-type",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await discountApi.get_promocode_type_not_authorized();
      return response.data;
    } catch (e) {
      const error = e as AxiosError<any>;
    }
  }
);

export const getHistoryRoyalsThunk = createAsyncThunk(
  "discount/get-royals",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await discountApi.get_history_royals();
      return response.data;
    } catch (e) {
      const error = e as AxiosError<any>;
    }
  }
);

export const getDiscountReceiptsThunk = createAsyncThunk(
  "discount/get-receipts",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await discountApi.get_discount_receipts();
      return response.data;
    } catch (e) {
      const error = e as AxiosError<any>;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getDiscountReceiptIdThunk = createAsyncThunk(
  "discount/get-receipt-id",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await discountApi.get_discount_receipt_id();
      return response.data;
    } catch (e) {
      const error = e as AxiosError<any>;
      return rejectWithValue(error?.response?.data);
    }
  }
);
