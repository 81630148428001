import styled from "styled-components";
import { Button, IconRoyals, ModalBody } from "../../../../shared";
import { BodyAnimation } from "../../../../shared/ui/molecules/modal-body/Modal-body-styles";
import { breakpoints } from "../../../../shared/config";
import { FieldPhoneFormik } from "../../../field-phone-formik";
import { Input } from "../../../../shared/ui/atom/input/Input-styles";

const ModalConfirmationPhone = styled.div``;

const Modal = styled(ModalBody)`
  ${BodyAnimation} {
    max-width: 702px;
  }

  @media (max-width: ${breakpoints.LG}) {
    ${BodyAnimation} {
      max-width: 100%;
    }
  }
`;

const Form = styled.form`
  max-width: 450px;
`;

const Title = styled.h2`
  margin-bottom: 32px;
  font: var(--font-3);
  color: var(--brand-1);
`;

const Icon = styled(IconRoyals)`
  width: 40px;
  height: 40px;
  vertical-align: middle;
`;

const Text = styled.p`
  margin-bottom: 10px;
  font: var(--font-9);
  color: var(--interface-dark);
`;

const SubText = styled.p`
  margin-bottom: 24px;
  font: var(--font-16);
  color: var(--brand-2);
`;

const InputPhone = styled(FieldPhoneFormik)`
  margin-bottom: 40px;

  ${Input} {
    background-color: var(--brand-4);

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px var(--brand-4) inset;
    }
  }
`;

const ButtonCommon = styled(Button)`
  max-width: 100%;
`;

export {
  ModalConfirmationPhone,
  Modal,
  Form,
  Title,
  Icon,
  Text,
  SubText,
  InputPhone,
  ButtonCommon,
};
