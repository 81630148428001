import styled from "styled-components";
import { Button, ModalBody } from "../../../../shared";
import { BodyAnimation } from "../../../../shared/ui/molecules/modal-body/Modal-body-styles";
import { breakpoints } from "../../../../shared/config";
import { Input } from "../../../../shared/ui/atom/input/Input-styles";
import { FieldTextFormik } from "../../../field-text-formik";

const ModalRefEmailFriend = styled.div``;

const Modal = styled(ModalBody)`
  ${BodyAnimation} {
    max-width: 692px;
  }

  @media (max-width: ${breakpoints.LG}) {
    ${BodyAnimation} {
      max-width: 100%;
    }
  }
`;

const Form = styled.form`
  max-width: 450px;
`;

const Title = styled.h2`
  max-width: 520px;
  margin-bottom: 32px;
  font: var(--font-3);
  color: var(--brand-1);

  @media (max-width: ${breakpoints.LG}) {
    max-width: unset;
  }
`;

const Text = styled.p`
  margin-bottom: 10px;
  font: var(--font-9);
  color: var(--interface-dark);
`;

const SubText = styled.p`
  margin-bottom: 34px;
  font: var(--font-16);
  color: var(--brand-2);

  @media (max-width: ${breakpoints.XL}) {
    margin-bottom: 32px;
  }
`;

const InputEmail = styled(FieldTextFormik)`
  margin-bottom: 40px;

  ${Input} {
    background-color: var(--brand-4);

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px var(--brand-4) inset;
    }
  }
`;

const ButtonEmail = styled(Button)`
  max-width: 100%;
`;

export {
  ModalRefEmailFriend,
  Modal,
  Form,
  Title,
  Text,
  SubText,
  InputEmail,
  ButtonEmail,
};
