import React, {FC} from "react";
import * as s from "./Modal-check-email-styles";
import {pages} from "../../../../shared/config";
import {IconInfo} from "../../../../shared";
import {useNavigate} from "react-router-dom";
import {useAppDispatch} from "../../../../shared/lib";
import {setModals} from "../../../../widgets/modals/model";

type ModalCheckEmailType = {
  className?: string;
  onShowModal: () => void;
};

export const ModalCheckEmail: FC<ModalCheckEmailType> = ({className, onShowModal}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const onRedirectRecoverPassword = () => {
    navigate(pages.recoverPassword.link)
    dispatch(setModals({action: null}))
  }
  return (
    <s.ModalCheckEmail className={className}>
      <s.Modal onClickButtonBg={onShowModal} onClickButtonIcon={onShowModal}>
        <s.Title>Данный E-mail уже занят <IconInfo width={24} height={24}/></s.Title>

        <s.Text>
          Используйте другой e-mail или <s.Button type={"button"} onClick={onRedirectRecoverPassword}>восстановите
          пароль,</s.Button> если вы его не помните.
        </s.Text>
      </s.Modal>
    </s.ModalCheckEmail>
  );
};
