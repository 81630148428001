import React, { ChangeEvent, FC, useState } from "react";
import * as s from "./field-password-formik-styles";
import { FieldInput } from "../../../shared";
import { FieldHelperProps, FieldInputProps, FieldMetaProps } from "formik";

type FieldPasswordFormikType = FieldMetaProps<any> &
  FieldHelperProps<any> &
  FieldInputProps<any> & {
    className?: string;
    label: string;
    errorResponse?: string;
  };

export const FieldPasswordFormik: FC<FieldPasswordFormikType> = ({
  className,
  value,
  name,
  label,
  error,
  errorResponse,
  touched,
  onChange,
  onBlur,
}) => {
  const [isType, setType] = useState(false);
  const errorInput = touched ? (errorResponse ? errorResponse : error) : "";
  const type = isType ? "text" : "password"; // Added this line to determine the input type
  const icon = value ? (
    type === "password" ? (
      <s.IconOpenedEye $isError={!!errorInput} />
    ) : (
      <s.IconClosedEye $isError={!!errorInput} />
    )
  ) : null;

  const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e);
  };

  const onClickButtonIcon = () => {
    setType((value) => !value);
  };

  return (
    <s.FieldPasswordFormik className={className}>
      <FieldInput
        type={type}
        value={value}
        name={name}
        label={label}
        icon={icon}
        isFocus={!!value}
        error={errorInput}
        onChangeInput={onChangeInput}
        onClickButtonIcon={onClickButtonIcon}
        onBlurInput={onBlur}
      />
    </s.FieldPasswordFormik>
  );
};
