import React, { FC } from "react";
import * as s from "./Steps-royals-styles";
import { StepRoyals, StepRoyalsType } from "../../atom";

type StepsRoyalsType = {
  className?: string;
  steps: Array<StepRoyalsType>;
};

export const StepsRoyals: FC<StepsRoyalsType> = ({ className, steps }) => {
  return (
    <s.StepsRoyals className={className}>
      <s.ListSteps data-testid={"list-steps-test"}>
        {steps.map((step, index) => (
          <s.ItemSteps key={index}>
            <StepRoyals
              image={step.image}
              index={step.index}
              firstWord={step.firstWord}
              text={step.text}
              footnote={step.footnote}
            />
          </s.ItemSteps>
        ))}
      </s.ListSteps>
    </s.StepsRoyals>
  );
};
