import styled from "styled-components";
import { IconRoyals, IconRoyalsCard } from "../_icons";
import { breakpoints } from "../../../config";

const MiniRoyalsCard = styled.div`
  position: relative;
  max-width: max-content;
  color: var(--brand-1);
`;

const RoyalsCardIcon = styled(IconRoyalsCard)`
  width: 140px;
  transform: rotate(-9deg);

  @media (max-width: ${breakpoints.XXL}) {
    width: 100px;
  }
`;

const RoyalsAmount = styled.p`
  position: absolute;
  top: 48px;
  left: 29px;
  width: 93px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  transform: rotate(-9deg);

  @media (max-width: ${breakpoints.XXL}) {
    top: 35px;
    left: 15px;
    width: 67px;
  }
`;

const Royals = styled.span`
  font: var(--font-5);
`;

const RoyalsIcon = styled(IconRoyals)`
  width: 35px;

  @media (max-width: ${breakpoints.XXL}) {
    width: 25px;
  }
`;

const Text = styled.span`
  font: var(--font-12);
`;

export {
  MiniRoyalsCard,
  RoyalsCardIcon,
  Royals,
  RoyalsIcon,
  Text,
  RoyalsAmount,
};
