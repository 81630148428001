import React, { FC } from "react";
import * as s from "./Button-styles";
import { ButtonProperty } from "../../../config";

type ButtonType = {
  className?: string;
  text: string;
  type: "button" | "reset" | "submit";
  disabled?: boolean;
  property?:
    | ButtonProperty.DEFAULT
    | ButtonProperty.SECONDARY
    | ButtonProperty.HIGHLIGHTED;
  onClickButton: () => void;
};

export const Button: FC<ButtonType> = ({
  className,
  text,
  type,
  disabled,
  property = ButtonProperty.DEFAULT,
  onClickButton,
}) => {
  return (
    <s.Button
      className={className}
      type={type}
      disabled={disabled}
      property={property}
      onClick={disabled ? () => {} : onClickButton}
    >
      {text}
    </s.Button>
  );
};
