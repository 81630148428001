import styled from "styled-components";
import { breakpoints } from "../../../shared/config";
import { Button, ChecksValidations, DataWarning } from "../../../shared";
import { FieldPasswordFormik } from "../../../features";
import { Input } from "../../../shared/ui/atom/input/Input-styles";

const FormUserPassword = styled.form``;

const Wrap = styled.div`
  display: grid;
  column-gap: 64px;
  grid-template-columns: var(--input-width) 1fr;
  margin-bottom: 32px;

  @media (max-width: ${breakpoints.LG}) {
    column-gap: 16px;
  }

  @media (max-width: ${breakpoints.MD}) {
    grid-template-columns: 1fr;
    margin-bottom: 24px;
  }
`;

const SubTitle = styled.h3`
  grid-column: 1/2;
  grid-row: 1/2;
  margin-bottom: 24px;
  font: var(--font-9);
  color: var(--interface-dark);

  @media (max-width: ${breakpoints.MD}) {
    margin-bottom: 12px;
  }
`;

const List = styled.ul`
  grid-column: 1/2;
  grid-row: 2/3;
  display: grid;
  grid-auto-rows: var(--minmax);
  gap: 12px;

  @media (max-width: ${breakpoints.MD}) {
    grid-column: 1/2;
    grid-row: 3/4;
    gap: 8px;
  }
`;

const Item = styled.li``;

const InputPassword = styled(FieldPasswordFormik)`
  max-width: var(--input-width);

  ${Input} {
    background-color: var(--brand-4);

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px var(--brand-4) inset;
    }
  }
`;

const ChecksValidationsPassword = styled(ChecksValidations)`
  grid-column: 2/3;
  grid-row: 2/3;

  @media (max-width: ${breakpoints.MD}) {
    grid-column: 1/2;
    margin-bottom: 24px;
  }
`;

const ListCheckboxs = styled.ul`
  display: grid;
  grid-auto-rows: var(--minmax);
  gap: 8px;
  max-width: 570px;
  margin-bottom: 18px;
`;

const ItemCheckbox = styled.li``;

const Text = styled.p`
  font: var(--font-19);
  color: var(--brand-2);
`;

const Label = styled.div`
  font: var(--font-19);
  color: var(--text);
  align-self: center;
`;

const Link = styled.a`
  color: var(--brand-1);
`;

const TextLabel = styled.p`
  display: inline;
  color: var(--brand-1);
`;

const WrapButton = styled.div`
  margin-top: 48px;

  @media (max-width: ${breakpoints.XL}) {
    margin-top: 54px;
  }

  @media (max-width: ${breakpoints.LG}) {
    margin-top: 64px;
  }

  @media (max-width: ${breakpoints.MD}) {
    margin-top: 60px;
  }
`;

const Warning = styled(DataWarning)`
  margin-bottom: 4px;
`;

const ButtonRegistration = styled(Button)`
  max-width: 380px;
  margin-bottom: 12px;
`;

const TextWrapButton = styled(Text)`
  @media (max-width: ${breakpoints.LG}) {
    max-width: 312px;
  }
`;

const LinkWrapButton = styled.a`
  font: var(--font-17);
`;

export {
  FormUserPassword,
  Wrap,
  SubTitle,
  List,
  Item,
  InputPassword,
  ChecksValidationsPassword,
  ListCheckboxs,
  ItemCheckbox,
  Text,
  Label,
  Link,
  TextLabel,
  WrapButton,
  Warning,
  ButtonRegistration,
  TextWrapButton,
  LinkWrapButton,
};
