import styled from "styled-components";
import { breakpoints } from "../../../config";

const Faq = styled.section``;

const Title = styled.h2`
  font: var(--font-3);
  letter-spacing: -1.68px;
  color: var(--brand-1);
  margin-bottom: 40px;

  @media (max-width: ${breakpoints.MD}) {
    margin-bottom: 32px;
  }
`;

const List = styled.ul`
  display: flex;
  gap: 138px;

  @media (max-width: ${breakpoints.XXL}) {
    gap: 80px;
  }

  @media (max-width: ${breakpoints.XL}) {
    gap: 64px;
  }

  @media (max-width: ${breakpoints.LG}) {
    flex-direction: column;
    gap: 32px;
  }
`;

const Item = styled.li``;

export { Faq, Title, List, Item };
