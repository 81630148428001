import * as React from "react";
import { FC } from "react";

export const IconTick: FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox={"0 0 16 16"}
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="currentColor"
        d="m4.488 7.035 2.207 3.745c2.288-3.584 4.856-6.726 7.184-8.538.522-.484 1.325-.202.763.322-2.609 2.698-4.616 5.76-6.663 10.553-.802.563-1.725.845-2.167.322l-2.89-5.196c-.4-.645 1.004-2.135 1.566-1.208Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M2.844 2h12v12h-12z" />
      </clipPath>
    </defs>
  </svg>
);
