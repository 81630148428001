import styled, { css } from "styled-components";

type ButtonStopwatchType = {
  $isDisabled?: boolean;
};

const ButtonStopwatch = styled.button<ButtonStopwatchType>`
  font: var(--font-19);
  color: var(--interface-dark);
  will-change: transform;
  transition: transform 300ms ease, color 300ms ease;

  ${(props) =>
    props.$isDisabled &&
    css`
      color: var(--brand-2);
      pointer-events: none;
    `};

  &:active {
    transform: scale(0.97);
  }

  &:hover {
    color: var(--hyperlink);
  }
`;

const Timer = styled.span``;

export { ButtonStopwatch, Timer };
