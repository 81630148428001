import styled, { css } from "styled-components";
import { IconClose } from "../../../shared";

type IconCloseType = {
  $isError: boolean;
};

const FieldPhoneFormik = styled.div``;

const Icon = styled(IconClose)<IconCloseType>`
  display: flex;
  width: 20px;
  height: 20px;
  color: var(--interface);

  ${(props) =>
    props.$isError &&
    css`
      color: var(--pink-for-svg);
    `}
`;

export { FieldPhoneFormik, Icon };
