import styled, { css } from "styled-components";
import { breakpoints } from "../../../config";

type CardLinkHoverType = {
  $isBlueHover?: boolean;
};

const Image = styled.img<CardLinkHoverType>`
  position: absolute;
  right: 5px;
  bottom: 0;
  width: 223px;
  height: auto;
  z-index: 100;
  transition: ease-in-out 300ms;

  @media (max-width: ${breakpoints.XXL}) {
    width: 195px;

    ${(props) =>
      props.$isBlueHover &&
      css`
        width: 225px;
        right: -20px;
      `};
  }

  @media (max-width: ${breakpoints.XL}) {
    ${(props) =>
      props.$isBlueHover &&
      css`
        right: 5px;
        bottom: -10px;
        width: 210px;
      `};
  }

  @media (max-width: ${breakpoints.MD}) {
    width: 167px;

    ${(props) =>
      props.$isBlueHover &&
      css`
        top: 10px;
        right: 20px;
        bottom: unset;
        width: 163px;
      `};
  }
`;

const BgVector = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  height: 100%;
  color: var(--inactive);
  z-index: 1;
  transition: ease-in-out 300ms;

  & svg {
    width: 100%;
    height: 100%;
    border-radius: 36px;
  }
`;

const CardLink = styled.article<CardLinkHoverType>`
  position: relative;
  width: 100%;
  max-width: 424px;
  min-height: 184px;
  background-color: var(--brand-4);
  border-radius: 36px;
  z-index: ${(props) => (props.$isBlueHover ? "0" : "1")};
  transition: ease-in-out 300ms;

  @media (max-width: ${breakpoints.XL}) {
    min-height: 255px;
  }

  @media (max-width: ${breakpoints.LG}) {
    max-width: unset;
  }

  @media (max-width: ${breakpoints.MD}) {
    min-height: 150px;
  }

  &:hover {
    background-color: ${(props) =>
      props.$isBlueHover ? "var(--blue-bg)" : "var(--pink)"};
    transform: scale(0.97);
  }

  &:hover ${BgVector} {
    color: ${(props) =>
      props.$isBlueHover ? "var(--blue-vector)" : "var(--pink-for-svg)"};
  }

  &:hover ${Image} {
    transform: scale(1.4);
    transform-origin: 95px 35px;

    @media (max-width: ${breakpoints.XXL}) {
      transform: scale(1.2);
    }

    @media (max-width: ${breakpoints.XL}) {
      transform: scale(1.25);
      transform-origin: 110px 60px;
    }
  }

  &:active {
    transform: scale(0.99);
  }

  &:active ${Image} {
    transform: scale(1.2);

    @media (max-width: ${breakpoints.XXL}) {
      transform: scale(1.1);
    }
  }
`;

const Link = styled.a`
  display: block;
  width: 100%;
  height: 100%;
  padding: 30px;

  @media (max-width: ${breakpoints.XXL}) {
    padding: 32px;
  }

  @media (max-width: ${breakpoints.MD}) {
    padding: 24px;
  }
`;

const Text = styled.h4`
  position: relative;
  font: var(--font-8);
  color: var(--interface-dark);
  max-width: 200px;
  will-change: transform;
  letter-spacing: -0.48px;
  z-index: 2;

  @media (max-width: ${breakpoints.XL}) {
    max-width: 185px;
  }

  @media (max-width: ${breakpoints.XS}) {
    max-width: 160px;
  }
`;

export { CardLink, Link, Text, Image, BgVector };
