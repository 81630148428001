import React, { FC } from "react";
import * as s from "./Button-links-list-styles";

type ButtonLinkType = {
  label: string;
};

export type ButtonLinksListType = {
  className?: string;
  title?: string;
  buttons: Array<ButtonLinkType>;
  onClickButtonLink: (label: string) => () => void;
};

export const ButtonLinksList: FC<ButtonLinksListType> = ({
  className,
  title,
  buttons,
  onClickButtonLink,
}) => {
  return (
    <s.ButtonLinksList className={className}>
      {title && <s.Title>{title}</s.Title>}
      <s.List data-testid={"button-list-test"}>
        {buttons.map((button, index) => (
          <s.Item key={index}>
            <s.ButtonLink onClick={onClickButtonLink(button.label)}>
              {button.label}
            </s.ButtonLink>
          </s.Item>
        ))}
      </s.List>
    </s.ButtonLinksList>
  );
};
