import React, { FC, ReactNode } from "react";
import * as s from "./Card-link-styles";
import { breakpoints } from "../../../config";

type CardLinkType = {
  className?: string;
  text: string;
  imgDesktop: string;
  imgDesktop_2x: string;
  imgTablet: string;
  imgTablet_2x: string;
  imgDesktopWebp: string;
  imgDesktopWebp_2x: string;
  imgTabletWebp: string;
  imgTabletWebp_2x: string;
  bgVector: ReactNode;
  link: string;
  altImage: string;
  isBlueHover?: boolean;
};

export const CardLink: FC<CardLinkType> = ({
  className,
  text,
  imgDesktop,
  imgDesktop_2x,
  imgTablet,
  imgTablet_2x,
  imgDesktopWebp,
  imgDesktopWebp_2x,
  imgTabletWebp,
  imgTabletWebp_2x,
  bgVector,
  link,
  altImage,
  isBlueHover,
}) => {
  return (
    <s.CardLink className={className} $isBlueHover={isBlueHover}>
      <s.Link href={link} target={"_blank"}>
        <s.Text>{text}</s.Text>

        <picture>
          <source
            srcSet={`${imgTabletWebp} 1x, ${imgTabletWebp_2x} 2x`}
            media={`(max-width: ${breakpoints.XL})`}
            type="image/webp"
          />

          <source
            srcSet={`${imgTablet} 1x, ${imgTablet_2x} 2x`}
            media={`(max-width: ${breakpoints.XL})`}
          />

          <s.Image
            $isBlueHover={isBlueHover}
            src={imgDesktop}
            srcSet={`${imgDesktopWebp} 1x, ${imgDesktopWebp_2x} 2x, ${imgDesktop} 1x, ${imgDesktop_2x} 2x`}
            alt={altImage}
          />
        </picture>

        <s.BgVector>{bgVector}</s.BgVector>
      </s.Link>
    </s.CardLink>
  );
};
