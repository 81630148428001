import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { recoveryApi } from "../../api/recovery-api";
import { InitialValuesFormikRecoverEmailType } from "../../../../pages/recover-password-email";
import {
  RecoveryRequestCodeBodyType,
  RecoveryRequestNewPasswordBodyType,
} from "../type/recovery-type";

export const sendRecoveryEmailThunk = createAsyncThunk(
  "recovery/send-recovery-email",
  async (
    body: InitialValuesFormikRecoverEmailType,
    { rejectWithValue, dispatch }
  ) => {
    try {
      await recoveryApi.recovery_email(body);
    } catch (e) {
      const error = e as AxiosError<any>;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const sendRecoveryCodeThunk = createAsyncThunk(
  "recovery/send-recovery-email",
  async (body: RecoveryRequestCodeBodyType, { rejectWithValue, dispatch }) => {
    try {
      await recoveryApi.recovery_code(body);
    } catch (e) {
      const error = e as AxiosError<any>;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const sendRecoveryNewPasswordThunk = createAsyncThunk(
  "recovery/send-recovery-email",
  async (
    body: RecoveryRequestNewPasswordBodyType,
    { rejectWithValue, dispatch }
  ) => {
    try {
      await recoveryApi.recovery_new_password(body);
    } catch (e) {
      const error = e as AxiosError<any>;
      return rejectWithValue(error?.response?.data);
    }
  }
);
