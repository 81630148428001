import styled, { css } from "styled-components";

type ButtonTimeType = {
  $isActive: boolean;
};

const ButtonTime = styled.button<ButtonTimeType>`
  font: var(--font-14);
  color: var(--interface-dark);
  padding: 6px 12px;
  border-radius: 8px;
  background-color: transparent;
  border: 1px solid transparent;
  will-change: transform;
  transition: transform 300ms ease, background-color 300ms ease,
    border 300ms ease;

  ${(props) =>
    props.$isActive
      ? css`
          background-color: var(--inactive);
        `
      : css`
          &:hover {
            border: 1px solid var(--inactive);
          }

          &:active {
            transform: scale(0.95);
          }

          &:focus-visible {
            outline: 2px solid var(--brand-1);
          }
        `};

  &:first-letter {
    text-transform: uppercase;
  }
`;

export {ButtonTime};
