import React, { FC, ReactNode } from "react";
import * as s from "./Receipt-styles";
import { ErrorInfoTooltip, InfoBonusTooltip } from "../../molecules";
import { CounterRoyals } from "../../atom";
import { StatusProperty } from "../../../config";

type ReceiptType = {
  className?: string;
  index: number;
  dispatchDate: string;
  isInfoBonusTooltip: boolean;
  isTooltipVisibility: boolean;
  infoBonusTooltipCounterRoyals: string;
  infoBonusTooltipDate: string;
  onInfoBonusTooltipHandleMouseEnter: () => void;
  onInfoBonusTooltipHandleMouseLeave: () => void;
  infoBonusTooltipIcon: ReactNode;
  isCounterRoyals: boolean;
  counterRoyalsValue: string;
  approvedDate: string;
  receiptStatus:
    | StatusProperty.DEFAULT
    | StatusProperty.IN_PROCESS
    | StatusProperty.SUCCESS
    | StatusProperty.ERROR
    | StatusProperty.COMMON;
  statusCheckText: string;
  isErrorInfoTooltip: Boolean;
  errorInfoTooltipIcon: ReactNode;
  isErrorTooltipVisibility: boolean;
  errorInfoTooltipStatus: string;
  onErrorInfoTooltipHandleMouseErrorEnter: () => void;
  onErrorInfoTooltipHandleMouseErrorLeave: () => void;
};

export const Receipt: FC<ReceiptType> = ({
  className,
  index,
  dispatchDate,
  isInfoBonusTooltip,
  isTooltipVisibility,
  infoBonusTooltipCounterRoyals,
  infoBonusTooltipDate,
  onInfoBonusTooltipHandleMouseEnter,
  onInfoBonusTooltipHandleMouseLeave,
  infoBonusTooltipIcon,
  isCounterRoyals,
  counterRoyalsValue,
  approvedDate,
  receiptStatus,
  statusCheckText,
  isErrorInfoTooltip,
  errorInfoTooltipIcon,
  isErrorTooltipVisibility,
  errorInfoTooltipStatus,
  onErrorInfoTooltipHandleMouseErrorEnter,
  onErrorInfoTooltipHandleMouseErrorLeave,
}) => {
  const variantAnimationReceipt = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
  };

  return (
    <s.Receipt
      className={className}
      isShow={true}
      delay={index}
      variants={variantAnimationReceipt}
    >
      <s.Date>
        Чек от {dispatchDate}
        {isInfoBonusTooltip ? (
          <InfoBonusTooltip
            isTooltipVisibility={isTooltipVisibility}
            counterRoyals={infoBonusTooltipCounterRoyals}
            date={infoBonusTooltipDate}
            onHandleMouseEnter={onInfoBonusTooltipHandleMouseEnter}
            onHandleMouseLeave={onInfoBonusTooltipHandleMouseLeave}
            icon={infoBonusTooltipIcon}
          />
        ) : null}
      </s.Date>
      <s.Bonus>
        {isCounterRoyals ? (
          <>
            <CounterRoyals counter={counterRoyalsValue} />
            зачислены {approvedDate}
          </>
        ) : null}
      </s.Bonus>
      <s.StatusCheck
        status={receiptStatus}
        text={statusCheckText}
        icon={
          isErrorInfoTooltip ? (
            <ErrorInfoTooltip
              icon={errorInfoTooltipIcon}
              isErrorTooltipVisibility={isErrorTooltipVisibility}
              status={errorInfoTooltipStatus}
              onHandleMouseErrorEnter={onErrorInfoTooltipHandleMouseErrorEnter}
              onHandleMouseErrorLeave={onErrorInfoTooltipHandleMouseErrorLeave}
            />
          ) : null
        }
      />
    </s.Receipt>
  );
};
