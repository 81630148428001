import React, { ChangeEvent, FC, useEffect, useState } from "react";
import * as s from "./Select-search-formik-styles";
import { ButtonsDropdownItemType, SelectSearch } from "../../../shared";
import { FieldHelperProps, FieldInputProps, FieldMetaProps } from "formik";

type SelectSearchFormikType = FieldMetaProps<any> &
  FieldHelperProps<any> &
  FieldInputProps<any> & {
    className?: string;
    placeholderInput?: string;
    disabled?: boolean;
    list: Array<ButtonsDropdownItemType>;
  };

export const SelectSearchFormik: FC<SelectSearchFormikType> = ({
  className,
  disabled,
  value,
  placeholderInput,
  name,
  list,
  setValue,
  touched,
  error,
  onBlur,
  onChange,
}) => {
  const [isIOS, setIsIOS] = useState(false);
  const [listElement, setListElement] =
    useState<Array<ButtonsDropdownItemType>>(list);

  const [isListOpen, setisListOpen] = useState(false);
  const errorInput = touched ? (error ? error : "") : "";

  const onSortList = (value: string) => {
    const filter = list.filter((el) =>
      el.value.toLowerCase().includes(value.toLowerCase())
    );
    setListElement(filter);
  };

  const onChangeValueInput = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e);
    onSortList(e.currentTarget.value);
  };

  const onClickInputShowList = (value: string) => () => {
    setisListOpen(true);
    onSortList(value);
  };

  const onShowList = (value: string) => () => {
    setisListOpen((value) => !value);
    onSortList(value);
  };

  const onClickButtonItem = (id: string, value: string) => () => {
    setValue(value);
    onShowList(value)();
  };

  const onCloseValue = () => {
    setValue("");
    setListElement(list);
  };

  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    setIsIOS(/iPad|iPhone|iPod/.test(userAgent));
  }, []);

  return (
    <s.SelectSearchFormik className={className} $isActive={isListOpen}>
      <SelectSearch
        name={name}
        disabledInput={disabled}
        typeInput={"text"}
        isIOS={isIOS}
        valueInput={value}
        isListOpen={isListOpen}
        errorInput={errorInput}
        buttons={listElement}
        iconInput={
          value ? (
            <s.IconClose $isError={!!errorInput} />
          ) : (
            <s.IconArrow $isActive={isListOpen} $isError={!!errorInput} />
          )
        }
        placeholderInput={placeholderInput}
        onChangeInput={onChangeValueInput}
        onClickInput={onClickInputShowList(value)}
        onBlurInput={onBlur}
        onClickButtonItem={onClickButtonItem}
        onClickButtonIcon={value ? onCloseValue : onShowList(value)}
        onClickButtonBg={onShowList(value)}
      />
    </s.SelectSearchFormik>
  );
};
