import React, { FC, ReactNode } from "react";
import { StatusProperty } from "../../../config";
import * as s from "./Status-styles";

type StatusType = {
  className?: string;
  text: string | null;
  status:
    | StatusProperty.DEFAULT
    | StatusProperty.IN_PROCESS
    | StatusProperty.SUCCESS
    | StatusProperty.ERROR
    | StatusProperty.COMMON;
  icon?: ReactNode;
};

export const Status: FC<StatusType> = ({ className, text, status, icon }) => {
  return (
    <s.Status className={className} $status={status}>
      {text}
      {icon}
    </s.Status>
  );
};
