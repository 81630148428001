import React, { FC, useState } from "react";
import { UploadReceipt } from "../../../../widgets";
import * as s from "./Modal-receipt-styles";
import { breakpoints } from "../../../../shared/config";
import billHigh1440 from "../../../../shared/config/assets/images/checks-bill-high-1440.jpg";
import billHigh1440_2x from "../../../../shared/config/assets/images/checks-bill-high-1440@2x.jpg";
import billHigh1440Webp from "../../../../shared/config/assets/images/checks-bill-high-1440.webp";
import billHigh1440_2xWebp from "../../../../shared/config/assets/images/checks-bill-high-1440@2x.webp";
import invoiceHigh1440 from "../../../../shared/config/assets/images/checks-invoice-high-1440.jpg";
import invoiceHigh1440_2x from "../../../../shared/config/assets/images/checks-invoice-high-1440@2x.jpg";
import invoiceHigh1440Webp from "../../../../shared/config/assets/images/checks-invoice-high-1440.webp";
import invoiceHigh1440_2xWebp from "../../../../shared/config/assets/images/checks-invoice-high-1440@2x.webp";
import bill375 from "../../../../shared/config/assets/images/checks-bill-375.jpg";
import bill375_2x from "../../../../shared/config/assets/images/checks-bill-375@2x.jpg";
import bill375Webp from "../../../../shared/config/assets/images/checks-bill-375.webp";
import bill375_2xWebp from "../../../../shared/config/assets/images/checks-bill-375@2x.webp";
import invoice375 from "../../../../shared/config/assets/images/checks-invoice-375.jpg";
import invoice375_2x from "../../../../shared/config/assets/images/checks-invoice-375@2x.jpg";
import invoice375Webp from "../../../../shared/config/assets/images/checks-invoice-375.webp";
import invoice375_2xWebp from "../../../../shared/config/assets/images/checks-invoice-375@2x.webp";
import bill1440 from "../../../../shared/config/assets/images/checks-bill-1440.jpg";
import bill1440_2x from "../../../../shared/config/assets/images/checks-bill-1440@2x.jpg";
import bill1440Webp from "../../../../shared/config/assets/images/checks-bill-1440.webp";
import bill1440_2xWebp from "../../../../shared/config/assets/images/checks-bill-1440@2x.webp";
import invoice1440 from "../../../../shared/config/assets/images/checks-invoice-1440.jpg";
import invoice1440_2x from "../../../../shared/config/assets/images/checks-invoice-1440@2x.jpg";
import invoice1440Webp from "../../../../shared/config/assets/images/checks-invoice-1440.webp";
import invoice1440_2xWebp from "../../../../shared/config/assets/images/checks-invoice-1440@2x.webp";
import { useAppSelector } from "../../../../shared/lib";
import { selectDiscount } from "../../../../entities/discount/model";
import { IconUpload } from "../../../../shared";

type ModalReceiptType = {
  onShowModal: () => void;
};

type ModalReceiptActiveExample = "receipt" | "invoice";

export const ModalReceipt: FC<ModalReceiptType> = ({ onShowModal }) => {
  const { isReceiptsCurrentDate } = useAppSelector(selectDiscount);
  const [activeItem, setActiveItem] = useState("receipt");
  const [zoom, setZoom] = useState(false);
  const [isModal, setModal] = useState(false);

  const onOpenModal = () => {
    setModal((value) => !value);
  };

  const receiptsDescriptionList: string[] = [
    "Наименование товаров - влажные и сухие рационы Royal\u00A0Canin®. Список участвующих товаров в Правилах Акции;",
    "Наименование магазина, в котором была совершена покупка, и его ИНН;",
    "Дата и время совершения покупки (не ранее 18.03.2024);",
    "Номер чека (номер в смене);",
    "Итоговая сумма покупки;",
    "Фискальные данные чека ФН, ФД, ФП QR-код;",
  ];
  const invoiceDescriptionList: string[] = [
    "ИНН и название Юридического лица, адрес магазина.",
    "Купленные товары. В накладной обязательно должен присутствовать рацион от Royal\u00A0Canin®. В акции участвуют только рационы для щенков и котят.",
    "Дата и время совершения покупки (не ранее 18.03.2024);",
    "Печать организации.",
  ];

  const uploadDescriptionList: string[] = [
    "На фото должен присутствовать только один чек.",
    "Чек должен быть сфотографирован полностью. Если чек длинный, то Вы можете сфотографировать его по частям (не более 5 изображений).",
    "Изображение (я) должны быть загружены в формате JPEG/PNG или PDF весом не более 20Мб.",
    "QR-код чека должен быть отчетливо виден на фотографии или при сканировании.",
  ];

  const setActive = (item: ModalReceiptActiveExample): void => {
    setActiveItem(item);
  };

  const setZoomImg = (item: boolean): void => {
    setZoom(item);
  };

  const ReceiptExampleDescriptionList = () => {
    if (activeItem === "receipt") {
      return receiptsDescriptionList.map(
        (receiptDesc: string, index: number) => (
          <li key={index}>{receiptDesc}</li>
        )
      );
    } else {
      return invoiceDescriptionList.map(
        (invoiceDesc: string, index: number) => (
          <li key={index}>{invoiceDesc}</li>
        )
      );
    }
  };

  const UploadDescriptionList = () => {
    return uploadDescriptionList.map((uploadDesc: string, index: number) => (
      <li key={index}>{uploadDesc}</li>
    ));
  };

  const isActiveReceipt = (): string => {
    return activeItem === "receipt" ? "active receipt" : "receipt";
  };

  const isActiveInvoice = (): string => {
    return activeItem === "invoice" ? "active invoice" : "invoice";
  };

  const isActiveImgAlt = (): string => {
    return activeItem === "receipt" ? "bill" : "invoice";
  };

  const hasZoomModal = () => {
    return zoom ? (
      <s.ZoomImgModal onClick={() => setZoomImg(false)}>
        {activeItem === "receipt" ? (
          <picture>
            <s.ZoomImgReceipt
              src={billHigh1440}
              srcSet={`${billHigh1440Webp} 1x, ${billHigh1440_2xWebp} 2x, ${billHigh1440} 1x, ${billHigh1440_2x} 2x`}
              alt={isActiveImgAlt()}
            />
          </picture>
        ) : (
          <picture>
            <s.ZoomImg
              src={invoiceHigh1440}
              srcSet={`${invoiceHigh1440Webp} 1x, ${invoiceHigh1440_2xWebp} 2x, ${invoiceHigh1440} 1x, ${invoiceHigh1440_2x} 2x`}
              alt={isActiveImgAlt()}
            />
          </picture>
        )}
      </s.ZoomImgModal>
    ) : null;
  };

  return (
    <>
      <s.ModalReceipt>
        <s.Modal
          onClickButtonBg={onShowModal}
          onClickButtonIcon={onShowModal}
          buttonRedirectText={"К чекам"}
        >
          <s.ReceiptInfo>
            <s.Title>Загрузить чек</s.Title>

            <s.ReceiptExamples>
              <s.ReceiptExampleBar>
                <s.ReceiptExampleBarTitle>
                  Что должно быть на фото
                </s.ReceiptExampleBarTitle>

                <s.ReceiptExampleBarItem
                  className={isActiveReceipt()}
                  onClick={() => setActive("receipt")}
                >
                  Чек
                </s.ReceiptExampleBarItem>

                <s.ReceiptExampleBarItem
                  className={isActiveInvoice()}
                  onClick={() => setActive("invoice")}
                >
                  Накладная
                </s.ReceiptExampleBarItem>
              </s.ReceiptExampleBar>

              <s.ReceiptExampleInfo>
                <s.ReceiptExampleInfoImageContainer
                  onClick={() => setZoomImg(true)}
                >
                  {activeItem === "receipt" ? (
                    <picture>
                      <source
                        srcSet={`${bill375Webp} 1x, ${bill375_2xWebp} 2x`}
                        media={`(max-width: ${breakpoints.MD})`}
                        type="image/webp"
                      />

                      <source
                        srcSet={`${bill375} 1x, ${bill375_2x} 2x`}
                        media={`(max-width: ${breakpoints.MD})`}
                      />

                      <s.ReceiptExampleInfoImage
                        src={bill1440}
                        srcSet={`${bill1440Webp} 1x, ${bill1440_2xWebp} 2x, ${bill1440} 1x, ${bill1440_2x} 2x`}
                        alt={isActiveImgAlt()}
                      />
                    </picture>
                  ) : (
                    <picture>
                      <source
                        srcSet={`${invoice375Webp} 1x, ${invoice375_2xWebp} 2x`}
                        media={`(max-width: ${breakpoints.MD})`}
                        type="image/webp"
                      />

                      <source
                        srcSet={`${invoice375} 1x, ${invoice375_2x} 2x`}
                        media={`(max-width: ${breakpoints.MD})`}
                      />

                      <s.ReceiptExampleInfoImage
                        src={invoice1440}
                        srcSet={`${invoice1440Webp} 1x, ${invoice1440_2xWebp} 2x, ${invoice1440} 1x, ${invoice1440_2x} 2x`}
                        alt={isActiveImgAlt()}
                      />
                    </picture>
                  )}

                  <s.ReceiptExampleInfoImageZoom width={24} height={24} />
                </s.ReceiptExampleInfoImageContainer>

                <s.ReceiptExampleDescription>
                  <s.ReceiptExampleDescriptionTitle>
                    {`В ${
                      activeItem === "receipt" ? "чеке" : "накладной"
                    } обязательно должны присутствовать:`}
                  </s.ReceiptExampleDescriptionTitle>

                  <s.ReceiptExampleDescriptionList>
                    {ReceiptExampleDescriptionList()}
                  </s.ReceiptExampleDescriptionList>
                </s.ReceiptExampleDescription>
              </s.ReceiptExampleInfo>
            </s.ReceiptExamples>
          </s.ReceiptInfo>

          <s.UploadReceipt>
            {isReceiptsCurrentDate ? (
              <UploadReceipt />
            ) : (
              <s.ButtonUploadReceipt
                type={"button"}
                icon={<IconUpload height={24} width={24} />}
                onClickButtonUpload={onOpenModal}
                text="загрузить чек"
              />
            )}

            <s.UploadListDescription>
              {UploadDescriptionList()}
            </s.UploadListDescription>
          </s.UploadReceipt>

          {hasZoomModal()}
        </s.Modal>
      </s.ModalReceipt>
      {isModal && (
        <s.ModalButton
          onClickButtonBg={onOpenModal}
          onClickButtonIcon={onOpenModal}
        >
          <s.ModalButtonTitle>Не более 3-х чеков за сутки</s.ModalButtonTitle>

          <s.ModalButtonText>
            Попробуйте загрузить чек завтра. На сегодня лимит загрузки исчерпан.
          </s.ModalButtonText>
        </s.ModalButton>
      )}
    </>
  );
};
