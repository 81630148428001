import styled from "styled-components";
import { NavLink } from "react-router-dom";

const LinkIconText = styled(NavLink)`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 20px 24px 20px;
  font: var(--font-9);
  color: var(--hyperlink);
  background-color: transparent;
  border-radius: 12px;
  will-change: transform;
  transition: background-color 300ms ease, transform 300ms ease;

  &:hover {
    background-color: var(--brand-4);
  }

  &:active {
    transform: scale(0.95);
  }

  &.active {
    background-color: var(--brand-4);
  }
`;

export {LinkIconText};
