import React, { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { ButtonProperty, pages } from "../../../shared/config";
import * as s from "./Recover-password-restored-styles";
import { useScrollToTop } from "../../../shared/lib";

type RecoverPasswordRestoredType = {};

export const RecoverPasswordRestored: FC<
  RecoverPasswordRestoredType
> = ({}) => {
  useScrollToTop();

  const navigate = useNavigate();

  const routeToSignIn = useCallback(() => {
    navigate(pages.signIn.link);
  }, [navigate]);

  return (
    <s.RecoverPasswordRestored>
      <s.Title>Пароль восстановлен</s.Title>

      <s.Subtitle>Нажмите «Войти», чтобы авторизоваться</s.Subtitle>

      <s.ButtonToSignIn
        property={ButtonProperty.SECONDARY}
        type={"button"}
        text={"Войти"}
        onClickButton={routeToSignIn}
      />
    </s.RecoverPasswordRestored>
  );
};
