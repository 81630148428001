import styled from "styled-components";

const DataWarning = styled.div`
  padding: 24px 30px;
  font: var(--font-18);
  color: var(--brand-1);
  max-width: 381px;
  border-radius: 8px;
  background-color: var(--pink);
  letter-spacing: -0.64px;
`;

export { DataWarning };
