import styled from "styled-components";
import { Animation, ButtonIcon } from "../../atom";
import { breakpoints, stylesScrollbarMixin } from "../../../config";

const ModalBody = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10000;
  display: flex;
  overflow: auto;

  ${stylesScrollbarMixin("4px", "4px")};
`;

const ButtonBg = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--interface-dark);
  opacity: 0.3;
  z-index: 0;

  @media (max-width: ${breakpoints.LG}) {
    display: none;
  }
`;

const ButtonRedirect = styled.button`
  display: none;

  @media (max-width: ${breakpoints.LG}) {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-bottom: 48px;
    font: var(--font-19);
    color: var(--brand-2);
  }

  @media (max-width: ${breakpoints.MD}) {
    margin-bottom: 36px;
  }
`;

const BodyAnimation = styled(Animation)`
  position: relative;
  margin: auto;
  width: 100%;
  padding: 40px;
  background-color: var(--brand-3);
  border-radius: 24px;
  max-height: 100%;
  overflow: auto;
  ${stylesScrollbarMixin("4px", "4px")};

  @media (max-width: ${breakpoints.LG}) {
    min-height: 100vh;
    margin: auto;
    padding: 24px clamp(12px, 7.12vw + -14.72px, 40px) 40px;
    border-radius: unset;
  }
`;

const ButtonIconBody = styled(ButtonIcon)`
  position: absolute;
  top: 24px;
  right: 24px;

  @media (max-width: ${breakpoints.LG}) {
    display: none;
  }

  &:hover {
    color: var(--interface-dark);
  }
`;

export {ModalBody, ButtonBg, ButtonRedirect, BodyAnimation, ButtonIconBody};
