import { environment } from "../../environment";
import { authApi } from "../../entities/auth/api/auth-api";
import axios from "axios";

export const instance = axios.create({
  baseURL: environment.apiUrl,
  withCredentials: true,
});

let refreshingTokenPromise: Promise<any> | null = null;

const refreshTokenIfNeeded = async (): Promise<any> => {
  if (!refreshingTokenPromise) {
    const token = JSON.parse(localStorage.getItem("token") as string);
    if (token?.access) {
      refreshingTokenPromise = (async () => {
        try {
          // Отправляем запрос на обновление токена (refresh token)
          const newAccessToken = await authApi.refreshToken(token.refresh);
          const newToken = JSON.stringify({
            access: newAccessToken.data.access,
            refresh: token.refresh,
          });

          // Обновляем JWT-токен в localStorage или в хранилище вашего приложения
          localStorage.setItem("token", newToken);

          return newAccessToken.data.access;
        } catch (refreshError) {
          // Если обновление токена не удалось, обновляет страницу
          localStorage.removeItem("token");
          window.location.reload();
          console.log("Не удалось обновить токен:", refreshError);
          // Redirect to login page or handle error
          throw refreshError;
        } finally {
          refreshingTokenPromise = null;
        }
      })();
    }
  }

  return refreshingTokenPromise;
};

instance.interceptors.request.use(async (config: any): Promise<any> => {
  const token = JSON.parse(localStorage.getItem("token") as string);

  if (token?.access) {
    config.headers.Authorization = `Bearer ${token.access}`;
  }
  return config;
});

instance.interceptors.response.use(
  (response: any): any => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    const token = JSON.parse(localStorage.getItem("token") as string);
    // Проверяем статус ошибки и наличие JWT-токена
    if (
      token &&
      error.response.status === 401 &&
      originalRequest &&
      !originalRequest.url.includes("auth/jwt/refresh/") &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      try {
        const newAccessToken = await refreshTokenIfNeeded();

        // Повторяем исходный запрос с новым токеном
        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
        return instance(originalRequest);
      } catch (refreshError) {
        // Возвращаем ошибку в случае, если не удалось обновить токен или другая ошибка
        return Promise.reject(refreshError);
      }
    }

    // Возвращаем ошибку в случае, если не удалось обновить токен или другая ошибка
    return Promise.reject(error);
  }
);
