import styled from "styled-components";
import { ModalBodyRight } from "../../../../shared";
import { BodyAnimation } from "../../../../shared/ui/molecules/modal-body-right/Modal-body-right-styles";
import { breakpoints } from "../../../../shared/config";

const ModalFrequentlyAskedQuestion = styled.div``;

const Modal = styled(ModalBodyRight)`
  ${BodyAnimation} {
    padding: 80px 88px 96px 64px;

    @media (max-width: ${breakpoints.XXL}) {
      padding: 70px 42px 56px 60px;
    }

    @media (max-width: ${breakpoints.XL}) {
      padding: 50px 40px 35px 50px;
    }

    @media (max-width: ${breakpoints.LG}) {
      padding: 56px 90px 70px 56px;
    }

    @media (max-width: ${breakpoints.LG}) {
      padding: 65px 20px 30px;
    }
  }
`;

const Title = styled.h2`
  margin-bottom: 24px;
  font: var(--font-8);
  color: var(--interface-dark);
`;

const List = styled.ul`
  display: grid;
  grid-auto-rows: var(--minmax);
  gap: 12px;
  margin-bottom: 80px;

  @media (max-width: ${breakpoints.XXL}) {
    margin-bottom: 60px;
  }

  @media (max-width: ${breakpoints.XL}) {
    margin-bottom: 32px;
  }

  @media (max-width: ${breakpoints.LG}) {
    margin-bottom: 40px;
  }
`;

const Item = styled.li`
  font: var(--font-11);
  color: var(--brand-2);
`;

const SubList = styled.ul`
  display: grid;
  grid-auto-rows: var(--minmax);
  gap: 12px;
  margin-top: 16px;
`;

const SubItem = styled.li`
  position: relative;
  padding-left: 16px;
  line-height: 1;

  &:before {
    content: "";
    position: absolute;
    top: 4px;
    left: 0;
    width: 8px;
    height: 8px;
    background-color: currentColor;
    border-radius: 50%;

    @media (max-width: ${breakpoints.LG}) {
      width: 6px;
      height: 6px;
    }
  }

  @media (max-width: ${breakpoints.LG}) {
    padding-left: 14px;
  }
`;

const SubTitle = styled.h3`
  margin-bottom: 16px;
  font: var(--font-14);
  color: var(--interface-dark);
`;

const QuestionList = styled.ul`
  display: grid;
  grid-auto-rows: var(--minmax);
  gap: 12px;
`;

const QuestionItem = styled.li``;

const Button = styled.button`
  font: var(--font-16);
  color: var(--brand-2);
  text-align: left;
  will-change: transform;
  transition: transform 200ms ease;

  &:hover {
    transform: scale(1.02);
  }

  &:active {
    transform: scale(0.98);
  }
`;

export {
  ModalFrequentlyAskedQuestion,
  Modal,
  Title,
  List,
  Item,
  SubList,
  SubItem,
  SubTitle,
  QuestionList,
  QuestionItem,
  Button,
};
