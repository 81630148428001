import React, { FC, ReactNode } from "react";
import * as s from "./Bonus-mechanics-styles";
import { StepsRoyals } from "../../molecules";
import { StepRoyalsType } from "../../atom";

type BonusMechanicsType = {
  className?: string;
  steps: Array<StepRoyalsType>;
  buttonText: string;
  buttonType: "button" | "reset" | "submit";
  buttonIcon: ReactNode;
  onClickButtonUpload: () => void;
};

export const BonusMechanics: FC<BonusMechanicsType> = ({
  className,
  steps,
  buttonText,
  buttonType,
  buttonIcon,
  onClickButtonUpload,
}) => {
  return (
    <s.BonusMechanics className={className}>
      <StepsRoyals steps={steps} />

      <s.Button
        text={buttonText}
        type={buttonType}
        icon={buttonIcon}
        onClickButtonUpload={onClickButtonUpload}
      />
    </s.BonusMechanics>
  );
};
