import styled, { css } from "styled-components";
import { breakpoints } from "../../../config";

type InputType = {
  $isError?: boolean;
  $isFocus?: boolean;
};

const Input = styled.input<InputType>`
  width: 100%;
  padding: 21px 56px 21px 20px;
  font: var(--font-16);
  color: var(--hyperlink);
  background-color: var(--brand-3);
  border-radius: 10px;

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px var(--brand-3) inset;
  }

  ${(props) =>
    props.$isError &&
    css`
      border: 1px solid var(--error) !important;

      &::placeholder {
        color: var(--error) !important;
      }
    `};

  ${(props) =>
    props.$isFocus &&
    css`
      padding: 32px 56px 10px 20px;
    `};

  &[type="button"] {
    cursor: pointer;
    text-align: left;
  }

  &:disabled {
    color: var(--brand-2);
    pointer-events: none;
  }

  &::placeholder {
    color: var(--brand-2);
  }

  @media (max-width: ${breakpoints.XL}) {
    padding: 18px 56px 18px 20px;

    ${(props) =>
      props.$isFocus &&
      css`
        padding: 28px 56px 8px 20px;
      `};
  }
`;

export { Input };
