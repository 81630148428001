import React, { ChangeEvent, FC, ReactNode } from "react";
import * as s from "./Select-search-styles";
import { ButtonsDropdownItemType } from "../../molecules";
import { Animation } from "../../atom";

type SelectSearchType = {
  className?: string;
  name?: string;
  typeInput: string;
  valueInput: string;
  isIOS?: boolean;
  errorInput?: string;
  placeholderInput?: string;
  disabledInput?: boolean;
  isFocusInput?: boolean;
  isListOpen: boolean;
  buttons: Array<ButtonsDropdownItemType>;
  iconInput: ReactNode;
  onChangeInput: (e: ChangeEvent<HTMLInputElement>) => void;
  onClickInput: () => void;
  onBlurInput?: (e: ChangeEvent<HTMLInputElement>) => void;
  onClickButtonItem: (id: string, value: string) => () => void;
  onClickButtonIcon?: () => void;
  onClickButtonBg?: () => void;
};

export const SelectSearch: FC<SelectSearchType> = ({
  className,
  name,
  typeInput,
  valueInput,
  isIOS,
  disabledInput,
  placeholderInput,
  errorInput,
  isFocusInput,
  isListOpen,
  buttons,
  iconInput,
  onChangeInput,
  onClickInput,
  onBlurInput,
  onClickButtonItem,
  onClickButtonIcon,
  onClickButtonBg,
}) => {
  return (
    <s.SelectSearch className={className}>
      <s.InputSearch
        name={name}
        type={typeInput}
        value={valueInput}
        error={errorInput}
        placeholder={placeholderInput}
        isFocus={isFocusInput}
        disabled={disabledInput}
        icon={iconInput}
        onChangeInput={onChangeInput}
        onClickInput={onClickInput}
        onBlurInput={onBlurInput}
        onClickButtonIcon={onClickButtonIcon}
      />

      {isListOpen && <s.ButtonBg type={"button"} onClick={onClickButtonBg} />}

      <Animation isShow={isListOpen}>
        <s.Buttons
          list={buttons}
          onClickButtonItem={onClickButtonItem}
          isIOS={isIOS}
        />
      </Animation>
    </s.SelectSearch>
  );
};
