import React, { FC, ReactNode } from "react";
import * as s from "./Card-royals-styles";

export type CardRoyalsListInfo = {
  index: number;
  text: string;
  subItems?: Array<string>;
};

export type CardRoyalsType = {
  className?: string;
  title: string;
  footnote?: string;
  royals: number;
  image: ReactNode;
  backImage: ReactNode;
  isFlipped?: boolean;
  listInfo: Array<CardRoyalsListInfo>;
  onClickCard?: () => void;
};

export const CardRoyals: FC<CardRoyalsType> = ({
  className,
  title,
  footnote,
  royals,
  image,
  backImage,
  isFlipped,
  listInfo,
  onClickCard,
}) => {
  return (
    <s.CardRoyals className={className} $isFlipped={isFlipped}>
      <s.Body onClick={onClickCard}>
        {image}

        <s.WrapCaption>
          <s.Caption>{footnote ? `до ${royals}` : royals}</s.Caption>

          <s.Icon />
        </s.WrapCaption>

        <s.Content>
          <s.Title>{title}</s.Title>

          {footnote ? <s.Text>{footnote}</s.Text> : ""}
        </s.Content>
      </s.Body>

      {isFlipped && (
        <s.BodyFlipped>
          {backImage}

          <s.List>
            {listInfo.map((item) => (
              <s.Item key={item.index + item.text}>
                <s.Span>{item.index}.</s.Span>

                <s.WrapItem>
                  {item.text}

                  {item.subItems && (
                    <s.SubList>
                      {item.subItems.map((text, index) => (
                        <s.SubItem key={index}>{text}</s.SubItem>
                      ))}
                    </s.SubList>
                  )}
                </s.WrapItem>
              </s.Item>
            ))}
          </s.List>
        </s.BodyFlipped>
      )}
    </s.CardRoyals>
  );
};
