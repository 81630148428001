import React, { FC } from "react";
import * as s from "./Footer-styles";
import { ButtonProperty } from "../../../config";
import imageDog from "../../../config/assets/images/dog-up.png";
import imageDog2x from "../../../config/assets/images/dog-up@2x.png";
import imageDogWebp from "../../../config/assets/images/dog-up.webp";
import imageDogWebp2x from "../../../config/assets/images/dog-up@2x.webp";

type FooterItemsType = {
  text: string;
  href: string;
};

type FooterLinksType = {
  title: string;
  items: Array<FooterItemsType>;
};

type FooterType = {
  className?: string;
  id?: string;
  links: Array<FooterLinksType>;
  onClickButton: () => void;
};

export const Footer: FC<FooterType> = ({
  className,
  links,
  id,
  onClickButton,
}) => {
  return (
    <s.Footer id={id} className={className}>
      <s.SupportForm>
        <s.HeadText>
          <s.Title>Остались вопросы?</s.Title>

          <s.Text>
            Отредактировать личные данные или данные о питомце, уточнить
            информацию по чекам
          </s.Text>
        </s.HeadText>

        <s.ButtonText
          text={"Написать нам"}
          type={"button"}
          property={ButtonProperty.SECONDARY}
          onClickButton={onClickButton}
        />
      </s.SupportForm>

      <s.InfoBlock>
        <s.FaqWrap data-testid={"footer-links-test"}>
          {links.map((el, index) => (
            <s.LinksWrapper key={index}>
              <s.LinksTitle>{el.title}</s.LinksTitle>

              <s.LinksList data-testid={"footer-list-test"}>
                {el.items.map((link, index) => (
                  <s.LinkItem key={index}>
                    <s.LinkDefault href={link.href} target={"_blank"}>
                      {link.text}
                    </s.LinkDefault>
                  </s.LinkItem>
                ))}
              </s.LinksList>
            </s.LinksWrapper>
          ))}
        </s.FaqWrap>

        <s.Copyright>
          © 2024 Royal{"\u00A0"}Canin SAS. Все права защищены. Входит в группу
          компаний Mars, Incorporated®
        </s.Copyright>
      </s.InfoBlock>

      <s.Picture>
        <s.Image
          src={imageDog}
          srcSet={`${imageDogWebp} 1x, ${imageDogWebp2x} 2x, ${imageDog} 1x, ${imageDog2x} 2x`}
          alt="Собака смотрит вверх."
        />
      </s.Picture>
    </s.Footer>
  );
};
