import React, { FC, ReactNode } from "react";
import * as s from "./Select-input-calendar-styles";
import { CalendarType } from "../../molecules";
import { Animation } from "../../atom";

type SelectInputCalendarType = CalendarType & {
  //SelectInputCalendarType
  className?: string;
  onClickButtonBg: () => void;
  //InputType
  name?: string;
  valueInput: string;
  placeholderInput: string;
  icon: ReactNode;
  error?: string;
  onClickButtonIcon: () => void;
  //CalendarType
  isShowCalendar: boolean;
};

export const SelectInputCalendar: FC<SelectInputCalendarType> = ({
  className,
  onClickButtonBg,
  //Input
  name,
  valueInput,
  placeholderInput,
  icon,
  error,
  onClickButtonIcon,
  //Calendar
  typeButton,
  isShowCalendar,
  textButtonMonth,
  textButtonYear,
  isActiveButtonMonth,
  isActiveButtonYear,
  listMonth,
  listYear,
  setIsActiveButtonDate,
  onClickButtonTimeMonth,
  onClickButtonTimeYear,
  onClickButtonIconClose,
  onClickButtonDateMonth,
  onClickButtonDateYear,
}) => {
  return (
    <s.SelectInputCalendar className={className}>
      <s.Input
        name={name}
        type={"button"}
        value={valueInput}
        placeholder={placeholderInput}
        error={error}
        icon={icon}
        onClickInput={onClickButtonIcon}
        onChangeInput={() => {}}
        onClickButtonIcon={onClickButtonIcon}
      />

      {isShowCalendar && <s.Bg type={"button"} onClick={onClickButtonBg} />}

      <Animation isShow={isShowCalendar}>
        <s.SelectCalendar
          textButtonMonth={textButtonMonth}
          textButtonYear={textButtonYear}
          typeButton={typeButton}
          isActiveButtonMonth={isActiveButtonMonth}
          isActiveButtonYear={isActiveButtonYear}
          listMonth={listMonth}
          listYear={listYear}
          setIsActiveButtonDate={setIsActiveButtonDate}
          onClickButtonTimeMonth={onClickButtonTimeMonth}
          onClickButtonTimeYear={onClickButtonTimeYear}
          onClickButtonIconClose={onClickButtonIconClose}
          onClickButtonDateMonth={onClickButtonDateMonth}
          onClickButtonDateYear={onClickButtonDateYear}
        />
      </Animation>
    </s.SelectInputCalendar>
  );
};
