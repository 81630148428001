import * as yup from 'yup';

export const ValidationFormSignIn = () => {
  return yup.object().shape({
    email: yup
        .string()
        .required('Это поле обязательно для заполнения')
        .email('Введите корректную почту'),

    password: yup
        .string()
        .required('Это поле обязательно для заполнения'),
  });
};