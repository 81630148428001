import styled from "styled-components";
import { breakpoints, Container } from "../../../shared/config";
import bannerImage from "./../../../shared/config/assets/images/personal-area-banner-bg.svg";
import {
  ButtonCardAdd,
  CardInvite,
  CardWallet,
  IconOk,
  IconUserAdd,
  IconVk,
} from "../../../shared";

const PersonalAreaProfile = styled.section`
  display: grid;
  grid-template-columns: 390px 1fr;
  gap: 64px;

  @media (max-width: ${breakpoints.XXL}) {
    grid-template-columns: 340px 1fr;
  }

  @media (max-width: ${breakpoints.XL}) {
    grid-template-columns: 300px 1fr;
    gap: 48px;
  }

  @media (max-width: ${breakpoints.LG}) {
    grid-template-columns: 1fr;
    grid-auto-rows: var(--minmax);
    gap: 80px;
  }

  @media (max-width: ${breakpoints.MD}) {
    grid-template-columns: 1fr;
    gap: 70px;
  }
`;

const PersonalAreaContainer = styled(Container)``;

const WrapFooter = styled.div``;

const Footer = styled.footer`
  margin-top: 54px;

  @media (max-width: ${breakpoints.XXL}) {
    margin-top: 24px;
  }

  @media (max-width: ${breakpoints.XL}) {
    margin-top: 32px;
  }

  @media (max-width: ${breakpoints.LG}) {
    margin-top: 60px;
  }

  @media (max-width: ${breakpoints.MD}) {
    margin-top: 50px;
  }
`;

const List = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;

  @media (max-width: ${breakpoints.LG}) {
    grid-auto-columns: 1fr;
    gap: 12px;
  }

  @media (max-width: ${breakpoints.MD}) {
    grid-template-columns: 1fr;
  }
`;

const Item = styled.li``;

const Card = styled(CardInvite)`
  background-color: var(--brand-4);
`;

const IconButtonVk = styled(IconVk)`
  width: 32px;
  height: 32px;
  color: var(--blue-icon-1);
`;

const IconButtonOk = styled(IconOk)`
  width: 32px;
  height: 32px;
  color: var(--blue-icon-1-1);
`;

const IconUser = styled(IconUserAdd)`
  width: 32px;
  height: 32px;
  color: var(--brand-1);
`;

const WrapContent = styled.div``;

const MyWallet = styled.section`
  margin-bottom: 60px;

  @media (max-width: ${breakpoints.LG}) {
    margin-bottom: 80px;
  }

  @media (max-width: ${breakpoints.MD}) {
    margin-bottom: 70px;
  }
`;

const Title = styled.h2`
  margin-bottom: 24px;
  font: var(--font-9);
  color: var(--hyperlink);

  @media (max-width: ${breakpoints.XL}) {
    margin-bottom: 32px;
  }

  @media (max-width: ${breakpoints.MD}) {
    margin-bottom: 24px;
  }
`;

const WalletList = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  @media (max-width: ${breakpoints.XL}) {
    gap: 16px;
  }

  @media (max-width: ${breakpoints.MD}) {
    grid-template-columns: 1fr;
  }
`;

const WalletItem = styled.li``;

const WalletItemButton = styled.button`
  width: 100%;
  height: 100%;
  display: block;
  will-change: transform;
  transition: transform 300ms ease;

  &:hover {
    transform: scale(1.02);
  }

  &:active {
    transform: scale(0.98);
  }
`;

const WalletCard = styled(CardWallet)`
  @media (max-width: ${breakpoints.MD}) {
    max-width: none;
  }
`;

const MyAnimal = styled.section`
  margin-bottom: 80px;

  @media (max-width: ${breakpoints.XXL}) {
    margin-bottom: 70px;
  }

  @media (max-width: ${breakpoints.XL}) {
    margin-bottom: 50px;
  }

  @media (max-width: ${breakpoints.LG}) {
    margin-bottom: 40px;
  }

  @media (max-width: ${breakpoints.MD}) {
    margin-bottom: 60px;
  }
`;

const AnimalList = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: var(--minmax);
  gap: 20px;

  @media (max-width: ${breakpoints.XL}) {
    gap: 16px;
  }

  @media (max-width: ${breakpoints.MD}) {
    grid-template-columns: 1fr;
  }
`;

const AnimalItem = styled.li``;

const ButtonCardAddPet = styled(ButtonCardAdd)`
  max-width: 100%;
`;

const Banner = styled.section``;

const BannerLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  min-height: 260px;
  padding: 30px 30px 10px 40px;
  border-radius: 32px;
  background: var(--brand-4) url(${bannerImage}) no-repeat left bottom/612px
    100%;
  will-change: transform;
  transition: transform 300ms ease;

  &:hover {
    transform: scale(1.02);
  }

  @media (max-width: ${breakpoints.MD}) {
    flex-direction: column;
  }
`;

const BannerTitle = styled.h2`
  font: var(--font-3);
  color: var(--interface-dark);

  @media (max-width: ${breakpoints.MD}) {
    text-align: center;
  }
`;

const Image = styled.img`
  max-width: 430px;
  width: 100%;

  @media (max-width: ${breakpoints.XXL}) {
    max-width: 340px;
  }

  @media (max-width: ${breakpoints.XL}) {
    max-width: 310px;
  }
`;

export {
  PersonalAreaProfile,
  PersonalAreaContainer,
  WrapFooter,
  Footer,
  List,
  Item,
  Card,
  IconButtonVk,
  IconButtonOk,
  IconUser,
  WrapContent,
  MyWallet,
  Title,
  WalletList,
  WalletItem,
  WalletItemButton,
  WalletCard,
  MyAnimal,
  AnimalList,
  AnimalItem,
  ButtonCardAddPet,
  Banner,
  BannerLink,
  BannerTitle,
  Image,
};
