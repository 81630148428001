import { useEffect } from "react";
import {
  getDiscountReceiptIdThunk,
  getDiscountReceiptsThunk,
  selectDiscountReceiptId,
} from "../../../entities/discount/model";
import { useAppDispatch, useAppSelector } from "../../../shared/lib";
import * as s from "./Upload-receipt-styles";
import { setDeleteNotification, setNotification } from "../../../shared/model";
import { v4 as uuidv4 } from "uuid";

export const UploadReceipt = () => {
  const dispatch = useAppDispatch();
  const id = useAppSelector(selectDiscountReceiptId);

  useEffect(() => {
    const script: HTMLScriptElement = document.createElement("script");
    script.src = "https://qr.apmcheck.ru/1.0.5/js/widget.min.js";
    script.integrity =
      "sha384-EOWs7Shrg3lPqybFZv4Wj2fiE8FbH93vammV1Hl2H3lQ/NZOnfNvv3mPB1bfCX6c";
    script.crossOrigin = "anonymous";
    document.body?.appendChild(script);

    script.onload = (): void => {
      const widgetParams = {
        api: "https://api.apmcheck.ru",
        maxPhotoSizeInMiB: 10,
        apiKey: "ff315ed3-a66b-44ea-9fab-6bfe22c0d374",
        channel: "widget",
        userUuid: id || "",
        styles: {
          mainButton: "main-button red-button",
          manualQrButton: "manual-qr-button white-button",
          uploadPhotosButton: "upload-button red-button",
          submitManualQrButton: "submit-manual-qr-button red-button",
          addPhotoButton: "add-photo-button white-button",
          submitPhotosButton: "submit-photos-button red-button",
        },
        i18n: {
          labels: {
            mainButton: "Загрузить чек",
            manualQrButton: "Ввести данные вручную",
            uploadPhotosButton: "Загрузить фото чека",
            submitManualQrButton: "Отправить",
            addPhotoButton: "Добавить фото",
            submitPhotosButton: "Отправить",
          },
          screens: {
            scanQr: {
              header: "Сканирование",
              subheader: "Наведите камеру на QR-код",
            },
            manualInput: {
              header: "Ручной ввод",
              subheader: "Введите данные с чека",
            },
            cameraNotAvailable: {
              subheader:
                "Мы не можем получить доступ к&nbsp;камере&nbsp;устройства.<br>Разрешите браузеру обращаться к&nbsp;камере или введите данные с&nbsp;чека вручную",
            },
            qrParsed: {
              header: "Чек успешно отправлен!",
              subheader: "За его статусом можно следить в разделе «Чеки»",
            },
            uploadFiles: {
              header: "Загрузка фото",
              subheader: "Добавьте фото чека (до 5 частей)",
              fileTooLargeError:
                "Файл больше 10 МБ. Загрузите файл меньшего размера.",
            },
            sentReceiptFailed: {
              header: "Не удалось отправить чек!",
            },
          },
        },
        callbacks: {
          onReceiptSentSuccess: function (res: any): void {
            const id = uuidv4();
            dispatch(getDiscountReceiptsThunk());
            dispatch(getDiscountReceiptIdThunk());
            dispatch(
              setNotification({
                text: "Чек отправлен!",
                id: id,
              })
            );
            setTimeout(() => {
              dispatch(setDeleteNotification(id));
            }, 10000);
          },

          onReceiptSentError: function (res: any): void {},
        },
      };

      window.qrWidget.init("apm-widget", widgetParams);
    };

    return (): void => {
      document.body.removeChild(script);
    };
  }, []);

  return <s.UploadButton id="apm-widget" />;
};
