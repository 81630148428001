import React, { FC, ReactNode } from "react";
import { CardTargetAction } from "../../molecules";
import * as s from "./Additional-bonuses-styles";
import { MiniRoyalsCardType } from "../../atom";

type AdditionalBonusesType = {
  className?: string;
  titleCardTarget: string;
  textCardTarget: string;
  textButtonCard: string;
  typeButtonCard: "button" | "reset" | "submit";
  iconButtonCard: ReactNode;
  onClickButtonUploadCardTarget: () => void;
  titleTimeline: string;
  firstStepIconTimeline: ReactNode;
  cardsRoyalsList: Array<MiniRoyalsCardType>;
};

export const AdditionalBonuses: FC<AdditionalBonusesType> = ({
  className,
  titleCardTarget,
  textCardTarget,
  textButtonCard,
  typeButtonCard,
  iconButtonCard,
  onClickButtonUploadCardTarget,
  titleTimeline,
  firstStepIconTimeline,
  cardsRoyalsList,
}) => {
  return (
    <s.AdditionalBonuses className={className}>
      <CardTargetAction
        title={titleCardTarget}
        text={textCardTarget}
        textButton={textButtonCard}
        typeButton={typeButtonCard}
        iconButton={iconButtonCard}
        onClickButtonUpload={onClickButtonUploadCardTarget}
      />

      <s.TimelineRoyalsStyles
        title={titleTimeline}
        firstStepIcon={firstStepIconTimeline}
        cards={cardsRoyalsList}
      />
    </s.AdditionalBonuses>
  );
};
