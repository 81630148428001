import styled from "styled-components";

const ButtonIcon = styled.button`
  will-change: transform;
  transition: ease-in-out 300ms;
  color: var(--brand-2);

  &:active {
    transform: scale(0.95);
  }

  &:hover {
    color: var(--function);
  }
`;

export { ButtonIcon };
