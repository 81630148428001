import React, { FC } from "react";
import { NotificationsBar } from "../../../shared";
import { useAppDispatch, useAppSelector } from "../../../shared/lib";
import { useNavigate } from "react-router-dom";
import { selectShared, setDeleteNotification } from "../../../shared/model";
import { pages } from "../../../shared/config";

type NotificationsBarActionType = {
  className?: string;
};

export const NotificationsBarAction: FC<NotificationsBarActionType> = ({
  className,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { notifications } = useAppSelector(selectShared);
  const onDeleteNotifications = (id: string) => () => {
    dispatch(setDeleteNotification(id));
  };

  const onRedirectForCheck = () => {
    navigate(pages.personalAreaChecks.link);
  };

  return (
    <NotificationsBar
      className={className}
      notifications={notifications}
      onClickButtonIcon={onDeleteNotifications}
      onClickButton={onRedirectForCheck}
    />
  );
};
