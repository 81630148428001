import styled from "styled-components";
import { breakpoints } from "../../../config";

const ErrorInfoTooltip = styled.div``;

const Button = styled.button`
  position: relative;
  display: flex;
  height: 24px;
  width: 24px;
  color: currentColor;

  @media (max-width: ${breakpoints.MD}) {
    width: 16px;
    height: 16px;
  }
`;

const ErrorBonusTooltipWrap = styled.div`
  position: absolute;
  top: -50px;
  right: 0;
  left: auto;
  display: flex;
  align-items: center;
  width: max-content;
  padding: 4px 12px 4px 12px;
  font: var(--font-16);
  background-color: var(--brand-3);
  border-radius: 8px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05);

  @media (max-width: ${breakpoints.MD}) {
    max-width: 320px;
  }
`;

export { ErrorInfoTooltip, Button, ErrorBonusTooltipWrap };
