import styled, { css } from "styled-components";
import {
  ButtonStopwatch,
  FieldInputCode,
  IconClose,
  ModalBody,
} from "../../../../shared";
import { BodyAnimation } from "../../../../shared/ui/molecules/modal-body/Modal-body-styles";
import { breakpoints } from "../../../../shared/config";

type IconType = {
  $isError: boolean;
};

const ModalConfirmation = styled.div``;

const Modal = styled(ModalBody)`
  ${BodyAnimation} {
    max-width: 620px;
  }

  @media (max-width: ${breakpoints.LG}) {
    ${BodyAnimation} {
      max-width: 100%;
    }
  }
`;

const Title = styled.h2`
  max-width: 520px;
  margin-bottom: 16px;
  font: var(--font-3);
  color: var(--brand-1);

  @media (max-width: ${breakpoints.LG}) {
    max-width: unset;
  }
`;

const Text = styled.p`
  margin-bottom: 24px;
  font: var(--font-9);
  color: var(--interface-dark);
`;

const SubText = styled.p`
  margin-bottom: 40px;
  font: var(--font-16);
  color: var(--brand-2);

  @media (max-width: ${breakpoints.XL}) {
    margin-bottom: 32px;
  }
`;

const InputCode = styled(FieldInputCode)`
  margin-bottom: 16px;
`;

const Error = styled.p`
  margin-bottom: 8px;
  font: var(--font-18);
  color: var(--error);
`;

const Icon = styled(IconClose)<IconType>`
  width: 32px;
  height: 32px;
  color: var(--iconography);

  ${(props) =>
    props.$isError &&
    css`
      color: var(--pink-for-svg);
    `}
`;

const Button = styled(ButtonStopwatch)``;

export {
  ModalConfirmation,
  Modal,
  Title,
  SubText,
  Text,
  InputCode,
  Error,
  Icon,
  Button,
};
