import styled from "styled-components";
import { ModalBody } from "../../../../shared";
import { BodyAnimation } from "../../../../shared/ui/molecules/modal-body/Modal-body-styles";
import { breakpoints } from "../../../../shared/config";

const ModalConfirmationSuccessful = styled.div``;

const Modal = styled(ModalBody)`
  ${BodyAnimation} {
    max-width: 702px;
    padding-bottom: 60px;
  }

  @media (max-width: ${breakpoints.LG}) {
    ${BodyAnimation} {
      max-width: 100%;
    }
  }
`;

const Title = styled.h2`
  max-width: 520px;
  margin-bottom: 16px;
  font: var(--font-3);
  color: var(--brand-1);

  @media (max-width: ${breakpoints.LG}) {
    max-width: unset;
  }
`;

const Text = styled.p`
  font: var(--font-16);
  color: var(--brand-2);
`;

export { ModalConfirmationSuccessful, Modal, Title, Text };
