import { useEffect, useRef } from "react";

export function useOnClickOutside (
  onCallback?: () => void,
  isDoubleRef?: boolean
) {
  const ref = useRef<any>(null);
  const refNew = useRef<any>(null);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (isDoubleRef) {
        if (
          ref.current &&
          !ref.current.contains(event.target) &&
          refNew.current &&
          !refNew.current.contains(event.target)
        ) {
          onCallback?.();
        }
      } else {
        if (ref.current && !ref.current.contains(event.target)) {
          onCallback?.();
        }
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, isDoubleRef, onCallback]);

  return [ref, refNew];
}
