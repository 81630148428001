import {
  type ActionReducerMapBuilder,
  createSlice,
  type PayloadAction,
} from "@reduxjs/toolkit";
import { type NoInfer } from "react-redux";
import { ModalsEnumType } from "../../../../shared/config";

type modalsStateType = {
  action: ModalsEnumType;
  content?: any;
};

const initialState: modalsStateType = {
  action: null,
  content: null,
};

const modalsSlice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    setModals: (
      state: modalsStateType,
      action: PayloadAction<modalsStateType>
    ) => {
      state.action = action.payload.action;
      state.content = action.payload.content;
    },
  },

  extraReducers: (builder: ActionReducerMapBuilder<NoInfer<any>>) => {},

  selectors: {
    selectModals: (state) => state,
  },
});

export const { reducer } = modalsSlice;
export const modalsReducer = reducer;
export const { setModals } = modalsSlice.actions;
export const { selectModals } = modalsSlice.selectors;
