import React, { FC, ReactNode } from "react";
import * as s from "./Step-royals-styles";

export type StepRoyalsType = {
  className?: string;
  image: ReactNode;
  index: number;
  firstWord: string;
  text: string | ReactNode;
  footnote?: string;
};

export const StepRoyals: FC<StepRoyalsType> = ({
  className,
  image,
  index,
  firstWord,
  text,
  footnote,
}) => {
  return (
    <s.StepRoyals className={className}>
      <s.IndexShape>
        <s.Icon>{image}</s.Icon>
        <s.Index>{index}</s.Index>
      </s.IndexShape>

      <s.Item key={index}>
        <s.Text>
          <s.FirstWord>{firstWord}</s.FirstWord>
          {text}
        </s.Text>
        {footnote && <s.Footnote>{footnote}</s.Footnote>}
      </s.Item>
    </s.StepRoyals>
  );
};
