import { AxiosResponse } from "axios/index";
import { BreedRequestType, PetType } from "../model";
import { instance } from "../../../shared/api";

export const petApi = {
  get_pets: async () => {
    const response = await instance.get("pet/");
    return response as AxiosResponse<Array<PetType>>;
  },

  create_pet: async (body: BreedRequestType) => {
    const response = await instance.post("pet/", body);
    return response as AxiosResponse<BreedRequestType>;
  },

  update_pet: async (data: { body: any; id: number }) => {
    const response = await instance.patch(`pet/${data.id}/`, data.body);
    return response as AxiosResponse<PetType>;
  },
};
