import styled from "styled-components";
import {
  Avatar,
  ButtonIcon,
  IconInfo,
  MenuPersonalAreaDesktop,
} from "../../../shared";
import { breakpoints } from "../../../shared/config";

const SidebarPersonalArea = styled.div``;

const Wrap = styled.div`
  margin-bottom: 24px;

  @media (max-width: ${breakpoints.XL}) {
    margin-bottom: 32px;
  }

  @media (max-width: ${breakpoints.LG}) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 60px;
  }

  @media (max-width: ${breakpoints.MD}) {
    margin-bottom: 50px;
  }
`;

const AvatarPersonal = styled(Avatar)`
  margin-bottom: 20px;
`;

const Name = styled.h2`
  margin-bottom: 24px;
  font: var(--font-3);
  color: var(--interface-dark);
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  overflow-wrap: anywhere;
`;

const SubWrap = styled.div`
  word-break: break-word;

  @media (max-width: ${breakpoints.LG}) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 24px;
    row-gap: 8px;
  }
`;

const WrapPhone = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;

  @media (max-width: ${breakpoints.LG}) {
    margin-bottom: unset;
  }
`;

const Text = styled.p`
  font: var(--font-14);
  color: var(--brand-2);
`;

const ButtonPhone = styled(ButtonIcon)`
  display: flex;
  color: var(--brand-1);

  &:hover {
    color: var(--brand-1-dark);
  }
`;

const Icon = styled(IconInfo)`
  width: 16px;
  height: 16px;
`;

const Header = styled.header`
  @media (max-width: ${breakpoints.LG}) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
  }
`;

const MenuDesktop = styled(MenuPersonalAreaDesktop)``;

export {
  SidebarPersonalArea,
  Wrap,
  AvatarPersonal,
  Name,
  SubWrap,
  WrapPhone,
  Text,
  ButtonPhone,
  Icon,
  Header,
  MenuDesktop,
};
