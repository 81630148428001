import styled, { css } from "styled-components";
import { ButtonIcon, ButtonText, IconArrowRow, IconRoyals } from "../../atom";
import { breakpoints } from "../../../config";

type CardInviteType = {
  $isActive: boolean;
};

const MobileButtonText = styled(ButtonText)`
  font: var(--font-16);
  color: var(--text);
  text-align: start;
  letter-spacing: -0.32px;
`;

const IconArrow = styled(IconArrowRow)`
  width: 20px;
  transform: scaleX(-1);
  transition: ease-in-out 300ms;
`;

const CardInvite = styled.article<CardInviteType>`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  height: 100%;
  padding: 30px;
  background-color: var(--brand-3);
  border-radius: 32px;

  @media (max-width: ${breakpoints.XXL}) {
    padding: 30px 24px;
  }

  @media (max-width: ${breakpoints.XL}) {
    gap: 12px;
    padding: 32px 28px;
  }

  @media (max-width: ${breakpoints.LG}) {
    gap: 30px;
    padding: 24px;
    background-color: var(--brand-4);

    &:hover ${MobileButtonText} {
      color: 2px solid var(--brand-2);
    }

    &:hover ${IconArrow} {
      color: 2px solid var(--brand-2);
    }

    &:active ${IconArrow} {
      transform: scaleX(-0.9) scaleY(0.9);
    }

    & svg {
      color: var(--iconography);
    }
  }

  ${(props) =>
    props.$isActive &&
    css`
      transition: transform 300ms ease;
      cursor: pointer;

      &:active {
        transform: scale(0.98);
      }
    `}
`;

const ButtonCardIcon = styled(ButtonIcon)`
  position: absolute;
  top: 16px;
  right: 16px;
  color: var(--interface);

  @media (max-width: ${breakpoints.LG}) {
    display: none;
  }

  &:hover {
    color: var(--hyperlink);
  }
`;

const Title = styled.h4`
  font: var(--font-3);
  letter-spacing: -1.68px;
  color: var(--brand-1);

  @media (max-width: ${breakpoints.LG}) {
    font: var(--font-8);
    letter-spacing: -0.44px;
    color: var(--hyperlink);
  }
`;

const Text = styled.p`
  max-width: 340px;
  font: var(--font-12);
  color: var(--text);

  @media (max-width: ${breakpoints.XXL}) {
    max-width: 243px;
  }

  @media (max-width: ${breakpoints.XL}) {
    max-width: 219px;
    margin-bottom: 8px;
  }

  @media (max-width: ${breakpoints.LG}) {
    display: none;
  }
`;

const IconRoyal = styled(IconRoyals)`
  vertical-align: middle;
  width: 32px;
  height: 32px;
  padding-left: 3px;

  @media (max-width: ${breakpoints.LG}) {
    padding-left: 0;
  }
`;

const MobileButtonWrap = styled.div`
  display: flex;
  align-items: center;
  margin-top: auto;
`;

const List = styled.ul`
  display: grid;
  grid-auto-rows: var(--minmax);
  gap: 12px;
`;

const Item = styled.li``;

export {
  CardInvite,
  ButtonCardIcon,
  Title,
  Text,
  IconRoyal,
  MobileButtonWrap,
  MobileButtonText,
  IconArrow,
  List,
  Item,
};
