import React, { FC, useEffect } from "react";
import * as s from "./Personal-area-promo-code-styles";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  breakpointsTs,
  ModalsProperty,
  StatusPromoCode,
  StatusProperty,
} from "../../../shared/config";
import { A11y, Navigation, Pagination, Scrollbar } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { SidebarPersonalArea } from "../../../widgets";
import { Animation } from "../../../shared";
import { esDiscount } from "../../../entities";
import {
  formatDate,
  useAppDispatch,
  useAppSelector,
  useChangeLetter,
  useIsMobile,
} from "../../../shared/lib";
import { setModals } from "../../../widgets/modals/model";
import { PromocodeType, PromoCodeType } from "../../../entities/discount/model";
import { setDeleteNotification, setNotification } from "../../../shared/model";
import { v4 as uuidv4 } from "uuid";
import { useScrollToTop } from "../../../shared/lib/hooks/use-scroll-to-top";

type PersonalAreaPromoCodeType = {};

export const PersonalAreaPromoCode: FC<PersonalAreaPromoCodeType> = ({}) => {
  useScrollToTop();

  const dispatch = useAppDispatch();
  const {
    promoCodes,
    promoCodesTypesYoung,
    promoCodesTypesAdult,
    promoCodesTypesPartner,
  } = useAppSelector(esDiscount.model.selectDiscount);
  const [isTablet] = useIsMobile(breakpointsTs.LG);
  const [isMobile] = useIsMobile(breakpointsTs.MD);
  const [isMobileSm] = useIsMobile(375);

  const onCopyValueInput = (value: string) => () => {
    const id = uuidv4();
    window.navigator.clipboard.writeText(value);
    dispatch(
      setNotification({
        text: "Промокод скопирован!",
        id: id,
      })
    );
    setTimeout(() => {
      dispatch(setDeleteNotification(id));
    }, 10000);
  };

  const onOpenModalPromoCode = (data: PromocodeType) => () => {
    dispatch(
      setModals({
        action: ModalsProperty.MODAL_PROMO_CODE,
        content: data,
      })
    );
  };

  const onOpenModalPromoCodeType =
    (data: PromoCodeType, title?: string) => () => {
      dispatch(
        setModals({
          action: ModalsProperty.MODAL_PROMO_CODE_TYPE,
          content: { ...data, isButton: true, titleModal: title },
        })
      );
    };

  const getDatePromoCode = (date: string) => {
    const { day, month, year } = formatDate(date);

    return `${day}.${month}.${year}`;
  };

  const isDatePromoCode = (date: string): boolean => {
    const { day, month, year } = formatDate(date);
    const oldDate = new Date(`${year}-${month}-${day}`);
    const currentDate = new Date();

    return currentDate.getTime() > oldDate.getTime();
  };

  useEffect(() => {
    if (promoCodes?.length === 0) {
      dispatch(esDiscount.model.getPromoCodesThunk());
    }

    dispatch(esDiscount.model.getPromoCodesTypesThunk());
  }, []);

  return (
    <s.PersonalAreaContainer>
      <s.PersonalAreaPromoCode>
        <SidebarPersonalArea
          isMobile={isTablet}
          isAvatar={isTablet}
          onChangeInput={() => {}}
        />

        <s.WrapContent>
          <s.PromoCodes>
            <s.PromoCodeTitle>Мои промокоды</s.PromoCodeTitle>

            <Animation isShow={promoCodes?.length > 0} delay={0.2}>
              <Swiper
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                spaceBetween={16}
                slidesPerView={isTablet ? 2 : !isMobile ? 1.2 : 2}
                navigation={isMobile}
                onSlideChange={() => {}}
                onSwiper={(swiper: any) => {}}
              >
                {promoCodes?.map((promoCode, index) => (
                  <SwiperSlide key={index}>
                    <s.CardPromoSlide
                      onClickInput={onCopyValueInput(promoCode.code)}
                      onClickButtonIcon={onCopyValueInput(promoCode.code)}
                      onClickButtonPromoIcon={onOpenModalPromoCode({
                        title: promoCode?.promocode_type?.title || "",
                        assignment: promoCode?.promocode_type?.assignment,
                        description: promoCode?.promocode_type?.description,
                      })}
                      statusPromo={
                        promoCode.is_over
                          ? StatusProperty.COMMON
                          : isDatePromoCode(promoCode.expired_date)
                          ? StatusProperty.DEFAULT
                          : StatusProperty.SUCCESS
                      }
                      text={
                        useChangeLetter({
                          text: promoCode.promocode_type?.title,
                          icon: <s.RubleIcon />,
                          targetChar: "₽",
                        }) || ""
                      }
                      textStatusPromo={
                        promoCode.is_over
                          ? "использован"
                          : isDatePromoCode(promoCode.expired_date)
                          ? "истек срок действия"
                          : !isMobileSm
                          ? `до ${getDatePromoCode(promoCode.expired_date)}`
                          : `действует до ${getDatePromoCode(
                              promoCode.expired_date
                            )}`
                      }
                      valueInput={promoCode.code}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </Animation>

            <Animation
              isShow={promoCodes?.length === 0 || !promoCodes}
              delay={0.2}
            >
              <s.PromoCodesText>
                У вас еще нет активных промокодов
              </s.PromoCodesText>
            </Animation>
          </s.PromoCodes>

          <Animation isShow={true} delay={0.6}>
            <s.DiscountsCards>
              <s.DiscountsTitle>Скидки и полезные подарки</s.DiscountsTitle>

              <s.List>
                <s.Item>
                  <s.DiscountsSubTitle>
                    Скидки на рационы для&nbsp;котят и&nbsp;щенков
                  </s.DiscountsSubTitle>

                  <Swiper
                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                    spaceBetween={8}
                    slidesPerView={isTablet ? 3 : !isMobile ? 2.1 : 2.5}
                    navigation={isTablet}
                    onSlideChange={() => {}}
                    onSwiper={(swiper: any) => {}}
                  >
                    {promoCodesTypesYoung?.map((promoCodeYoung, index) => (
                      <SwiperSlide key={index}>
                        <s.CardPromoCodeSlide
                          counterRoyals={String(promoCodeYoung.price)}
                          image={promoCodeYoung.image}
                          statusPromoCode={
                            promoCodeYoung.available
                              ? StatusPromoCode.DEFAULT
                              : StatusPromoCode.INVALID
                          }
                          text={useChangeLetter({
                            text: promoCodeYoung.title,
                            icon: <s.RubleIcon />,
                            targetChar: "₽",
                          })}
                          onClickButton={onOpenModalPromoCodeType(
                            promoCodeYoung,
                            "Скидки на рационы для котят и щенков"
                          )}
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </s.Item>

                <s.Item>
                  <s.DiscountsSubTitle>
                    Скидки на рационы для&nbsp;взрослых кошек и&nbsp;собак
                  </s.DiscountsSubTitle>

                  <Swiper
                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                    spaceBetween={8}
                    slidesPerView={isTablet ? 3 : !isMobile ? 2.1 : 2.5}
                    navigation={isTablet}
                    onSlideChange={() => {}}
                    onSwiper={(swiper: any) => {}}
                  >
                    {promoCodesTypesAdult?.map((promoCodeAdult, index) => (
                      <SwiperSlide key={index}>
                        <s.CardPromoCodeSlide
                          counterRoyals={String(promoCodeAdult.price)}
                          image={promoCodeAdult.image}
                          statusPromoCode={
                            promoCodeAdult.available
                              ? StatusPromoCode.DEFAULT
                              : StatusPromoCode.INVALID
                          }
                          isBigBgImage={
                            promoCodeAdult.assignment === "grown_up"
                          }
                          isRoyals={!!promoCodeAdult.price}
                          text={useChangeLetter({
                            text: promoCodeAdult.title,
                            icon: <s.RubleIcon />,
                            targetChar: "₽",
                          })}
                          onClickButton={onOpenModalPromoCodeType(
                            promoCodeAdult,
                            "Скидки на рационы для взрослых кошек и собак"
                          )}
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </s.Item>

                <s.Item>
                  <s.DiscountsSubTitle>
                    Подарки&nbsp;от&nbsp;партнера
                  </s.DiscountsSubTitle>

                  <Swiper
                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                    spaceBetween={8}
                    slidesPerView={isTablet ? 3 : !isMobile ? 2.1 : 2.5}
                    navigation={isTablet}
                    onSlideChange={() => {}}
                    onSwiper={(swiper: any) => {}}
                  >
                    {promoCodesTypesPartner?.map((promoCodePartner, index) => (
                      <SwiperSlide key={index}>
                        <s.CardPromoCodeSlide
                          counterRoyals={String(promoCodePartner.price)}
                          image={promoCodePartner.image}
                          statusPromoCode={
                            promoCodePartner.available
                              ? StatusPromoCode.DEFAULT
                              : StatusPromoCode.INVALID
                          }
                          isRoyals={false}
                          text={promoCodePartner.title}
                          onClickButton={onOpenModalPromoCodeType(
                            promoCodePartner,
                            "Подарки от партнера"
                          )}
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </s.Item>
              </s.List>
            </s.DiscountsCards>
          </Animation>
        </s.WrapContent>
      </s.PersonalAreaPromoCode>
    </s.PersonalAreaContainer>
  );
};
