import styled from "styled-components";
import {PopupBody} from "../../../../shared";
import {breakpoints} from "../../../../shared/config";
import {BodyAnimation} from "../../../../shared/ui/molecules/popup-body/Popup-body-styles";

const ModalPromoCode = styled.div``;

const Modal = styled(PopupBody)`
  ${BodyAnimation} {
    max-width: 940px;
    padding: 50px 90px 60px 50px;
  }

  @media (max-width: ${breakpoints.LG}) {
    ${BodyAnimation} {
      max-width: 100%;
      padding: 60px 55px 70px;
    }
  }

  @media (max-width: ${breakpoints.MD}) {
    ${BodyAnimation} {
      max-width: 100%;
      padding: 65px 20px 30px;
    }
  }
`;

const Title = styled.h2`
  margin-bottom: 20px;
  font: var(--font-10);
  color: var(--text);
`;

const Text = styled.p`
  margin-bottom: 40px;
  font: var(--font-4);
  color: var(--brand-1);
`;

const List = styled.ul`
  display: grid;
  grid-auto-rows: var(--minmax);
  gap: 32px;
`;

const Item = styled.li``;

const SubTitle = styled.h3`
  margin-bottom: 12px;
  font: var(--font-9);
  color: var(--interface-dark);
`;

const SubText = styled.p`
  font: var(--font-11);
  color: var(--text);
`;

export {ModalPromoCode, Modal, Title, Text, List, Item, SubTitle, SubText};
