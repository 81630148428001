import styled from "styled-components";
import { PopupBody } from "../../../../shared";
import { breakpoints } from "../../../../shared/config";
import { BodyAnimation } from "../../../../shared/ui/molecules/popup-body/Popup-body-styles";
import { ReactNode } from "react";

type BgImageType = {
  $bgImage: ReactNode;
};
const ModalAdditionalRoyals = styled.div``;

const Modal = styled(PopupBody)<BgImageType>`
  ${BodyAnimation} {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 40px;
    padding: 50px 50px 60px;
    max-width: 702px;
    background-image: ${(props) => `url(${props.$bgImage})`};
    background-repeat: no-repeat;
    background-position: right;
    background-size: 75%;
  }

  @media (max-width: ${breakpoints.XL}) {
    ${BodyAnimation} {
      gap: 32px;
    }
  }

  @media (max-width: ${breakpoints.LG}) {
    ${BodyAnimation} {
      gap: 40px;
      padding: 56px 56px 70px;
      max-width: 100%;
      background-image: none;
    }
  }

  @media (max-width: ${breakpoints.MD}) {
    ${BodyAnimation} {
      padding: 65px 20px 35px;
    }
  }
`;

const Title = styled.h3`
  font: var(--font-4);
  color: var(--brand-1);
  max-width: 92%;
  letter-spacing: -1.2px;

  @media (max-width: ${breakpoints.MD}) {
    max-width: none;
    letter-spacing: -0.96px;
  }
`;

const List = styled.ol`
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 92%;
  margin-left: 25px;

  @media (max-width: ${breakpoints.XL}) {
    max-width: 100%;
    margin-left: 20px;
  }

  @media (max-width: ${breakpoints.LG}) {
    max-width: 93%;
    gap: 24px;
  }

  @media (max-width: ${breakpoints.MD}) {
    max-width: none;
  }
`;

const Item = styled.li`
  font: var(--font-9);
  list-style: unset;
  padding-left: 5px;

  @media (max-width: ${breakpoints.XL}) {
    padding-left: 2px;
  }
`;

const Subtitle = styled.h4`
  font: var(--font-9);
  color: var(--interface-dark);
  margin-bottom: 7px;
`;

const Text = styled.div`
  font: var(--font-11);
  color: var(--text);
`;

export { ModalAdditionalRoyals, Modal, Title, List, Item, Subtitle, Text };
