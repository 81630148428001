import React, {
  ChangeEvent,
  FC,
  HTMLInputTypeAttribute,
  RefObject,
} from "react";
import * as s from "./Input-styles";

export type InputType = {
  className?: string;
  type: HTMLInputTypeAttribute;
  value: string;
  name?: string;
  placeholder?: string;
  disabled?: boolean;
  isError?: boolean;
  isFocus?: boolean;
  inputRef?: RefObject<HTMLInputElement>;
  onClickInput?: () => void;
  onChangeInput: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlurInput?: (e: ChangeEvent<HTMLInputElement>) => void;
  onFocusInput?: (e: ChangeEvent<HTMLInputElement>) => void;
  onHandelBlurInput?: () => void;
};

export const Input: FC<InputType> = ({
  className,
  type,
  inputRef,
  placeholder,
  value,
  name,
  disabled,
  isError,
  isFocus,
  onChangeInput,
  onClickInput,
  onBlurInput,
  onFocusInput,
  onHandelBlurInput,
}) => {
  return (
    <s.Input
      ref={inputRef}
      className={className}
      type={type}
      value={value}
      name={name}
      placeholder={placeholder}
      disabled={disabled}
      $isError={isError}
      $isFocus={isFocus}
      onChange={disabled ? () => {} : onChangeInput}
      onClick={disabled ? () => {} : onClickInput}
      onFocus={disabled ? () => {} : onFocusInput}
      onBlur={
        disabled
          ? () => {}
          : (event: ChangeEvent<HTMLInputElement>) => {
              onBlurInput && onBlurInput(event);
              onHandelBlurInput && onHandelBlurInput();
            }
      }
    />
  );
};
