import React, {FC} from "react";
import {ConfigRouters} from "./providers/config-routers/config-routers";
import {Provider} from "react-redux";
import {store} from "./store/store";
import {sd} from "../shared";

type AppType = {};

export const App: FC<AppType> = () => {
  return (
    <Provider store={store}>
      <sd.config.Styles>
        <ConfigRouters/>
      </sd.config.Styles>
    </Provider>
  );
};
