import styled, { css } from "styled-components";
import { breakpoints } from "../../../config";

type BurgerType = {
  $isActive?: boolean;
};

const MenuMobile = styled.div``;

const Span = styled.span``;

const Burger = styled.button<BurgerType>`
  width: 32px;
  height: 32px;
  position: relative;
  transition: transform 300ms ease;

  &::before,
  &::after,
  ${Span} {
    content: "";
    position: absolute;
    width: 24px;
    height: 2px;
    background-color: var(--iconography);
    transition: transform 300ms ease, bottom 300ms ease, top 300ms ease,
      background-color 300ms ease, opacity 300ms ease;
  }

  &:before {
    top: 7px;
    left: 50%;
    transform: translate(-50%);
  }

  ${Span} {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:after {
    bottom: 7px;
    left: 50%;
    transform: translate(-50%);
  }

  ${(props) =>
    props.$isActive &&
    css`
      &::after,
      &::before,
      ${Span} {
        background-color: var(--brand-1);
      }

      &:after {
        bottom: 16px;
        transform: translate(-50%) rotate(-45deg);
      }

      ${Span} {
        top: 14px;
        transform: translate(-50%) scale(0);
        opacity: 0;
      }

      &:before {
        top: 14px;
        transform: translate(-50%) rotate(45deg);
      }
    `}
  &:hover {
    &::after,
    &::before,
    ${Span} {
      background-color: var(--brand-1);
    }
  }

  &:active {
    transform: scale(0.95);
  }
`;

const List = styled.ul`
  padding: 40px;
  background-color: var(--brand-3);
  border-radius: 0 0 32px 32px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.02), 0 9px 9px 0 rgba(0, 0, 0, 0.02),
    0 20px 12px 0 rgba(0, 0, 0, 0.01), 0 36px 15px 0 rgba(0, 0, 0, 0),
    0 57px 16px 0 rgba(0, 0, 0, 0);

  @media (max-width: ${breakpoints.MD}) {
    padding: 24px;
  }
`;

const Item = styled.li`
  padding: 16px 0;
  border-bottom: 1px solid var(--inactive);

  &:first-of-type {
    padding-top: unset;
  }

  &:last-of-type {
    border-bottom: unset;
    padding-bottom: unset;
  }
`;

export { MenuMobile, Burger, Span, List, Item };
