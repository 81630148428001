import React, { FC } from "react";
import * as s from "./Banner-styles";
import { breakpoints } from "../../../config";

type BannerType = {
  className?: string;
  title: string;
  subTitle: string;
  extraText: string;
  imgPath375Webp: string;
  imgPath375_2xWebp: string;
  imgPath375: string;
  imgPath375_2x: string;
  imgPath1024Webp: string;
  imgPath1024_2xWebp: string;
  imgPath1024: string;
  imgPath1024_2x: string;
  imgPath1440: string;
  imgPath1440_2x: string;
  imgPath1440Webp: string;
  imgPath1440_2xWebp: string;
  altText: string;
};

export const Banner: FC<BannerType> = ({
  className,
  title,
  subTitle,
  extraText,
  imgPath375Webp,
  imgPath375_2xWebp,
  imgPath375,
  imgPath375_2x,
  imgPath1024Webp,
  imgPath1024_2xWebp,
  imgPath1024,
  imgPath1024_2x,
  imgPath1440,
  imgPath1440_2x,
  imgPath1440Webp,
  imgPath1440_2xWebp,
  altText,
}) => {
  return (
    <s.Banner className={className}>
      <s.HeaderGroup>
        <s.HeroTitle>{title}</s.HeroTitle>

        <s.HeroSubtitle>{subTitle}</s.HeroSubtitle>
      </s.HeaderGroup>

      <s.ShapeWrapper>
        <s.ExtraText>{extraText}</s.ExtraText>
      </s.ShapeWrapper>

      <s.Picture>
        <s.Source375Webp
          srcSet={`${imgPath375Webp} 1x, ${imgPath375_2xWebp} 2x`}
          media={`(max-width: ${breakpoints.MD})`}
          type="image/webp"
        />

        <s.Source375
          srcSet={`${imgPath375} 1x, ${imgPath375_2x} 2x`}
          media={`(max-width: ${breakpoints.MD})`}
        />

        <s.Source1024Webp
          srcSet={`${imgPath1024Webp} 1x, ${imgPath1024_2xWebp} 2x`}
          media={`(max-width: ${breakpoints.XL})`}
          type="image/webp"
        />

        <s.Source1024
          srcSet={`${imgPath1024} 1x, ${imgPath1024_2x} 2x`}
          media={`(max-width: ${breakpoints.XL})`}
        />

        <s.Image
          src={imgPath1440}
          srcSet={`${imgPath1440Webp} 1x, ${imgPath1440_2xWebp} 2x, ${imgPath1440} 1x, ${imgPath1440_2x} 2x`}
          alt={altText}
        />
      </s.Picture>
    </s.Banner>
  );
};
