import styled, { css } from "styled-components";

type TextRoyalsType = {
  $iconLeftAndIconRight: boolean;
};

const Text = styled.p`
  font: var(--font-12);
`;

const TextRoyals = styled.div<TextRoyalsType>`
  display: flex;
  align-items: center;

  ${(props) =>
    props.$iconLeftAndIconRight
      ? css`
          gap: 8px;

          ${Text} {
            color: var(--text);
          }
        `
      : css`
          gap: 20px;

          ${Text} {
            color: var(--hyperlink);
          }
        `}
`;

export { TextRoyals, Text };
