export const pages = {
  root: {
    link: "/",
    router: "",
  },

  auth: {
    link: "/Auth",
    router: "Auth",
  },

  registration: {
    link: "/Auth/Registration",
    router: "Registration/:invitation_token?",
  },

  signIn: {
    link: "/Auth/Sign-in",
    router: "Sign-in",
  },

  recoverPassword: {
    link: "/Auth/Recover-password",
    router: "Recover-password",
  },

  recoverPasswordEmail: {
    link: "/Auth/Recover-password",
    router: ":email?",
  },

  recoverPasswordCode: {
    link: "/Auth/Recover-password/Code",
    router: "Code/:email?",
  },

  recoverPasswordReset: {
    link: "/Auth/Recover-password/Reset",
    router: "Reset/:email?/:code?",
  },

  recoverPasswordRestored: {
    link: "/Auth/Recover-password/Restored",
    router: "Restored",
  },

  main: {
    link: "/Main",
    router: "Main",
  },

  personalArea: {
    link: "/Personal-area",
    router: "Personal-area",
  },

  personalAreaProfile: {
    link: "/Personal-area/Profile",
    router: "Profile",
  },

  personalAreaChecks: {
    link: "/Personal-area/Checks",
    router: "Checks/:isModal?",
  },

  personalAreaPromoCode: {
    link: "/Personal-area/Promo-code",
    router: "Promo-code",
  },

  faqAnswers: {
    link: "/Faq",
    router: "Faq",
  },
};
