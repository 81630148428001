import styled, { css } from "styled-components";
import {
  Button,
  FieldCheckbox,
  ModalBodyRight,
  Textarea,
} from "../../../../shared";
import { BodyAnimation } from "../../../../shared/ui/molecules/modal-body-right/Modal-body-right-styles";
import { Input } from "../../../../shared/ui/atom/input/Input-styles";
import { FieldTextFormik } from "../../../field-text-formik";
import { SelectSearchFormik } from "../../../select-search-formik";
import { FieldPhoneFormik } from "../../../field-phone-formik";
import { Error } from "../../../../shared/ui/molecules/field-input/Field-input-styles";
import { Error as ErrorFieldCheckbox } from "../../../../shared/ui/molecules/field-checkbox/Field-checkbox-styles";
import { breakpoints } from "../../../../shared/config";
import {
  InputCheckbox,
  WrapIcon,
} from "../../../../shared/ui/atom/input-checkbox/Input-checkbox-styles";

type InputType = {
  $isError: boolean;
};

const ModalQuestion = styled.div``;

const Modal = styled(ModalBodyRight)`
  ${BodyAnimation} {
    padding: 80px 128px 94px 64px;

    @media (max-width: ${breakpoints.XXL}) {
      padding: 60px 70px 52px;
    }

    @media (max-width: ${breakpoints.XL}) {
      padding: 40px 50px 45px;
    }

    @media (max-width: ${breakpoints.LG}) {
      padding: 56px 116px 70px;
    }

    @media (max-width: ${breakpoints.LG}) {
      padding: 65px 20px 30px;
    }
  }
`;

const Title = styled.h2`
  margin-bottom: 40px;
  font: var(--font-3);
  color: var(--brand-3);

  @media (max-width: ${breakpoints.XL}) {
    margin-bottom: 24px;
  }

  @media (max-width: ${breakpoints.LG}) {
    text-align: center;
  }
`;

const Form = styled.form`
  @media (max-width: ${breakpoints.LG}) {
    max-width: 540px;
    margin: 0 auto;
  }
`;

const List = styled.ul`
  display: grid;
  grid-auto-rows: var(--minmax);
  gap: 12px;
  margin-bottom: 40px;

  @media (max-width: ${breakpoints.XL}) {
    margin-bottom: 20px;
  }

  @media (max-width: ${breakpoints.LG}) {
    margin-bottom: 24px;
  }
`;

const Checkbox = styled(FieldCheckbox)`
  ${WrapIcon} {
    color: var(--inactive);
  }

  ${InputCheckbox} {
    &:hover {
      ${WrapIcon} {
        color: var(--brand-3);
      }
    }
  }

  ${ErrorFieldCheckbox} {
    color: var(--pink-for-svg);
    padding: 4px 0 0 16px;
  }
`;

const Label = styled.div`
  font: var(--font-19);
  color: var(--brand-3);
  align-self: center;
`;

const LabelLink = styled.a`
  text-decoration: underline;
`;

const Item = styled.li``;

const InputCss = css`
  max-width: var(--input-width);

  ${Input} {
    background-color: var(--brand-4);

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px var(--brand-4) inset;
    }
  }

  ${Error} {
    color: var(--pink-for-svg);
  }
`;

const InputPhone = styled(FieldPhoneFormik)<InputType>`
  ${InputCss};

  ${(props) =>
    props.$isError &&
    css`
      ${Input} {
        background-color: var(--pink) !important;
      }
    `}
`;

const InputText = styled(FieldTextFormik)<InputType>`
  ${InputCss};

  ${(props) =>
    props.$isError &&
    css`
      ${Input} {
        background-color: var(--pink) !important;
      }
    `}
`;

const SelectQuestions = styled(SelectSearchFormik)<InputType>`
  ${InputCss}

  ${(props) =>
    props.$isError &&
    css`
      ${Input} {
        background-color: var(--pink) !important;
      }
    `}
`;

const TextareaQuestion = styled(Textarea)`
  ${InputCss};

  @media (max-width: ${breakpoints.XL}) {
    min-height: 150px;
  }
`;

const TextareaError = styled.p`
  padding: 4px 0 0 16px;
  font: var(--font-18);
  color: var(--brand-3);
`;

const Counter = styled.div`
  margin-top: 4px;
  font: var(--font-19);
  color: var(--brand-3);
  text-align: right;
`;

const ButtonSubmit = styled(Button)`
  max-width: 100%;
`;

export {
  ModalQuestion,
  Modal,
  Title,
  Form,
  List,
  Checkbox,
  Label,
  LabelLink,
  Item,
  InputPhone,
  InputText,
  SelectQuestions,
  TextareaQuestion,
  TextareaError,
  Counter,
  ButtonSubmit,
};
