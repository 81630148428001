import React, {ChangeEvent, FC, useEffect} from "react";
import * as s from "./Personal-area-profile-styles";
import {
  Animation,
  ButtonInvite,
  CardPet,
  IconAdd,
  IconFemale,
  IconMale,
  IconPromoSmallFilled,
  IconRoyals,
} from "../../../shared";
import bannerImgFood1440 from "./../../../shared/config/assets/images/personal-area-banner-food-1440.png";
import bannerImgFood1440_2x from "./../../../shared/config/assets/images/personal-area-banner-food-1440@2x.png";
import bannerImgFood1440Webp from "./../../../shared/config/assets/images/personal-area-banner-food-1440.webp";
import bannerImgFood1440_2xWebp from "./../../../shared/config/assets/images/personal-area-banner-food-1440@2x.webp";
import bannerImgFood1024 from "./../../../shared/config/assets/images/personal-area-banner-food-1024.png";
import bannerImgFood1024_2x from "./../../../shared/config/assets/images/personal-area-banner-food-1024@2x.png";
import bannerImgFood1024Webp from "./../../../shared/config/assets/images/personal-area-banner-food-1024.webp";
import bannerImgFood1024_2xWebp from "./../../../shared/config/assets/images/personal-area-banner-food-1024@2x.webp";
import bannerImgFood375 from "./../../../shared/config/assets/images/personal-area-banner-food-375.png";
import bannerImgFood375_2x from "./../../../shared/config/assets/images/personal-area-banner-food-375@2x.png";
import bannerImgFood375Webp from "./../../../shared/config/assets/images/personal-area-banner-food-375.webp";
import bannerImgFood375_2xWebp from "./../../../shared/config/assets/images/personal-area-banner-food-375@2x.webp";
import {getNumberMonth, getNumberYear, useAppDispatch, useAppSelector, useIsMobile,} from "../../../shared/lib";
import {setModals} from "../../../widgets/modals/model";
import {breakpoints, breakpointsTs, ModalsProperty, pages,} from "../../../shared/config";
import {useNavigate} from "react-router-dom";
import {SidebarPersonalArea} from "../../../widgets";
import {esAuth, esDiscount, esPet} from "../../../entities";
import {editUserThunk, getUserThunk} from "../../../entities/auth/model";
import {findDateDifference} from "../../../shared/lib/function/find-Date-Difference";
import {PetType, updatePetsThunk} from "../../../entities/pet/model";
import {useScrollToTop} from "../../../shared/lib/hooks/use-scroll-to-top";
import {getOkRepostThunk, getVkRepostThunk} from "../../../shared/model";

type PersonalAreaProfileType = {};

export const PersonalAreaProfile: FC<PersonalAreaProfileType> = ({}) => {
  useScrollToTop();

  const [isMobile] = useIsMobile(breakpointsTs.LG);
  const promoCodes = useAppSelector(esDiscount.model.selectDiscountPromoCodes);
  const user = useAppSelector(esAuth.model.selectAuthUser);
  const pets = useAppSelector(esPet.model.selectPets);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onEditAvatar = async (e: ChangeEvent<HTMLInputElement>) => {
    const files = await e.currentTarget.files;
    if (files && files?.length > 0) {
      const image = files[0];
      const reader = new FileReader();

      reader.onload = (event: ProgressEvent<FileReader>) => {
        const imageDataUrl = (event.target as FileReader).result as string;
        dispatch(
          editUserThunk({
            ...user,
            image: imageDataUrl,
          })
        );
      };

      reader.readAsDataURL(image);
    }
  };

  const onEditAvatarPet =
    (pet: PetType) => async (e: ChangeEvent<HTMLInputElement>) => {
      const files = await e.currentTarget.files;
      if (files && files?.length > 0) {
        const image = files[0];
        const reader = new FileReader();

        reader.onload = (event: ProgressEvent<FileReader>) => {
          const imageDataUrl = (event.target as FileReader).result as string;
          dispatch(
            updatePetsThunk({
              body: {image: imageDataUrl},
              id: pet.id,
            })
          );
        };

        reader.readAsDataURL(image);
      }
    };

  const onOpenModalHistory = () => {
    dispatch(setModals({action: ModalsProperty.MODAL_HISTORY_OPERATIONS}));
  };

  const onRedirectPagePromoCode = () => {
    navigate(pages.personalAreaPromoCode.link);
  };

  const onOpenModalAddAnimal = () => {
    dispatch(setModals({action: ModalsProperty.MODAL_ADD_ANIMAL}));
  };

  const onShowModalRef = () => {
    dispatch(setModals({action: ModalsProperty.MODAL_REF_EMAIL_FRIEND}));
  };

  const onClickCardButtonIconSocialMedia = () => {
    dispatch(
      setModals({action: ModalsProperty.MODAL_ABOUT_INVITATION_SOCIAL_MEDIA})
    );
  };

  const onClickCardButtonIconSocialMediaButtons = () => {
    dispatch(
      setModals({
        action: ModalsProperty.MODAL_ABOUT_INVITATION_SOCIAL_MEDIA_BUTTONS,
      })
    );
  };

  const onClickCardButtonIconAnimal = () => {
    dispatch(
      setModals({action: ModalsProperty.MODAL_ABOUT_INVITATION_ANIMAL})
    );
  };

  const onClickCardButtonIconAnimalButtons = () => {
    dispatch(
      setModals({
        action: ModalsProperty.MODAL_ABOUT_INVITATION_ANIMAL_BUTTONS,
      })
    );
  };

  const getNoun = (number: number, one: string, two: string, five: string) => {
    if (number < 0) {
      return "Ошибка: число не может быть отрицательным";
    }

    const lastTwoDigits = number % 100;
    const lastDigit = number % 10;

    if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
      return five;
    }

    if (lastDigit === 1) {
      return one;
    }
    if (lastDigit >= 2 && lastDigit <= 4) {
      return two;
    }

    return five;
  };

  const getDatePet = (date: string) => {
    const {yearsDifference, monthsDifference} = findDateDifference(date);
    return `${getNumberYear(yearsDifference)} ${getNumberMonth(
      monthsDifference
    )}`;
  };

  const getDatePetAdult = (years: number, months: number) => {
    return `${getNumberYear(years)}${getNumberMonth(months)}`;
  };
  const animation = {
    initial: {opacity: 0},
    animate: {opacity: 1},
    exit: {opacity: 0},
  };

  useEffect(() => {
    if (pets?.length === 0) {
      dispatch(esPet.model.getPetsThunk());
    }

    if (promoCodes?.length === 0) {
      dispatch(esDiscount.model.getPromoCodesThunk());
    }
  }, []);

  const onRepostVk = async () => {
    await dispatch(getVkRepostThunk());
    await dispatch(getUserThunk());
  };

  const onRepostOk = async () => {
    await dispatch(getOkRepostThunk());
    await dispatch(getUserThunk());
  };

  return (
    <s.PersonalAreaContainer>
      <s.PersonalAreaProfile>
        <s.WrapFooter>
          <SidebarPersonalArea
            isMobile={isMobile}
            onChangeInput={onEditAvatar}
          />

          <Animation isShow={true} delay={0.1} variants={animation}>
            <s.Footer>
              <s.List>
                {(!user.vk_repost || !user.ok_repost) && (
                  <s.Item>
                    <s.Card
                      isMobile={!isMobile}
                      title={"Получите 3\u00A0роялса"}
                      text={"Расскажите о программе в\u00A0соц.сетях"}
                      mobileButtonText={"Расскажите о программе в соц.сетях"}
                      buttons={[
                        !user.vk_repost && (
                          <a
                            href="https://vk.com/share.php?url=https://xn----ctbfggjf0anaeki8a4dxd.xn--p1ai&title=%D0%9F%D1%80%D0%B8%D1%81%D0%BE%D0%B5%D0%B4%D0%B8%D0%BD%D1%8F%D0%B9%D1%82%D0%B5%D1%81%D1%8C%20%D0%BA%20%D0%B0%D0%BA%D1%86%D0%B8%D0%B8%20%D0%97%D0%B4%D0%BE%D1%80%D0%BE%D0%B2%D1%8B%D0%B9%20%D0%BF%D0%B8%D1%82%D0%BE%D0%BC%D0%B5%D1%86.&image=https://xn----ctbfggjf0anaeki8a4dxd.xn--p1ai/media/banners/banner-vk.jpg&noparse=true"
                            target={"_blank"}
                          >
                            <ButtonInvite
                              icon={<s.IconButtonVk/>}
                              onClickButtonInvite={onRepostVk}
                              text="Вконтакте"
                              type="button"
                            />
                          </a>
                        ),
                        !user.ok_repost && (
                          <a
                            href="https://connect.ok.ru/offer?url=https://xn----ctbfggjf0anaeki8a4dxd.xn--p1ai"
                            target={"_blank"}
                          >
                            <ButtonInvite
                              icon={<s.IconButtonOk/>}
                              onClickButtonInvite={onRepostOk}
                              text="Одноклассники"
                              type="button"
                            />
                          </a>
                        ),
                      ]}
                      onClickCardButtonIcon={onClickCardButtonIconSocialMedia}
                      onClickMobileButton={
                        onClickCardButtonIconSocialMediaButtons
                      }
                    />
                  </s.Item>
                )}

                <s.Item>
                  <s.Card
                    isMobile={!isMobile}
                    title={"Получите 5\u00A0роялсов"}
                    text={"Пригласите друга с\u00A0питомцем"}
                    mobileButtonText={"Пригласите друга с питомцем"}
                    buttons={[
                      <ButtonInvite
                        icon={<s.IconUser/>}
                        onClickButtonInvite={onShowModalRef}
                        text="Пригласить друга"
                        type="button"
                      />,
                    ]}
                    onClickCardButtonIcon={onClickCardButtonIconAnimal}
                    onClickMobileButton={onClickCardButtonIconAnimalButtons}
                  />
                </s.Item>
              </s.List>
            </s.Footer>
          </Animation>
        </s.WrapFooter>

        <s.WrapContent>
          <Animation isShow={true} delay={0.3} variants={animation}>
            <s.MyWallet>
              <s.Title>Мой кошелек</s.Title>

              <s.WalletList>
                <s.WalletItem>
                  <s.WalletItemButton
                    type={"button"}
                    onClick={onOpenModalHistory}
                  >
                    <s.WalletCard
                      icon={<IconRoyals height={64} width={64}/>}
                      title={`${user?.available_royals} ${getNoun(
                        user?.available_royals,
                        "роялс",
                        "роялса",
                        "роялсов"
                      )}`}
                      text={"История операций"}
                    />
                  </s.WalletItemButton>
                </s.WalletItem>

                <s.WalletItem>
                  <s.WalletItemButton
                    type={"button"}
                    onClick={onRedirectPagePromoCode}
                  >
                    <s.WalletCard
                      icon={<IconPromoSmallFilled height={64} width={64}/>}
                      title={`${promoCodes?.length || "0"} ${getNoun(
                        promoCodes?.length,
                        "активный промокод",
                        "активных промокода",
                        "активных промокодов"
                      )}`}
                      text={"Перейти в раздел"}
                    />
                  </s.WalletItemButton>
                </s.WalletItem>
              </s.WalletList>
            </s.MyWallet>
          </Animation>

          <Animation isShow={true} delay={0.5} variants={animation}>
            <s.MyAnimal>
              <s.Title>Мои питомцы</s.Title>

              <s.AnimalList>
                {pets?.map((pet, index) => (
                  <CardPet
                    key={index}
                    src={pet?.image || ""}
                    alt={`Питомец ${index + 1}`}
                    breed_class={pet?.breed?.breed_class || ""}
                    date={getDatePet(pet?.date_of_birth || "") || ""}
                    name={pet?.name || ""}
                    genderIcon={
                      pet?.gender == "male" ? <IconMale/> : <IconFemale/>
                    }
                    breed={pet?.breed?.title || ""}
                    dateEnd={
                      getDatePetAdult(
                        pet?.time_to_adult?.years || 0,
                        pet?.time_to_adult?.months || 0
                      ) || ""
                    }
                    onChangeInput={onEditAvatarPet(pet)}
                  />
                ))}
                <s.AnimalItem>
                  <s.ButtonCardAddPet
                    icon={<IconAdd/>}
                    onClickButtonCardAdd={onOpenModalAddAnimal}
                    text={"Добавить нового питомца"}
                  />
                </s.AnimalItem>
              </s.AnimalList>
            </s.MyAnimal>
          </Animation>

          <Animation isShow={true} delay={0.8}>
            <s.Banner>
              <s.BannerLink
                href="https://zoozavr.ru/catalog/index/name/sortforbrand/brand/18703/"
                target={"_blank"}
              >
                <s.BannerTitle>Рацион для взрослого питомца</s.BannerTitle>

                <picture>
                  <source
                    srcSet={`${bannerImgFood375Webp} 1x, ${bannerImgFood375_2xWebp} 2x`}
                    media={`(max-width: ${breakpoints.MD})`}
                    type="image/webp"
                  />

                  <source
                    srcSet={`${bannerImgFood375} 1x, ${bannerImgFood375_2x} 2x`}
                    media={`(max-width: ${breakpoints.MD})`}
                  />

                  <source
                    srcSet={`${bannerImgFood1024Webp} 1x, ${bannerImgFood1024_2xWebp} 2x`}
                    media={`(max-width: ${breakpoints.XL})`}
                    type="image/webp"
                  />

                  <source
                    srcSet={`${bannerImgFood1024} 1x, ${bannerImgFood1024_2x} 2x`}
                    media={`(max-width: ${breakpoints.XL})`}
                  />

                  <s.Image
                    src={bannerImgFood1440}
                    srcSet={`${bannerImgFood1440Webp} 1x, ${bannerImgFood1440_2xWebp} 2x, ${bannerImgFood1440} 1x, ${bannerImgFood1440_2x} 2x`}
                    alt={"Серия рационов Royal Canin для животных"}
                  />
                </picture>
              </s.BannerLink>
            </s.Banner>
          </Animation>
        </s.WrapContent>
      </s.PersonalAreaProfile>
    </s.PersonalAreaContainer>
  );
};
