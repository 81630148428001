import React, {FC, useEffect, useState} from "react";
import * as s from "./Modal-add-animal-styles";
import {ButtonProperty, ButtonTagProperty} from "../../../../shared/config";
import {FormPet} from "../../../../widgets";
import {useFormik} from "formik";
import {IconCat, IconDog, IconFemale, IconMale} from "../../../../shared";
import {AnimalCatAndDogType, selectStateAnimals,} from "../../../../entities/animal/model";
import {useAppDispatch, useAppSelector} from "../../../../shared/lib";
import {ValidationFormModalAddAnimal} from "../config";
import {esAnimal, esAuth, esPet} from "../../../../entities";
import {BreedRequestType} from "../../../../entities/pet/model";
import {setDeleteNotification, setNotification} from "../../../../shared/model";
import {v4 as uuidv4} from "uuid";

type ModalAddAnimalType = {
  className?: string;
  onShowModal: () => void;
};

export const ModalAddAnimal: FC<ModalAddAnimalType> = ({
                                                         className,
                                                         onShowModal,
                                                       }) => {
  const user = useAppSelector(esAuth.model.selectAuthUser);
  const dispatch = useAppDispatch();
  const {cat, dog} = useAppSelector(selectStateAnimals);
  const [animals, setAnimals] = useState<Array<AnimalCatAndDogType>>([]);
  const formik = useFormik({
    initialValues: {
      animal: "",
      name: "",
      date_of_birth: "",
      breed: "",
      gender: "",
    },

    validationSchema: ValidationFormModalAddAnimal(cat, dog),

    onSubmit: async (body: any) => {
      const id = uuidv4();
      delete body.animal;
      const request = {
        ...body,
        user: user.id,
        breed: animals.find((el) => el.value === body.breed)?.id,
      } as BreedRequestType;
      await dispatch(esPet.model.createPetThunk(request));
      formik.resetForm();
      onShowModal();
      dispatch(
        setNotification({
          text: "Питомец добавлен!",
          id: id,
        })
      );
      setTimeout(() => {
        dispatch(setDeleteNotification(id));
      }, 10000);
    },
  });

  const errors = formik.errors;

  const onSendTagAnimal =
    (value: "Cat" | "Dog", animalsData: Array<AnimalCatAndDogType>) => () => {
      formik.setFieldValue("animal", value);
      setAnimals(animalsData);
    };

  const onSendTagGender = (value: "male" | "female") => () => {
    formik.setFieldValue("gender", value);
  };

  const setErrorForAnimalTags = () => {
    formik.setFieldTouched(`animal`, true);
  };

  useEffect(() => {
    dispatch(esAnimal.model.getAnimalsThunk());
    formik.setFieldTouched("animal", false);
  }, []);

  return (
    <s.ModalAddAnimal className={className}>
      <s.Modal onClickButtonBg={onShowModal} onClickButtonIcon={onShowModal}>
        <s.Title>Добавление питомца</s.Title>

        <s.Form onSubmit={formik.handleSubmit}>
          <FormPet
            formik={formik}
            onClickSelectAnimalDisabled={setErrorForAnimalTags}
            buttonsAnimalError={(formik.touched.animal && errors?.animal) || ""}
            buttonsAnimalTags={[
              {
                property:
                  formik.touched.animal && !!errors?.animal
                    ? ButtonTagProperty.ERROR
                    : ButtonTagProperty.DEFAULT,
                text: "кошка",
                type: "button",
                isActive: formik.values.animal === "Cat",
                icon: <IconCat width={24} height={24}/>,
                onClickButtonTag: onSendTagAnimal("Cat", cat),
              },
              {
                property:
                  formik.touched.animal && !!errors?.animal
                    ? ButtonTagProperty.ERROR
                    : ButtonTagProperty.DEFAULT,
                text: "собака",
                type: "button",
                icon: <IconDog width={24} height={24}/>,
                isActive: formik.values.animal === "Dog",
                onClickButtonTag: onSendTagAnimal("Dog", dog),
              },
            ]}
            inputName={{
              name: "name",
            }}
            inputSelectCalendar={{name: "date_of_birth"}}
            inputSelectAnimal={{
              name: "breed",
              disabled: animals.length === 0,
              list: animals || [],
            }}
            buttonsGenderError={(formik.touched.gender && errors?.gender) || ""}
            buttonsGenderTags={[
              {
                property:
                  formik.touched.gender && !!errors?.gender
                    ? ButtonTagProperty.ERROR
                    : ButtonTagProperty.DEFAULT,
                text: "самец",
                type: "button",
                isActive: formik.values.gender === "male",
                icon: <IconMale width={24} height={24}/>,
                onClickButtonTag: onSendTagGender("male"),
              },
              {
                property:
                  formik.touched.gender && !!errors?.gender
                    ? ButtonTagProperty.ERROR
                    : ButtonTagProperty.DEFAULT,
                text: "самка",
                type: "button",
                isActive: formik.values.gender === "female",
                icon: <IconFemale width={24} height={24}/>,
                onClickButtonTag: onSendTagGender("female"),
              },
            ]}
          />
          <s.ButtonSubmit
            property={ButtonProperty.SECONDARY}
            text={"Добавить питомца"}
            type={"submit"}
            onClickButton={() => {
            }}
          />
        </s.Form>
      </s.Modal>
    </s.ModalAddAnimal>
  );
};
