import React, { FC, ReactNode } from "react";
import * as s from "./Field-input-code-styles";
import { InputCode, InputCodeType } from "../../atom";

type FieldInputCodeType = InputCodeType & {
  icon?: ReactNode;
  onClickButtonIcon: () => void;
};

export const FieldInputCode: FC<FieldInputCodeType> = ({
  icon,
  className,
  type,
  value,
  placeholder,
  disabled,
  isError,
  isFocus,
  onClickInputCode,
  onChangeInputCode,
  onClickButtonIcon,
}) => {
  return (
    <s.FieldInputCode className={className}>
      <InputCode
        type={type}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        isError={isError}
        isFocus={isFocus}
        onClickInputCode={onClickInputCode}
        onChangeInputCode={onChangeInputCode}
      />

      {icon && (
        <s.ButtonIcon
          icon={icon}
          type={"button"}
          onClickButtonIcon={disabled ? () => {} : onClickButtonIcon}
        />
      )}
    </s.FieldInputCode>
  );
};
