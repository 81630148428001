import styled from "styled-components";
import {PopupBody} from "../../../../shared";
import {BodyAnimation} from "../../../../shared/ui/molecules/popup-body/Popup-body-styles";
import {breakpoints} from "../../../../shared/config";

const ModalCheckEmail = styled.div``;

const Modal = styled(PopupBody)`
  ${BodyAnimation} {
    padding: 40px 80px 40px 40px;
    max-width: 650px;
  }

  @media (max-width: ${breakpoints.LG}) {
    ${BodyAnimation} {
      max-width: 100%;
    }
  }
  @media (max-width: ${breakpoints.MD}) {
    ${BodyAnimation} {
      padding: 30px 45px 30px 30px;
    }
  }
`;

const Title = styled.h2`
  margin-bottom: 16px;
  font: var(--font-3);
  color: var(--brand-1);
`;

const Text = styled.p`
  font: var(--font-16);
  color: var(--brand-2);
`;

const Button = styled.button`
  font: var(--font-15);
  color: currentColor;
`

export {ModalCheckEmail, Modal, Title, Text, Button};
