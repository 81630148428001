import styled, { css } from "styled-components";
import { IconTick } from "../_icons";

type CheckValidationType = {
  $isStatus: boolean;
};

const CheckValidation = styled.div<CheckValidationType>`
  display: flex;
  align-items: stretch;
  gap: 6px;
  font: var(--font-20);
  line-height: 1;

  ${(props) =>
    props.$isStatus
      ? css`
          color: var(--hyperlink);
        `
      : css`
          color: var(--text);
        `}
`;

const WrapIcon = styled.div`
  position: relative;
`;

const IconCheck = styled(IconTick)`
  width: 16px;
  height: 16px;
  color: var(--success);
`;

const Icon = styled.div`
  width: 16px;
  height: 16px;
  background-color: transparent;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 5px;
    height: 5px;
    background-color: var(--text);
    border-radius: 50%;
  }
`;

export { CheckValidation, WrapIcon, Icon, IconCheck };
