import React, { FC, ReactNode } from "react";
import * as s from "./Modal-additional-royals-styles";

export type ModalAdditionalRoyalsType = {
  className?: string;
  title: string;
  list: Array<{ subTitle: string; text: ReactNode }>;
  bgImage: ReactNode;
  onShowModal: () => void;
};

export const ModalAdditionalRoyals: FC<ModalAdditionalRoyalsType> = ({
  className,
  title,
  list,
  bgImage,
  onShowModal,
}) => {
  return (
    <s.ModalAdditionalRoyals className={className}>
      <s.Modal
        onClickButtonBg={onShowModal}
        onClickButtonIcon={onShowModal}
        $bgImage={bgImage}
      >
        <s.Title>{title}</s.Title>
        <s.List>
          {list.map((item, index) => (
            <s.Item key={index}>
              <s.Subtitle>{item.subTitle}</s.Subtitle>
              <s.Text>{item.text}</s.Text>
            </s.Item>
          ))}
        </s.List>
      </s.Modal>
    </s.ModalAdditionalRoyals>
  );
};
