import styled, { css } from "styled-components";
import { breakpoints } from "../../../shared/config";
import { MenuMobile } from "../../../shared";
import { List as ListMenuMobileStyled } from "../../../shared/ui/atom/menu-mobile/Menu-mobile-styles";

type HeaderType = {
  $isActiveScroll?: boolean;
};

const Header = styled.header<HeaderType>`
  position: relative;
  z-index: 1000;
  padding: 40px 0;
  background-color: var(--brand-3);

  @media (max-width: ${breakpoints.LG}) {
    padding: 24px 0;
  }

  @media (max-width: ${breakpoints.MD}) {
    padding: 16px 0;
  }

  ${(props) =>
    props.$isActiveScroll &&
    css`
      position: sticky;
      top: 0;
      box-shadow: 0 57px 16px 0 rgba(0, 0, 0, 0), 0 36px 15px 0 rgba(0, 0, 0, 0),
        0 20px 12px 0 rgba(0, 0, 0, 0.01), 0 9px 9px 0 rgba(0, 0, 0, 0.02),
        0 2px 5px 0 rgba(0, 0, 0, 0.02);
    `}
`;

const Menu = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeaderMenuMobile = styled(MenuMobile)`
  ${ListMenuMobileStyled} {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
  }
`;

const List = styled.ul`
  display: flex;
  align-items: center;
  gap: 24px;

  @media (max-width: ${breakpoints.XL}) {
    gap: 14px;
  }

  @media (max-width: ${breakpoints.LG}) {
    gap: 20px;
  }

  @media (max-width: ${breakpoints.MD}) {
    gap: 16px;
  }
`;

const Item = styled.li`
  & svg {
    @media (max-width: ${breakpoints.LG}) {
      width: 32px;
      height: 32px;
    }
  }
`;

export { Header, Menu, HeaderMenuMobile, List, Item };
