import styled from "styled-components";

const MenuPersonalAreaDesktopStyles = styled.nav``;

const List = styled.ul`
  display: grid;
  grid-auto-rows: var(--minmax);
  gap: 4px;
`;

const Item = styled.li``;

export { MenuPersonalAreaDesktopStyles, List, Item };
