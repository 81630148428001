import React, { FC } from "react";

export const IconCalendar: FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox={"0 0 27 25"}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M20.939 7.48a.875.875 0 0 0-.875-.876H7.007a.875.875 0 0 0-.875.875V18.75c0 .483.392.875.875.875h13.057a.875.875 0 0 0 .875-.875V7.48ZM6.824 5.561a1.75 1.75 0 0 0-1.75 1.75v11.605c0 .966.784 1.75 1.75 1.75h13.423a1.75 1.75 0 0 0 1.75-1.75V7.312a1.75 1.75 0 0 0-1.75-1.75H6.824Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      d="M6.133 9.208H20.94v1.042H6.133V9.208ZM10.36 8.167a.525.525 0 0 1-.528-.521V4.52c0-.288.237-.521.529-.521.292 0 .529.233.529.52v3.126c0 .287-.237.52-.53.52ZM16.707 8.167a.525.525 0 0 1-.529-.521V4.52c0-.288.237-.521.529-.521.292 0 .529.233.529.52v3.126c0 .287-.237.52-.53.52ZM10.891 13.115a.787.787 0 0 1-.793.78.787.787 0 0 1-.793-.78c0-.432.355-.782.793-.782.438 0 .793.35.793.782ZM14.329 13.115a.787.787 0 0 1-.794.78.787.787 0 0 1-.793-.78c0-.432.355-.782.793-.782.438 0 .793.35.793.782ZM17.766 13.115a.787.787 0 0 1-.793.78.787.787 0 0 1-.794-.78c0-.432.356-.782.794-.782.438 0 .793.35.793.782ZM10.891 15.719a.787.787 0 0 1-.793.781.787.787 0 0 1-.793-.781c0-.432.355-.781.793-.781.438 0 .793.35.793.78ZM14.329 15.719a.787.787 0 0 1-.794.781.787.787 0 0 1-.793-.781c0-.432.355-.781.793-.781.438 0 .793.35.793.78ZM17.766 15.719a.787.787 0 0 1-.793.781.787.787 0 0 1-.794-.781c0-.432.356-.781.794-.781.438 0 .793.35.793.78Z"
    />
  </svg>
);
