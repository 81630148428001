import React, { FC, ReactNode } from "react";
import * as s from "./Info-bonus-tooltip-styles";
import { Animation, CounterRoyals } from "../../atom";

type InfoBonusTooltipType = {
  className?: string;
  isTooltipVisibility: boolean;
  counterRoyals: string;
  date: string;
  icon: ReactNode;
  onHandleMouseEnter: () => void;
  onHandleMouseLeave: () => void;
};

export const InfoBonusTooltip: FC<InfoBonusTooltipType> = ({
  className,
  isTooltipVisibility,
  counterRoyals,
  date,
  icon,
  onHandleMouseEnter,
  onHandleMouseLeave,
}) => {
  return (
    <s.InfoBonusTooltip className={className}>
      <s.Button
        onMouseEnter={onHandleMouseEnter}
        onMouseLeave={onHandleMouseLeave}
      >
        {icon}
      </s.Button>
      <Animation isShow={isTooltipVisibility}>
        <s.InfoBonusTooltipWrap>
          <s.Span>
            <CounterRoyals counter={counterRoyals} />
          </s.Span>
          зачислены {date}
        </s.InfoBonusTooltipWrap>
      </Animation>
    </s.InfoBonusTooltip>
  );
};
