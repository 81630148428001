import styled from "styled-components";
import { Calendar, FieldInput } from "../../molecules";

const SelectInputCalendar = styled.div`
  position: relative;
  max-width: 100%;
`;

const Bg = styled.button`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--dark-30);
  z-index: 0;
`;

const Input = styled(FieldInput)``;

const SelectCalendar = styled(Calendar)`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
`;

export { SelectInputCalendar, Bg, Input, SelectCalendar };
