import React, { FC, ReactNode } from "react";
import * as s from "./Button-card-add-styles";

type ButtonCardAddType = {
  className?: string;
  text: string;
  icon: ReactNode;
  onClickButtonCardAdd: () => void;
};

export const ButtonCardAdd: FC<ButtonCardAddType> = ({
  className,
  text,
  icon,
  onClickButtonCardAdd,
}) => {
  return (
    <s.ButtonCardAdd className={className} onClick={onClickButtonCardAdd}>
      <s.Content>
        <s.Icon>{icon}</s.Icon>
        <s.Text>{text}</s.Text>
      </s.Content>
    </s.ButtonCardAdd>
  );
};
