import styled from "styled-components";
import { ButtonUpload } from "../../atom";
import { breakpoints } from "../../../config";

const BonusMechanics = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 54px;
  width: 100%;
  max-width: 1240px;
  max-height: 217px;
  padding: 32px 40px;
  border-radius: 32px;
  background-color: var(--background-form);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.02), 0 9px 9px 0 rgba(0, 0, 0, 0.02),
    0 20px 12px 0 rgba(0, 0, 0, 0.01), 0 36px 15px 0 rgba(0, 0, 0, 0),
    0 57px 16px 0 rgba(0, 0, 0, 0);

  @media (max-width: ${breakpoints.XXL}) {
    gap: 16px;
    width: 95%;
    padding: 32px;
  }

  @media (max-width: ${breakpoints.XL}) {
    gap: 29px;
    width: 98%;
    max-height: 184px;
    padding: 20px 28px;
  }

  @media (max-width: ${breakpoints.LG}) {
    flex-direction: column;
    gap: 34px;
    width: 100%;
    max-height: none;
    padding-right: 0;
  }

  @media (max-width: ${breakpoints.MD}) {
    align-items: flex-start;
    gap: 20px;
    background-color: var(--brand-3);
    border-radius: 0;
    padding: 0;
    box-shadow: none;
  }
`;

const Button = styled(ButtonUpload)`
  max-width: 230px;

  @media (max-width: ${breakpoints.XXL}) {
    min-width: 212px;
  }

  @media (max-width: ${breakpoints.XL}) {
    min-width: 180px;
    padding: 16px 20px;
  }

  @media (max-width: ${breakpoints.LG}) {
    max-width: 340px;
    margin-right: 28px;
  }

  @media (max-width: ${breakpoints.MD}) {
    max-width: none;
    margin-right: 0;
  }
`;

export { BonusMechanics, Button };
