import React, { FC, useState } from "react";
import * as s from "./Button-dropdown-styles";

type ButtonDropdownType = {
  className?: string;
  type: "button" | "submit" | "reset";
  text: string;
  onClickButtonDropdown: () => void;
  isIOS?: boolean;
};

export const ButtonDropdown: FC<ButtonDropdownType> = ({
  className,
  type,
  text,
  onClickButtonDropdown,
  isIOS,
}) => {
  const [touchStartY, setTouchStartY] = useState<number | null>(null);

  const handleTouchStart = (e: React.TouchEvent<HTMLButtonElement>) => {
    if (isIOS) {
      setTouchStartY(e.touches[0].clientY);
    }
  };

  const handleTouchMove = (e: React.TouchEvent<HTMLButtonElement>) => {
    if (isIOS) {
      if (touchStartY !== null) {
        const deltaY = e.touches[0].clientY - touchStartY;
        // Если пользователь начал скроллить, не обрабатываем нажатие на кнопку
        if (Math.abs(deltaY) > 10) {
          setTouchStartY(null);
        }
      }
    }
  };

  const handleClick = () => {
    if (!isIOS) {
      onClickButtonDropdown();
    }
  };

  const handleTouchEnd = () => {
    if (isIOS) {
      if (touchStartY !== null) {
        onClickButtonDropdown();
      }
      setTouchStartY(null);
    }
  };

  return (
    <s.ButtonDropdown
      className={className}
      type={type}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      onClick={handleClick}
    >
      <s.Span>{text}</s.Span>
    </s.ButtonDropdown>
  );
};
