import React, { FC, ReactNode } from "react";
import * as s from "./Button-invite-styles";

type ButtonInviteType = {
  className?: string;
  text: string;
  type: "button" | "reset" | "submit";
  disabled?: boolean;
  icon: ReactNode;
  onClickButtonInvite: () => void;
};

export const ButtonInvite: FC<ButtonInviteType> = ({
  className,
  text,
  type,
  disabled,
  icon,
  onClickButtonInvite,
}) => {
  return (
    <s.ButtonInvite
      className={className}
      type={type}
      disabled={disabled}
      onClick={disabled ? () => {} : onClickButtonInvite}
    >
      {icon}
      {text}
    </s.ButtonInvite>
  );
};
