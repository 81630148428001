import React, { FC, ReactNode, RefObject } from "react";
import * as s from "./Field-input-styles";
import { Animation, InputType } from "../../atom";

type FieldInputType = InputType & {
  classNameInput?: string;
  label?: string;
  error?: string;
  icon?: ReactNode;
  onClickButtonIcon?: () => void;
  inputRef?: RefObject<HTMLInputElement>;
};

export const FieldInput: FC<FieldInputType> = ({
  className,
  label,
  error,
  icon,
  placeholder,
  onClickButtonIcon,
  classNameInput,
  type,
  value,
  inputRef,
  name,
  disabled,
  isFocus,
  onChangeInput,
  onClickInput,
  onBlurInput,
  onHandelBlurInput,
}) => {
  return (
    <s.FieldInput
      className={className}
      data-testid={"field-input-test"}
      $isDisabled={disabled}
      $isButton={type === "button"}
      $isActivePlaceholderButton={type === "button" && !value && !!placeholder}
    >
      <s.Wrap>
        <s.InputField
          className={classNameInput}
          inputRef={inputRef}
          type={type}
          value={
            type === "button" ? (value ? value : placeholder || "") : value
          }
          name={name}
          placeholder={placeholder}
          disabled={disabled}
          isError={!!error}
          isFocus={isFocus}
          $isLable={!!label}
          onChangeInput={onChangeInput}
          onClickInput={onClickInput}
          onBlurInput={onBlurInput}
          onHandelBlurInput={onHandelBlurInput}
        />

        {label && (
          <s.Label $isError={!!error} $isFocus={isFocus}>
            {label}
          </s.Label>
        )}

        {icon && (
          <s.ButtonIcon
            icon={icon}
            type={"button"}
            onClickButtonIcon={disabled ? () => {} : onClickButtonIcon}
          />
        )}
      </s.Wrap>

      <Animation isShow={!!error}>
        <s.Error>{error}</s.Error>
      </Animation>
    </s.FieldInput>
  );
};
