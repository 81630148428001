import React, { FC, ReactNode } from "react";
import * as s from "./Card-wallet-styles";

type CardWalletType = {
  className?: string;
  title: string;
  text: string;
  icon: ReactNode;
};

export const CardWallet: FC<CardWalletType> = ({
  className,
  title,
  text,
  icon,
}) => {
  return (
    <s.CardWallet className={className}>
      <s.WrapIcon>{icon}</s.WrapIcon>
      <s.Content>
        <s.Title>{title}</s.Title>
        <s.Text>{text}</s.Text>
      </s.Content>
    </s.CardWallet>
  );
};
