import { createAsyncThunk } from "@reduxjs/toolkit";
import { animalApi } from "../../api/animal-api";
import { AxiosError } from "axios";

export const getAnimalsThunk = createAsyncThunk(
  "user/get-user",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await animalApi.breed();
      return response.data;
    } catch (e) {
      const error = e as AxiosError<any>;
      return rejectWithValue(error.response?.data.error);
    }
  }
);
