import styled from "styled-components";
import {ModalBodyRight} from "../../../../shared";
import {BodyAnimation} from "../../../../shared/ui/molecules/modal-body-right/Modal-body-right-styles";
import {breakpoints} from "../../../../shared/config";

const ModalQuestionSent = styled.div``;

const Modal = styled(ModalBodyRight)`
  ${BodyAnimation} {
    padding: 80px 128px 94px 64px;

    @media (max-width: ${breakpoints.XXL}) {
      padding: 60px 70px 52px;
    }

    @media (max-width: ${breakpoints.XL}) {
      padding: 40px 50px 45px;
    }

    @media (max-width: ${breakpoints.LG}) {
      padding: 56px 116px 70px;
    }

    @media (max-width: ${breakpoints.MD}) {
      padding: 32px 20px 30px;
    }
  }
`;

const Title = styled.h2`
  margin-bottom: 16px;
  font: var(--font-3);
  color: var(--brand-3);

  @media (max-width: ${breakpoints.LG}) {
    text-align: center;
  }

  @media (max-width: ${breakpoints.MD}) {
    margin-bottom: 12px;
  }
`;

const Text = styled.p`
  font: var(--font-16);
  color: var(--brand-3);

  @media (max-width: ${breakpoints.LG}) {
    text-align: center;
  }
`;

export {ModalQuestionSent, Modal, Title, Text};
