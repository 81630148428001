import React, { FC, ReactNode } from "react";
import { AnimatePresence, motion, Variants } from "framer-motion";

type AnimationType = {
  className?: string;
  children: ReactNode;
  isShow: boolean;
  variants?: Variants;
  delay?: number;
  duration?: number;
};

export const Animation: FC<AnimationType> = ({
  isShow,
  children,
  className,
  variants = {
    initial: { height: 0, opacity: 0 },
    animate: { height: "auto", opacity: 1 },
    exit: { height: 0, opacity: 0 },
  },
  delay,
  duration,
  ...props
}) => {
  return (
    <AnimatePresence>
      {isShow && (
        <motion.div
          className={className}
          variants={variants}
          transition={{
            delay,
            duration,
          }}
          initial={"initial"}
          animate={"animate"}
          exit={"exit"}
          {...props}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};
