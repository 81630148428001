import React, { FC } from "react";
import * as s from "./Notifications-bar-styles";
import { Animation, IconClose, NotificationBar } from "../../atom";
import { NotificationType } from "../../../model";

type NotificationsBarType = {
  className?: string;
  notifications: Array<NotificationType>;
  onClickButtonIcon: (id: string) => () => void;
  onClickButton: () => void;
};

export const NotificationsBar: FC<NotificationsBarType> = ({
  className,
  notifications,
  onClickButtonIcon,
  onClickButton,
}) => {
  return (
    <s.NotificationsBar className={className}>
      <s.List>
        {notifications.map((notification, index) => (
          <s.Item key={notification.id + index + notification.text}>
            <Animation isShow={true}>
              <NotificationBar
                text={notification.text}
                icon={<IconClose width={24} height={24} />}
                onClickButtonIcon={onClickButtonIcon(notification.id)}
                buttonText={notification.buttonText}
                onClickButton={onClickButton}
              />
            </Animation>
          </s.Item>
        ))}
      </s.List>
    </s.NotificationsBar>
  );
};
