import React, { FC } from "react";
import * as s from "./Mini-royals-card-styles";

export type MiniRoyalsCardType = {
  className?: string;
  amount: null | number;
  month?: string;
};

export const MiniRoyalsCard: FC<MiniRoyalsCardType> = ({
  className,
  amount,
}) => {
  return (
    <s.MiniRoyalsCard className={className}>
      <s.RoyalsCardIcon />

      <s.RoyalsAmount>
        <s.Royals>{amount}</s.Royals>
        <s.RoyalsIcon />
        <s.Text>роялсов</s.Text>
      </s.RoyalsAmount>
    </s.MiniRoyalsCard>
  );
};
