import React, { FC, ReactNode } from "react";
import * as s from "./Link-icon-text-styles";

type LinkIconTextType = {
  className?: string;
  text: string;
  to: string;
  icon: ReactNode;
};

export const LinkIconText: FC<LinkIconTextType> = ({
  className,
  text,
  to,
  icon,
}) => {
  return (
    <s.LinkIconText className={className} to={to}>
      {icon}
      {text}
    </s.LinkIconText>
  );
};
