import React from "react";
import { type RouteObject } from "react-router-dom";
import { sd } from "../../../../shared";
import { RecoverPasswordReset } from "../../ui/Recover-password-reset";
import {pages} from "../../../../shared/config";

export const router: RouteObject = {
  path: pages.recoverPasswordReset.router,
  element: <RecoverPasswordReset />,
};
