import React, { FC, ReactNode, useEffect, useRef, useState } from "react";
import * as s from "./Header-styles";
import { MenuDesktop } from "../../../shared";
import { Container } from "../../../shared/config";

type HeaderType = {
  className?: string;
  isMobile: boolean;
  isScroll?: boolean;
  listLeft: Array<ReactNode>;
  listRight: Array<ReactNode>;
};

export const Header: FC<HeaderType> = ({
  className,
  isMobile,
  isScroll,
  listLeft,
  listRight,
}) => {
  const [isActiveMenuMobile, setIsActiveMenuMobile] = useState(false);
  const [hasShadow, setHasShadow] = useState(false);
  const headerRef = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    if (headerRef.current) {
      const scrollTop = window.pageYOffset;
      if (scrollTop > 1) {
        setHasShadow(true);
      } else {
        setHasShadow(false);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const onShowMenuMobile = () => {
    setIsActiveMenuMobile((value) => !value);
  };

  return (
    <s.Header
      className={className}
      ref={headerRef}
      $isActiveScroll={isScroll && hasShadow}
    >
      <Container>
        <s.Menu>
          {isMobile ? (
            <s.HeaderMenuMobile
              isActive={isActiveMenuMobile}
              list={listLeft}
              onClickBurger={onShowMenuMobile}
            />
          ) : (
            <MenuDesktop listLeft={listLeft} />
          )}

          <s.List>
            {listRight.map(
              (item, index) => item && <s.Item key={index}>{item}</s.Item>
            )}
          </s.List>
        </s.Menu>
      </Container>
    </s.Header>
  );
};
