import React, {ChangeEvent, FC, ReactNode, useEffect, useState} from "react";
import * as s from "./Modal-confirmation-styles";
import {Animation} from "../../../../shared";
import {formatSeconds} from "../../../../shared/lib";

type ModalConfirmationType = {
  className?: string;
  title: ReactNode;
  text?: ReactNode;
  subText: ReactNode;
  buttonRedirectText: string;
  error?: string | null;
  onShowModal: () => void;
  onClickButtonTime: () => void;
  onChangeInput: (code: string) => void;
  lengthNumber: number;
  buttonTextBefore: string;
  buttonTextAfter: string;
  inputPlaceholder: string;
};

export const ModalConfirmation: FC<ModalConfirmationType> = (
  {
    className,
    title,
    subText,
    text,
    buttonTextBefore,
    buttonTextAfter,
    buttonRedirectText,
    error,
    lengthNumber,
    inputPlaceholder,
    onShowModal,
    onClickButtonTime,
    onChangeInput,
  }) => {
  const [seconds, setSeconds] = useState(0);
  const [valueInput, setValueInput] = useState("");
  const icon = valueInput ? <s.Icon $isError={!!error}/> : "";
  const isDisabledButton = seconds !== 0;
  const buttonText =
    seconds === 0 ? buttonTextBefore : buttonTextAfter;

  const onChangeValueInput = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.value.length <= lengthNumber) {
      setValueInput(e.currentTarget.value);
    }
  };

  const onShowValueInput = () => {
    setValueInput("");
  };

  const onSendSms = () => {
    setSeconds(60);
    onClickButtonTime();
  };

  useEffect(() => {
    if (seconds !== 0) {
      setTimeout(() => {
        setSeconds((value) => value - 1);
      }, 1000);
    }
  }, [seconds]);

  useEffect(() => {
    const idTimeout = setTimeout(() => {
      onChangeInput(valueInput);
    }, 300);

    return () => clearTimeout(idTimeout);
  }, [valueInput]);

  useEffect(() => {
    setSeconds(60);
  }, []);

  return (
    <s.ModalConfirmation className={className}>
      <s.Modal
        buttonRedirectText={buttonRedirectText}
        onClickButtonBg={onShowModal}
        onClickButtonIcon={onShowModal}
      >
        <s.Title>{title}</s.Title>

        {text && (
          <s.Text>{text}</s.Text>
        )}

        <s.SubText>{subText}</s.SubText>

        <s.InputCode
          type={"number"}
          value={valueInput}
          placeholder={inputPlaceholder}
          icon={icon}
          isError={!!error}
          isFocus={!!valueInput}
          onChangeInputCode={onChangeValueInput}
          onClickButtonIcon={onShowValueInput}
        />

        <Animation isShow={!!error}>
          <s.Error>{error}</s.Error>
        </Animation>

        <s.Button
          type={"button"}
          text={buttonText}
          disabled={isDisabledButton}
          time={seconds === 0 ? "" : formatSeconds(seconds)}
          onClickButton={onSendSms}
        />
      </s.Modal>
    </s.ModalConfirmation>
  );
};
