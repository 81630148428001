import React, { FC } from "react";

export const IconAdd: FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox={"0 0 40 40"}
    fill={"currentColor"}
    {...props}
  >
    <circle cx={20} cy={20} r={20} fill="currentColor" />
    <path
      fill="#fff"
      d="M28.451 21.153h-7.258v7.299h-1.895v-7.299H12v-1.895h7.298V12h1.896v7.258h7.258v1.895Z"
    />
  </svg>
);
