import * as yup from "yup";
import { AnimalCatAndDogType } from "../../../../entities/animal/model";

const getBreedSchema = (array: Array<string>, animalName: string) => {
  return yup
    .string()
    .required(`Выберите породу ${animalName}`)
    .lowercase()
    .oneOf(array, `Выберите поддерживаемую породу для ${animalName}`);
};

export const ValidationFormRegistration = (
  arrayCat: Array<AnimalCatAndDogType>,
  arrayDog: Array<AnimalCatAndDogType>
) => {
  const getTitles = (array: Array<AnimalCatAndDogType>) => {
    return array.map((animal) => animal.value.toLowerCase());
  };

  return yup.object().shape({
    first_name: yup
      .string()
      .trim()
      .required("Это поле обязательно для заполнения"),

    last_name: yup
      .string()
      .trim()
      .required("Это поле обязательно для заполнения"),

    phone: yup
      .string()
      .trim()
      .required("Это поле обязательно для заполнения")
      .min(18, "Минимальное количество символов 11"),

    email: yup
      .string()
      .trim()
      .required("Это поле обязательно для заполнения")
      .email("Введите корректную почту"),

    password: yup
      .string()
      .trim()
      .required("Это поле обязательно для заполнения")
      .min(11, "Минимальное количество символов 11")
      .matches(/[0-9]/, "В поле обязательно должна быть одна цифра")
      .matches(/[А-ЯЁA-Z]/, "В поле обязательно должна заглавная буква")
      .matches(/[а-яёa-z]/, "В поле обязательно должна строчная буква"),

    confirm_password: yup
      .string()
      .trim()
      .oneOf([yup.ref("password")], "Пароли не совпадают")
      .required("Это поле обязательно для заполнения"),

    personal_data_handling: yup
      .boolean()
      .required("Это поле обязательно для заполнения")
      .oneOf([true], "Нужно согласиться с обработкой персональных данных"),

    age_confirmation: yup
      .boolean()
      .required("Это поле обязательно для заполнения")
      .oneOf([true], "Нужно подтвердить свой возраст"),

    pets: yup.array().of(
      yup.object().shape({
        animal: yup.string().trim().required("Укажите вид питомца"),
        name: yup.string().trim().required("Укажите имя питомца"),
        date_of_birth: yup
          .string()
          .trim()
          .required("Укажите дату рождения питомца"),
        breed: yup
          .string()
          .trim()
          .when("animal", {
            is: (animal: any) => animal === "Cat",
            then: (animal: any) => getBreedSchema(getTitles(arrayCat), "кошки"),
            otherwise: (animal: any) =>
              getBreedSchema(getTitles(arrayDog), "собаки"),
          }),

        gender: yup.string().trim().required("Укажите пол питомца"),
      })
    ),
  });
};
