import React, {ReactNode} from "react";

type useChangeLetterType = {
  text: string;
  icon: React.ReactNode;
  targetChar: string;
};

const useChangeLetter = ({
                           text,
                           icon,
                           targetChar,
                         }: useChangeLetterType): ReactNode => {
  const replaceIcon = (text: string): ReactNode => {
    if (!text || typeof text !== "string") return text;

    const index = text.indexOf(targetChar);
    if (index === -1) return text;

    const before = text.slice(0, index).trim();
    const after = text.slice(index + 1).trim();

    const beforeNumberMatch = before.match(/\d+$/);
    const afterNumberMatch = after.match(/^\d+/);

    const beforeNumber = beforeNumberMatch ? beforeNumberMatch[0] : "";
    const afterNumber = afterNumberMatch ? afterNumberMatch[0] : "";

    const beforeText = before.replace(/\d+$/, "").trim();
    const afterText = after.replace(/^\d+/, "").trim();

    return (
      <>
        <span>
          {beforeText}
          <span style={{
            whiteSpace: "nowrap",
          }}> {beforeNumber} {icon} </span>
        </span>
        <span>{replaceIcon(afterText)}</span>
      </>
    );
  };

  return replaceIcon(text);
};

export {useChangeLetter};
