import styled, {css} from "styled-components";
import {CounterRoyals} from "../../atom";
import {breakpoints, StatusPromoCode} from "../../../config";
import {Icon} from "../../atom/counter-royals/Counter-royals-styles";

type CardPromoCodeType = {
  $status: StatusPromoCode.DEFAULT | StatusPromoCode.INVALID;
  $isBigBgImage?: boolean;
};

const Text = styled.p`
  position: absolute;
  top: 126px;
  left: 25px;
  max-width: 250px;
  padding-right: 3px;
  font: var(--font-9);
  color: var(--text);
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  z-index: 1;

  @media (max-width: ${breakpoints.XL}) {
    top: 122px;
    max-width: 210px;
  }

  @media (max-width: ${breakpoints.MD}) {
    top: 110px;
    left: 16px;
    max-width: 85%;
  }
`;

const Royals = styled(CounterRoyals)`
  font: var(--font-6);
  color: var(--brand-1);

  ${Icon} {
    width: 40px;
    height: 40px;
    z-index: 10;

    @media (max-width: ${breakpoints.MD}) {
      width: 24px;
      height: 24px;
    }
`;

const CardPromoCode = styled.article<CardPromoCodeType>`
  position: relative;
  width: 100%;
  max-width: 290px;
  max-height: 220px;
  min-height: 200px;
  padding: 24px 17px 24px 25px;
  background: var(--brand-3);
  border: 1px solid var(--pink-for-svg);
  border-radius: 24px;
  overflow: hidden;
  cursor: pointer;
  will-change: transform;
  transition: transform 300ms ease;

  @media (max-width: ${breakpoints.XL}) {
    max-height: 210px;
  }

  @media (max-width: ${breakpoints.MD}) {
    padding: 16px 9px 16px 16px;
  }

  &:active {
    transform: scale(0.95);
  }

  ${(props) =>
          props.$status === StatusPromoCode.INVALID &&
          css`
            border: unset;

            ${Text} {
              color: var(--interface);
            }

            ${Royals} {
              opacity: 0.5;
            }
          `};

  ${(props) =>
          props.$isBigBgImage &&
          css`
            ${BgImage} {
              top: -45px;
              right: 22px;
              width: 248px;
              height: 248px;

              @media (max-width: ${breakpoints.XL}) {
                top: -17px;
                right: 29px;
                width: 200px;
                height: 200px;
              }

              @media (max-width: ${breakpoints.MD}) {
                top: -25px;
                right: -25px;
                width: 180px;
                height: 180px;
              }
          `}
`;

const BgImage = styled.img`
  position: absolute;
  top: -30px;
  right: -15px;
  color: var(--pink);

  @media (max-width: ${breakpoints.XL}) {
    top: -15px;
    width: 156px;
    height: 156px;
  }

  @media (max-width: ${breakpoints.MD}) {
    top: 0;
    right: -18px;
    width: 120px;
    height: 120px;
  }
`;

const Bg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 24px;
  background-color: var(--dark-10);
`;

const Tag = styled.div`
  margin: auto;
  padding: 8px 16px 10px 16px;
  font: var(--font-9);
  border-radius: 30px;
  color: var(--hyperlink);
  background-color: var(--brand-3);
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.08), 0 2px 2px 0 rgba(0, 0, 0, 0.07),
  0 4px 3px 0 rgba(0, 0, 0, 0.04), 0 8px 3px 0 rgba(0, 0, 0, 0.01),
  0 12px 3px 0 rgba(0, 0, 0, 0);
`;

const Button = styled.button`
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: -1px;
  left: -1px;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
`;

export {CardPromoCode, BgImage, Bg, Tag, Royals, Text, Button};
