import * as React from "react";
import { FC } from "react";

export const IconInfo: FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox={"0 0 24 24"}
    fill="currentColor"
    {...props}
  >
    <g fill="currentColor" fillRule="evenodd" clipRule="evenodd">
      <path d="M12 2.1c-5.467 0-9.9 4.432-9.9 9.9s4.433 9.9 9.9 9.9c5.468 0 9.9-4.432 9.9-9.9S17.469 2.1 12 2.1ZM.3 12C.3 5.538 5.54.3 12 .3 18.463.3 23.7 5.538 23.7 12c0 6.462-5.238 11.7-11.7 11.7C5.54 23.7.3 18.462.3 12Z" />
      <path d="M10.8 7.2A1.2 1.2 0 0 1 12 6h.013a1.2 1.2 0 0 1 1.2 1.2v.012a1.2 1.2 0 0 1-1.2 1.2H12a1.2 1.2 0 0 1-1.2-1.2V7.2ZM12.002 11.1a.9.9 0 0 1 .9.9v4.8a.9.9 0 0 1-1.8 0V12a.9.9 0 0 1 .9-.9Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
