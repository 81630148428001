export const findDateDifference = (oldDate: string) => {
  const currentDate = new Date(); // Текущая дата
  const oldDateObj = new Date(oldDate); // Старая дата

  // Разница в миллисекундах
  const differenceMs = currentDate.getTime() - oldDateObj.getTime();

  // Преобразование разницы в года и месяцы
  const differenceDate = new Date(differenceMs);
  const yearsDifference = differenceDate.getUTCFullYear() - 1970;
  const monthsDifference = differenceDate.getUTCMonth();

  return { yearsDifference, monthsDifference };
};