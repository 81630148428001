import styled from "styled-components";
import { breakpoints } from "../../../config";
import heroLine1_1440 from "../../../config/assets/images/hero-line-1-1440.svg";
import heroLine1_1280 from "../../../config/assets/images/hero-line-1-1280.svg";
import heroLine1_1024 from "../../../config/assets/images/hero-line-1-1024.svg";
import heroLine1_768 from "../../../config/assets/images/hero-line-1-768.svg";
import heroLine1_375 from "../../../config/assets/images/hero-line-1-375.svg";
import heroLine2_1440 from "../../../config/assets/images/hero-line-2-1440.svg";
import heroLine2_1280 from "../../../config/assets/images/hero-line-2-1280.svg";
import heroLine2_1024 from "../../../config/assets/images/hero-line-2-1024.svg";
import heroLine2_768 from "../../../config/assets/images/hero-line-2-768.svg";
import heroShape1440 from "../../../config/assets/images/hero-shape-1440.svg";
import heroShape1280 from "../../../config/assets/images/hero-shape-1280.svg";
import heroShape768 from "../../../config/assets/images/hero-shape-768.svg";
import heroShape375 from "../../../config/assets/images/hero-shape-375.svg";

const Banner = styled.div`
  position: relative;
  min-height: 600px;
  padding: 70px;
  border-radius: 52px;
  border: 1px solid var(--pink);
  background-color: var(--brand-3);
  overflow: hidden;

  @media (max-width: ${breakpoints.XXL}) {
    padding: 41px 70px;
  }

  @media (max-width: ${breakpoints.XL}) {
    min-height: 500px;
    padding: 21px 33px;
  }

  @media (max-width: ${breakpoints.LG}) {
    min-height: 580px;
    padding: 38px 34px;
  }

  @media (max-width: ${breakpoints.MD}) {
    padding: 20px 13px 20px 30px;
  }


  @media (max-width: ${breakpoints.SM}) {
    min-height: 620px;
  }

  @media (max-width: ${breakpoints.XS}) {
    padding-left: 20px;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 235px;
    width: 1087px;
    height: 226px;
    background-image: url(${heroLine1_1440});
    background-repeat: no-repeat;

    @media (max-width: ${breakpoints.XXL}) {
      width: 1200px;
      height: 195px;
      background-image: url(${heroLine1_1280});
    }

    @media (max-width: ${breakpoints.XL}) {
      top: -35px;
      right: 115px;
      width: 1180px;
      height: 195px;
      background-image: url(${heroLine1_1024});
    }

    @media (max-width: ${breakpoints.LG}) {
      top: -49px;
      right: -19px;
      width: 1114px;
      height: 216px;
      background-image: url(${heroLine1_768});
    }

    @media (max-width: ${breakpoints.MD}) {
      top: -57px;
      right: -36px;
      width: 807px;
      height: 189px;
      background-image: url(${heroLine1_375});
    }
  }

  &:after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    width: 482px;
    height: 418px;
    background-image: url(${heroLine2_1440});
    background-repeat: no-repeat;

    @media (max-width: ${breakpoints.XXL}) {
      width: 480px;
      height: 600px;
      background-image: url(${heroLine2_1280});
    }

    @media (max-width: ${breakpoints.XL}) {
      width: 351px;
      height: 498px;
      background-image: url(${heroLine2_1024});
    }

    @media (max-width: ${breakpoints.LG}) {
      right: unset;
      left: 0;
      width: 305px;
      height: 165px;
      background-image: url(${heroLine2_768});
    }

    @media (max-width: ${breakpoints.SM}) {
      display: none;
    }
`;

const HeaderGroup = styled.hgroup`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 30px;
  z-index: 5;

  @media (max-width: ${breakpoints.XXL}) {
    gap: 24px;
  }

  @media (max-width: ${breakpoints.MD}) {
    gap: 12px;
    max-width: 350px;
  }
`;

const HeroTitle = styled.h1`
  position: relative;
  font: var(--font-1);
  max-width: 580px;
  color: var(--brand-1);
  padding-left: 20px;
  margin-left: -20px;
  background-color: var(--brand-3);
  text-transform: uppercase;

  @media (max-width: ${breakpoints.XXL}) {
    max-width: 380px;
  }

  @media (max-width: ${breakpoints.XL}) {
    max-width: 360px;
    padding-left: 10px;
    margin-left: -10px;
  }

  @media (max-width: ${breakpoints.LG}) {
    max-width: 475px;
  }

  @media (max-width: ${breakpoints.MD}) {
    max-width: 265px;
    padding-left: 6px;
    margin-left: -6px;
  }
`;

const HeroSubtitle = styled.p`
  font: var(--font-2);
  max-width: 423px;
  color: var(--text);

  @media (max-width: ${breakpoints.XXL}) {
    max-width: 515px;
  }

  @media (max-width: ${breakpoints.XL}) {
    max-width: 337px;
  }

  @media (max-width: ${breakpoints.LG}) {
    margin-left: -8px;
  }

  @media (max-width: ${breakpoints.MD}) {
    margin-left: 0;
  }
`;

const ShapeWrapper = styled.div`
  position: absolute;
  right: 353px;
  bottom: 167px;
  background-image: url(${heroShape1440});
  background-repeat: no-repeat;
  width: 335px;
  height: 146px;
  z-index: 5;

  @media (max-width: ${breakpoints.XXL}) {
    right: unset;
    bottom: 179px;
    left: 46vw;
    background-image: url(${heroShape1280});
    background-size: contain;
    width: 312px;
    height: 155px;
  }

  @media (max-width: ${breakpoints.XL}) {
    bottom: 116px;
    left: 37vw;
    width: 290px;
    height: 140px;
  }

  @media (max-width: ${breakpoints.LG}) {
    bottom: 95px;
    left: 22vw;
    background-image: url(${heroShape768});
    width: 289px;
    height: 134px;
  }

  @media (max-width: ${breakpoints.MD}) {
    right: 0;
    bottom: 175px;
    left: unset;
    background-image: url(${heroShape375});
    width: 230px;
    height: 136px;
    z-index: 1;
  }

  @media (max-width: ${breakpoints.XS}) {
    right: -20px;
  }
`;

const ExtraText = styled.p`
  position: absolute;
  top: 29px;
  left: 51px;
  font: var(--font-27);
  letter-spacing: -1.12px;
  max-width: 265px;
  color: var(--brand-1);

  @media (max-width: ${breakpoints.XXL}) {
    top: 40px;
    left: 36px;
    max-width: 260px;
  }

  @media (max-width: ${breakpoints.XL}) {
    top: 36px;
    max-width: 230px;
  }

  @media (max-width: ${breakpoints.MD}) {
    top: 25px;
    left: 6px;
    text-align: center;
    transform: rotate(-1.5deg);
  }
`;

const Picture = styled.picture``;

const Source375 = styled.source``;

const Source375Webp = styled.source``;

const Source1024 = styled.source``;

const Source1024Webp = styled.source``;

const Image = styled.img`
  position: absolute;
  right: 28px;
  bottom: 0;
  z-index: 1;

  @media (max-width: ${breakpoints.XXL}) {
    top: 16px;
    right: unset;
    left: 49vw;
  }

  @media (max-width: ${breakpoints.XL}) {
    top: 0;
    left: 41vw;
  }

  @media (max-width: ${breakpoints.LG}) {
    top: unset;
    bottom: 0;
  }

  @media (max-width: ${breakpoints.MD}) {
    right: 4vw;
    left: unset;
  }

  @media (max-width: ${breakpoints.XS}) {
    right: -3vw;
  }
`;

export {
  Banner,
  HeaderGroup,
  HeroTitle,
  HeroSubtitle,
  ShapeWrapper,
  ExtraText,
  Picture,
  Source375,
  Source375Webp,
  Source1024,
  Source1024Webp,
  Image,
};
