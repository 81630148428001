import React, { FC } from "react";

export const IconUrolithiasis: FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox={"0 0 200 200"}
    {...props}
  >
    <path
      fill="#FFE1EE"
      d="M12.436 84.613s32.562 107.674 96.285 95.833c63.722-11.841 73.379-29.634 81.313-56.223 7.935-26.59-54.273-87.85-63.451-95.355-9.672-7.908-144.478-68.936-114.147 55.745Z"
    />
    <path
      fill="#fff"
      stroke="#BBB"
      strokeWidth={2}
      d="M190.946 150.857c0 9.926-8.046 17.973-17.973 17.973-9.926 0-17.973-8.047-17.973-17.973 0-9.848 7.965-16.857 17.973-16.857s17.973 7.009 17.973 16.857Z"
    />
    <path
      fill="#DDC89A"
      d="M40.662 81.183s21.524-6.442 38.063 2.328c16.54 8.769 29.093 30.08 24.129 55.83-4.964 25.749-37.734 8.333-40.923-2.684-4.055-14.084-12.34-11.108-19.425-18.442-7.084-7.334-19.738-31.739-1.844-37.032Z"
    />
    <path
      fill="#fff"
      stroke="#BBB"
      strokeWidth={4}
      d="M91.579 161.021C51.526 161.021 19 128.904 19 89.481 19 50.061 51.524 18 91.579 18 131.63 18 164.1 50.115 164.1 89.54c0 39.421-32.525 71.481-72.521 71.481Z"
    />
    <path
      fill="#808285"
      d="M89.514 68.48a2.78 2.78 0 1 0 0-5.558 2.78 2.78 0 0 0 0 5.559Z"
    />
    <path
      fill="#858585"
      d="M166.376 155.678a2.375 2.375 0 1 0 0-4.75 2.375 2.375 0 0 0 0 4.75ZM175.338 150.012a4.474 4.474 0 1 0 0-8.949 4.475 4.475 0 1 0 0 8.949ZM178.001 162.268a3.614 3.614 0 1 0 0-7.228 3.614 3.614 0 0 0 0 7.228Z"
    />
    <path
      fill="#808285"
      d="M96.918 63.57a3.433 3.433 0 1 0 0-6.867 3.433 3.433 0 0 0 0 6.867ZM97.045 76.325a3.56 3.56 0 1 0 0-7.121 3.56 3.56 0 0 0 0 7.121ZM80.59 63.894a4.374 4.374 0 1 0 0-8.748 4.374 4.374 0 0 0 0 8.748ZM90.624 54.727a4.964 4.964 0 1 0 0-9.928 4.964 4.964 0 0 0 0 9.928Z"
    />
    <path
      fill="#B68D2D"
      d="M118.387 76.952a11.575 11.575 0 0 1 5.065 4.284l-8.116 4.608-5.25-1.063 2.221-7.722c.115-.295.175-.609.175-.925a2.22 2.22 0 0 0-.053-.255h.569a12.754 12.754 0 0 1 5.389 1.073Zm-8.222 16.146-12.367 5.134-22.066 9.162h-.059l-6.606-1.062 3.72-5.033 17.13-7.048 1.748-.722 16.151-6.564 5.873 1.185-3.524 4.948Zm-39.913-1.45c1.818-1.064 7.409-3.344 13.818-5.847 8.36-3.258 18.548-7.005 23.252-8.721 0 0-1.85 7.547-1.711 8.004l-14.966 6.08-1.749.722-16.066 6.607a2.508 2.508 0 0 0-1.116-1.063 512.367 512.367 0 0 1-6.51-2.918 36.78 36.78 0 0 1 5.027-2.865h.021Zm-11.32 11.463c.532-2.041 1.478-3.545 2.982-5.41.192.2.417.367.664.494l7.143 2.912-3.508 4.783-7.217 2.865a11.533 11.533 0 0 1-.085-5.644h.021Zm1.786 9.428 6.643-3.88 6.186.978-2.519 7.861a2.49 2.49 0 0 0-.047.722 14.806 14.806 0 0 1-5.932-1.7 11.898 11.898 0 0 1-4.352-3.986l.021.005Zm42.448-4.315c-10.342 4.533-21.854 8.631-27.732 9.741a950.17 950.17 0 0 1 2.195-7.105 2.847 2.847 0 0 0 0-1.504l21.131-8.775 11.161-4.645c.181.334.435.623.744.845l6.468 4.534a140.402 140.402 0 0 1-13.967 6.909Zm21.439-13.925a20.81 20.81 0 0 1-3.991 4.682 2.51 2.51 0 0 0-.532-.627l-6.978-5.011 3.332-4.672 8.86-3.114c.866 3.087.771 6.314-.686 8.763l-.005-.02ZM76.706 85.966a376.593 376.593 0 0 1 5.516-2.2 49.995 49.995 0 0 0-24.729-7.345 51.807 51.807 0 0 0-10.772.855 2.307 2.307 0 0 0 .855 4.534 45.573 45.573 0 0 1 29.125 4.156h.005ZM103.906 110.716c-1.371.601-2.79 1.201-4.251 1.796a45.592 45.592 0 0 1 2.317 12.681l-4.581-3.566 4.953 19.202 9.928-17.193-5.65 2.328a50.12 50.12 0 0 0-2.716-15.248Z"
    />
  </svg>
);
