import { AxiosResponse } from "axios";
import { InitialValuesFormikRecoverEmailType } from "../../../pages/recover-password-email";
import {
  RecoveryRequestCodeBodyType,
  RecoveryRequestNewPasswordBodyType,
} from "../model";
import { instance } from "../../../shared/api";

export const recoveryApi = {
  recovery_email: async (
    body: InitialValuesFormikRecoverEmailType
  ): Promise<AxiosResponse<number>> => {
    const response = await instance.post<number>(
      "/new_pass_confirmation/",
      body
    );
    return response as AxiosResponse<number>;
  },

  recovery_code: async (
    body: RecoveryRequestCodeBodyType
  ): Promise<AxiosResponse<boolean>> => {
    const response = await instance.post<boolean>(
      "/check_email_confirmation/",
      body
    );
    return response as AxiosResponse<boolean>;
  },

  recovery_new_password: async (
    body: RecoveryRequestNewPasswordBodyType
  ): Promise<AxiosResponse<unknown>> => {
    const response = await instance.post<unknown>("/new_password/", body);
    return response as AxiosResponse<unknown>;
  },
};
