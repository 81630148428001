export const formatDate = (date: string) => {
  const newdate = new Date(date);

  // Получаем день и месяц
  const day = newdate.getDate().toString().padStart(2, "0");
  const month = (newdate.getMonth() + 1).toString().padStart(2, "0"); // Месяц начинается с 0, поэтому добавляем 1
  const year = newdate.getFullYear().toString();

  return { day, month, year };
};
