import styled from "styled-components";
import { breakpoints } from "../../../config";

const CardFeeding = styled.article`
  position: relative;
  display: flex;
  width: 100%;
  min-height: 266px;
  max-height: 266px;
  padding: 0 19px 28px 16px;
  border: 1px solid var(--inactive);
  background: var(--brand-3);
  border-radius: 32px;
  overflow: hidden;
  cursor: pointer;
  will-change: transform;
  transition: ease-in-out 250ms;

  @media (max-width: ${breakpoints.XXL}) {
    min-height: 256px;
  }

  @media (max-width: ${breakpoints.XL}) {
    padding: 30px;
  }

  @media (max-width: ${breakpoints.LG}) {
    align-items: center;
    min-height: 138px;
    padding: 42px 24px;
    border-radius: 20px;
  }

  &:hover {
    transform: scale(1.02);
  }

  &:active {
    transform: scale(0.98);
  }
`;

const WrapIcon = styled.div`
  position: absolute;
  top: -34px;
  right: -51px;
  width: 200px;
  height: 200px;

  @media (max-width: ${breakpoints.LG}) {
    width: 160px;
    height: 160px;
    top: -15px;
    right: -44px;

    & svg {
      width: 160px;
      height: 160px;
    }
  }
`;

const Text = styled.p`
  max-width: 210px;
  font: var(--font-12);
  color: var(--text);
  margin-top: 166px;
  z-index: 2;

  @media (max-width: ${breakpoints.XXL}) {
    margin-top: 156px;
  }

  @media (max-width: ${breakpoints.XL}) {
    max-width: 200px;
    margin-top: 150px;
  }

  @media (max-width: ${breakpoints.LG}) {
    margin-top: 0;
  }
`;

export {CardFeeding, WrapIcon, Text};
