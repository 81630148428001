export const changeFormatPhone = (phone: string) => {
  return (
    "+" +
    phone.slice(0, 1) +
    "-" +
    phone.slice(1, 4) +
    "-" +
    phone.slice(4, 7) +
    "-" +
    phone.slice(7, 9) +
    "-" +
    phone.slice(9, 11)
  );
};

export const deleteMaskPhone = (phone: string) => {
  return (
    phone.slice(1, 2) +
    phone.slice(4, 7) +
    phone.slice(9, 12) +
    phone.slice(13, 15) +
    phone.slice(16, 18)
  );
};
