import React, { ChangeEvent, FC } from "react";
import * as s from "./field-phone-formik-styles";
import { FieldInput } from "../../../shared";
import { FieldHelperProps, FieldInputProps, FieldMetaProps } from "formik";
import { useMask } from "@react-input/mask";

type FieldPhoneFormikType = FieldMetaProps<any> &
  FieldHelperProps<any> &
  FieldInputProps<any> & {
    className?: string;
    label: string;
    errorResponse?: string;
  };

export const FieldPhoneFormik: FC<FieldPhoneFormikType> = ({
  className,
  value,
  name,
  label,
  error,
  errorResponse,
  touched,
  onBlur,
  setValue,
}) => {
  const inputRef = useMask({
    mask: "+7 (___) ___-__-__",
    replacement: { _: /\d/ },
  });
  const errorInput = touched ? (errorResponse ? errorResponse : error) : "";
  const icon = value ? <s.Icon $isError={!!errorInput} /> : "";

  const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const onClickButtonIcon = () => {
    setValue("");
  };

  const onAddValueInput = () => {
    if (!value) {
      setValue("+7 ");
    }
  };

  return (
    <s.FieldPhoneFormik className={className}>
      <FieldInput
        inputRef={inputRef}
        type={"text"}
        value={value}
        name={name}
        label={label}
        icon={icon}
        isFocus={!!value}
        error={errorInput}
        onChangeInput={onChangeInput}
        onClickButtonIcon={onClickButtonIcon}
        onClickInput={onAddValueInput}
        onBlurInput={onBlur}
      />
    </s.FieldPhoneFormik>
  );
};
