import React, { FC, MouseEvent, ReactNode } from "react";
import * as s from "./Input-checkbox-styles";
import { Animation } from "../animation/Animation";

type InputCheckbox = {
  className?: string;
  checked: boolean;
  label: ReactNode;
  onClickInputChecked: (e: MouseEvent<HTMLButtonElement>) => void;
};

export const InputCheckbox: FC<InputCheckbox> = ({
  className,
  checked,
  label,
  onClickInputChecked,
}) => {
  return (
    <s.InputCheckbox
      type={"button"}
      className={className}
      onClick={onClickInputChecked}
    >
      <s.WrapIcon $isChecked={checked}>
        <s.IconNoCheck />

        <Animation isShow={checked}>
          <s.IconYesCheck />
        </Animation>
      </s.WrapIcon>

      {label}
    </s.InputCheckbox>
  );
};
