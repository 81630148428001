import styled from "styled-components";
import { breakpoints } from "../../../config";

const CardsFeedings = styled.div``;

const ListCards = styled.ul`
  display: flex;
  gap: 24px;

  @media (max-width: ${breakpoints.XXL}) {
    gap: 20px;
  }

  @media (max-width: ${breakpoints.XL}) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 16px;
  }

  @media (max-width: ${breakpoints.LG}) {
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    row-gap: 16px;
  }

  @media (max-width: ${breakpoints.MD}) {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
`;

const ItemCards = styled.li`
  flex: 1;
`;

export { CardsFeedings, ListCards, ItemCards };
