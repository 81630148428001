import * as yup from "yup";
import { AnimalCatAndDogType } from "../../../../../entities/animal/model";

const getBreedSchema = (array: Array<string>, animalName: string) => {
  return yup
    .string()
    .required(`Выберите породу ${animalName}`)
    .lowercase()
    .oneOf(array, `Выберите поддерживаемую породу для ${animalName}`);
};

export const ValidationFormModalAddAnimal = (
  arrayCat: Array<AnimalCatAndDogType>,
  arrayDog: Array<AnimalCatAndDogType>
) => {
  const getTitles = (array: Array<AnimalCatAndDogType>) => {
    return array.map((animal) => animal.value.toLowerCase());
  };

  return yup.object().shape({
    animal: yup.string().trim().required("Укажите вид питомца"),
    name: yup.string().trim().required("Укажите имя питомца"),
    date_of_birth: yup
      .string()
      .trim()
      .required("Укажите дату рождения питомца"),
    breed: yup
      .string()
      .trim()
      .when("animal", {
        is: (animal: any) => animal === "Cat",
        then: (animal: any) => getBreedSchema(getTitles(arrayCat), "кота"),
        otherwise: (animal: any) =>
          getBreedSchema(getTitles(arrayDog), "собаки"),
      }),

    gender: yup.string().trim().required("Укажите пол питомца"),
  });
};
