import {ModalConfirmation} from "../../../features";
import styled from "styled-components";
import {Title} from "../../../features/_modals/modal-confirmation/ui/Modal-confirmation-styles";
import {BodyAnimation} from "../../../shared/ui/molecules/modal-body/Modal-body-styles";
import {breakpoints} from "../../../shared/config";
import {ButtonInvite, IconOk, IconRoyals, IconRub, IconUserAdd, IconVk,} from "../../../shared";

const ModalConfirmationPhone = styled(ModalConfirmation)`
  ${Title} {
    max-width: 575px;
    margin-bottom: 32px;
    color: var(--brand-1);
  }

  ${BodyAnimation} {
    max-width: 702px;
  }

  @media (max-width: ${breakpoints.LG}) {
    ${Title} {
      max-width: unset;
      color: var(--brand-1);
    }

    ${BodyAnimation} {
      max-width: 100%;
    }
  }
`;

const IconRoyal = styled(IconRoyals)`
  width: 40px;
  height: 40px;
  vertical-align: middle;

  @media (max-width: ${breakpoints.LG}) {
    width: 32px;
    height: 32px;
  }
`;

const ButtonInviteModal = styled(ButtonInvite)`
  max-width: 100%;
`;

const IconButtonVk = styled(IconVk)`
  width: 32px;
  height: 32px;
  color: var(--blue-icon-1);
`;

const IconButtonOk = styled(IconOk)`
  width: 32px;
  height: 32px;
  color: var(--blue-icon-1-1);
`;

const IconUser = styled(IconUserAdd)`
  width: 32px;
  height: 32px;
  color: var(--brand-1);
`;

const RubleIcon = styled(IconRub)`
  width: 26px;
  height: 26px;
  color: currentColor;

  @media (max-width: ${breakpoints.XL}) {
    width: 23px;
    height: 23px;
  }

  @media (max-width: ${breakpoints.MD}) {
    width: 17px;
    height: 17px;
  }
`;

const TextModalConfirmationPhone = styled.p`
  margin-bottom: 10px;
  font: var(--font-9);
  color: var(--interface-dark);
`

export {
  ModalConfirmationPhone,
  IconRoyal,
  ButtonInviteModal,
  IconButtonVk,
  IconButtonOk,
  IconUser,
  RubleIcon,
  TextModalConfirmationPhone,
};
