import styled from "styled-components";
import { ButtonIcon } from "../button-icon/Button-icon";
import { breakpoints } from "../../../config";

const NotificationBar = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 24px 40px;
  width: max-content;
  max-width: 500px;
  background-color: var(--brand-4);
  border-radius: 12px;

  @media (max-width: ${breakpoints.MD}) {
    width: unset;
    padding: 16px 20px;
  }
`;

const Wrap = styled.div`
  display: flex;
  align-items: baseline;
`;

const Text = styled.p`
  font: var(--font-12);
  color: var(--hyperlink);
`;

const Button = styled.button`
  font: var(--font-11);
  color: var(--text);
  margin-left: 12px;
`;

const ButtonClose = styled(ButtonIcon)`
  width: 24px;
  height: 24px;
  color: var(--iconography);

  &:hover {
    color: var(--interface-dark);
  }
`;

export { NotificationBar, Wrap, Text, Button, ButtonClose };
