import React, { FC } from "react";
import * as s from "./Form-registration-mini-styles";
import { useFormik } from "formik";
import { ButtonProperty, pages } from "../../../shared/config";
import { ValidationFormRegistrationMini } from "../config";
import { useNavigate } from "react-router-dom";

type FormRegistrationMiniType = {
  className?: string;
  id?: string;
};

export const FormRegistrationMini: FC<FormRegistrationMiniType> = ({
  className,
  id,
}) => {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
    },

    validationSchema: ValidationFormRegistrationMini,

    onSubmit: async (body: any) => {
      await sessionStorage.setItem(
        "miniFormData",
        JSON.stringify(formik.values)
      );
      navigate(pages.registration.link);
    },
  });

  const onRedirectSignIn = () => {
    navigate(pages.signIn.link);
  };

  return (
    <s.FormRegistrationMini className={className} id={id}>
      <s.Title>Зарегистрируйтесь и получайте бонусы и подарки!</s.Title>

      <s.Form onSubmit={formik.handleSubmit}>
        <s.SubTitle>Введите данные</s.SubTitle>

        <s.List>
          <s.Item>
            <s.InputText
              type={"text"}
              label={"Имя*"}
              $isError={
                !!formik.getFieldMeta("first_name").error &&
                !!formik.getFieldMeta("first_name").touched
              }
              {...formik.getFieldProps("first_name")}
              {...formik.getFieldMeta("first_name")}
              {...formik.getFieldHelpers("first_name")}
            />
          </s.Item>

          <s.Item>
            <s.InputText
              type={"text"}
              label={"Фамилия*"}
              $isError={
                !!formik.getFieldMeta("last_name").error &&
                !!formik.getFieldMeta("last_name").touched
              }
              {...formik.getFieldProps("last_name")}
              {...formik.getFieldMeta("last_name")}
              {...formik.getFieldHelpers("last_name")}
            />
          </s.Item>

          <s.Item>
            <s.WrapInput>
              <s.InputText
                type={"text"}
                label={"E-mail*"}
                $isError={
                  !!formik.getFieldMeta("email").error &&
                  !!formik.getFieldMeta("email").touched
                }
                {...formik.getFieldProps("email")}
                {...formik.getFieldMeta("email")}
                {...formik.getFieldHelpers("email")}
              />
            </s.WrapInput>
          </s.Item>
        </s.List>

        <s.WrapButton>
          <s.ButtonRegistration
            property={ButtonProperty.DEFAULT}
            text={"Зарегистрироваться"}
            type={"submit"}
            onClickButton={() => {}}
          />

          <s.TextWrapButton>
            Нажимая кнопку “Зарегистрироваться”, вы соглашаетесь с нашей{" "}
            <s.LinkWrapButton
              href={"https://www.mars.com/global/policies/privacy/pp-russian"}
              target={"_blank"}
            >
              политикой конфиденциальности
            </s.LinkWrapButton>
          </s.TextWrapButton>
        </s.WrapButton>

        <s.RedirectRegistrationText>
          Уже есть аккаунт?{" "}
          <s.RedirectRegistrationButton
            type={"button"}
            onClick={onRedirectSignIn}
          >
            Авторизоваться
          </s.RedirectRegistrationButton>
        </s.RedirectRegistrationText>
      </s.Form>
    </s.FormRegistrationMini>
  );
};
