import React, {FC} from "react";
import * as s from "./Partners-section-styles";
import {CardLink} from "../../atom";
import kittenImg1440 from "../../../config/assets/images/kitten-clew-1440.png";
import kittenImg1440_2x from "../../../config/assets/images/kitten-clew-1440@2x.png";
import kittenImg1440Webp from "../../../config/assets/images/kitten-clew-1440.webp";
import kittenImg1440_2xWebp from "../../../config/assets/images/kitten-clew-1440@2x.webp";
import puppyImg1440 from "../../../config/assets/images/puppy-ball-1440.png";
import puppyImg1440_2x from "../../../config/assets/images/puppy-ball-1440@2x.png";
import puppyImg1440Webp from "../../../config/assets/images/puppy-ball-1440.webp";
import puppyImg1440_2xWebp from "../../../config/assets/images/puppy-ball-1440@2x.webp";
import kittenImg768 from "../../../config/assets/images/kitten-clew-768.png";
import kittenImg768_2x from "../../../config/assets/images/kitten-clew-768@2x.png";
import kittenImg768Webp from "../../../config/assets/images/kitten-clew-768.webp";
import kittenImg768_2xWebp from "../../../config/assets/images/kitten-clew-768@2x.webp";
import puppyImg768 from "../../../config/assets/images/puppy-ball-768.png";
import puppyImg768_2x from "../../../config/assets/images/puppy-ball-768@2x.png";
import puppyImg768Webp from "../../../config/assets/images/puppy-ball-768.webp";
import puppyImg768_2xWebp from "../../../config/assets/images/puppy-ball-768@2x.webp";

type PartnersSectionType = {
  className?: string;
};

export const PartnersSection: FC<PartnersSectionType> = ({className}) => {
  const bgVector1 = (
    <svg
      width="373"
      height="184"
      viewBox="0 0 373 184"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.29942 205.344C14.2846 145.411 72.412 170.397 99.846 190.367L103.778 193.503C100.016 176.662 100.444 158.622 113.353 143.54C127.173 127.418 146.135 124.569 160.627 126.316C146.879 103.517 141.305 78.8854 160.574 61.6873C185.91 39.1034 228.445 50.2285 261.631 66.1213C242.997 34.0168 232.613 -3.45041 262.269 -22.7519C317.976 -58.9857 463.857 63.1602 424.199 122.249C404.954 150.916 371.731 148.403 341.669 136.481C350.795 153.207 354.868 171.602 347.055 188.867C335.307 214.855 302.367 215.63 270.499 207.338C287.547 237.435 294.548 269.123 271.175 289.612C246.415 311.329 210.925 301.69 178.935 283.143C183.112 301.444 181.371 320.981 166.593 337.221C127.082 380.599 -14.9235 280.223 1.29942 205.344Z"
        fill="currentColor"
      />
    </svg>
  );

  const bgVector2 = (
    <svg
      width="347"
      height="184"
      viewBox="0 0 347 184"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.853652 291.013C-7.2865 230.232 55.8716 233.992 88.4571 243.459L93.2199 245.073C83.9623 230.511 78.239 213.397 85.2582 194.827C92.782 174.971 109.649 165.851 123.874 162.573C103.2 145.798 89.5921 124.523 101.875 101.803C118.036 71.9571 161.821 67.976 198.432 71.6541C170.003 47.7858 147.512 16.0718 168.85 -12.1538C208.941 -65.1526 387.634 0.191635 370.4 69.2365C362.034 102.735 329.933 111.655 297.608 110.651C311.872 123.284 321.95 139.201 320.465 158.094C318.24 186.527 287.521 198.443 254.731 201.466C280.987 223.985 298.334 251.412 283.308 278.621C267.394 307.456 230.74 310.442 194.353 303.862C204.497 319.656 209.494 338.624 201.11 358.917C178.678 413.136 11.0241 366.95 0.853652 291.013Z"
        fill="currentColor"
      />
    </svg>
  );

  return (
    <s.PartnersSection className={className}>
      <CardLink
        text={"Подберите рацион для своего котенка"}
        imgDesktop={kittenImg1440}
        imgDesktop_2x={kittenImg1440_2x}
        imgTablet={kittenImg768}
        imgTablet_2x={kittenImg768_2x}
        imgDesktopWebp={kittenImg1440Webp}
        imgDesktopWebp_2x={kittenImg1440_2xWebp}
        imgTabletWebp={kittenImg768Webp}
        imgTabletWebp_2x={kittenImg768_2xWebp}
        bgVector={bgVector1}
        link={"https://zoozavr.ru/catalog/index/name/korm_dlia_kotyat/brand/18703/"}
        altImage={"Котенок играется с клубком ниток."}
      />

      <CardLink
        text={"Подберите рацион для своего щенка\u00A0\u00A0\u00A0"}
        imgDesktop={puppyImg1440}
        imgDesktop_2x={puppyImg1440_2x}
        imgTablet={puppyImg768}
        imgTablet_2x={puppyImg768_2x}
        imgDesktopWebp={puppyImg1440Webp}
        imgDesktopWebp_2x={puppyImg1440_2xWebp}
        imgTabletWebp={puppyImg768Webp}
        imgTabletWebp_2x={puppyImg768_2xWebp}
        bgVector={bgVector2}
        link={"https://zoozavr.ru/catalog/index/name/korm_dlia_shchenkov/brand/18703/"}
        altImage={"Щенок играется с шариком."}
        isBlueHover={true}
      />

      <s.CardPartner>
        <s.CardPartnerLink
          href={
            "https://zoozavr.ru/catalog/index/name/sortforbrand/brand/18703/"
          }
          target={"_blank"}
        >
          <s.CardPartnerText>Официальный партнер программы</s.CardPartnerText>

          <s.CardPartnerLogo/>
        </s.CardPartnerLink>
      </s.CardPartner>
    </s.PartnersSection>
  );
};
