import React, { FC, ReactNode } from "react";
import * as s from "./Modal-promo-code-styles";
import { AssignmentType } from "../../../../entities/discount/model";

type ModalPromoCodeType = {
  className?: string;
  onShowModal: () => void;
  title: string | ReactNode;
  assignment: AssignmentType;
  description: { [key: string]: string | [] };
};

export const ModalPromoCode: FC<ModalPromoCodeType> = ({
  className,
  onShowModal,
  title,
  assignment,
  description,
}) => {
  const text =
    assignment === "young"
      ? "Скидки на рационы для котят и щенков"
      : assignment === "adult" || assignment === "grown_up"
      ? "Скидки на рационы для взрослых кошек и собак"
      : "Подарки от партнера";

  return (
    <s.ModalPromoCode className={className}>
      <s.Modal onClickButtonBg={onShowModal} onClickButtonIcon={onShowModal}>
        <s.Title>{text}</s.Title>
        <s.Text>{title}</s.Text>

        <s.List>
          <s.Item>
            <s.SubTitle>Сколько действует промокод</s.SubTitle>

            <s.SubText>{description["Сколько действует промокод"]}</s.SubText>
          </s.Item>

          <s.Item>
            <s.SubTitle>Где можно потратить</s.SubTitle>

            <s.SubText>{description["Где можно потратить"]}</s.SubText>
          </s.Item>
        </s.List>
      </s.Modal>
    </s.ModalPromoCode>
  );
};
