import styled, { css } from "styled-components";
import { breakpoints } from "../../../shared/config";
import {
  AdditionalBonuses,
  CardPromoCode,
  Faq,
  Hero,
  IconProfile,
  IconRub,
  PartnersSection,
} from "../../../shared";
import { Image } from "../../../shared/ui/atom/banner/Banner-styles";
import { BannerStyles } from "../../../shared/ui/templates/hero/Hero-styles";
import { AdditionalRoyals } from "../../../shared/ui/organisms/additional-royals/Additional-royals";
import { DuoFeeding } from "../../../shared/ui/organisms/duo-feeding/Duo-feeding";
import { FormRegistrationMini, Header } from "../../../widgets";
import { Swiper } from "swiper/react";
import { Text } from "../../../shared/ui/molecules/card-promo-code/Card-promo-code-styles";

type ButtonAvatarType = {
  $isImage: boolean;
};

const Main = styled.section``;

const HeaderMain = styled(Header)`
  padding: 24px 0;

  @media (max-width: ${breakpoints.XXL}) {
    padding: 32px 0;
  }

  @media (max-width: ${breakpoints.LG}) {
    padding: 24px 0;
  }

  @media (max-width: ${breakpoints.MD}) {
    padding: 12px 0;
  }
`;

const LinkCss = css`
  font: var(--font-16);
  color: var(--text);
  will-change: transform;
  transition: color 300ms ease, transform 300ms ease;

  &:hover {
    color: var(--hyperlink);
  }

  &:active {
    transform: scale(0.98);
  }
`;

const LinkDefault = styled.a`
  display: flex;
  align-items: center;
  gap: 4px;
  ${LinkCss};
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  gap: 4px;
  ${LinkCss};
`;

const ButtonAvatar = styled.button<ButtonAvatarType>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: var(--brand-1);
  border-radius: 50%;
  font: var(--font-28);
  color: var(--brand-3);
  will-change: transform;
  text-transform: uppercase;
  transition: color 300ms ease, transform 300ms ease;
  overflow: hidden;

  @media (max-width: ${breakpoints.XL}) {
    width: 32px;
    height: 32px;
  }

  @media (max-width: ${breakpoints.MD}) {
    width: 40px;
    height: 40px;
  }

  &:active {
    transform: scale(0.98);
  }

  ${(props) =>
    props.$isImage &&
    css`
      background-color: transparent;
    `};

  @media (-webkit-min-device-pixel-ratio: 2),
    (min--moz-device-pixel-ratio: 2),
    (-o-min-device-pixel-ratio: 2/1),
    (min-resolution: 2dppx),
    (min-resolution: 192dpi) {
    align-items: flex-end;
  }
`;

const IconAvatar = styled(IconProfile)`
  width: 100%;
  height: 100%;
`;

const ImageAvatar = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

const Content = styled.div`
  flex: 1 1 auto;
  overflow: hidden;
`;

const HeroSection = styled(Hero)`
  margin-bottom: 90px;

  @media (max-width: ${breakpoints.XXL}) {
    margin-bottom: 60px;
  }

  @media (max-width: ${breakpoints.LG}) {
    margin-bottom: 50px;
  }

  @media (max-width: ${breakpoints.MD}) {
    margin-bottom: 55px;
  }

  ${BannerStyles} {
    ${Image} {
      width: 646px;

      @media (max-width: ${breakpoints.XXL}) {
        width: 569px;
      }

      @media (max-width: ${breakpoints.XL}) {
        width: 520px;
      }

      @media (max-width: ${breakpoints.LG}) {
        width: 395px;
      }

      @media (max-width: ${breakpoints.MD}) {
        width: 337px;
      }

      @media (max-width: ${breakpoints.XS}) {
        width: 320px;
      }
    }
  }
`;

const PartnersWrapSection = styled(PartnersSection)`
  margin-bottom: 90px;

  @media (max-width: ${breakpoints.XXL}) {
    margin-bottom: 80px;
  }

  @media (max-width: ${breakpoints.XL}) {
    margin-bottom: 60px;
  }

  @media (max-width: ${breakpoints.LG}) {
    margin-bottom: 80px;
  }

  @media (max-width: ${breakpoints.MD}) {
    margin-bottom: 55px;
  }
`;

const AdditionalBonusesSection = styled(AdditionalBonuses)`
  margin-bottom: 124px;

  @media (max-width: ${breakpoints.XXL}) {
    margin-bottom: 114px;
  }

  @media (max-width: ${breakpoints.XL}) {
    margin-bottom: 104px;
  }

  @media (max-width: ${breakpoints.LG}) {
    margin-bottom: 80px;
  }
`;

const DiscountsSection = styled.section`
  margin-bottom: 120px;
  scroll-margin-top: 120px;

  @media (max-width: ${breakpoints.XXL}) {
    margin-bottom: 110px;
  }

  @media (max-width: ${breakpoints.LG}) {
    margin-bottom: 80px;
  }
`;

const DiscountsHeader = styled.header`
  max-width: 1440px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
  padding: 0 clamp(40px, 12.63vw + -120px, 60px);

  @media (max-width: ${breakpoints.XXL}) {
    padding: 0 clamp(40px, 10.63vw + -120px, 60px);
  }

  @media (max-width: ${breakpoints.MD}) {
    margin-right: 0;
    margin-bottom: 32px;
    margin-left: 0;
    padding: 0 clamp(12px, 7.12vw + -14.72px, 40px);
  }
`;

const DiscountsTitle = styled.h2`
  font: var(--font-3);
  color: var(--brand-1);
  max-width: 385px;

  @media (max-width: ${breakpoints.MD}) {
    max-width: 70%;
  }
`;

const DiscountsList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media (max-width: ${breakpoints.MD}) {
    gap: 32px;
  }
`;

const DiscountsItem = styled.li``;

const DiscountsSubTitle = styled.h3`
  font: var(--font-8);
  color: var(--text);
  max-width: 1440px;
  width: 100%;
  margin-right: auto;
  margin-bottom: 24px;
  margin-left: auto;
  padding: 0 clamp(40px, 12.63vw + -120px, 60px);

  @media (max-width: ${breakpoints.XXL}) {
    padding: 0 clamp(40px, 10.63vw + -120px, 60px);
  }

  @media (max-width: ${breakpoints.MD}) {
    margin-right: 0;
    margin-bottom: 16px;
    margin-left: 0;
    padding: 0 clamp(12px, 7.12vw + -14.72px, 40px);
    max-width: 80%;
    text-wrap: balance;
  }
`;

const SwiperContainer = styled(Swiper)`
  width: 100%;
  max-width: 1320px;
  position: relative;
  margin-left: auto;
  padding-left: 0;

  @media (max-width: ${breakpoints.XXL}) {
    overflow: visible;
    max-width: none;
    margin-left: 0;
    padding: 0 clamp(40px, 10.63vw + -120px, 60px);
  }

  @media (max-width: ${breakpoints.MD}) {
    padding: 0 clamp(12px, 7.12vw + -14.72px, 40px);
  }

  & .swiper-button-prev {
    top: 50%;
    bottom: 100px;
    left: 5px;

    @media (max-width: ${breakpoints.XXL}) {
      left: 20px;
    }

    @media (max-width: ${breakpoints.XL}) {
      bottom: 90px;
    }

    @media (max-width: ${breakpoints.MD}) {
      bottom: 74px;
      left: 0;
    }
  }

  & .swiper-button-next {
    top: 50%;
    right: 5px;
    bottom: 100px;

    @media (max-width: ${breakpoints.XXL}) {
      right: 11px;
    }

    @media (max-width: ${breakpoints.XL}) {
      right: 40px;
      bottom: 90px;
    }

    @media (max-width: ${breakpoints.LG}) {
      right: 21px;
    }

    @media (max-width: ${breakpoints.MD}) {
      right: 3px;
      bottom: 74px;
    }
`;

const CardSlide = styled(CardPromoCode)`
  max-width: 100%;
  min-height: 220px;
  padding-bottom: 44px;

  @media (max-width: ${breakpoints.XL}) {
    min-height: 210px;
  }

  @media (max-width: ${breakpoints.MD}) {
    min-height: 180px;
  }
`;

const CardSlideGifts = styled(CardPromoCode)`
  max-width: 100%;

  @media (max-width: ${breakpoints.XXL}) {
    min-height: 220px;
  }

  @media (max-width: ${breakpoints.XL}) {
    min-height: 200px;
  }

  @media (max-width: ${breakpoints.MD}) {
    min-height: 180px;
  }

  ${Text} {
    max-width: 238px;

    @media (max-width: ${breakpoints.XL}) {
      max-width: 202px;
    }

    @media (max-width: ${breakpoints.LG}) {
      max-width: 170px;
    }
  }
`;

const RubleIcon = styled(IconRub)`
  width: 16px;
  height: 16px;
  white-space: nowrap;

  @media (max-width: ${breakpoints.XL}) {
    width: 14px;
    height: 14px;
  }

  @media (max-width: ${breakpoints.MD}) {
    width: 11px;
    height: 11px;
  }
`;

const AdditionalRoyalsSection = styled(AdditionalRoyals)`
  margin-bottom: 120px;
  scroll-margin-top: 120px;

  @media (max-width: ${breakpoints.XXL}) {
    margin-bottom: 112px;
  }

  @media (max-width: ${breakpoints.XL}) {
    margin-bottom: 102px;
  }

  @media (max-width: ${breakpoints.LG}) {
    margin-bottom: 80px;
  }
`;

const DuoFeedingSection = styled(DuoFeeding)`
  margin-bottom: 120px;
  scroll-margin-top: 120px;

  @media (max-width: ${breakpoints.XL}) {
    margin-bottom: 100px;
  }

  @media (max-width: ${breakpoints.LG}) {
    margin-bottom: 80px;
  }

  @media (max-width: ${breakpoints.MD}) {
    margin-bottom: 76px;
  }
`;

const FormRegistrationSection = styled(FormRegistrationMini)`
  margin-bottom: 120px;
  scroll-margin-top: 120px;

  @media (max-width: ${breakpoints.XXL}) {
    margin-bottom: 110px;
  }

  @media (max-width: ${breakpoints.XL}) {
    margin-bottom: 100px;
  }

  @media (max-width: ${breakpoints.LG}) {
    margin-bottom: 80px;
  }
`;

const FaqSection = styled(Faq)`
  margin-bottom: 120px;
  scroll-margin-top: 120px;

  @media (max-width: ${breakpoints.XXL}) {
    margin-bottom: 110px;
  }

  @media (max-width: ${breakpoints.XL}) {
    margin-bottom: 100px;
  }

  @media (max-width: ${breakpoints.LG}) {
    margin-bottom: 104px;
  }

  @media (max-width: ${breakpoints.MD}) {
    margin-bottom: 96px;
  }
`;

export {
  Main,
  HeaderMain,
  LinkDefault,
  Button,
  ButtonAvatar,
  IconAvatar,
  ImageAvatar,
  Content,
  HeroSection,
  PartnersWrapSection,
  AdditionalBonusesSection,
  BannerStyles,
  DiscountsSection,
  DiscountsHeader,
  DiscountsTitle,
  DiscountsList,
  DiscountsItem,
  DiscountsSubTitle,
  SwiperContainer,
  CardSlide,
  CardSlideGifts,
  RubleIcon,
  AdditionalRoyalsSection,
  DuoFeedingSection,
  FormRegistrationSection,
  FaqSection,
};
