import React, { FC, useEffect } from "react";
import * as s from "./Registration-styles";
import { useFormik } from "formik";
import { ValidationFormRegistration } from "../config";
import { v4 as uuidv4 } from "uuid";
import { esAnimal, esAuth } from "../../../entities";
import { AnimalPetType } from "../../../entities/animal/model";
import {
  deleteMaskPhone,
  useAppDispatch,
  useAppSelector,
  useScrollToTop,
} from "../../../shared/lib";
import { RegistrationRequestBodyType } from "../../../entities/auth/model";
import bg from "./../../../shared/config/assets/images/auth-bg.svg";
import { setModals } from "../../../widgets/modals/model";
import { ModalsProperty } from "../../../shared/config";
import { checkEmailThunk } from "../../../shared/model";

type RegistrationType = {};

export type InitialValuesFormikRegistrationType = {
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  password: string;
  confirm_password: string;
  personal_data_handling: boolean;
  age_confirmation: boolean;
  promotional_mailing: boolean;
  loyalty_card: boolean;
  pets: Array<{
    id: string;
    animal: string;
    name: string;
    date_of_birth: string;
    breed: string;
    gender: string;
  }>;
};

export const Registration: FC<RegistrationType> = ({}) => {
  useScrollToTop();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const invitationToken = urlParams.get("invitation_token");

  const dispatch = useAppDispatch();
  const { cat, dog } = useAppSelector(esAnimal.model.selectStateAnimals);

  const storedFormData = JSON.parse(
    sessionStorage.getItem("miniFormData") || "{}"
  );

  const formik = useFormik({
    initialValues: {
      first_name: storedFormData.first_name || "",
      last_name: storedFormData.last_name || "",
      phone: "",
      email: storedFormData.email || "",
      password: "",
      confirm_password: "",
      personal_data_handling: false,
      age_confirmation: false,
      promotional_mailing: false,
      loyalty_card: false,
      pets: [
        {
          id: uuidv4(),
          animal: "",
          name: "",
          date_of_birth: "",
          breed: "",
          gender: "",
        },
      ],
    },

    validationSchema: ValidationFormRegistration(cat, dog),

    onSubmit: async (body: InitialValuesFormikRegistrationType) => {
      const requestBody: RegistrationRequestBodyType = {
        ...body,
        code: "",
        phone: deleteMaskPhone(body.phone),
        pets: body.pets.map((pet: AnimalPetType) => {
          return {
            name: pet.name,
            date_of_birth: pet.date_of_birth,
            breed: Number(
              [...cat, ...dog].find(
                (el) => el.value.toLowerCase() === pet.breed.toLowerCase()
              )?.id
            ),
            gender: pet.gender,
          };
        }),
      };

      delete requestBody.confirm_password;

      try {
        const response = await dispatch(
          checkEmailThunk({ email: formik.values.email })
        );
        // @ts-ignore
        if (response.payload?.availible === false) {
          dispatch(setModals({ action: ModalsProperty.MODAL_CHECK_EMAIL }));
        } else {
          await esAuth.api.authApi.email_confirmation({
            email: requestBody.email,
            name: requestBody.first_name,
          });

          dispatch(
            setModals({
              action: ModalsProperty.MODAL_CONFIRMATION_EMAIL,
              content: { body: requestBody, invitationToken: invitationToken },
            })
          );
        }
      } catch (e) {}
    },
  });

  useEffect(() => {
    dispatch(esAnimal.model.getAnimalsThunk());

    formik.setFieldTouched("personal_data_handling", false);
    formik.setFieldTouched("age_confirmation", false);
    formik.values.pets.forEach((pet, index) => {
      formik.setFieldTouched(`pets.${index}.animal`, false);
    });
  }, []);

  return (
    <s.Registration>
      <s.ImageBg src={bg} alt={"животное"} />

      <s.Title>Регистрация</s.Title>

      <s.FormRegUser formik={formik} />

      <s.FormAnimal
        formik={formik}
        pets={formik.values.pets}
        cat={cat}
        dog={dog}
      />

      <s.FormPassword formik={formik} />
    </s.Registration>
  );
};
