import React, {FC, useState} from "react";
import * as s from "./Modal-frequently-asked-question-styles";
import {dataQuestion} from "../config";

type ModalFrequentlyAskedQuestionType = {
  className?: string;
  title: string;
  onShowModal: () => void;
};

export const ModalFrequentlyAskedQuestion: FC<ModalFrequentlyAskedQuestionType> = ({className, onShowModal, title}) => {
  const [dataTitle, setDataTitle] = useState<string>(title);
  const data = dataQuestion[dataTitle];

  const onChangeDataTitle = (title: string) => () => {
    setDataTitle(title);
  };

  return (
    <s.ModalFrequentlyAskedQuestion className={className}>
      <s.Modal onClickButtonBg={onShowModal} onClickButtonIcon={onShowModal}>
        <s.Title>{dataTitle}</s.Title>

        <s.List>
          {data.question.values.map((text, index) => (
            <s.Item key={text + index}>
              {text}

              {data.question.list.length > 0 && (
                <s.SubList>
                  {data.question.list.map((item, itemIndex) => (
                    <s.SubItem key={itemIndex}>{item}</s.SubItem>
                  ))}
                </s.SubList>
              )}
            </s.Item>
          ))}
        </s.List>

        <s.SubTitle>Другие вопросы</s.SubTitle>

        <s.QuestionList>
          {data.listQuestions.map((text, index) => (
            <s.QuestionItem key={text + index}>
              <s.Button type={"button"} onClick={onChangeDataTitle(text)}>
                {text}
              </s.Button>
            </s.QuestionItem>
          ))}
        </s.QuestionList>
      </s.Modal>
    </s.ModalFrequentlyAskedQuestion>
  );
};
