import React, { FC } from "react";
import * as s from "./Data-warning-styles";

type DataWarningType = {
  className?: string;
  children: string;
};

export const DataWarning: FC<DataWarningType> = ({ className, children }) => {
  return <s.DataWarning className={className}>{children}</s.DataWarning>;
};
