import React, { FC } from "react";
import * as s from "./Modal-confirmation-ref-email-friend-styles";
import { ButtonProperty } from "../../../../shared/config";

type ModalConfirmationRefEmailFriendType = {
  className?: string;
  buttonRedirectText: string;
  onShowModal: () => void;
  onClickButton: () => void;
};

export const ModalConfirmationRefEmailFriend: FC<
  ModalConfirmationRefEmailFriendType
> = ({ className, buttonRedirectText, onShowModal, onClickButton }) => {
  return (
    <s.ModalConfirmationRefEmailFriend className={className}>
      <s.Modal
        buttonRedirectText={buttonRedirectText}
        onClickButtonBg={onShowModal}
        onClickButtonIcon={onShowModal}
      >
        <s.Title>Ссылка успешно отправлена</s.Title>

        <s.ButtonWrap>
          <s.ButtonCommon
            property={ButtonProperty.HIGHLIGHTED}
            text={"Пригласить еще одного друга"}
            type={"button"}
            onClickButton={onClickButton}
          />

          <s.ButtonRedirect
            property={ButtonProperty.SECONDARY}
            text={"Перейти в профиль"}
            type={"button"}
            onClickButton={onShowModal}
          />
        </s.ButtonWrap>
      </s.Modal>
    </s.ModalConfirmationRefEmailFriend>
  );
};
