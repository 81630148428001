import { useFormik } from "formik";
import React, { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ButtonProperty, pages } from "../../../shared/config";
import * as s from "./Recover-password-reset-styles";
import {
  useAppDispatch,
  useScrollToTop,
  validateText,
} from "../../../shared/lib";
import { sendRecoveryNewPasswordThunk } from "../../../entities/recovery/model";
import { ValidationFormRecoverReset } from "../config/validation/validation-recover-reset";

type RecoverPasswordResetType = {};

type InitialRecoverPasswordResetForm = {
  password: string;
  confirm_password: string;
};

export const RecoverPasswordReset: FC<RecoverPasswordResetType> = ({}) => {
  useScrollToTop();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams<{ email: string; code: string }>();
  const email = params?.email ? params.email : "";
  const code = params?.code ? params.code : "";

  const formik = useFormik({
    initialValues: {
      password: "",
      confirm_password: "",
    },

    validationSchema: ValidationFormRecoverReset,

    onSubmit: async (body: InitialRecoverPasswordResetForm) => {
      const response = await dispatch(
        sendRecoveryNewPasswordThunk({
          email: email,
          code: Number(code),
          password: body.password,
        })
      );
      if (response.meta.requestStatus === "fulfilled") {
        navigate(pages.recoverPasswordRestored.link);
      } else {
        if (!!response?.payload) {
          formik.setFieldError("email", response.payload as string);
        }
      }
    },
  });

  return (
    <s.RecoverPasswordReset>
      <s.Title>Восстановление пароля</s.Title>
      <s.Subtitle>Придумайте новый пароль</s.Subtitle>
      <s.FormPassword onSubmit={formik.handleSubmit}>
        <s.Wrap>
          <s.List>
            <s.Item>
              <s.InputPassword
                label={"Пароль"}
                {...formik.getFieldProps("password")}
                {...formik.getFieldMeta("password")}
                {...formik.getFieldHelpers("password")}
              />
            </s.Item>

            <s.Item>
              <s.InputPassword
                label={"Повторить пароль"}
                {...formik.getFieldProps("confirm_password")}
                {...formik.getFieldMeta("confirm_password")}
                {...formik.getFieldHelpers("confirm_password")}
              />
            </s.Item>
          </s.List>

          <s.ChecksValidationsPassword
            title={"Пароль должен содержать:"}
            checksValidations={[
              {
                isStatus: validateText(formik.values["password"]).statusLength,
                text: "минимум 11 символов",
              },
              {
                isStatus: validateText(formik.values["password"]).statusNumber,
                text: "минимум 1 цифру",
              },
              {
                isStatus: validateText(formik.values["password"]).statusStr1,
                text: "минимум 1 строчную букву",
              },
              {
                isStatus: validateText(formik.values["password"]).statusStr2,
                text: "минимум 1 прописную букву",
              },
            ]}
          />
        </s.Wrap>

        <s.ButtonChangePassword
          property={ButtonProperty.SECONDARY}
          text={"Изменить пароль"}
          disabled={!formik.isValid || !formik.dirty}
          type={"submit"}
          onClickButton={() => {}}
        />
      </s.FormPassword>
    </s.RecoverPasswordReset>
  );
};
