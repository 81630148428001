import styled, { css } from "styled-components";
import {
  FieldTextFormik,
  SelectInputCalendarFormik,
  SelectSearchFormik,
} from "../../../features";
import { Input } from "../../../shared/ui/atom/input/Input-styles";
import { breakpoints } from "../../../shared/config";

type ItemInputType = {
  $isActive?: boolean;
};

const FormPets = styled.form``;

const List = styled.ul`
  display: grid;
  grid-auto-rows: var(--minmax);
  gap: 24px;
  max-width: 678px;
  margin-top: 24px;
  margin-bottom: 24px;

  @media (max-width: ${breakpoints.MD}) {
    gap: 30px;
  }
`;

const Item = styled.li`
  padding: 40px;
  background-color: var(--background-form);
  border-radius: 20px;

  @media (max-width: ${breakpoints.MD}) {
    padding: 40px 24px;
  }
`;

const Title = styled.h3`
  display: flex;
  align-items: center;
  gap: 12px;
  font: var(--font-9);
  color: var(--interface-dark);
  margin-bottom: 12px;
`;

const SpanTitle = styled.span`
  display: flex;
  align-items: center;
  font: var(--font-8);
  color: var(--brand-1);
`;

const Text = styled.p`
  font: var(--font-16);
  color: var(--text);
  margin-bottom: 24px;
`;

const WrapSubTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const SubTitle = styled.h4`
  font: var(--font-12);
  color: var(--brand-2-2);
`;

const ButtonDeletePet = styled.button`
  font: var(--font-16);
  color: var(--text);
`;

const InputCss = css`
  max-width: var(--input-width);

  ${Input} {
    background-color: var(--brand-4);

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px var(--brand-4) inset;
    }
  }
`;

const InputText = styled(FieldTextFormik)`
  ${InputCss}
`;

const SelectCalendar = styled(SelectInputCalendarFormik)`
  ${InputCss}
`;

const SelectAnimal = styled(SelectSearchFormik)`
  ${InputCss}
`;

const ButtonAddPet = styled.button`
  font: var(--font-18);
  color: var(--brand-1);
  transition: transform 300ms ease;

  &:active {
    transform: scale(0.95);
  }
`;

export {
  FormPets,
  WrapSubTitle,
  Title,
  SpanTitle,
  ButtonDeletePet,
  Text,
  List,
  Item,
  SubTitle,
  InputText,
  SelectCalendar,
  SelectAnimal,
  ButtonAddPet,
};
