import * as React from "react";
import { FC } from "react";

export const IconPromoSmall: FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox={"0 0 32 32"}
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.625}
      d="M24.126 16.541a2.71 2.71 0 0 1 2.709-2.708v-1.084c0-4.333-1.084-5.416-5.417-5.416H10.585c-4.334 0-5.417 1.083-5.417 5.416v.542a2.71 2.71 0 0 1 0 5.417v.541c0 4.334 1.083 5.417 5.417 5.417h10.833c4.333 0 5.417-1.083 5.417-5.417a2.71 2.71 0 0 1-2.709-2.708ZM12.75 18.98l6.5-6.5"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2.167}
      d="M19.243 18.98h.01M12.743 13.021h.01"
    />
  </svg>
);
