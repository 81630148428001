import React, { ChangeEvent, FC } from "react";
import * as s from "./Textarea-styles";

type TextareaType = {
  className?: string;
  value: string;
  placeholder?: string;
  disabled?: boolean;
  isError?: boolean;
  onChangeTextarea: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
};

export const Textarea: FC<TextareaType> = ({
  className,
  value,
  placeholder,
  disabled,
  isError,
  onChangeTextarea,
  onBlur,
}) => {
  return (
    <s.Textarea
      className={className}
      value={value}
      placeholder={placeholder}
      disabled={disabled}
      $isError={isError}
      onChange={disabled ? () => {} : onChangeTextarea}
      onBlur={disabled ? () => {} : onBlur}
    />
  );
};
