import styled from "styled-components";
import { PopupBody } from "../../../../shared";
import { breakpoints } from "../../../../shared/config";
import { BodyAnimation } from "../../../../shared/ui/molecules/popup-body/Popup-body-styles";

const ModalFeedingTypes = styled.div``;

const Modal = styled(PopupBody)`
  ${BodyAnimation} {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 32px;
    padding: 40px 40px 24px;
    max-width: 696px;
    min-height: 288px;
  }

  @media (max-width: ${breakpoints.XXL}) {
    ${BodyAnimation} {
      max-width: 676px;
      min-height: 272px;
    }
  }

  @media (max-width: ${breakpoints.LG}) {
    ${BodyAnimation} {
      padding: 56px 56px 70px;
      max-width: 100%;
    }
  }

  @media (max-width: ${breakpoints.MD}) {
    ${BodyAnimation} {
      padding: 65px 20px 30px;
    }
  }
`;

const List = styled.ol`
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 480px;

  @media (max-width: ${breakpoints.XXL}) {
    max-width: 450px;
  }

  @media (max-width: ${breakpoints.LG}) {
    max-width: 90%;
    gap: 24px;
  }

  @media (max-width: ${breakpoints.MD}) {
    max-width: none;
    gap: 15px;
  }
`;

const Item = styled.li``;

const Paragraph = styled.p`
  font: var(--font-10);
  color: var(--text);

  & span {
    font: var(--font-12);
    color: var(--interface-dark);
  }
`;

const Footnote = styled.p`
  font: var(--font-19);
  color: var(--brand-2);
  max-width: 350px;

  @media (max-width: ${breakpoints.LG}) {
    max-width: none;
  }
`;

const Icon = styled.span`
  position: absolute;
  right: 16px;
  bottom: 16px;
  display: block;
  width: 150px;
  height: 150px;

  @media (max-width: ${breakpoints.LG}) {
    display: none;
  }
`;

export { ModalFeedingTypes, Modal, List, Item, Paragraph, Footnote, Icon };
