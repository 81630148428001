import React, { FC } from "react";
import * as s from "./Form-pet-styles";
import {
  ButtonsDropdownItemType,
  ButtonsTags,
  ButtonTagType,
} from "../../../shared";
import { FormikProps } from "formik";

type InputPet = {
  name: string;
};

type FormPetType = {
  className?: string;
  index?: number;
  formik: FormikProps<any>;
  buttonsAnimalError: string;
  buttonsAnimalTags: Array<ButtonTagType>;
  buttonsGenderError: string;
  buttonsGenderTags: Array<ButtonTagType>;
  onClickSelectAnimalDisabled: () => void;
  inputName: InputPet;
  inputSelectCalendar: InputPet;
  inputSelectAnimal: InputPet & {
    list: Array<ButtonsDropdownItemType>;
    disabled: boolean;
  };
};

export const FormPet: FC<FormPetType> = ({
  className,
  index,
  formik,
  inputName,
  inputSelectCalendar,
  buttonsAnimalTags,
  buttonsAnimalError,
  buttonsGenderError,
  buttonsGenderTags,
  onClickSelectAnimalDisabled,
  inputSelectAnimal,
}) => {
  return (
    <s.FormPet className={className}>
      <s.Text>Выберите питомца</s.Text>

      <s.ButtonsAnimal
        error={buttonsAnimalError}
        buttonsTags={buttonsAnimalTags}
      />

      <s.ListInputs>
        <s.ItemInput>
          <s.InputText
            type={"text"}
            label={"Кличка"}
            {...formik.getFieldProps(inputName.name)}
            {...formik.getFieldMeta(inputName.name)}
            {...formik.getFieldHelpers(inputName.name)}
          />
        </s.ItemInput>

        <s.ItemInput>
          <s.SelectCalendar
            placeholderInput={"Месяц рождения питомца"}
            {...formik.getFieldProps(inputSelectCalendar.name)}
            {...formik.getFieldMeta(inputSelectCalendar.name)}
            {...formik.getFieldHelpers(inputSelectCalendar.name)}
          />
        </s.ItemInput>

        <s.ItemInput
          title={
            inputSelectAnimal.disabled
              ? "Выберите вид питомца для снятия блокировки"
              : ""
          }
          onClick={
            inputSelectAnimal.disabled ? onClickSelectAnimalDisabled : () => {}
          }
        >
          <s.SelectAnimal
            disabled={inputSelectAnimal.disabled && true}
            list={inputSelectAnimal.list || []}
            placeholderInput={"Порода"}
            {...formik.getFieldProps(inputSelectAnimal.name)}
            {...formik.getFieldMeta(inputSelectAnimal.name)}
            {...formik.getFieldHelpers(inputSelectAnimal.name)}
          />
        </s.ItemInput>
      </s.ListInputs>

      <s.Text>Укажите пол питомца</s.Text>

      <ButtonsTags error={buttonsGenderError} buttonsTags={buttonsGenderTags} />
    </s.FormPet>
  );
};
