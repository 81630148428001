import React, { FC, ReactNode } from "react";
import * as s from "./Error-info-tooltip-styles";
import { Animation } from "../../atom";

type ErrorInfoTooltipType = {
  className?: string;
  icon: ReactNode;
  isErrorTooltipVisibility: boolean;
  status: string;
  onHandleMouseErrorEnter: () => void;
  onHandleMouseErrorLeave: () => void;
};

export const ErrorInfoTooltip: FC<ErrorInfoTooltipType> = ({
  className,
  icon,
  isErrorTooltipVisibility,
  status,
  onHandleMouseErrorEnter,
  onHandleMouseErrorLeave,
}) => {
  return (
    <s.ErrorInfoTooltip className={className}>
      <s.Button
        onMouseEnter={onHandleMouseErrorEnter}
        onMouseLeave={onHandleMouseErrorLeave}
      >
        {icon}

        <Animation isShow={isErrorTooltipVisibility}>
          <s.ErrorBonusTooltipWrap>{status}</s.ErrorBonusTooltipWrap>
        </Animation>
      </s.Button>
    </s.ErrorInfoTooltip>
  );
};
