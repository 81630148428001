import styled from "styled-components";
import { breakpoints } from "../../../shared/config";
import topLine1440 from "../../../shared/config/assets/images/recover-pass-line-1-1440.svg";
import topLine1280 from "../../../shared/config/assets/images/recover-pass-line-1-1280.svg";
import bottomLine1440 from "../../../shared/config/assets/images/recover-pass-line-2-1440.svg";
import bottomLine1280 from "../../../shared/config/assets/images/recover-pass-line-2-1280.svg";

const RecoverPassword = styled.section`
  margin-bottom: 110px;
  overflow: hidden;

  @media (max-width: ${breakpoints.MD}) {
    margin-top: 28px;
    margin-bottom: 80px;
  }

  @media (max-width: ${breakpoints.SM}) {
    margin-bottom: 60px;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    height: 265px;
    width: 749px;
    background-image: url(${topLine1440});
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 1000;

    @media (max-width: ${breakpoints.XXL}) {
      height: 597px;
      width: 436px;
      background-image: url(${topLine1280});
    }

    @media (max-width: ${breakpoints.XL}) {
      top: -30px;
    }

    @media (max-width: ${breakpoints.LG}) {
      top: -33%;
    }

    @media (max-width: ${breakpoints.MD}) {
      display: none;
    }
  }

  &:after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    height: 570px;
    width: 963px;
    background-image: url(${bottomLine1440});
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 1000;

    @media (max-width: ${breakpoints.XXL}) {
      bottom: 130px;
      width: 811px;
      height: 284px;
      background-image: url(${bottomLine1280});
    }

    @media (max-width: ${breakpoints.XL}) {
      bottom: 130px;
      width: 700px;
    }

    @media (max-width: ${breakpoints.LG}) {
      right: -31%;
      bottom: 240px;
      width: 700px;
      transform: rotate(-22deg);
    }

    @media (max-width: ${breakpoints.MD}) {
      display: none;
    }

    @media (max-height: 750px) {
      bottom: -100px;
    }

    @media (max-height: 550px) {
      bottom: -200px;
    }
  }
`;

const Title = styled.h2`
  margin-bottom: 40px;
  font: var(--font-3);
  color: var(--brand-1);

  @media (max-width: ${breakpoints.MD}) {
    margin-bottom: 32px;
  }
`;

export { RecoverPassword, Title };
