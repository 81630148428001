import React, { FC, ReactNode } from "react";
import * as s from "./Menu-personal-area-desktop-styles";
import { LinkIconText } from "../../atom";

type MenuPersonalAreaLinkDesktopType = {
  text: string;
  to: string;
  icon: ReactNode;
};

type MenuPersonalAreaDesktopType = {
  className?: string;
  links: Array<MenuPersonalAreaLinkDesktopType>;
};

export const MenuPersonalAreaDesktop: FC<MenuPersonalAreaDesktopType> = ({
  className,
  links,
}) => {
  return (
    <s.MenuPersonalAreaDesktopStyles className={className}>
      <s.List>
        {links.map((link, index) => (
          <s.Item key={index}>
            <LinkIconText text={link.text} to={link.to} icon={link.icon} />
          </s.Item>
        ))}
      </s.List>
    </s.MenuPersonalAreaDesktopStyles>
  );
};
