import styled, { css } from "styled-components";
import { breakpoints, Container } from "../../../shared/config";
import { Link } from "react-router-dom";

const PersonalArea = styled.section`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-bottom: 24px;

  @media (max-width: ${breakpoints.LG}) {
    padding-bottom: 100px;
  }

  @media (max-width: ${breakpoints.MD}) {
    padding-bottom: 90px;
  }
`;

const PersonalAreaContainer = styled(Container)``;

const LinkCss = css`
  font: var(--font-16);
  color: var(--text);
  will-change: transform;
  transition: color 300ms ease, transform 300ms ease;

  &:hover {
    color: var(--hyperlink);
  }

  &:active {
    transform: scale(0.98);
  }
`;

const LinkDefault = styled.a`
  display: flex;
  align-items: center;
  gap: 4px;
  ${LinkCss};
`;

const LinkRouter = styled(Link)`
  display: block;
  ${LinkCss};
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  gap: 4px;
  ${LinkCss};
`;

const Content = styled.div`
  flex: 1 1 auto;
  padding-bottom: 120px;

  @media (max-width: ${breakpoints.XXL}) {
    padding-bottom: 40px;
  }

  @media (max-width: ${breakpoints.XL}) {
    padding-bottom: 100px;
  }

  @media (max-width: ${breakpoints.LG}) {
    padding-bottom: 60px;
  }

  @media (max-width: ${breakpoints.MD}) {
    gap: 70px;
  }
`;

export {
  PersonalArea,
  LinkDefault,
  LinkRouter,
  Button,
  PersonalAreaContainer,
  Content,
};
