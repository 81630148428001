import React from 'react';
import {type RouteObject} from 'react-router-dom';
import {sd} from '../../../../shared';
import {RecoverPassword} from '../../ui/Recover-password';
import {pages} from "../../../../shared/config";

export const router = (children: Array<RouteObject>): RouteObject => {
  return {
    path: pages.recoverPassword.router,
    element: <RecoverPassword/>,
    children,
  };
};
