import styled, { css } from "styled-components";
import { breakpoints } from "../../../shared/config";
import { Input } from "../../../shared/ui/atom/input/Input-styles";
import { FieldPhoneFormik, FieldTextFormik } from "../../../features";

const FormUser = styled.form`
  max-width: 788px;
`;

const SubTitle = styled.h3`
  margin-bottom: 24px;
  font: var(--font-9);
  color: var(--interface-dark);
`;

const List = styled.ul`
  display: grid;
  grid-auto-rows: var(--minmax);
  gap: 12px;

  @media (max-width: ${breakpoints.MD}) {
    gap: 8px;
  }
`;

const Item = styled.li``;

const InputCss = css`
  max-width: var(--input-width);

  ${Input} {
    background-color: var(--brand-4);

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px var(--brand-4) inset;
    }
  }
`;

const WrapInput = styled.div`
  display: grid;
  grid-template-columns: var(--input-width) 1fr;
  align-items: center;
  gap: 16px;

  @media (max-width: ${breakpoints.MD}) {
    grid-template-columns: 1fr;
  }
`;

const InputText = styled(FieldTextFormik)`
  ${InputCss}
`;

const InputPhone = styled(FieldPhoneFormik)`
  ${InputCss}
`;

const Text = styled.p`
  font: var(--font-19);
  color: var(--brand-2);
  max-width: 320px;

  @media (max-width: ${breakpoints.MD}) {
    max-width: 280px;
  }
`;

export {
  FormUser,
  SubTitle,
  List,
  Item,
  WrapInput,
  InputText,
  InputPhone,
  Text,
};
