import styled, { css } from "styled-components";
import { FieldTextFormik } from "../../../features";
import { breakpoints } from "../../../shared/config";
import { Input } from "../../../shared/ui/atom/input/Input-styles";

const RecoverPasswordCode = styled.section`
  max-width: 450px;

  @media (max-width: ${breakpoints.XL}) {
    max-width: 400px;
  }

  @media (max-width: ${breakpoints.LG}) {
    max-width: 380px;
  }
`;

const Title = styled.h2`
  margin-bottom: 40px;
  font: var(--font-3);
  color: var(--brand-1);

  @media (max-width: ${breakpoints.MD}) {
    margin-bottom: 32px;
  }
`;

const InputCss = css`
  max-width: var(--input-width);

  ${Input} {
    background-color: var(--brand-4);

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px var(--brand-4) inset;
    }
  }
`;

const InfoText = styled.p`
  font: var(--font-16);
  color: var(--text);
  letter-spacing: -0.36px;
  margin-bottom: 24px;
`;

const InfoTextChange = styled.a`
  font: var(--font-15);
  color: var(--brand-1);
  margin-left: 10px;
`;

const InputText = styled(FieldTextFormik)`
  ${InputCss};
`;

const Subtitle = styled.p`
  font: var(--font-9);
  color: var(--interface-dark);
  margin-bottom: 10px;

  @media (max-width: ${breakpoints.SM}) {
    margin-bottom: 8px;
  }
`;

const ResendCode = styled.p`
  font: var(--font-19);
  color: var(--brand-2);
  margin-bottom: 16px;

  @media (max-width: ${breakpoints.SM}) {
    margin-bottom: 10px;
  }
`;

const SendRepeat = styled.button`
  font: var(--font-19);
  color: var(--brand-1);
  margin-bottom: 16px;
  cursor: pointer;

  @media (max-width: ${breakpoints.SM}) {
    margin-bottom: 10px;
  }
`;

const FormRecoverPasswordCode = styled.form`
  margin-bottom: 24px;

  @media (max-width: ${breakpoints.SM}) {
    margin-bottom: 16px;
  }
`;

export {
  Title,
  RecoverPasswordCode,
  InfoText,
  InfoTextChange,
  InputText,
  Subtitle,
  FormRecoverPasswordCode,
  ResendCode,
  SendRepeat,
};
