import React, { FC } from "react";

export const IconRoyals: FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox={"0 0 32 32"}
    {...props}
  >
    <path
      fill="#E2001A"
      d="M3.575 19.278C.587 11.312 9.799 6 18.512 6c15.353 0 11.619 13.54 1.66 18.257-6.307 2.987-15.042-.83-16.597-4.979Z"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M15.84 14.426c.775 0 1.404-.551 1.404-1.235 0-.683-.629-1.237-1.404-1.237-.776 0-1.404.554-1.404 1.237 0 .684.628 1.235 1.404 1.235ZM12.93 10.33c.762-.598 1.145-.832 2.36-.985 1.027-.13 1.926.281 2.057.323 1.614.517 2.706 2.288 2.489 4.042-.128 1.043-.777 1.984-1.584 2.665.78 1.643 2.237 2.715 2.005 3.529-.16.562-.323.708-.843.924-.335.137-.815.101-1.091-.125-1.354-1.096-2.068-2.681-3.208-3.815-.28-.278-.407-.155-.407-.003.003 1.33.015 2.317-.058 2.967-.043.39-.427.838-.692.962-.728.348-1.714-.072-1.841-.851-.025-.14-.228-.742-.24-3.487-.003-1.36 0-1.657 0-3.815 0-.881.438-1.85 1.053-2.33Z"
      clipRule="evenodd"
    />
  </svg>
);
