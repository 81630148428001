import { css } from "styled-components";

const stylesScrollbarMixin = (width: string, height?: string) => css`
  &::-webkit-scrollbar {
    width: ${width};
    height: ${height};
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--interface);
    border-radius: 5px;
    border: 0 solid transparent;

    &:hover {
      background-color: var(--text);
    }

    &:active {
      background-color: var(--brand-2);
    }
  }
`;

const buttonStyleMix = css`
  color: var(--brand-3);
  border: 2px solid var(--brand-3);
  background-color: transparent;
`;

export { stylesScrollbarMixin, buttonStyleMix };
