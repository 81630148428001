import React, { FC } from "react";

export const IconOk: FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox={"0 0 40 40"}
    {...props}
  >
    <path d="M20.444 4a8.282 8.282 0 1 0 0 16.564 8.282 8.282 0 0 0 0-16.564Zm0 11.706a3.424 3.424 0 1 1 0-6.848 3.424 3.424 0 0 1 0 6.848ZM23.368 27.154c3.316-.676 5.302-2.246 5.408-2.33a2.252 2.252 0 0 0-2.818-3.514c-.02.017-2.14 1.642-5.626 1.645-3.487-.003-5.65-1.628-5.671-1.645a2.252 2.252 0 0 0-2.818 3.514c.107.085 2.176 1.696 5.584 2.356l-4.75 4.964a2.252 2.252 0 1 0 3.242 3.126l4.413-4.7 4.859 4.73a2.252 2.252 0 1 0 3.182-3.186l-5.005-4.96ZM20.331 22.954c-.004 0 .004 0 0 0s.005 0 0 0Z" />
  </svg>
);
