import React, {FC, ReactNode} from "react";
import {useNavigate} from "react-router-dom";
import {esAuth, esDiscount} from "../../../entities";
import {
  authRegistrationThunk,
  getUserThunk,
  RegistrationRequestBodyType,
  setAuthorization,
} from "../../../entities/auth/model";
import {
  ModalAboutInvitation,
  ModalAddAnimal,
  ModalAdditionalRoyals,
  ModalCheckEmail,
  ModalConfirmation,
  ModalConfirmationPhone,
  ModalConfirmationRefEmailFriend,
  ModalConfirmationSuccessful,
  ModalExit,
  ModalFeedingTypes,
  ModalFrequentlyAskedQuestion,
  ModalHistoryOperations,
  ModalPromoCode,
  ModalPromoCodeType,
  ModalQuestion,
  ModalQuestionSent,
  ModalReceipt,
  ModalRefEmailFriend,
  ModalSuccessfullyRegistered,
} from "../../../features";
import {ModalAboutInvitationButtons} from "../../../features/_modals/modal-about-invitation-buttons";
import {
  ButtonInvite,
  ButtonText,
  IconDiabetes,
  IconOldPets,
  IconOptimalWeight,
  IconPhysicalNeeds,
  IconUrolithiasis,
} from "../../../shared";
import {ModalsEnumType, ModalsProperty, pages} from "../../../shared/config";
import {deleteMaskPhone, useAppDispatch, useAppSelector, useChangeLetter,} from "../../../shared/lib";
import {ws} from "../index";
import {setModals} from "../model";
import * as s from "./Modals-styles";
import {PromocodeType, PromoCodeType} from "../../../entities/discount/model";
import popupBgImageHeart from "../../../shared/config/assets/images/popup-bg-heart.svg";
import popupBgImageWings from "../../../shared/config/assets/images/popup-bg-wings.svg";
import popupBgImageStar from "../../../shared/config/assets/images/popup-bg-star.svg";
import popupBgImageShape from "../../../shared/config/assets/images/popup-bg-shape.svg";
import {
  checkSmsThunk,
  getOkRepostThunk,
  getVkRepostThunk,
  selectShared,
  sendSmsThunk,
  setDeleteNotification,
  setNotification,
  SmsType,
} from "../../../shared/model";
import {v4 as uuidv4} from "uuid";

type ModalsType = {
  action: ModalsEnumType;
  content?: ReactNode;
};

export const Modals: FC<ModalsType> = ({action, content}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector(esAuth.model.selectAuthUser);
  const errorAuth = useAppSelector(esAuth.model.selectAuthError);
  const {error} = useAppSelector(selectShared);
  const resetModal = () => {
    dispatch(ws.model.setModals({action: null, content: null}));
  };

  const getModal = (action: ModalsEnumType) => {
    switch (action) {
      case ModalsProperty.MODAL_CONFIRMATION_EMAIL:
        const obj = content as unknown as {
          body: RegistrationRequestBodyType;
          invitationToken?: string;
        };
        return (
          <ModalConfirmation
            buttonRedirectText={"К регистрации"}
            buttonTextBefore={"Отправить письмо"}
            buttonTextAfter={"Отправить письмо повторно через"}
            title={"Введите код из письма для подтверждения почты"}
            subText={`Мы отправили код на ${
              obj?.body.email ? obj.body.email : ""
            }. Письмо может попасть в папку «Спам».`}
            onShowModal={() => {
              resetModal();
              dispatch(esAuth.model.setError(null));
            }}
            error={errorAuth}
            lengthNumber={7}
            inputPlaceholder={"2564714"}
            onChangeInput={(code: string) => {
              if (code.length === 7) {
                dispatch(
                  authRegistrationThunk({
                    body: {
                      ...obj.body,
                      code: code,
                    },
                    invitationToken: obj.invitationToken,
                  })
                );
              }
            }}
            onClickButtonTime={async () => {
              await esAuth.api.authApi.email_confirmation({
                email: obj.body.email,
                name: obj.body.last_name,
              });

              dispatch(esAuth.model.setError(null));
            }}
          />
        );

      case ModalsProperty.MODAL_SUCCESSFULLY_REGISTERED:
        return (
          <ModalSuccessfullyRegistered
            onShowModal={resetModal}
            onClickButton={() => {
              dispatch(setAuthorization(true));
              dispatch(getUserThunk());
              navigate(pages.personalAreaProfile.link);
              resetModal();
            }}
          />
        );

      case ModalsProperty.MODAL_SEND_PHONE:
        const objModalSendPhone = content as unknown as {
          phone: string;
        };
        return (
          <ModalConfirmationPhone
            onShowModal={resetModal}
            phone={objModalSendPhone?.phone}
            onSubmit={async (body: SmsType) => {
              await dispatch(
                sendSmsThunk({phone: deleteMaskPhone(body.phone)})
              );

              await dispatch(
                setModals({
                  action: ModalsProperty.MODAL_CONFIRMATION_PHONE,
                  content: {phone: body.phone},
                })
              );
            }}
          />
        );

      case ModalsProperty.MODAL_CONFIRMATION_PHONE:
        const obj1 = content as unknown as { phone: string };

        return (
          <s.ModalConfirmationPhone
            buttonRedirectText={"К профилю"}
            buttonTextBefore={"Отправить код"}
            buttonTextAfter={"Отправить код повторно через"}
            title={
              <>
                Подтвердите номер телефона и&nbsp;получите 2&nbsp;роялса&nbsp;
                <s.IconRoyal/>
              </>
            }
            subText={
              <>
                <s.TextModalConfirmationPhone>
                  Введите код из смс
                </s.TextModalConfirmationPhone>
                Мы отправили его на&nbsp;номер {obj1.phone}{" "}
                <ButtonText
                  type={"button"}
                  text={"Изменить"}
                  onClickButtonText={async () => {
                    await resetModal();
                    await dispatch(esAuth.model.setError(null));
                    await dispatch(
                      setModals({
                        action: ModalsProperty.MODAL_SEND_PHONE,
                      })
                    );
                  }}
                />
              </>
            }
            onShowModal={resetModal}
            error={error}
            lengthNumber={5}
            inputPlaceholder={"25647"}
            onChangeInput={async (code: string) => {
              if (code.length === 5) {
                await dispatch(
                  checkSmsThunk({
                    phone: deleteMaskPhone(obj1.phone),
                    code: Number(code),
                  })
                );
                await dispatch(getUserThunk())
              }
            }}
            onClickButtonTime={() => {
              dispatch(sendSmsThunk({phone: deleteMaskPhone(obj1.phone)}));
            }}
          />
        );

      case ModalsProperty.MODAL_CONFIRMATION_SUCCESSFULLY:
        return (
          <ModalConfirmationSuccessful
            buttonRedirectText={"К профилю"}
            onShowModal={resetModal}
          />
        );

      case ModalsProperty.MODAL_REF_EMAIL_FRIEND:
        return (
          <ModalRefEmailFriend
            buttonRedirectText={"К профилю"}
            onShowModal={resetModal}
          />
        );

      case ModalsProperty.MODAL_CONFIRMATION_REF_EMAIL_FRIEND:
        return (
          <ModalConfirmationRefEmailFriend
            buttonRedirectText={"К профилю"}
            onShowModal={resetModal}
            onClickButton={async () => {
              await resetModal();
              dispatch(
                setModals({action: ModalsProperty.MODAL_REF_EMAIL_FRIEND})
              );
            }}
          />
        );

      case ModalsProperty.MODAL_RECEIPT:
        return <ModalReceipt onShowModal={resetModal}/>;

      case ModalsProperty.MODAL_ABOUT_INVITATION_ANIMAL:
        return (
          <ModalAboutInvitation
            title={"Приглашайте друзей с\u00A0питомцами"}
            onShowModal={resetModal}
            list={[
              {
                title: "Зайдите в Личный кабинет",
                text: "и нажмите на кнопку «Пригласить друга».",
              },

              {
                title: "Введите e-mail друга",
                text: "на него будет отправлена ссылка для регистрации на сайте.",
              },

              {
                title: "За каждого друга начислим 5 роялсов",
                text: "Зачисление пройдет в течение 10 дней после того, как он зарегистрируется по вашей ссылке и загрузит чеки в сумме на 5 роялсов.",
              },
            ]}
          />
        );

      case ModalsProperty.MODAL_ABOUT_INVITATION_SOCIAL_MEDIA:
        return (
          <ModalAboutInvitation
            title={"Рассказывайте о программе в\u00A0социальных сетях"}
            onShowModal={resetModal}
            list={[
              {
                title: "Зайдите в Личный кабинет",
                text: "в блок «Рассказывайте о программе в соц.сетях».",
              },

              {
                title: "Перейдите по ссылке",
                text: "и авторизуйтесь в Одноклассники или Вконтакте.",
              },

              {
                title: "Опубликуйте пост",
                text: "с информацией о программе «Здоровый питомец».",
              },

              {
                title: "За каждую соц.сеть начислим 3 роялса",
                text: "Получите бонусы в течение 10 дней.",
              },
            ]}
          />
        );

      case ModalsProperty.MODAL_ABOUT_INVITATION_ANIMAL_BUTTONS:
        return (
          <ModalAboutInvitationButtons
            title={
              <>
                Пригласите друга с питомцем и получите 5 роялсов <s.IconRoyal/>
              </>
            }
            onShowModal={resetModal}
            list={[
              {
                title: "Зайдите в Личный кабинет",
                text: "и нажмите на кнопку «Пригласить друга».",
              },

              {
                title: "Введите e-mail друга",
                text: "на него будет отправлена ссылка для регистрации на сайте.",
              },

              {
                title: "За каждого друга начислим 5 роялсов",
                text: "Зачисление пройдет в течение 10 дней после того, как он зарегистрируется по вашей ссылке и загрузит чеки в сумме на 5 роялсов.",
              },
            ]}
            buttons={[
              <s.ButtonInviteModal
                icon={<s.IconUser/>}
                onClickButtonInvite={() => {
                  resetModal();
                  dispatch(
                    setModals({action: ModalsProperty.MODAL_REF_EMAIL_FRIEND})
                  );
                }}
                text="Пригласить друга"
                type="button"
              />,
            ]}
          />
        );

      case ModalsProperty.MODAL_ABOUT_INVITATION_SOCIAL_MEDIA_BUTTONS:
        const onRepostVk = async () => {
          await dispatch(getVkRepostThunk());
          await dispatch(getUserThunk());
        };

        const onRepostOk = async () => {
          await dispatch(getOkRepostThunk());
          await dispatch(getUserThunk());
        };

        return (
          (!user.vk_repost || !user.ok_repost) &&
          <ModalAboutInvitationButtons
              title={
                <>
                  Рассказывайте о программе в социальных сетях <s.IconRoyal/>
                </>
              }
              onShowModal={resetModal}
              list={[
                {
                  title: "Зайдите в Личный кабинет",
                  text: "в блок «Рассказывайте о программе в соц.сетях».",
                },

                {
                  title: "Перейдите по ссылке",
                  text: "и авторизуйтесь в Одноклассники или Вконтакте.",
                },

                {
                  title: "Опубликуйте пост",
                  text: "с информацией о программе «Здоровый питомец».",
                },

                {
                  title: "За каждую соц.сеть начислим 3 роялса",
                  text: "Получите бонусы в течение 10 дней.",
                },
              ]}
              buttons={[
                !user.vk_repost && (
                  <a
                    href="https://vk.com/share.php?url=https://xn----ctbfggjf0anaeki8a4dxd.xn--p1ai&title=%D0%9F%D1%80%D0%B8%D1%81%D0%BE%D0%B5%D0%B4%D0%B8%D0%BD%D1%8F%D0%B9%D1%82%D0%B5%D1%81%D1%8C%20%D0%BA%20%D0%B0%D0%BA%D1%86%D0%B8%D0%B8%20%D0%97%D0%B4%D0%BE%D1%80%D0%BE%D0%B2%D1%8B%D0%B9%20%D0%BF%D0%B8%D1%82%D0%BE%D0%BC%D0%B5%D1%86.&image=https://xn----ctbfggjf0anaeki8a4dxd.xn--p1ai/media/banners/banner-vk.jpg&noparse=true"
                    target={"_blank"}
                  >
                    <ButtonInvite
                      icon={<s.IconButtonVk/>}
                      onClickButtonInvite={onRepostVk}
                      text="Вконтакте"
                      type="button"
                    />
                  </a>
                ),
                !user.ok_repost && (
                  <a
                    href="https://connect.ok.ru/offer?url=https://xn----ctbfggjf0anaeki8a4dxd.xn--p1ai"
                    target={"_blank"}
                  >
                    <ButtonInvite
                      icon={<s.IconButtonOk/>}
                      onClickButtonInvite={onRepostOk}
                      text="Одноклассники"
                      type="button"
                    />
                  </a>
                ),
              ]}
          />
        );

      case ModalsProperty.MODAL_FEEDING_TYPES_NATURAL_NEEDS:
        return (
          <ModalFeedingTypes
            onShowModal={resetModal}
            list={[
              {
                text: (
                  <>
                    Домашние собаки и кошки{" "}
                    <span>естественным образом выбирают</span> рацион с высоким
                    содержанием белка и жиров и относительно низким содержанием
                    углеводов, а также с высоким содержанием влаги*.
                  </>
                ),
              },
              {
                text: (
                  <>
                    Наибольшее соответствие этим потребностям питомцев{" "}
                    <span>
                      обеспечивается посредством смешивания влажного и сухого
                      рационов.
                    </span>
                  </>
                ),
              },
            ]}
            footnote={"*(Bradshaw JW, 1991, 2006)"}
            icon={<IconPhysicalNeeds/>}
          />
        );

      case ModalsProperty.MODAL_FEEDING_TYPES_OPTIMAL_WEIGHT:
        return (
          <ModalFeedingTypes
            onShowModal={resetModal}
            list={[
              {
                text: (
                  <>
                    Высокое содержание влаги во влажном рационе{" "}
                    <span>
                      обеспечивает снижение количества ежедневно потребляемых
                      калорий,
                    </span>{" "}
                    {""}
                    ускоряет наступление сытости и снижает скорость потребления
                    рациона.
                  </>
                ),
              },
              {
                text: (
                  <>
                    Набор массы тела можно предотвратить* при помощи диет с
                    содержанием влаги <span>как минимум 40%.</span>
                  </>
                ),
              },
            ]}
            footnote={"*(Morris et al, 2006)."}
            icon={<IconOptimalWeight/>}
          />
        );

      case ModalsProperty.MODAL_FEEDING_TYPES_UROLITHIASIS:
        return (
          <ModalFeedingTypes
            onShowModal={resetModal}
            list={[
              {
                text: (
                  <>
                    Риск развития заболеваний нижнего отдела мочевыводящих путей
                    обратно{" "}
                    <span>пропорционален содержанию влаги в рационе.</span>
                  </>
                ),
              },

              {
                text: (
                  <>
                    Рацион, который состоит как минимум на 25% из влажного
                    рациона, способствует увеличению объема мочи у животных и{" "}
                    <span>
                      помогает снизить риск образования мочевых камней*.
                    </span>
                  </>
                ),
              },
            ]}
            footnote={"*(Buckley et al, 2010, 2011)."}
            icon={<IconUrolithiasis/>}
          />
        );

      case ModalsProperty.MODAL_FEEDING_TYPES_DIABETES:
        return (
          <ModalFeedingTypes
            onShowModal={resetModal}
            list={[
              {
                text: (
                  <>
                    Эксперты сходятся во мнении*, что{" "}
                    <span>наилучшей мерой профилактики диабета</span> считается
                    применение рациона с высоким содержанием белка, умеренным
                    содержанием жиров и низким содержанием углеводов.
                  </>
                ),
              },

              {
                text: (
                  <>
                    Это можно реализовать посредством{" "}
                    <span>сочетания влажного и сухого рационов.</span>
                  </>
                ),
              },
            ]}
            footnote={
              "*(Slingerland LI, Fazilova VV, Plantinga EA, 2009; Rand JS, Farrow HA, Fleeman LM, et al, 2003)"
            }
            icon={<IconDiabetes/>}
          />
        );

      case ModalsProperty.MODAL_FEEDING_TYPES_OLD_PETS:
        return (
          <ModalFeedingTypes
            onShowModal={resetModal}
            list={[
              {
                text: (
                  <>
                    Благодаря мягкой текстуре{" "}
                    <span>влажные рационы легче захватывать</span> и поедать
                    животным с неполным набором зубов или повышенной
                    чувствительностью десен*.
                  </>
                ),
              },
            ]}
            footnote={
              <>
                (Fahey GC, Barry KA, Swanson KS, 2008; Harper&nbsp;EJ, Turner
                CL., 2000)
              </>
            }
            icon={<IconOldPets/>}
          />
        );

      case ModalsProperty.MODAL_ADDITIONAL_ROYALS_HEART:
        return (
          <ModalAdditionalRoyals
            title={"Регистрируйтесь и заполняйте анкету про своего питомца"}
            bgImage={popupBgImageHeart}
            list={[
              {
                subTitle: "Нажмите на кнопку “Загрузить чек”",
                text: "нажмите кнопку «Зарегистрироваться» или «Загрузить чек» на Сайте.",
              },

              {
                subTitle: "Заполните данные 1-го питомца",
                text: "при регистрации заполните обязательные личные данные, а также данные минимум 1-го питомца.",
              },

              {
                subTitle: "Подтвердите e-mail",
                text: "подтвердите e-mail, указанный в форме регистрации. Нажмите кнопку «Зарегистрироваться».",
              },

              {
                subTitle: "Авторизируйтесь",
                text: "на сайте и зайдите в Личный кабинет.",
              },
            ]}
            onShowModal={resetModal}
          />
        );

      case ModalsProperty.MODAL_ADDITIONAL_ROYALS_WINGS:
        return (
          <ModalAdditionalRoyals
            title={"Приглашайте друзей с питомцами"}
            bgImage={popupBgImageWings}
            list={[
              {
                subTitle: "Зайдите в Личный кабинет",
                text: "и нажмите на кнопку «Пригласить друга».",
              },

              {
                subTitle: "Введите e-mail друга",
                text: "на него будет отправлена ссылка для регистрации на сайте.",
              },

              {
                subTitle: "За каждого друга начислим 5 роялсов",
                text: "Зачисление пройдет в течение 10 дней после того, как он зарегистрируется по вашей ссылке и загрузит чеки в сумме на 5 роялсов.",
              },
            ]}
            onShowModal={resetModal}
          />
        );

      case ModalsProperty.MODAL_ADDITIONAL_ROYALS_STAR:
        return (
          <ModalAdditionalRoyals
            title={"Рассказывайте о программе в\u00A0социальных сетях"}
            bgImage={popupBgImageStar}
            list={[
              {
                subTitle: "Зайдите в Личный кабинет",
                text: "в блок «Рассказывайте о программе в соц.сетях».",
              },

              {
                subTitle: "Перейдите по ссылке",
                text: "и авторизуйтесь в Одноклассники или Вконтакте.",
              },

              {
                subTitle: "За каждую соц.сеть начислим 3 роялса",
                text: "Получите бонусы в течение 10 дней.",
              },
            ]}
            onShowModal={resetModal}
          />
        );

      case ModalsProperty.MODAL_ADDITIONAL_ROYALS_SHAPE:
        return (
          <ModalAdditionalRoyals
            title={"Получайте бонусы за регулярную покупку Royal\u00A0Canin"}
            bgImage={popupBgImageShape}
            list={[
              {
                subTitle: "Совершайте покупки рационов",
                text: "из ассортимента Royal\u00A0Canin, представленных в сети магазинов «Зоозавр» и на сайте Zoozavr.ru, кроме ветеринарных диет.",
              },

              {
                subTitle: "Загружайте чеки",
                text: "с Royal\u00A0Canin из Зоозавра.",
              },

              {
                subTitle: "Получайте бонусы за регулярные покупки",
                text: (
                  <>
                    <ul>
                      Бонус за регулярную покупку Royal Canin каждый месяц:
                      <li>в течение 3 мес. - 30 роялсов.</li>
                      <li>в течение 6 мес. - 45 роялсов.</li>
                      <li>в течение 9 мес. - 60 роялсов.</li>
                    </ul>
                  </>
                ),
              },
            ]}
            onShowModal={resetModal}
          />
        );

      case ModalsProperty.MODAL_HISTORY_OPERATIONS:
        return <ModalHistoryOperations onShowModal={resetModal}/>;

      case ModalsProperty.MODAL_ADD_ANIMAL:
        return <ModalAddAnimal onShowModal={resetModal}/>;

      case ModalsProperty.MODAL_PROMO_CODE:
        const obj4 = content as unknown as PromocodeType;
        return (
          <ModalPromoCode
            onShowModal={resetModal}
            assignment={obj4.assignment}
            description={obj4.description}
            title={useChangeLetter({
              text: obj4.title || "",
              icon: <s.RubleIcon/>,
              targetChar: "₽",
            })}
          />
        );

      case ModalsProperty.MODAL_PROMO_CODE_TYPE:
        const promoCodeType = content as unknown as PromoCodeType & {
          isButton: boolean;
          titleModal?: string;
        };
        const getPromoCode = async (id: number) => {
          const idv4 = uuidv4();
          await dispatch(esDiscount.model.sendPromoCodeThunk(id));
          await dispatch(esAuth.model.getUserThunk());
          await dispatch(
            setNotification({
              text: "Промокод получен!",
              id: idv4,
            })
          );
          await setTimeout(() => {
            dispatch(setDeleteNotification(idv4));
          }, 10000);
        };
        return (
          <ModalPromoCodeType
            title={promoCodeType.titleModal}
            counter={String(promoCodeType.price)}
            isRoyals={!!promoCodeType.price}
            list={promoCodeType.description}
            text={promoCodeType.title}
            isButton={promoCodeType.isButton}
            isDisabledButton={promoCodeType.available}
            onShowModal={resetModal}
            onClickButton={async () => {
              await getPromoCode(promoCodeType.id);
              resetModal();
            }}
          />
        );
      case ModalsProperty.MODAL_QUESTION:
        return (
          <ModalQuestion
            onShowModal={resetModal}
            onSubmit={() => {
              dispatch(
                setModals({action: ModalsProperty.MODAL_QUESTION_SENT})
              );
            }}
          />
        );

      case ModalsProperty.MODAL_QUESTION_SENT:
        return <ModalQuestionSent onShowModal={resetModal}/>;

      case ModalsProperty.MODAL_FREQUENTLY_ASKED_QUESTION_QUESTION:
        const modalFrequentlyAskedQuestionTitle = content as unknown as string;
        return (
          <ModalFrequentlyAskedQuestion
            title={modalFrequentlyAskedQuestionTitle}
            onShowModal={resetModal}
          />
        );

      case ModalsProperty.MODAL_EXIT:
        return <ModalExit onShowModal={resetModal}/>;

      case ModalsProperty.MODAL_CHECK_EMAIL:
        return <ModalCheckEmail onShowModal={resetModal}/>;
      default:
        return "";
    }
  };

  return <>{getModal(action)}</>;
};
