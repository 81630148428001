import styled from "styled-components";
import { IconsCardsRoyals } from "../../atom";
import { breakpoints } from "../../../config";

const IconHeartPink = styled(IconsCardsRoyals)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 248px;
  height: 288px;
  color: var(--pink-for-svg);

  @media (max-width: ${breakpoints.MD}) {
    width: 250px;
    height: 160px;
  }
`;

const IconHeartRed = styled(IconsCardsRoyals)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 248px;
  height: 288px;
  color: var(--red-for-svg);
`;

const IconWingPink = styled(IconsCardsRoyals)`
  position: absolute;
  top: 0;
  left: 0;
  width: 248px;
  height: 288px;
  color: var(--pink-for-svg);

  @media (max-width: ${breakpoints.MD}) {
    width: 200px;
    height: 236px;
  }
`;

const IconWingRed = styled(IconsCardsRoyals)`
  position: absolute;
  top: 0;
  left: 0;
  width: 248px;
  height: 288px;
  color: var(--red-for-svg);
`;

const IconStarPink = styled(IconsCardsRoyals)`
  position: absolute;
  right: 0;
  bottom: -20px;
  width: 327px;
  height: 290px;
  color: var(--pink-for-svg);

  @media (max-width: ${breakpoints.MD}) {
    bottom: -60px;
    width: 190px;
    height: 165px;
    transform: rotate(-10deg);
  }
`;

const IconStarRed = styled(IconsCardsRoyals)`
  position: absolute;
  right: 0;
  bottom: -20px;
  width: 327px;
  height: 290px;
  color: var(--red-for-svg);
`;

const IconShapePink = styled(IconsCardsRoyals)`
  position: absolute;
  top: 0;
  right: -54px;
  width: 253px;
  height: 280px;
  color: var(--pink-for-svg);

  @media (max-width: ${breakpoints.MD}) {
    right: -38px;
    width: 231px;
    height: 220px;
  }
`;

const IconShapeRed = styled(IconsCardsRoyals)`
  position: absolute;
  top: 0;
  right: -54px;
  width: 253px;
  height: 280px;
  color: var(--red-for-svg);
`;

const AdditionalRoyals = styled.section`
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media (max-width: ${breakpoints.MD}) {
    gap: 32px;
  }
`;

const Title = styled.h2`
  font: var(--font-3);
  letter-spacing: -1.68px;
  color: var(--brand-1);

  @media (max-width: ${breakpoints.MD}) {
    max-width: 90%;
    letter-spacing: -1.04px;
  }
`;

export {
  AdditionalRoyals,
  Title,
  IconHeartPink,
  IconHeartRed,
  IconWingPink,
  IconWingRed,
  IconStarPink,
  IconStarRed,
  IconShapePink,
  IconShapeRed,
};
