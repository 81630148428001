import React, { FC } from "react";
import * as s from "./Check-validation-styles";
import { Animation } from "../animation/Animation";

export type CheckValidationType = {
  className?: string;
  text: string;
  isStatus: boolean;
};

export const CheckValidation: FC<CheckValidationType> = ({
  className,
  text,
  isStatus,
}) => {
  return (
    <s.CheckValidation className={className} $isStatus={isStatus}>
      <s.WrapIcon>
        <Animation isShow={!isStatus}>
          <s.Icon />
        </Animation>

        <Animation isShow={isStatus}>
          <s.IconCheck />
        </Animation>
      </s.WrapIcon>

      {text}
    </s.CheckValidation>
  );
};
