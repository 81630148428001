import React, { FC, ReactNode } from "react";
import * as s from "./Card-invite-styles";
import { IconInfo } from "../../atom";

type CardInviteType = {
  className?: string;
  title: ReactNode;
  text: string;
  mobileButtonText: string;
  buttons: Array<ReactNode>;
  isMobile: boolean;
  onClickMobileButton: () => void;
  onClickCardButtonIcon: () => void;
};

export const CardInvite: FC<CardInviteType> = ({
  className,
  title,
  text,
  mobileButtonText,
  buttons,
  isMobile,
  onClickMobileButton,
  onClickCardButtonIcon,
}) => {
  return (
    <s.CardInvite
      className={className}
      onClick={isMobile ? onClickMobileButton : () => {}}
      $isActive={isMobile}
    >
      <s.ButtonCardIcon
        type={"button"}
        icon={<IconInfo width={24} height={24} />}
        onClickButtonIcon={onClickCardButtonIcon}
      />

      <s.Title>
        {title}
        {"\u00A0"}
        <s.IconRoyal />
      </s.Title>

      <s.Text>{text}</s.Text>

      {isMobile ? (
        <s.MobileButtonWrap>
          <s.MobileButtonText
            type={"button"}
            text={mobileButtonText}
            onClickButtonText={onClickMobileButton}
          />
          <s.IconArrow />
        </s.MobileButtonWrap>
      ) : (
        buttons.length > 0 && (
          <s.List>
            {buttons.map((button, index) => (
              <s.Item key={index}>{button}</s.Item>
            ))}
          </s.List>
        )
      )}
    </s.CardInvite>
  );
};
