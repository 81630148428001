import React, { FC, ReactNode } from "react";
import * as s from "./Cards-royals-styles";
import { CardRoyals, CardRoyalsListInfo } from "../../atom";
import { ModalsEnumType } from "../../../config";

export type CardModalRoyalsInfoType = {
  title: string;
  bgImage: ReactNode;
  list: Array<{ subTitle: string; text: ReactNode }>;
};

export type CardArrayRoyalsType = {
  title: string;
  footnote?: string;
  royals: number;
  image: ReactNode;
  backImage: ReactNode;
  listInfo: Array<CardRoyalsListInfo>;
  modal: ModalsEnumType;
};

type CardsRoyalsType = {
  className?: string;
  isFlipped?: boolean;
  cards: Array<CardArrayRoyalsType>;
  onClickCardRoyals: (modalInfo: ModalsEnumType) => () => void;
};

export const CardsRoyals: FC<CardsRoyalsType> = ({
  className,
  cards,
  isFlipped,
  onClickCardRoyals,
}) => {
  return (
    <s.CardsRoyals className={className}>
      <s.ListCards>
        {cards.map((card, index) => (
          <s.ItemCards key={index}>
            <CardRoyals
              title={card.title}
              footnote={card.footnote}
              royals={card.royals}
              image={card.image}
              backImage={card.backImage}
              listInfo={card.listInfo}
              isFlipped={isFlipped}
              onClickCard={onClickCardRoyals(card.modal)}
            />
          </s.ItemCards>
        ))}
      </s.ListCards>
    </s.CardsRoyals>
  );
};
