import React, { FC, ReactNode } from "react";
import * as s from "./Hero-styles";
import { IconsStepsRoyals } from "../../atom";

type HeroType = {
  className?: string;
  titleBanner: string;
  subTitleBanner: string;
  extraTextBanner: string;
  imgBanner375Webp: string;
  imgBanner375_2xWebp: string;
  imgBanner375: string;
  imgBanner375_2x: string;
  imgBanner1024Webp: string;
  imgBanner1024_2xWebp: string;
  imgBanner1024: string;
  imgBanner1024_2x: string;
  imgBanner1440: string;
  imgBanner1440_2x: string;
  imgBanner1440Webp: string;
  imgBanner1440_2xWebp: string;
  altTextImgBanner: string;
  buttonText: string;
  buttonTypeBonus: "button" | "reset" | "submit";
  buttonIconUpload: ReactNode;
  onClickButtonUploadHero: () => void;
};
export const Hero: FC<HeroType> = ({
  className,
  titleBanner,
  subTitleBanner,
  extraTextBanner,
  imgBanner375Webp,
  imgBanner375_2xWebp,
  imgBanner375,
  imgBanner375_2x,
  imgBanner1024Webp,
  imgBanner1024_2xWebp,
  imgBanner1024,
  imgBanner1024_2x,
  imgBanner1440,
  imgBanner1440_2x,
  imgBanner1440Webp,
  imgBanner1440_2xWebp,
  altTextImgBanner,
  buttonText,
  buttonTypeBonus,
  buttonIconUpload,
  onClickButtonUploadHero,
}) => {
  const stepsRoyalsListMock = [
    {
      image: <IconsStepsRoyals status={1} />,
      index: 1,
      firstWord: "Покупайте ",
      text: (
        <>
          рационы Royal&nbsp;Canin для&nbsp;котят и&nbsp;щенков в&nbsp;Зоозавре
        </>
      ),
    },

    {
      image: <IconsStepsRoyals status={2} />,
      index: 2,
      firstWord: "Регистрируйтесь ",
      text: "и загружайте чеки",
    },

    {
      image: <IconsStepsRoyals status={3} />,
      index: 3,
      firstWord: "Копите роялсы*",
      text: "",
      footnote: "*Роялс - наш внутренний бонус",
    },

    {
      image: <IconsStepsRoyals status={4} />,
      index: 4,
      firstWord: "Обменивайте ",
      text: <>их на подарки от Royal&nbsp;Canin и&nbsp;Зоозавр</>,
    },

    {
      image: <IconsStepsRoyals status={5} />,
      index: 5,
      firstWord: "Участвуйте ",
      text: "в розыгрыше призов!",
    },
  ];

  return (
    <s.Hero className={className}>
      <s.BannerStyles
        title={titleBanner}
        subTitle={subTitleBanner}
        extraText={extraTextBanner}
        imgPath375Webp={imgBanner375Webp}
        imgPath375_2xWebp={imgBanner375_2xWebp}
        imgPath375={imgBanner375}
        imgPath375_2x={imgBanner375_2x}
        imgPath1024Webp={imgBanner1024Webp}
        imgPath1024_2xWebp={imgBanner1024_2xWebp}
        imgPath1024={imgBanner1024}
        imgPath1024_2x={imgBanner1024_2x}
        imgPath1440={imgBanner1440}
        imgPath1440_2x={imgBanner1440_2x}
        imgPath1440Webp={imgBanner1440Webp}
        imgPath1440_2xWebp={imgBanner1440_2xWebp}
        altText={altTextImgBanner}
      />

      <s.BonusMechanicsStyles
        steps={stepsRoyalsListMock}
        buttonText={buttonText}
        buttonType={buttonTypeBonus}
        buttonIcon={buttonIconUpload}
        onClickButtonUpload={onClickButtonUploadHero}
      />
    </s.Hero>
  );
};
