import React, {FC} from "react";
import * as s from "./Faq-styles";
import {ButtonLinksList} from "../../atom";
import {dataQuestion} from "../../../../features/_modals/modal-frequently-asked-question/config";

type FaqType = {
  className?: string;
  id?: string;
  onClickButton: (title: string) => () => void;
};
export const Faq: FC<FaqType> = ({className, id, onClickButton}) => {
  return (
    <s.Faq className={className} id={id}>
      <s.Title>Часто задаваемые вопросы</s.Title>

      <s.List>
        <s.Item>
          <ButtonLinksList
            title={"Общие вопросы"}
            buttons={[
              {
                label: dataQuestion["Что такое «Здоровый питомец»?"].title,
              },
              {
                label:
                dataQuestion["Чеки принимаются только из Зоозавра?"].title,
              },
              {
                label:
                dataQuestion["Какой ассортимент участвует в акции?"].title,
              },
              {
                label: dataQuestion["Сколько будет действовать акция?"].title,
              },
              {
                label: dataQuestion["В каких городах действует акция?"].title,
              },
              {
                label:
                dataQuestion[
                  "Куда обратиться, если у меня есть вопросы или уточнения по программе?"
                  ].title,
              },
            ]}
            onClickButtonLink={onClickButton}
          />
        </s.Item>

        <s.Item>
          <ButtonLinksList
            title={"Баланс"}
            buttons={[
              {
                label: dataQuestion["Что такое роялс?"].title,
              },
              {
                label: dataQuestion["Как копить роялсы?"].title,
              },
              {
                label: dataQuestion["Могут ли обнулиться мои роялсы?"].title,
              },
            ]}
            onClickButtonLink={onClickButton}
          />
        </s.Item>

        <s.Item>
          <ButtonLinksList
            title={"Подарки и чеки"}
            buttons={[
              {
                label: dataQuestion["Какие подарки меня ждут?"].title,
              },
              {
                label:
                dataQuestion["Сколько чеков в день я могу загрузить?"].title,
              },
              {
                label: dataQuestion["Как мне получить мой подарок?"].title,
              },
            ]}
            onClickButtonLink={onClickButton}
          />
        </s.Item>
      </s.List>
    </s.Faq>
  );
};
