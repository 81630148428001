import { AxiosResponse } from "axios";
import { useFormik } from "formik";
import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { esAuth } from "../../../entities";
import {
  setAuthorization,
  SignInRequestInterface,
} from "../../../entities/auth/model";
import { ValidationFormSignIn } from "../config/validation/validation-form-signin";
import bgImage1440 from "../../../shared/config/assets/images/sign-in-bg-1440.png";
import bgImage1440_2x from "../../../shared/config/assets/images/sign-in-bg-1440@2x.png";
import bgImage1024 from "../../../shared/config/assets/images/sign-in-bg-1024.png";
import bgImage1024_2x from "../../../shared/config/assets/images/sign-in-bg-1024@2x.png";
import bgImage1440Webp from "../../../shared/config/assets/images/sign-in-bg-1440.webp";
import bgImage1440_2xWebp from "../../../shared/config/assets/images/sign-in-bg-1440@2x.webp";
import bgImage1024Webp from "../../../shared/config/assets/images/sign-in-bg-1024.webp";
import bgImage1024_2xWebp from "../../../shared/config/assets/images/sign-in-bg-1024@2x.webp";

import * as s from "./Sign-in-styles";
import { useAppDispatch, useScrollToTop } from "../../../shared/lib";
import { breakpoints, pages } from "../../../shared/config";

type SignInType = {};

export type InitialValuesFormikSignInType = {
  email: string;
  password: string;
  isRemember: boolean;
};

export const SignIn: FC<SignInType> = ({}) => {
  useScrollToTop();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      isRemember: false,
    },

    validationSchema: ValidationFormSignIn,

    onSubmit: async (body: InitialValuesFormikSignInType): Promise<void> => {
      try {
        const response: AxiosResponse<SignInRequestInterface> =
          await esAuth.api.authApi.sign_in({
            email: body.email,
            password: body.password,
          });

        const responseJSON = JSON.stringify(response.data);
        localStorage.setItem("token", responseJSON);
        dispatch(setAuthorization(true));
        dispatch(esAuth.model.getUserThunk());
        navigate(pages.personalAreaProfile.link);
      } catch (e) {
        if ((e as XMLHttpRequest).response.status === 401)
          formik.setFieldError("email", "Неправильный логин или пароль");
        formik.setFieldError("password", "Неправильный логин или пароль");
      }
    },
  });

  return (
    <s.SignIn>
      <picture>
        <source
          srcSet={`${bgImage1024Webp} 1x, ${bgImage1024_2xWebp} 2x`}
          media={`(max-width: ${breakpoints.XL})`}
          type="image/webp"
        />

        <source
          srcSet={`${bgImage1024} 1x, ${bgImage1024_2x} 2x`}
          media={`(max-width: ${breakpoints.XL})`}
        />

        <s.ImageBg
          src={bgImage1440}
          srcSet={`${bgImage1440Webp} 1x, ${bgImage1440_2xWebp} 2x, ${bgImage1440} 1x, ${bgImage1440_2x} 2x`}
          alt={"Британские коты"}
        />
      </picture>

      <s.Title>Авторизация</s.Title>

      <s.FormSignIn formik={formik} />

      <s.ForgottenPassword href={"/Auth/Recover-password"}>
        Не помню пароль
      </s.ForgottenPassword>
      <s.ToRegistration href={"/Auth/Registration"}>
        Нет аккаунта? <span>Зарегистрироваться</span>
      </s.ToRegistration>
    </s.SignIn>
  );
};
