import React, { FC } from "react";
import { Outlet } from "react-router-dom";
import * as s from "./Recover-password-styles";

type RecoverPasswordType = {};

export const RecoverPassword: FC<RecoverPasswordType> = ({}) => {
  return (
    <s.RecoverPassword>
      <Outlet />
    </s.RecoverPassword>
  );
};
