import React, { FC, ReactNode } from "react";
import * as s from "./Notification-bar-styles";

type NotificationBarType = {
  className?: string;
  text: string;
  buttonText?: string;
  icon: ReactNode;
  onClickButton?: () => void;
  onClickButtonIcon: () => void;
};

export const NotificationBar: FC<NotificationBarType> = ({
  className,
  text,
  buttonText,
  icon,
  onClickButton,
  onClickButtonIcon,
}) => {
  return (
    <s.NotificationBar className={className}>
      <s.Wrap>
        <s.Text>{text}</s.Text>
        {onClickButton && (
          <s.Button type={"button"} onClick={onClickButton}>
            {buttonText}
          </s.Button>
        )}
      </s.Wrap>

      <s.ButtonClose
        onClickButtonIcon={onClickButtonIcon}
        icon={icon}
        type={"button"}
      />
    </s.NotificationBar>
  );
};
