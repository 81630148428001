import styled from "styled-components";
import { Animation, Status } from "../../atom";
import { breakpoints } from "../../../config";

const Receipt = styled(Animation)`
  display: flex;
  align-items: center;
  padding: 16px 0;
  border-bottom: 1px solid var(--inactive);

  @media (max-width: ${breakpoints.MD}) {
    justify-content: space-between;
  }
`;

const Date = styled.div`
  font: var(--font-15);
  color: var(--interface-dark);
  display: flex;
  align-items: center;
  gap: 8px;

  @media (max-width: ${breakpoints.MD}) {
    align-items: end;
  }
`;

const Bonus = styled.div`
  font: var(--font-16);
  letter-spacing: -0.36px;
  display: flex;
  align-items: center;
  margin-right: 48px;
  margin-left: auto;

  p {
    margin-right: 8px;
  }
`;

const StatusCheck = styled(Status)`
  width: 166px;

  @media (max-width: ${breakpoints.MD}) {
    width: 144px;
  }
`;

export { Receipt, Date, Bonus, StatusCheck };
