import styled, { css } from "styled-components";
import { Avatar } from "../../atom";
import { breakpoints } from "../../../config";

type CardPetType = {
  $dateEnd: string;
};

const CardPet = styled.article`
  padding: 32px 25px 32px 28px;
  border-radius: 32px;
  border: 1px solid var(--interface);

  @media (max-width: ${breakpoints.XXL}) {
    padding: 32px 16px 22px 32px;
  }

  @media (max-width: ${breakpoints.XL}) {
    padding: 32px 14px 24px 24px;
    border-radius: 28px;
  }

  @media (max-width: ${breakpoints.LG}) {
    padding: 24px 13px 20px 17px;
    border-radius: 32px;
  }

  @media (max-width: ${breakpoints.MD}) {
    padding: 24px 28px 24px 17px;
  }
`;

const Wrap = styled.div<CardPetType>`
  position: relative;
  display: flex;
  align-items: center;
  gap: 16px;
  padding-bottom: 28px;
  margin-bottom: 24px;
  overflow: hidden;

  @media (max-width: ${breakpoints.XXL}) {
    margin-bottom: 20px;
  }

  @media (max-width: ${breakpoints.XL}) {
    gap: 12px;
    padding-bottom: 32px;
    margin-bottom: 24px;
  }

  @media (max-width: ${breakpoints.LG}) {
    gap: 16px;
    padding-bottom: 12px;
    margin-bottom: 16px;
  }

  &::before {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: #ddd;

    @media (max-width: ${breakpoints.XXL}) {
      right: 24px;
      width: 89%;
    }

    @media (max-width: ${breakpoints.LG}) {
      right: 9px;
      width: 95%;
    }

    @media (max-width: ${breakpoints.MD}) {
      right: 3px;
      width: 95%;
    }
  }
`;

const WrapAvatar = styled.div`
  width: 116px;
  height: 116px;
  margin-left: 11px;

  @media (max-width: ${breakpoints.XXL}) {
    margin: 0;
  }

  @media (max-width: ${breakpoints.XL}) {
    width: 90px;
    height: 90px;
  }

  @media (max-width: ${breakpoints.LG}) {
    width: 120px;
    height: 120px;
  }
`;

const AvatarPet = styled(Avatar)`
  margin: auto;

  @media (max-width: ${breakpoints.XL}) {
    width: 78px;
    height: 78px;
  }

  @media (max-width: ${breakpoints.LG}) {
    width: 103px;
    height: 103px;
  }
`;

const Content = styled.div``;

const Date = styled.p`
  margin-bottom: 5px;
  font: var(--font-13);
  color: var(--text);
`;

const BioPet = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  @media (-webkit-min-device-pixel-ratio: 2),
    (min--moz-device-pixel-ratio: 2),
    (-o-min-device-pixel-ratio: 2/1),
    (min-resolution: 2dppx),
    (min-resolution: 192dpi) {
    align-items: flex-end;
  }

  & svg {
    width: 32px;
    height: 32px;
    flex: 0 0 32px;
    color: var(--iconography);
  }
`;

const Name = styled.h2`
  display: flex;
  align-items: center;
  gap: 4px;
  font: var(--font-6);
  color: var(--interface-dark);

  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  overflow-wrap: anywhere;
  word-break: break-all;
`;

const TextCss = css`
  font: var(--font-14);
  color: var(--text);
`;

const Breed = styled.p`
  ${TextCss};
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  overflow-wrap: anywhere;
`;

const Text = styled.p`
  ${TextCss};
  margin-left: 17px;

  @media (max-width: ${breakpoints.XXL}) {
    margin-left: 8px;
  }

  @media (max-width: ${breakpoints.XL}) {
    margin-left: 0;
  }

  @media (max-width: ${breakpoints.LG}) {
    margin-left: 6px;
  }

  @media (max-width: ${breakpoints.MD}) {
    max-width: unset;
  }
`;

const Caption = styled.p`
  ${TextCss};
  max-width: 350px;
  margin-left: 17px;

  @media (max-width: ${breakpoints.XXL}) {
    margin-left: 8px;
  }

  @media (max-width: ${breakpoints.XL}) {
    margin-left: 0;
  }

  @media (max-width: ${breakpoints.LG}) {
    margin-left: 6px;
  }
`;

const Span = styled.span`
  color: var(--brand-1);
`;

export {
  CardPet,
  Wrap,
  WrapAvatar,
  AvatarPet,
  Content,
  Date,
  BioPet,
  Name,
  Breed,
  Caption,
  Text,
  Span,
};
