import React, { ChangeEvent, FC, ReactNode } from "react";
import * as s from "./Card-pet-styles";
import imageCat from "./../../../config/assets/images/icons/avatar-cat.svg";
import imageDog from "./../../../config/assets/images/icons/avatar-dog.svg";

type CardPetType = {
  className?: string;
  src: string;
  alt: string;
  breed_class: string;
  date: string;
  name: string;
  genderIcon: ReactNode;
  breed: string;
  dateEnd: string;
  onChangeInput: (e: ChangeEvent<HTMLInputElement>) => void;
};

export const CardPet: FC<CardPetType> = ({
  className,
  src,
  alt,
  breed_class,
  date,
  name,
  genderIcon,
  breed,
  dateEnd,
  onChangeInput,
}) => {
  return (
    <s.CardPet className={className}>
      <s.Wrap $dateEnd={dateEnd}>
        <s.WrapAvatar>
          <s.AvatarPet
            src={src ? src : breed_class === "cat" ? imageCat : imageDog}
            alt={alt}
            onChangeInput={onChangeInput}
          />
        </s.WrapAvatar>

        <s.Content>
          <s.Date>{date}</s.Date>

          <s.BioPet>
            <s.Name title={name}>{name}</s.Name>

            {genderIcon}
          </s.BioPet>

          <s.Breed title={breed}>{breed}</s.Breed>
        </s.Content>
      </s.Wrap>

      {dateEnd ? (
        <s.Text>
          Переход на рацион для взрослого питомца через&nbsp;
          <s.Span>{dateEnd}</s.Span>
        </s.Text>
      ) : (
        <s.Caption>
          Подбирайте рацион для взрослого питомца, учитывая особенности здоровья{" "}
        </s.Caption>
      )}
    </s.CardPet>
  );
};
