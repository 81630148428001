import * as yup from "yup";

export const ValidationFormRegistrationMini = yup.object().shape({
  first_name: yup
    .string()
    .trim()
    .required("Это поле обязательно для заполнения"),

  last_name: yup
    .string()
    .trim()
    .required("Это поле обязательно для заполнения"),

  email: yup
    .string()
    .required("Это поле обязательно для заполнения")
    .email("Введите корректную почту"),
});
