import React from "react";
import { type RouteObject } from "react-router-dom";
import { sd } from "../../../../shared";
import { RecoverPasswordEmail } from "../../ui/Recover-password-email";
import {pages} from "../../../../shared/config";

export const router: RouteObject = {
  path: pages.recoverPasswordEmail.router,
  element: <RecoverPasswordEmail />,
};
