import React, { FC } from "react";

export const IconMale: FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox={"0 0 24 24"}
    {...props}
  >
    <path
      d="M18.5028 5H16.0028C15.8702 5 15.743 5.05268 15.6492 5.14645C15.5554 5.24021 15.5028 5.36739 15.5028 5.5C15.5028 5.63261 15.5554 5.75979 15.6492 5.85355C15.743 5.94732 15.8702 6 16.0028 6H17.7978L14.8728 8.92C13.6906 7.92096 12.168 7.41742 10.6233 7.5146C9.07853 7.61178 7.6311 8.30217 6.58343 9.4415C5.53576 10.5808 4.9689 12.081 5.00132 13.6284C5.03373 15.1759 5.6629 16.6509 6.75736 17.7454C7.85182 18.8399 9.32689 19.469 10.8744 19.5014C12.4218 19.5339 13.9219 18.967 15.0613 17.9193C16.2006 16.8717 16.891 15.4242 16.9882 13.8795C17.0853 12.3347 16.5818 10.8122 15.5828 9.63L18.5028 6.705V8.5C18.5028 8.63261 18.5554 8.75979 18.6492 8.85355C18.743 8.94732 18.8702 9 19.0028 9C19.1354 9 19.2625 8.94732 19.3563 8.85355C19.4501 8.75979 19.5028 8.63261 19.5028 8.5V6C19.5028 5.73478 19.3974 5.48043 19.2099 5.29289C19.0223 5.10536 18.768 5 18.5028 5ZM14.5378 17.035C13.8386 17.7344 12.9476 18.2108 11.9777 18.4038C11.0078 18.5969 10.0023 18.4979 9.08863 18.1195C8.17491 17.7411 7.39393 17.1003 6.84445 16.278C6.29498 15.4557 6.0017 14.489 6.0017 13.5C6.0017 12.511 6.29498 11.5443 6.84445 10.722C7.39393 9.89971 8.17491 9.25885 9.08863 8.88046C10.0023 8.50206 11.0078 8.40314 11.9777 8.59618C12.9476 8.78923 13.8386 9.26559 14.5378 9.965C15.4751 10.9026 16.0017 12.1742 16.0017 13.5C16.0017 14.8258 15.4751 16.0974 14.5378 17.035Z"
      fill="currentColor"
    />
  </svg>
);
