import styled, { css } from "styled-components";
import { IconArrowRow, IconClose as IconCloseStyled } from "../../../shared";

type IconCloseType = {
  $isError: boolean;
};

type IconType = {
  $isActive: boolean;
  $isError: boolean;
};

type SelectSearchFormikType = {
  $isActive: boolean;
};

const SelectSearchFormik = styled.div<SelectSearchFormikType>`
  ${(props) =>
    props.$isActive &&
    css`
      position: relative;
      z-index: 10000000;
    `}
`;

const IconClose = styled(IconCloseStyled)<IconCloseType>`
  display: flex;
  width: 20px;
  height: 20px;
  color: var(--interface);

  ${(props) =>
    props.$isError &&
    css`
      color: var(--pink-for-svg);
    `}
`;

const IconArrow = styled(IconArrowRow)<IconType>`
  display: flex;
  width: 24px;
  height: 24px;
  color: var(--iconography);
  will-change: transform;
  transition: transform 300ms ease;

  ${(props) =>
    props.$isActive
      ? css`
          transform: rotate(90deg);
        `
      : css`
          transform: rotate(-90deg);
        `};

  ${(props) =>
    props.$isError &&
    css`
      color: var(--error);
    `}
`;

export {SelectSearchFormik, IconArrow, IconClose};
