import * as React from "react";
import { FC } from "react";

export const IconCheck: FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox={"0 0 20 20"}
    fill="currentColor"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M13.818 5.09H6.182A1.09 1.09 0 0 0 5.09 6.183v7.636c0 .603.488 1.091 1.09 1.091h7.637a1.09 1.09 0 0 0 1.091-1.09V6.181a1.09 1.09 0 0 0-1.09-1.091ZM6.182 4A2.182 2.182 0 0 0 4 6.182v7.636C4 15.023 4.977 16 6.182 16h7.636A2.182 2.182 0 0 0 16 13.818V6.182A2.182 2.182 0 0 0 13.818 4H6.182Z"
      clipRule="evenodd"
    />
  </svg>
);
