export type RecoveryRequestCodeBodyType = {
  email: string;
  code: number;
};

export type RecoveryRequestNewPasswordBodyType = {
  email: string;
  code: number;
  password: string;
};

export type RecoveryStateType = {
  error: string | null;
  code?: number | null;
};

export const initialStateRecovery: RecoveryStateType = {
  error: null,
  code: null,
};