import React, { FC } from "react";

export const IconProfile: FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox={"0 0 24 24"}
    {...props}
  >
    <rect width={24} height={24} fill="#D7D7D7" rx={12} />
    <path
      fill="#767676"
      d="M16.167 12.9a6.066 6.066 0 0 0-2.508-1.47 3.436 3.436 0 1 0-3.785 0 5.752 5.752 0 0 0-4.124 5.383v.773a1.288 1.288 0 0 0 1.289 1.289h9.45a1.289 1.289 0 0 0 1.29-1.289v-.94a5.225 5.225 0 0 0-1.612-3.747Zm-6.98-4.335a2.578 2.578 0 1 1 5.155 0 2.578 2.578 0 0 1-5.155 0Zm7.732 9.021a.43.43 0 0 1-.43.43H7.04a.43.43 0 0 1-.43-.43v-.773c0-2.607 2.268-4.764 5.056-4.811a5.426 5.426 0 0 1 3.9 1.507 4.39 4.39 0 0 1 1.354 3.136v.941Z"
    />
  </svg>
);
