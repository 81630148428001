import styled, { css } from "styled-components";
import { breakpoints } from "../../../shared/config";
import { Input } from "../../../shared/ui/atom/input/Input-styles";
import { FieldTextFormik } from "../../../features";
import { Button } from "../../../shared";
import { Error } from "../../../shared/ui/molecules/field-input/Field-input-styles";
import registrationFormShape1440 from "../../../shared/config/assets/images/registration-form-shape-1440.svg";
import registrationFormShape1280 from "../../../shared/config/assets/images/registration-form-shape-1280.svg";
import registrationFormShape1024 from "../../../shared/config/assets/images/registration-form-shape-1024.svg";
import registrationFormShape768 from "../../../shared/config/assets/images/registration-form-shape-768.svg";

type InputTextType = {
  $isError: boolean;
};

const FormRegistrationMini = styled.section`
  padding: 50px;
  background-color: var(--brand-1);
  background-image: url(${registrationFormShape1440});
  background-repeat: no-repeat;
  background-position: right -40px bottom;
  background-size: contain;
  border-radius: 36px;

  @media (max-width: ${breakpoints.XXL}) {
    background-image: url(${registrationFormShape1280});
  }

  @media (max-width: ${breakpoints.XL}) {
    background-image: url(${registrationFormShape1024});
  }

  @media (max-width: ${breakpoints.LG}) {
    padding-left: 36px;
    background-image: url(${registrationFormShape768});
    background-position: right bottom;
    background-size: auto;
  }

  @media (max-width: ${breakpoints.MD}) {
    padding: 40px 24px;
    background-image: none;
  }
`;

const Title = styled.h2`
  font: var(--font-3);
  letter-spacing: -1.68px;
  color: var(--brand-3);
  margin-bottom: 48px;

  @media (max-width: ${breakpoints.MD}) {
    margin-bottom: 32px;
  }
`;

const Form = styled.form`
  max-width: 613px;

  @media (max-width: ${breakpoints.XXL}) {
    max-width: 775px;
  }

  @media (max-width: ${breakpoints.XL}) {
    max-width: 86%;
  }

  @media (max-width: ${breakpoints.LG}) {
    max-width: 71%;
  }

  @media (max-width: ${breakpoints.MD}) {
    max-width: none;
  }
`;

const SubTitle = styled.h3`
  margin-bottom: 24px;
  font: var(--font-9);
  color: var(--brand-3);

  @media (max-width: ${breakpoints.MD}) {
    margin-bottom: 20px;
  }
`;

const List = styled.ul`
  display: grid;
  grid-auto-rows: var(--minmax);
  gap: 12px;
  max-width: 450px;

  @media (max-width: ${breakpoints.XL}) {
    max-width: 55%;
  }

  @media (max-width: ${breakpoints.LG}) {
    max-width: 94%;
  }

  @media (max-width: ${breakpoints.MD}) {
    max-width: none;
    gap: 8px;
  }
`;

const Item = styled.li``;

const InputCss = css`
  max-width: var(--input-width);

  ${Input} {
    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px var(--brand-4) inset;
    }
  }
`;

const WrapInput = styled.div`
  display: grid;
  grid-template-columns: var(--input-width) 1fr;
  align-items: center;
  gap: 16px;

  @media (max-width: ${breakpoints.MD}) {
    grid-template-columns: 1fr;
  }
`;

const InputText = styled(FieldTextFormik)<InputTextType>`
  ${InputCss}
  ${(props) =>
    props.$isError &&
    css`
      ${Input} {
        background-color: var(--pink) !important;
      }
    `}
  ${Error} {
    color: var(--pink);
  }
`;
const WrapButton = styled.div`
  display: flex;
  align-items: center;
  gap: 21px;
  margin-top: 48px;
  margin-bottom: 12px;

  @media (max-width: ${breakpoints.XL}) {
    margin-top: 54px;
  }

  @media (max-width: ${breakpoints.LG}) {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 64px;
  }

  @media (max-width: ${breakpoints.MD}) {
    margin-top: 36px;
  }
`;

const ButtonRegistration = styled(Button)`
  max-width: 288px;

  @media (max-width: ${breakpoints.XXL}) {
    max-width: 450px;
  }

  @media (max-width: ${breakpoints.XL}) {
    max-width: 55%;
  }

  @media (max-width: ${breakpoints.LG}) {
    max-width: 94%;
  }

  @media (max-width: ${breakpoints.MD}) {
    max-width: none;
  }
`;

const TextWrapButton = styled.p`
  font: var(--font-19);
  color: var(--brand-3);
  max-width: 304px;

  @media (max-width: ${breakpoints.LG}) {
    max-width: none;
  }

  @media (max-width: ${breakpoints.MD}) {
    max-width: 89%;
  }
`;

const LinkWrapButton = styled.a`
  font: var(--font-17);
`;

const RedirectRegistrationText = styled.p`
  font: var(--font-19);
  color: var(--brand-3);
`;

const RedirectRegistrationButton = styled.button`
  font: var(--font-17);
  color: currentColor;
`;

export {
  FormRegistrationMini,
  Title,
  Form,
  SubTitle,
  List,
  Item,
  WrapInput,
  InputText,
  WrapButton,
  ButtonRegistration,
  TextWrapButton,
  LinkWrapButton,
  RedirectRegistrationText,
  RedirectRegistrationButton,
};
