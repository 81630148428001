import { useFormik } from "formik";
import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  sendRecoveryCodeThunk,
  sendRecoveryEmailThunk,
} from "../../../entities/recovery/model";
import * as s from "./Recover-password-code-styles";
import { useAppDispatch, useScrollToTop } from "../../../shared/lib";
import { ValidationFormRecoverPasswordCode } from "../config";
import { pages } from "../../../shared/config";

type RecoverPasswordCodeProps = {};

export type InitialValuesFormikRecoverPasswordCodeType = {
  code: string;
};

export const RecoverPasswordCode: FC<RecoverPasswordCodeProps> = () => {
  useScrollToTop();

  const [seconds, setSeconds] = useState(0);
  const dispatch = useAppDispatch();
  const params = useParams<{ email: string }>();
  const email = params?.email;
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      code: "",
    },

    validationSchema: ValidationFormRecoverPasswordCode,

    onSubmit: async (values: InitialValuesFormikRecoverPasswordCodeType) => {
      if (values.code?.toString().length >= 7) {
        const response = await dispatch(
          sendRecoveryCodeThunk({
            email: email || "",
            code: Number(values.code),
          })
        );

        if (response.meta.requestStatus === "fulfilled") {
          navigate(
            pages.recoverPasswordReset.link + "/" + email + "/" + values.code
          );
        } else {
          if (!!response?.payload) {
            formik.setFieldError("code", response.payload as string);
          }
        }
      }
    },
  });

  const onSendSms = () => {
    setSeconds(60);
  };

  const routeToRecoverEmail = () => {
    navigate(pages.recoverPasswordEmail.link + "/" + email);
  };

  const resendCode = () => {
    dispatch(sendRecoveryEmailThunk({ email: email || "" }));
    onSendSms();
  };

  useEffect(() => {
    if (seconds !== 0) {
      setTimeout(() => {
        setSeconds((value) => value - 1);
      }, 1000);
    }
  }, [seconds]);

  useEffect(() => {
    if (!email) routeToRecoverEmail();
    setSeconds(60);
  }, [email]);

  useEffect(() => {
    if (formik.values.code) {
      const idTimeout = setTimeout(() => {
        formik.handleSubmit();
      }, 300);

      return () => clearTimeout(idTimeout);
    }
  }, [formik.values.code]);

  return (
    <s.RecoverPasswordCode>
      <s.Title>Восстановление пароля</s.Title>
      <s.Subtitle>Введите код из письма</s.Subtitle>
      <s.InfoText>
        Мы отправили код на {email}
        <s.InfoTextChange onClick={routeToRecoverEmail}>
          Изменить
        </s.InfoTextChange>
      </s.InfoText>

      <s.FormRecoverPasswordCode onSubmit={formik.handleSubmit}>
        <s.InputText
          type={"number"}
          label={"Введите код"}
          {...formik.getFieldProps("code")}
          {...formik.getFieldMeta("code")}
          {...formik.getFieldHelpers("code")}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            formik.setFieldValue(
              "code",
              e.currentTarget.value.toString().slice(0, 7)
            );
          }}
        />
      </s.FormRecoverPasswordCode>

      {seconds !== 0 ? (
        <s.ResendCode>Отправить письмо еще раз через {seconds}</s.ResendCode>
      ) : (
        <s.SendRepeat type={"button"} onClick={resendCode}>
          Отправить письмо повторно
        </s.SendRepeat>
      )}
    </s.RecoverPasswordCode>
  );
};
