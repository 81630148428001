import styled from "styled-components";
import { breakpoints } from "../../../config";
import duoFeedingShape from "../../../config/assets/images/duo-feeding-shape.svg";

const DuoFeeding = styled.section`
  display: flex;
  flex-direction: column;
  gap: 40px;
  overflow: visible;

  @media (max-width: ${breakpoints.XL}) {
    gap: 58px;
  }

  @media (max-width: ${breakpoints.LG}) {
    gap: 53px;
  }

  @media (max-width: ${breakpoints.MD}) {
    gap: 24px;
  }
`;

const HeadText = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${breakpoints.MD}) {
    max-width: 90%;
    align-items: flex-start;
    flex-direction: column;
    gap: 12px;
  }

  &:after {
    content: "";
    position: absolute;
    top: -104px;
    right: -32px;
    width: 490px;
    height: 395px;
    background-image: url(${duoFeedingShape});
    background-repeat: no-repeat;
    z-index: -1;

    @media (max-width: ${breakpoints.XXL}) {
      top: -105px;
      right: -30px;
    }

    @media (max-width: ${breakpoints.XL}) {
      background-size: contain;
      right: -97px;
      height: 385px;
    }

    @media (max-width: ${breakpoints.LG}) {
      top: -90px;
      right: -160px;
    }

    @media (max-width: ${breakpoints.MD}) {
      display: none;
    }
`;

const Title = styled.h2`
  font: var(--font-3);
  letter-spacing: -1.68px;
  color: var(--brand-1);
  max-width: 509px;

  @media (max-width: ${breakpoints.XL}) {
    max-width: 415px;
  }

  @media (max-width: ${breakpoints.MD}) {
    max-width: 87%;
  }
`;

const Text = styled.p`
  font: var(--font-12);
  color: var(--brand-1);
  max-width: 291px;
  margin-right: 21px;

  @media (max-width: ${breakpoints.XL}) {
    max-width: 263px;
    margin-right: 0;
  }

  @media (max-width: ${breakpoints.LG}) {
    max-width: 209px;
  }

  @media (max-width: ${breakpoints.MD}) {
    max-width: none;
    color: var(--iconography);
    margin-right: 0;
  }
`;

export { DuoFeeding, HeadText, Title, Text };
