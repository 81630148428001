import styled from "styled-components";
import {Button, ModalBodyRight} from "../../../../shared";
import {breakpoints} from "../../../../shared/config";
import {BodyAnimation} from "../../../../shared/ui/molecules/modal-body-right/Modal-body-right-styles";

const ModalAddAnimal = styled.div``;

const Modal = styled(ModalBodyRight)`
  ${BodyAnimation} {

    @media (max-width: ${breakpoints.MD}) {
      padding-top: 65px;
    }
  }
`;

const Title = styled.h2`
  margin-bottom: 40px;
  font: var(--font-8);
  color: var(--interface-dark);

  @media (max-width: ${breakpoints.XL}) {
    margin-bottom: 32px;
  }
`;

const Form = styled.form``;

const ButtonSubmit = styled(Button)`
  max-width: 320px;
  margin-top: 62px;

  @media (max-width: ${breakpoints.XL}) {
    margin-top: 36px;
    max-width: 100%;
  }

  @media (max-width: ${breakpoints.LG}) {
    margin-top: 40px;
  }
`;

export {ModalAddAnimal, Modal, Title, Form, ButtonSubmit};
