import styled, { css } from "styled-components";
import { breakpoints, StatusProperty } from "../../../config";

type StatusType = {
  $status:
    | StatusProperty.DEFAULT
    | StatusProperty.IN_PROCESS
    | StatusProperty.SUCCESS
    | StatusProperty.ERROR
    | StatusProperty.COMMON;
};

const Status = styled.div<StatusType>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px 16px;
  font: var(--font-14);
  text-align: center;
  height: 42px;
  border-radius: 30px;

  @media (max-width: ${breakpoints.MD}) {
    padding: 8px 12px;
    gap: 6px;
    height: unset;
    min-height: 35px;
  }

  & svg {
    position: relative;
    width: 24px;
    height: 24px;
    cursor: pointer;

    @media (max-width: ${breakpoints.MD}) {
      width: 16px;
      height: 16px;
    }
  }

  ${(props) =>
    props.$status === StatusProperty.DEFAULT &&
    css`
      color: var(--brand-1);
      background-color: var(--pink);
    `};

  ${(props) =>
    props.$status === StatusProperty.IN_PROCESS &&
    css`
      color: var(--warning);
      background-color: var(--light-orange);
    `};

  ${(props) =>
    props.$status === StatusProperty.SUCCESS &&
    css`
      color: var(--success);
      background-color: var(--light-green);
    `};

  ${(props) =>
    props.$status === StatusProperty.ERROR &&
    css`
      color: var(--error);
      background-color: var(--pink);
    `};

  ${(props) =>
    props.$status === StatusProperty.COMMON &&
    css`
      color: var(--brand-2);
      background-color: var(--inactive);
    `};
`;

export { Status };
