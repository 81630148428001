import React, { FC } from "react";
import * as s from "./Button-stopwatch-styles";

type ButtonStopwatchType = {
  className?: string;
  type: "button" | "reset" | "submit";
  text: string;
  time: string;
  disabled?: boolean;
  onClickButton: () => void;
};

export const ButtonStopwatch: FC<ButtonStopwatchType> = ({
  className,
  type,
  text,
  time,
  disabled,
  onClickButton,
}) => {
  return (
    <s.ButtonStopwatch
      className={className}
      type={type}
      disabled={disabled}
      $isDisabled={disabled}
      onClick={disabled ? () => {} : onClickButton}
    >
      {text}

      <s.Timer> {time}</s.Timer>
    </s.ButtonStopwatch>
  );
};
