import React, { FC } from "react";
import * as s from "./Button-text-styles";

type ButtonTextType = {
  className?: string;
  type: "button" | "reset" | "submit";
  text: string;
  disabled?: boolean;
  onClickButtonText: () => void;
};

export const ButtonText: FC<ButtonTextType> = ({
  className,
  type,
  text,
  disabled,
  onClickButtonText,
}) => {
  return (
    <s.ButtonText
      className={className}
      type={type}
      disabled={disabled}
      onClick={disabled ? () => {} : onClickButtonText}
    >
      {text}
    </s.ButtonText>
  );
};
