import styled from "styled-components";
import { IconAdd } from "../_icons";

const IconPlus = styled(IconAdd)`
  position: absolute;
  left: 50%;
  bottom: -12px;
  transform: translateX(-50%);
  width: 24px;
  height: 24px;
  color: var(--text);
  border-radius: 50%;
  transition: border 300ms ease;
`;

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
  border: 2px dashed transparent;
  transition: border 300ms ease;
`;

const Avatar = styled.label`
  position: relative;
  display: block;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  cursor: pointer;
  will-change: transform;
  transition: transform 300ms ease, border 300ms ease;

  &:hover {
    ${Image} {
      border: 2px dashed var(--text);
    }

    ${IconPlus} {
      border: 2px dashed var(--text);
    }
  }

  &:active {
    transform: scale(0.98);
  }
`;

const Input = styled.input`
  position: fixed;
  top: -1px;
  left: -1px;
  width: 0;
  height: 0;
  padding: 0;
  margin: -1px;
  visibility: hidden;
  opacity: 0;
`;

export {Avatar, Input, Image, IconPlus};
