import styled from "styled-components";
import { breakpoints } from "../../../config";

const ButtonLinksList = styled.div`
  max-width: 366px;

  @media (max-width: ${breakpoints.XL}) {
    max-width: 278px;
  }

  @media (max-width: ${breakpoints.LG}) {
    max-width: none;
  }

  @media (max-width: ${breakpoints.MD}) {
    max-width: 90%;
  }
`;

const Title = styled.h3`
  font: var(--font-8);
  letter-spacing: -0.48px;
  color: var(--interface-dark);
  margin-bottom: 24px;

  @media (max-width: ${breakpoints.LG}) {
    margin-bottom: 16px;
  }
`;

const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media (max-width: ${breakpoints.LG}) {
    gap: 10px;
  }
`;

const Item = styled.li``;

const ButtonLink = styled.button`
  font: var(--font-11);
  color: var(--text);
  text-align: start;
  transition: ease-in-out 300ms;

  &:hover {
    color: var(--interface-dark);
  }
`;

export { ButtonLinksList, Title, List, Item, ButtonLink };
