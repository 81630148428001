import styled, { css } from "styled-components";
import { stylesScrollbarMixin } from "../../../config";
import { ButtonIcon as ButtonIconCalendar } from "../../atom";

type ButtonDateType = {
  $isActive: boolean;
};

const Calendar = styled.article`
  max-width: 338px;
  width: 100%;
  background-color: var(--brand-3);
  box-shadow: 0 32px 32px 0 rgba(68, 83, 113, 0.05),
    0 0 32px 0 rgba(68, 83, 113, 0.1);
  border-radius: 24px;
  overflow: hidden;
`;

const Title = styled.h2`
  position: fixed;
  top: -1px;
  left: -1px;
  width: 0;
  height: 0;
  visibility: hidden;
  opacity: 0;
  clip-path: ellipse(0);
`;

const Header = styled.header`
  position: relative;
  padding: 14px 0 12px;
`;

const ButtonIcon = styled(ButtonIconCalendar)`
  position: absolute;
  top: 12px;
  right: 12px;
  width: 16px;
  height: 16px;
`;

const WrapButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

const ListStyle = css`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 17px;
  grid-auto-rows: var(--minmax);
`;

const ContentMonth = styled.div`
  padding: 20px 14px;
`;

const ListMonth = styled.ul`
  ${ListStyle}
`;

const ItemMonth = styled.li``;

const ContentYear = styled.div`
  position: relative;
  padding: 0 14px 10px 14px;
`;

const ListYear = styled.ul`
  max-height: 220px;
  ${ListStyle};
  row-gap: 4px;
  overflow: auto;
  ${stylesScrollbarMixin("4px")};
`;

const ItemYear = styled.li``;

const ButtonDate = styled.button<ButtonDateType>`
  width: 84px;
  padding: 11px 0 10px;
  text-align: center;
  font: var(--font-15);
  color: var(--text);
  border: 1px solid transparent;
  border-radius: 6px;
  will-change: transform;
  transition: transform 300ms ease, color 500ms ease,
    background-color 300ms ease, border 300ms ease;

  ${(props) =>
    props.$isActive &&
    css`
      background-color: var(--brand-1);
      color: var(--brand-3) !important;
    `};

  &:hover {
    border: 1px solid var(--brand-1);
    color: var(--brand-1);
  }

  &:active {
    transform: scale(0.95);
  }

  &:first-letter {
    text-transform: uppercase;
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }
`;

export {
  Calendar,
  Title,
  Header,
  ButtonIcon,
  WrapButton,
  ContentMonth,
  ListMonth,
  ItemMonth,
  ContentYear,
  ListYear,
  ItemYear,
  ButtonDate,
};
