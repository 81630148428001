import React from "react";
import { type RouteObject } from "react-router-dom";
import { sd } from "../../../../shared";
import { Root } from "../../ui/Root";
import {pages} from "../../../../shared/config";

export const router = (children: Array<RouteObject>): RouteObject => {
  return {
    path: pages.root.router,
    element: <Root />,
    children,
  };
};
