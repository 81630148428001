import React, { FC } from "react";
import * as s from "./Modal-exit-styles";
import { ButtonProperty, pages } from "../../../../shared/config";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../../shared/lib";
import { esAuth } from "../../../../entities";
import { ws } from "../../../../widgets";

type ModalExitType = {
  className?: string;
  onShowModal: () => void;
};

export const ModalExit: FC<ModalExitType> = ({ className, onShowModal }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const onExitPage = () => {
    localStorage.removeItem("token");
    dispatch(esAuth.model.setAuthorization(false));
    dispatch(ws.model.setModals({ action: null, content: null }));
    navigate(pages.signIn.link);
  };
  const onNoExitPage = () => {
    dispatch(ws.model.setModals({ action: null, content: null }));
  };

  return (
    <s.ModalExit>
      <s.Modal onClickButtonBg={onShowModal} onClickButtonIcon={onShowModal}>
        <s.Title>Выйти из аккаунта?</s.Title>

        <s.Text>
          Чтобы войти в аккаунт заново, нужно будет авторизоваться
        </s.Text>

        <s.WrapButtons>
          <s.ButtonExit
            property={ButtonProperty.HIGHLIGHTED}
            text={"Да, выйти"}
            type={"button"}
            onClickButton={onExitPage}
          />

          <s.ButtonNoExit
            property={ButtonProperty.SECONDARY}
            text={"Нет, остаться на сайте"}
            type={"button"}
            onClickButton={onNoExitPage}
          />
        </s.WrapButtons>
      </s.Modal>
    </s.ModalExit>
  );
};
