import React, {FC, useState} from "react";
import * as s from "./Form-pets-styles";
import {Animation, IconCat, IconDog, IconFemale, IconMale, IconRoyals,} from "../../../shared";
import {ButtonTagProperty} from "../../../shared/config";
import {FormikProps} from "formik";
import {InitialValuesFormikRegistrationType} from "../../../pages";
import {v4 as uuidv4} from "uuid";
import {AnimalCatAndDogType, AnimalPetType,} from "../../../entities/animal/model";
import {FormPet} from "../../form-pet";

export type PetFormType = AnimalPetType & {
  id: string;
  animal: string;
};

type FormPetsType = {
  className?: string;
  pets: Array<PetFormType>;
  cat: Array<AnimalCatAndDogType>;
  dog: Array<AnimalCatAndDogType>;
  formik: FormikProps<InitialValuesFormikRegistrationType>;
};

export const FormPets: FC<FormPetsType> = ({
                                             className,
                                             formik,
                                             pets,
                                             cat,
                                             dog,
                                           }) => {
  const [animals, setAnimals] = useState<{
    [key: string]: Array<AnimalCatAndDogType>;
  }>({});
  const errors = formik.errors?.pets as Array<PetFormType>;

  const onSetAnimals = (
    animalsArray: Array<AnimalCatAndDogType>,
    id: string
  ) => {
    pets.forEach(() => {
      setAnimals({...animals, [id]: animalsArray});
    });
  };

  const onSendTagAnimal =
    (
      index: number,
      value: "Cat" | "Dog",
      animals: Array<AnimalCatAndDogType>
    ) =>
      () => {
        formik.setFieldValue(`pets.${index}.animal`, value);
        onSetAnimals(animals, pets[index].id);
      };

  const onSendTagGender = (index: number, value: "male" | "female") => () => {
    formik.setFieldValue(`pets.${index}.gender`, value);
  };

  const onAddPet = () => {
    formik.setFieldValue("pets", [
      ...formik.values.pets,
      {
        id: uuidv4(),
        animal: "",
        name: "",
        date_of_birth: "",
        breed: "",
        gender: "",
      },
    ]);
  };

  const onDeletePet = (idPet: string) => () => {
    const updatedAnimals = {...animals};

    formik.setFieldValue("pets", [
      ...formik.values.pets.filter((el) => el.id !== idPet),
    ]);

    delete updatedAnimals[idPet];

    formik.values.pets.forEach((pet, index) => {
      formik.setFieldTouched(`pets.${index}.animal`, false);
      formik.setFieldTouched(`pets.${index}.name`, false);
      formik.setFieldTouched(`pets.${index}.date_of_birth`, false);
      formik.setFieldTouched(`pets.${index}.breed`, false);
      formik.setFieldTouched(`pets.${index}.gender`, false);
    });

    setAnimals(updatedAnimals);
  };

  const getButtonsProperty = (index: number, value: "animal" | "gender") => {
    return formik.touched.pets &&
    formik.touched.pets[index] &&
    formik.touched.pets[index][value] &&
    errors &&
    errors[index] &&
    errors[index][value] &&
    errors[index][value]
      ? ButtonTagProperty.ERROR
      : ButtonTagProperty.DEFAULT;
  };

  const getButtonsError = (index: number, value: "animal" | "gender") => {
    return formik.touched.pets &&
    formik.touched.pets[index] &&
    formik.touched.pets[index][value] &&
    errors &&
    errors[index] &&
    errors[index][value] &&
    errors[index][value] &&
    errors
      ? errors[index][value]
      : "";
  };

  const setErrorForAnimalTags = (index: number) => () => {
    formik.setFieldTouched(`pets.${index}.animal`, true);
  };

  return (
    <s.FormPets className={className} onSubmit={formik.handleSubmit}>
      <s.Title>
        Добавить питомцев{" "}
        <s.SpanTitle>
          5 <IconRoyals width={40} height={40}/>
        </s.SpanTitle>
      </s.Title>

      <s.Text>
        Добавьте минимум 1 питомца. Других питомцев можно добавить позже
      </s.Text>

      <s.List>
        {pets.map((pet, index) => (
          <s.Item key={pet.id + index}>
            <Animation isShow={true}>
              <s.WrapSubTitle>
                <s.SubTitle>Питомец {index + 1}</s.SubTitle>

                <Animation isShow={pets.length > 1}>
                  <s.ButtonDeletePet
                    type={"button"}
                    onClick={onDeletePet(pet.id)}
                  >
                    Удалить
                  </s.ButtonDeletePet>
                </Animation>
              </s.WrapSubTitle>

              <FormPet
                index={index}
                formik={formik}
                onClickSelectAnimalDisabled={setErrorForAnimalTags(index)}
                buttonsAnimalError={getButtonsError(index, "animal")}
                buttonsAnimalTags={[
                  {
                    property: getButtonsProperty(index, "animal"),
                    text: "кошка",
                    type: "button",
                    isActive: formik.values.pets[index].animal === "Cat",
                    icon: <IconCat width={24} height={24}/>,
                    onClickButtonTag: onSendTagAnimal(index, "Cat", cat),
                  },
                  {
                    property: getButtonsProperty(index, "animal"),
                    text: "собака",
                    type: "button",
                    icon: <IconDog width={24} height={24}/>,
                    isActive: formik.values.pets[index].animal === "Dog",
                    onClickButtonTag: onSendTagAnimal(index, "Dog", dog),
                  },
                ]}
                inputName={{
                  name: `pets.${index}.name`,
                }}
                inputSelectCalendar={{name: `pets.${index}.date_of_birth`}}
                inputSelectAnimal={{
                  name: `pets.${index}.breed`,
                  disabled: !Array.isArray(animals[pets[index].id]),
                  list: animals[pets[index].id] || [],
                }}
                buttonsGenderError={getButtonsError(index, "gender")}
                buttonsGenderTags={[
                  {
                    property: getButtonsProperty(index, "gender"),
                    text: "самец",
                    type: "button",
                    isActive: formik.values.pets[index].gender === "male",
                    icon: <IconMale width={24} height={24}/>,
                    onClickButtonTag: onSendTagGender(index, "male"),
                  },
                  {
                    property: getButtonsProperty(index, "gender"),
                    text: "самка",
                    type: "button",
                    isActive: formik.values.pets[index].gender === "female",
                    icon: <IconFemale width={24} height={24}/>,
                    onClickButtonTag: onSendTagGender(index, "female"),
                  },
                ]}
              />
            </Animation>
          </s.Item>
        ))}
      </s.List>

      <s.ButtonAddPet type={"button"} onClick={onAddPet}>
        +добавить еще питомца
      </s.ButtonAddPet>
    </s.FormPets>
  );
};
