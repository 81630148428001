import styled from "styled-components";
import { PopupBody } from "../../../../shared";
import { breakpoints } from "../../../../shared/config";
import { BodyAnimation } from "../../../../shared/ui/molecules/popup-body/Popup-body-styles";

const ModalAboutInvitationButtons = styled.div``;

const Modal = styled(PopupBody)`
  ${BodyAnimation} {
    padding: 50px 80px 60px 80px;
    max-width: 940px;
  }

  @media (max-width: ${breakpoints.LG}) {
    ${BodyAnimation} {
      max-width: 100%;
    }
  }

  @media (max-width: ${breakpoints.MD}) {
    ${BodyAnimation} {
      padding: 65px 20px 30px;
    }
  }
`;

const Title = styled.h2`
  margin-bottom: 35px;
  font: var(--font-8);
  color: var(--interface-dark);
  text-align: center;
  letter-spacing: -0.48px;
`;

const List = styled.ol`
  display: grid;
  grid-auto-rows: var(--minmax);
  gap: 24px;
  max-width: 540px;
  margin-bottom: 40px;
`;

const Item = styled.li`
  display: flex;
  gap: 8px;
`;

const Number = styled.div`
  font: var(--font-9);
  color: var(--interface-dark);
`;

const Wrap = styled.div``;

const SubTitle = styled.h3`
  margin-bottom: 10px;
  font: var(--font-9);
  color: var(--interface-dark);
`;

const Text = styled.p`
  font: var(--font-11);
  color: var(--text);
`;

const Buttons = styled.ul`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  gap: 12px;

  @media (max-width: ${breakpoints.MD}) {
    display: flex;
    flex-wrap: wrap;
  }
`;

const ItemButton = styled.li`
  @media (max-width: ${breakpoints.MD}) {
    width: 100%;
  }
`;

export {
  ModalAboutInvitationButtons,
  Modal,
  Title,
  List,
  Item,
  Number,
  Wrap,
  SubTitle,
  Text,
  Buttons,
  ItemButton,
};
