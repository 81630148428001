import React, { FC } from "react";
import * as s from "./Duo-feeding-styles";
import { CardsFeedings } from "../../molecules";
import {
  IconDiabetes,
  IconOldPets,
  IconOptimalWeight,
  IconPhysicalNeeds,
  IconUrolithiasis,
} from "../../atom";
import { ModalsEnumType, ModalsProperty } from "../../../config";

type DuoFeedingType = {
  className?: string;
  id?: string;
  titleDuoFeeding: string;
  textDuoFeeding: string;
  onClickCardFeedings: (modalInfo: ModalsEnumType) => () => void;
};

export const DuoFeeding: FC<DuoFeedingType> = ({
  className,
  id,
  titleDuoFeeding,
  textDuoFeeding,
  onClickCardFeedings,
}) => {
  const cardsFeedingsMock = [
    {
      text: "Соответствует естественным потребностям",
      icon: <IconPhysicalNeeds />,
      modal: ModalsProperty.MODAL_FEEDING_TYPES_NATURAL_NEEDS,
    },

    {
      text: "Способствует поддержанию оптимального веса",
      icon: <IconOptimalWeight />,
      modal: ModalsProperty.MODAL_FEEDING_TYPES_OPTIMAL_WEIGHT,
    },

    {
      text: "Обеспечивает профилактику мочекаменной болезни",
      icon: <IconUrolithiasis />,
      modal: ModalsProperty.MODAL_FEEDING_TYPES_UROLITHIASIS,
    },

    {
      text: "Помогает предотвратить развитие диабета",
      icon: <IconDiabetes />,
      modal: ModalsProperty.MODAL_FEEDING_TYPES_DIABETES,
    },

    {
      text: "Соответствует потребностям молодых и стареющих животных",
      icon: <IconOldPets />,
      modal: ModalsProperty.MODAL_FEEDING_TYPES_OLD_PETS,
    },
  ];

  return (
    <s.DuoFeeding className={className} id={id}>
      <s.HeadText>
        <s.Title>{titleDuoFeeding}</s.Title>
        <s.Text>{textDuoFeeding}</s.Text>
      </s.HeadText>

      <CardsFeedings
        cards={cardsFeedingsMock}
        onClickCardFeedings={onClickCardFeedings}
      />
    </s.DuoFeeding>
  );
};
