import { AxiosResponse } from "axios";
import {
  HistoryRoyalsType,
  PromoCode,
  PromoCodeType,
  ReceiptType,
} from "../model";
import { instance } from "../../../shared/api";

export const discountApi = {
  get_promocode: async (): Promise<AxiosResponse<Array<PromoCode>>> => {
    return await instance.get("discount/promocode/");
  },

  get_promocode_type_not_authorized: async (): Promise<
    AxiosResponse<Array<PromoCode>>
  > => {
    return await instance.get("discount/get_promocode_types/");
  },

  send_promocode: async (id: number): Promise<AxiosResponse<any>> => {
    return await instance.post("discount/get_promocode/", {
      promocode_type: id,
    });
  },

  get_promocode_type: async (): Promise<
    AxiosResponse<Array<PromoCodeType>>
  > => {
    return await instance.get("discount/promocode_type/");
  },

  get_history_royals: async (): Promise<
    AxiosResponse<Array<HistoryRoyalsType>>
  > => {
    return await instance.get("royals/");
  },

  get_discount_receipts: async (): Promise<
    AxiosResponse<Array<ReceiptType>>
  > => {
    return await instance.get("discount/receipt/");
  },

  get_discount_receipt_id: async (): Promise<
    AxiosResponse<{ api_uuid: string | null }>
  > => {
    return await instance.get("discount/get_receipt_uuid/");
  },
};
