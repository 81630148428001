import * as yup from "yup";

export const ValidationFormRecoverReset = yup.object().shape({
  password: yup
    .string()
    .required("Это поле обязательно для заполнения")
    .min(11, "Минимальное количество символов 11")
    .matches(/[0-9]/, "В поле обязательно должна быть одна цифра")
    .matches(/[А-ЯЁA-Z]/, "В поле обязательно должна заглавная буква")
    .matches(/[а-яёa-z]/, "В поле обязательно должна строчная буква"),

  confirm_password: yup
    .string()
    .oneOf([yup.ref("password")], "Пароли не совпадают")
    .required("Это поле обязательно для заполнения"),
});
