import {
  type ActionReducerMapBuilder,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { type NoInfer } from "react-redux";
import {
  initialStateRecovery as initialState,
  RecoveryStateType,
} from "../type/recovery-type";

const recoverySlice = createSlice({
  name: "recovery",
  initialState,
  reducers: {
    setCode: (
      state: RecoveryStateType,
      action: PayloadAction<number | null>
    ) => {
      state.code = action.payload;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<NoInfer<any>>) => {},

  selectors: {
    selectRecovery: (state) => state,
  },
});

export const recoveryReducer = recoverySlice.reducer;
export const { setCode } = recoverySlice.actions;
export const { selectRecovery } = recoverySlice.selectors;
