import "./colors-variables.css";
import "./fonts-variables.css";
import "./global-variables.css";
import "./normalize.css";
import React, { type FC, type ReactNode } from "react";

type StylesType = {
  children: ReactNode;
};

export const Styles: FC<StylesType> = ({ children }) => {
  return <>{children}</>;
};
