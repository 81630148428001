import styled from "styled-components";

const ButtonText = styled.button`
  position: relative;
  font: var(--font-18);
  color: var(--brand-1);
  will-change: transform;
  transition: color 300ms ease, transform 300ms ease;
  box-sizing: border-box;

  &:hover {
    color: var(--brand-1-dark);
  }

  &:disabled {
    pointer-events: none;
    color: var(--inactive);
  }

  &:active {
    transform: scale(0.95);
  }

  &:focus-visible::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 100%;
    border-bottom: 2px solid var(--brand-1);
    pointer-events: none;
  }
`;

export {ButtonText};
