import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { esAuth } from "../../../entities";
import { editUserThunk } from "../../../entities/auth/model";
import {
  getDiscountReceiptIdThunk,
  getDiscountReceiptsThunk,
  ReceiptType,
  selectDiscountReceipts,
} from "../../../entities/discount/model";
import { IconInfo, IconUpload, Receipt } from "../../../shared";
import {
  breakpointsTs,
  ModalsProperty,
  StatusProperty,
} from "../../../shared/config";
import {
  useAppDispatch,
  useAppSelector,
  useIsMobile,
} from "../../../shared/lib";
import { SidebarPersonalArea } from "../../../widgets";
import { setModals } from "../../../widgets/modals/model";
import * as s from "./Personal-area-cheks-styles";
import { useScrollToTop } from "../../../shared/lib/hooks/use-scroll-to-top";
import { useParams } from "react-router-dom";

type PersonalAreaCheksType = {};

type TooltipVisibilityType = {
  [key: number]: boolean;
};

export const PersonalAreaCheks: FC<PersonalAreaCheksType> = ({}) => {
  useScrollToTop();
  const dispatch = useAppDispatch();
  const params = useParams<{ isModal?: string }>();
  const receipts = useAppSelector(selectDiscountReceipts);
  const user = useAppSelector(esAuth.model.selectAuthUser);
  const [isMobile] = useIsMobile(breakpointsTs.LG);
  const [listMaxLength, setListMaxLength] = useState(!isMobile ? 3 : 9);
  const [tooltipVisibility, setTooltipVisibility] =
    useState<TooltipVisibilityType>({});
  const [errorTooltipVisibility, setErrorTooltipVisibility] =
    useState<TooltipVisibilityType>({});

  const formatterDate = (dateString: string): string => {
    const [year, month, day] = dateString.split("-");
    return `${day}.${month}.${year}`;
  };

  const onChangeListMaxLength = () => {
    setListMaxLength((value) => value + 10);
  };

  const getStatusText = (status: StatusProperty): string => {
    switch (status) {
      case StatusProperty.SUCCESS:
        return "Чек принят";
      case StatusProperty.IN_PROCESS:
        return "Чек в обработке";
      case StatusProperty.ERROR:
        return "Ошибка";
      default:
        return "Ошибка";
    }
  };

  const onShowPopupReceipt = (): void => {
    dispatch(setModals({ action: ModalsProperty.MODAL_RECEIPT }));
  };

  const onShowTooltip = (index: number) => (): void => {
    setTooltipVisibility((prev) => ({ ...prev, [index]: true }));
  };

  const onHideTooltip = (index: number) => (): void => {
    setTooltipVisibility((prev) => ({ ...prev, [index]: false }));
  };

  const onShowErrorTooltip = (index: number) => (): void => {
    setErrorTooltipVisibility((prev) => ({ ...prev, [index]: true }));
  };

  const onHideErrorTooltip = (index: number) => (): void => {
    setErrorTooltipVisibility((prev) => ({ ...prev, [index]: false }));
  };

  const onEditAvatar = async (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.currentTarget.files;
    if (files && files.length > 0) {
      const image = files[0];
      const reader = new FileReader();

      reader.onload = (event: ProgressEvent<FileReader>) => {
        const imageDataUrl = (event.target as FileReader).result as string;
        dispatch(
          editUserThunk({
            ...user,
            image: imageDataUrl,
          })
        );
      };

      reader.readAsDataURL(image);
    }
  };

  useEffect(() => {
    if (receipts.length === 0) {
      dispatch(getDiscountReceiptsThunk());
    }

    if (params?.isModal) {
      onShowPopupReceipt();
    }
  }, []);

  useEffect(() => {
    dispatch(getDiscountReceiptIdThunk());
  }, []);

  const variantAnimationReceipt = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
  };

  return (
    <s.PersonalAreaContainer>
      <s.PersonalAreaCheks>
        <SidebarPersonalArea
          isMobile={isMobile}
          isAvatar={isMobile}
          onChangeInput={onEditAvatar}
        />
        <div>
          <s.UploadButton
            icon={<IconUpload height={24} width={24} />}
            type="button"
            text="Загрузить чек"
            onClickButtonUpload={() => {
              onShowPopupReceipt();
            }}
          />
          <s.ReceiptList>
            {receipts
              .slice(0, listMaxLength)
              .map((check: ReceiptType, index: number) => (
                <s.ReceiptItem>
                  <Receipt
                    index={index * 0.03}
                    dispatchDate={formatterDate(check.dispatch_date || "")}
                    isInfoBonusTooltip={!isMobile && !!check.royals}
                    isTooltipVisibility={tooltipVisibility[index]}
                    infoBonusTooltipCounterRoyals={String(check.royals) || ""}
                    infoBonusTooltipDate={formatterDate(
                      check.approved_date || ""
                    )}
                    onInfoBonusTooltipHandleMouseEnter={onShowTooltip(index)}
                    onInfoBonusTooltipHandleMouseLeave={onHideTooltip(index)}
                    infoBonusTooltipIcon={
                      <s.IconInfoBonus width={20} height={20} />
                    }
                    isCounterRoyals={!!check.royals && isMobile}
                    counterRoyalsValue={String(check.royals) || ""}
                    approvedDate={formatterDate(check.approved_date || "")}
                    receiptStatus={check.receipt_status}
                    statusCheckText={getStatusText(check.receipt_status) || ""}
                    isErrorInfoTooltip={
                      check.receipt_status === StatusProperty.ERROR
                    }
                    errorInfoTooltipIcon={<IconInfo width={24} height={24} />}
                    isErrorTooltipVisibility={errorTooltipVisibility[index]}
                    errorInfoTooltipStatus={check.status}
                    onErrorInfoTooltipHandleMouseErrorEnter={onShowErrorTooltip(
                      index
                    )}
                    onErrorInfoTooltipHandleMouseErrorLeave={onHideErrorTooltip(
                      index
                    )}
                  />
                </s.ReceiptItem>
              ))}
            {listMaxLength < receipts.length && (
              <s.ReceiptListMoreButton onClick={onChangeListMaxLength}>
                Показать ещё {Math.min(10, receipts.length - listMaxLength)}
              </s.ReceiptListMoreButton>
            )}
          </s.ReceiptList>
        </div>
      </s.PersonalAreaCheks>
    </s.PersonalAreaContainer>
  );
};
