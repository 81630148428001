import React, { FC } from "react";
import * as s from "./Modal-successfully-registered-styles";
import { ButtonProperty } from "../../../../shared/config";

type ModalConfirmationEmailType = {
  className?: string;
  onShowModal: () => void;
  onClickButton: () => void;
};

export const ModalSuccessfullyRegistered: FC<ModalConfirmationEmailType> = ({
  className,
  onShowModal,
  onClickButton,
}) => {
  return (
    <s.ModalSuccessfullyRegistered className={className}>
      <s.Modal
        buttonRedirectText={"К регистрации"}
        onClickButtonBg={onShowModal}
        onClickButtonIcon={onShowModal}
      >
        <s.Title>Вы успешно зарегистрированы!</s.Title>

        <s.Text>Чтобы зайти в личный кабинет, нажмите кнопку «Войти».</s.Text>

        <s.ButtonCommon
          property={ButtonProperty.SECONDARY}
          text={"Войти"}
          type={"button"}
          onClickButton={onClickButton}
        />
      </s.Modal>
    </s.ModalSuccessfullyRegistered>
  );
};
