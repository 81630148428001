import { instance } from "./instance";
import { type AxiosResponse } from "axios";
import {
  CheckEmailResponseType,
  CheckEmailType,
  InviteType,
  QuestionType,
  SmsCheckType,
  SmsType,
} from "../model";

export const sharedApi = {
  sendQuestion: async (body: QuestionType) => {
    const response = await instance.post("send_question/", body);
    return response as AxiosResponse<any>;
  },

  sendInvite: async (body: InviteType) => {
    const response = await instance.post("send_invite/", body);
    return response as AxiosResponse<any>;
  },

  sendSms: async (body: SmsType) => {
    const response = await instance.post("send_confirmation_sms/", body);
    return response as AxiosResponse<any>;
  },

  checkSms: async (body: SmsCheckType) => {
    const response = await instance.post("check_sms_confirmation/", body);
    return response as AxiosResponse<any>;
  },

  checkEmail: async (body: CheckEmailType) => {
    const response = await instance.post("check_email/", body);
    return response as AxiosResponse<CheckEmailResponseType>;
  },

  getVkRepost: async () => {
    const response = await instance.get("vk_repost/");
    return response as AxiosResponse<any>;
  },

  getOkRepost: async () => {
    const response = await instance.get("ok_repost/");
    return response as AxiosResponse<any>;
  },
};
