import * as React from "react";
import { FC } from "react";

export const IconDiabetes: FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    viewBox={"0 0 200 200"}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M178.295 102.743C178.295 102.743 145.732 -4.93134 82.0098 6.90964C18.2874 18.7506 8.63077 36.544 0.69603 63.1331C-7.23871 89.7222 54.969 150.984 64.1473 158.488C73.82 166.396 208.625 227.424 178.295 102.743Z"
      fill="#FFE1EE"
    />
    <path
      d="M190.946 150.857C190.946 160.783 182.9 168.83 172.973 168.83C163.047 168.83 155 160.783 155 150.857C155 141.009 162.965 134 172.973 134C182.981 134 190.946 141.009 190.946 150.857Z"
      fill="white"
      stroke="#BBBBBB"
      strokeWidth="2"
    />
    <path
      d="M176.791 149.517C176.739 149.578 176.673 149.626 176.599 149.66C176.526 149.693 176.447 149.711 176.366 149.712C176.227 149.715 176.09 149.668 175.984 149.578C175.927 149.53 175.88 149.472 175.847 149.406C175.813 149.339 175.793 149.267 175.788 149.193C175.782 149.12 175.79 149.045 175.814 148.974C175.837 148.905 175.874 148.839 175.922 148.783C177.167 147.325 176.221 145.141 176.209 145.122C176.149 144.985 176.144 144.83 176.197 144.69C176.25 144.55 176.356 144.437 176.493 144.375C176.628 144.314 176.784 144.309 176.925 144.362C177.064 144.416 177.177 144.522 177.239 144.659C177.303 144.774 178.505 147.512 176.791 149.517ZM174.378 139.001C173.697 138.905 169.863 144.666 169.863 147.065C169.863 149.464 171.963 151.404 174.554 151.404C177.142 151.404 179.243 149.46 179.243 147.065C179.243 144.67 174.951 139.085 174.378 139.001Z"
      fill="#858585"
    />
    <path
      d="M167.464 155.988C167.437 156.018 167.404 156.043 167.369 156.06C167.332 156.076 167.291 156.085 167.251 156.085C167.181 156.088 167.114 156.064 167.06 156.019C167.032 155.995 167.009 155.965 166.991 155.932C166.975 155.899 166.965 155.864 166.962 155.826C166.96 155.789 166.964 155.752 166.975 155.717C166.986 155.681 167.005 155.648 167.029 155.62C167.651 154.893 167.179 153.8 167.174 153.79C167.142 153.722 167.14 153.644 167.166 153.575C167.193 153.505 167.246 153.448 167.314 153.418C167.383 153.386 167.461 153.385 167.531 153.411C167.6 153.437 167.657 153.491 167.688 153.558C167.721 153.617 168.321 154.986 167.464 155.988ZM166.257 150.73C165.917 150.682 164 153.562 164 154.762C164 155.961 165.049 156.931 166.345 156.931C167.64 156.931 168.69 155.96 168.69 154.762C168.69 153.565 166.543 150.772 166.257 150.73Z"
      fill="#858585"
    />
    <path
      d="M179.823 161.315C179.781 161.363 179.73 161.401 179.674 161.427C179.615 161.453 179.553 161.467 179.49 161.468C179.381 161.47 179.275 161.432 179.191 161.363C179.147 161.325 179.11 161.279 179.084 161.227C179.057 161.175 179.042 161.118 179.037 161.062C179.033 161.003 179.039 160.945 179.057 160.889C179.076 160.835 179.105 160.783 179.143 160.739C180.117 159.597 179.376 157.885 179.367 157.87C179.319 157.764 179.315 157.642 179.357 157.532C179.399 157.423 179.482 157.335 179.589 157.286C179.696 157.238 179.818 157.235 179.928 157.276C180.037 157.317 180.125 157.4 180.174 157.508C180.224 157.598 181.166 159.744 179.823 161.315ZM177.932 153.075C177.397 152.999 174.395 157.514 174.395 159.393C174.395 161.273 176.04 162.793 178.07 162.793C180.099 162.793 181.745 161.27 181.745 159.393C181.745 157.517 178.381 153.141 177.932 153.075Z"
      fill="#858585"
    />
    <path
      d="M40.6601 81.1829C40.6601 81.1829 62.1843 74.7415 78.7234 83.5107C95.2626 92.2798 107.816 113.591 102.852 139.341C97.888 165.09 65.118 147.674 61.9292 136.657C57.8741 122.573 49.5886 125.549 42.5042 118.215C35.4198 110.881 22.7657 86.4762 40.6601 81.1829Z"
      fill="#DDC89A"
    />
    <path
      d="M91.579 161.021C51.526 161.021 19 128.904 19 89.4814C19 50.0609 51.5239 18 91.579 18C131.63 18 164.1 50.1146 164.1 89.5391C164.1 128.961 131.575 161.021 91.579 161.021Z"
      fill="white"
      stroke="#BBBBBB"
      strokeWidth="4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M119.22 120.046C112.29 131.78 99.5469 142.842 84.9474 140.931C70.3712 138.943 60.9423 125.08 57.2603 111.937C52.5592 95.1547 52.1926 76.7089 55.275 59.5851C55.929 55.9513 57.0789 49.5632 62.53 44.5789C68.4073 39.2049 78.9832 35.695 98.3885 38.2299C110.007 39.7477 124.631 42.9391 131.462 53.6004C135.446 59.8195 134.914 66.2885 134.61 69.9682C133.182 87.3085 128.081 105.039 119.22 120.046Z"
      fill="#8DB2A1"
    />
    <mask
      id="mask0_653_3290"
      maskUnits="userSpaceOnUse"
      x="53"
      y="37"
      width="82"
      height="105"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M119.22 120.046C112.29 131.78 99.5469 142.842 84.9474 140.931C70.3712 138.943 60.9423 125.08 57.2603 111.937C52.5592 95.1547 52.1926 76.7089 55.275 59.5851C55.929 55.9513 57.0789 49.5632 62.53 44.5789C68.4073 39.2049 78.9832 35.695 98.3885 38.2299C110.007 39.7477 124.631 42.9391 131.462 53.6004C135.446 59.8195 134.914 66.2885 134.61 69.9682C133.182 87.3085 128.081 105.039 119.22 120.046Z"
        fill="#BBBBBB"
      />
    </mask>
    <g mask="url(#mask0_653_3290)">
      <path
        d="M74.0487 49.6064L119.273 55.5251C123.165 56.0346 125.908 59.6033 125.399 63.4961L123.659 76.7898L121.919 90.0835C121.409 93.9763 117.841 96.7191 113.948 96.2096L68.7241 90.2909C64.8311 89.7814 62.0884 86.2127 62.5979 82.3199L66.0775 55.7324C66.587 51.8396 70.1557 49.0969 74.0487 49.6064Z"
        stroke="white"
        strokeWidth="3"
      />
      <path
        d="M80.3701 111.025C80.4049 111.053 80.4528 111.061 80.4948 111.044C80.5369 111.028 80.5667 110.99 80.5726 110.945L81.5378 103.569C81.5438 103.524 81.5244 103.479 81.4875 103.452C81.4504 103.425 81.4018 103.421 81.3606 103.441L75.0883 106.447C75.05 106.466 75.0236 106.502 75.0186 106.544C75.0134 106.587 75.0302 106.628 75.063 106.655L80.3701 111.025Z"
        fill="white"
        stroke="white"
        strokeLinejoin="bevel"
      />
      <path
        d="M97.3852 113.252C97.344 113.27 97.2959 113.265 97.2595 113.239C97.223 113.212 97.2041 113.167 97.21 113.122L98.1753 105.747C98.1812 105.701 98.2114 105.663 98.254 105.647C98.2967 105.63 98.3449 105.639 98.3795 105.668L103.667 110.188C103.699 110.215 103.715 110.257 103.709 110.299C103.703 110.342 103.676 110.378 103.638 110.395L97.3852 113.252Z"
        fill="white"
        stroke="white"
        strokeLinejoin="bevel"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M84.036 146.65C100.854 148.851 115.467 136.611 123.384 123.593C133.508 106.944 139.289 87.2468 140.844 67.964C141.174 63.8721 141.754 56.6785 137.134 49.7395C129.215 37.8441 112.363 34.2183 98.9793 32.4698C76.6259 29.5495 64.4687 33.3995 57.7301 39.3474C51.4804 44.8639 50.1896 51.9645 49.4553 56.0035C45.9953 75.0369 46.5126 95.5586 52.0115 114.252C56.3182 128.893 67.2449 144.363 84.036 146.65ZM52.1766 56.5365C52.9199 52.4893 54.0715 46.2196 59.6084 41.3322C65.489 36.1416 76.6617 32.2532 98.632 35.1233C111.125 36.7554 127.374 40.0079 134.808 51.174C138.901 57.3217 138.4 63.6766 138.077 67.7788L138.077 67.7842C136.591 86.635 130.884 105.955 120.992 122.22C113.651 134.293 100.073 146.049 84.3833 143.996C78.284 143.198 61.7969 137.749 54.6758 113.541C49.3029 95.2759 48.7605 75.1366 52.1766 56.5365Z"
      fill="#818385"
    />
    <path
      d="M76.9183 64.5921C76.8575 64.6459 76.7859 64.6861 76.7084 64.7102C76.6308 64.7341 76.5492 64.7415 76.4685 64.7316C76.3287 64.7164 76.1991 64.6511 76.1038 64.5478C76.0536 64.4924 76.0149 64.4276 75.9899 64.3571C75.9649 64.2867 75.9541 64.212 75.9581 64.1373C75.962 64.0627 75.9807 63.9896 76.0132 63.9222C76.0456 63.8549 76.0909 63.7945 76.1467 63.7447C77.5796 62.451 76.9212 60.1447 76.9121 60.124C76.8686 59.9797 76.8843 59.824 76.9556 59.6911C77.027 59.5583 77.1482 59.4593 77.2926 59.4158C77.4369 59.3723 77.5928 59.3878 77.7256 59.4593C77.8584 59.5305 77.9574 59.6517 78.0009 59.7961C78.0509 59.9193 78.8929 62.8133 76.9183 64.5921ZM75.8816 53.7684C75.2137 53.5837 70.63 58.8392 70.3164 61.2359C70.0027 63.6327 71.8477 65.8453 74.4355 66.184C77.0235 66.5227 79.3761 64.8556 79.6893 62.4626C80.0025 60.0697 76.444 53.9275 75.8816 53.7684Z"
      fill="#FFE1EE"
    />
  </svg>
);
