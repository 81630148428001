import React, { FC } from "react";

export const IconsStepsRoyals: FC<
  React.SVGProps<SVGSVGElement> & { status: 1 | 2 | 3 | 4 | 5 }
> = ({ status, ...props }) => (
  <>
    {/* Icon circle 1 */}
    {status === 1 && (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox={"0 0 80 80"}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M40.81.001C24.983-.082 7.655 4.48 2.517 19.45-2.818 34.993 1.039 51.724 14.5 61.15c12.681 8.88 34.898 8.897 47.568 0 13.34-9.366 21.167-25.909 15.815-41.304C72.795 5.208 56.307.083 40.81 0Z"
          clipRule="evenodd"
        />
      </svg>
    )}

    {/* Icon circle 2 */}
    {status === 2 && (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox={"0 0 80 80"}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M38.774 67.473c15.827.084 33.155-4.477 38.293-19.448C82.402 32.481 77.961 18.426 64.5 9 51.819.12 30.186-2.572 17.516 6.324 4.176 15.691-3.651 32.233 1.701 47.629c5.088 14.637 21.576 19.763 37.073 19.844Z"
          clipRule="evenodd"
        />
      </svg>
    )}

    {/* Icon circle 3 */}
    {status === 3 && (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox={"0 0 80 80"}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M38.774.001C54.6-.082 71.929 4.48 77.067 19.45c5.335 15.544-4.087 31.57-17.548 40.995-12.681 8.88-29.333 9.603-42.003.706C4.176 51.784-3.651 35.241 1.701 19.846 6.789 5.208 23.277.083 38.774 0Z"
          clipRule="evenodd"
        />
      </svg>
    )}

    {/* Icon circle 4 */}
    {status === 4 && (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox={"0 0 80 80"}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M39.81.001C23.983-.082 6.655 4.48 1.517 19.45c-5.335 15.544 4.087 31.57 17.548 40.995 12.681 8.88 29.333 9.603 42.003.706 13.34-9.366 21.167-25.909 15.815-41.304C71.795 5.208 55.307.083 39.81 0Z"
          clipRule="evenodd"
        />
      </svg>
    )}

    {/* Icon circle 5 */}
    {status === 5 && (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox={"0 0 80 80"}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M38.774 67.473c15.827.084 33.155-4.477 38.293-19.448C82.402 32.481 72.98 16.456 59.519 7.03c-12.681-8.88-29.333-9.602-42.003-.706C4.176 15.691-3.651 32.233 1.701 47.629c5.088 14.637 21.576 19.763 37.073 19.844Z"
          clipRule="evenodd"
        />
      </svg>
    )}
  </>
);
