import React, { FC } from "react";

export const IconCopy: FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox={"0 0 16 16"}
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M10.002 4a.5.5 0 0 0-.5-.5h-7a.5.5 0 0 0-.5.5v10.5a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5V4Zm-8-1.5a1 1 0 0 0-1 1V15a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V3.5a1 1 0 0 0-1-1h-8Z"
      clipRule="evenodd"
    />
    <path d="M12.502 1H5.377a.375.375 0 0 0-.375.375.375.375 0 0 1-.375.375h-.125a.5.5 0 0 1-.5-.5V1a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v11.5a1 1 0 0 1-1 1h-.5a.5.5 0 1 1 0-1 .5.5 0 0 0 .5-.5V1.5a.5.5 0 0 0-.5-.5Z" />
  </svg>
);
