import React, { FC } from "react";
import * as s from "./Counter-royals-styles";

type CounterRoyalsType = {
  className?: string;
  counter: string;
};

export const CounterRoyals: FC<CounterRoyalsType> = ({
  className,
  counter,
}) => {
  return (
    <s.CounterRoyals className={className}>
      <s.Span>{counter}</s.Span>
      <s.Icon />
    </s.CounterRoyals>
  );
};
