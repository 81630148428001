import styled from "styled-components";
import { TimelineRoyals } from "../../molecules";
import { breakpoints } from "../../../config";

const AdditionalBonuses = styled.section`
  display: flex;
  gap: 24px;
  margin-bottom: 124px;

  @media (max-width: ${breakpoints.XXL}) {
    margin-bottom: 114px;
  }

  @media (max-width: ${breakpoints.XL}) {
    gap: 16px;
  }

  @media (max-width: ${breakpoints.LG}) {
    flex-direction: column;
  }
`;

const TimelineRoyalsStyles = styled(TimelineRoyals)`
  flex: 1;
`;

export { AdditionalBonuses, TimelineRoyalsStyles };
