import React, { ChangeEvent, FC, HTMLInputTypeAttribute } from "react";
import * as s from "./Input-code-styles";

export type InputCodeType = {
  className?: string;
  type: HTMLInputTypeAttribute;
  value: string;
  placeholder?: string;
  disabled?: boolean;
  isError?: boolean;
  isFocus?: boolean;
  onClickInputCode?: () => void;
  onChangeInputCode: (e: ChangeEvent<HTMLInputElement>) => void;
};

export const InputCode: FC<InputCodeType> = ({
  className,
  type,
  value,
  placeholder,
  disabled,
  isError,
  isFocus,
  onClickInputCode,
  onChangeInputCode,
}) => {
  return (
    <s.InputCode
      className={className}
      type={type}
      value={value}
      placeholder={placeholder}
      disabled={disabled}
      $disabled={disabled}
      $isError={isError}
      $isFocus={isFocus}
      onClick={disabled ? () => {} : onClickInputCode}
      onChange={disabled ? () => {} : onChangeInputCode}
    />
  );
};
