import React, { FC } from "react";

export const IconDoc: FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox={"0 0 25 24"}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16.727 18.293V16.5h1.792l-1.792 1.793Zm-9.5.207v-13a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 .5.5v10h-3a.5.5 0 0 0-.5.5v3h-8a.5.5 0 0 1-.5-.5Zm13-2.914V5.5c0-.827-.673-1.5-1.5-1.5h-11c-.827 0-1.5.673-1.5 1.5v13c0 .827.673 1.5 1.5 1.5h8.085c.212 0 .415-.046.603-.129l.022-.011a1.49 1.49 0 0 0 .436-.3l2.914-2.914a1.5 1.5 0 0 0 .3-.436l.011-.022c.083-.187.129-.39.129-.602Z"
      clipRule="evenodd"
    />
  </svg>
);
