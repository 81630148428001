import React, { FC } from "react";

export const IconCat: FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox={"0 0 40 40"}
    {...props}
  >
    <path
      fillRule="evenodd"
      d="m24.878 22.885 1.14 6.007.475.022c.537.048.817.309.963.52.39.568.2 1.332.158 1.48a.577.577 0 0 1-.555.417h-2.412a.578.578 0 0 1-.51-.303l-.61-1.14c-.299.363-.921 1.07-1.05 1.12-1.179.467-2.96.483-5.448.051-3.33-.594-3.695-.084-3.715-.052-.282.426-.37.83-.27 1.247.133.608.281.788.3.807 1.08.912 2.926 1.348 5.46 1.296 1.304-.04 3.17-.337 3.573-.402.403-.064.715.121.947.352.232.231.355.526.355.853 0 .328-.124.616-.36.834a1.17 1.17 0 0 1-.676.35c-1.164.384-2.48.578-3.914.578h-.151c-1.465-.008-2.256-.011-2.374-.011l-.044-.002c-1.147-.1-2.185-.342-3.086-.718-1.38-.577-2.292-1.454-2.708-2.608-.54-1.522-.482-2.83.173-3.892.409-.66.578-1.299.515-1.949a13.964 13.964 0 0 1-.704-3.845c-.107-2.377.366-4.71 1.406-6.936.85-1.806 2.388-3.255 4.568-4.304.978-.487 1.65-.866 1.984-1.118a3.53 3.53 0 0 0 1.16-1.703c-.268-1.851.165-3.31 1.322-4.457.18-.18.35-.369.515-.552.75-.837 1.526-1.702 3.37-1.702.162 0 .31.065.418.184.195.214.17.488.144.753-.053.56.018.574.106.592l.08.024c.772.297 1.264.678 1.499 1.155.121.182.883.742 1.519 1.153.318.206.472.642.519 1.457v.034l-.004.084c-.201 1.447-1.017 2.378-2.363 2.702-.545.58-.648 1.356-.314 2.366.574 1.198.578 2.696.012 4.453l-.012.032c-.175.44-.359.796-.56 1.087-.371.55-.57 1.114-.588 1.676l-.005.056c-.1.688-.183 1.34-.248 1.952Z"
      clipRule="evenodd"
    />
  </svg>
);
