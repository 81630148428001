import React from "react";
import { type RouteObject } from "react-router-dom";
import { sd } from "../../../../shared";
import { SignIn } from "../../ui/Sign-in";
import {pages} from "../../../../shared/config";

export const router: RouteObject = {
  path: pages.signIn.router,
  element: <SignIn />,
};
