import React from "react";
import { type RouteObject } from "react-router-dom";
import { sd } from "../../../../shared";
import { Registration } from "../../ui/Registration";
import { pages } from "../../../../shared/config";

export const router: RouteObject = {
  path: pages.registration.router,
  element: <Registration />,
};
