import React from "react";
import { Navigate, type RouteObject } from "react-router-dom";
import { Auth } from "../../ui/Auth";
import { pages } from "../../../../shared/config";

export const router = (
  isPage: boolean,
  children: Array<RouteObject>
): RouteObject => {
  return {
    path: pages.auth.router,
    element: isPage ? (
      <Navigate to={pages.personalAreaProfile.link} />
    ) : (
      <Auth />
    ),
    children,
  };
};
