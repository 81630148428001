import * as React from "react";
import { FC } from "react";

export const IconReceipt: FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox={"0 0 32 32"}
    fill="none"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.625}
      d="M26.918 9.5v2.622c0 1.712-1.042 2.795-2.688 2.795h-3.562V7.344c0-1.202.948-2.177 2.104-2.177A4.109 4.109 0 0 1 25.7 6.434 4.45 4.45 0 0 1 26.918 9.5Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.625}
      d="M6.082 10.584V25.75c0 .9.98 1.409 1.667.867l1.78-1.387a1.02 1.02 0 0 1 1.376.109l1.73 1.809a1.022 1.022 0 0 0 1.478 0l1.75-1.82c.365-.38.948-.423 1.354-.098L19 26.617c.687.53 1.666.022 1.666-.867V7.334c0-1.192.938-2.167 2.084-2.167h-12.5c-3.125 0-4.167 1.94-4.167 4.333v1.084Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.625}
      d="M13.375 17.095H16.5M13.375 12.762H16.5"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2.167}
      d="M10.246 17.083h.01M10.246 12.75h.01"
    />
  </svg>
);
