import React, { FC } from "react";
import * as s from "./Modal-question-sent-styles";

type ModalQuestionSentType = {
  className?: string;
  onShowModal: () => void;
};

export const ModalQuestionSent: FC<ModalQuestionSentType> = ({
  className,
  onShowModal,
}) => {
  return (
    <s.ModalQuestionSent className={className}>
      <s.Modal
        onClickButtonBg={onShowModal}
        onClickButtonIcon={onShowModal}
        isStatus={true}
      >
        <s.Title>Ваш вопрос отправлен</s.Title>

        <s.Text>Мы пришлем ответ на указанную почту</s.Text>
      </s.Modal>
    </s.ModalQuestionSent>
  );
};
