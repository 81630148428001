import styled, { css } from "styled-components";
import { breakpoints, ButtonProperty, buttonStyleMix } from "../../../config";

type ButtonType = {
  property?:
    | ButtonProperty.DEFAULT
    | ButtonProperty.SECONDARY
    | ButtonProperty.HIGHLIGHTED;
};

const Button = styled.button<ButtonType>`
  width: 100%;
  max-width: 288px;
  min-height: 72px;
  padding: 15px 40px;
  font: var(--font-9);
  border-radius: 8px;
  overflow: hidden;
  will-change: transform;
  transition: transform 300ms linear, background-color 300ms ease,
    color 300ms ease, border 300ms ease;

  @media (max-width: ${breakpoints.XL}) {
    max-width: 230px;
    min-height: 64px;
  }

  ${(props) =>
    props.property === ButtonProperty.DEFAULT &&
    css`
      ${buttonStyleMix};

      &:hover {
        color: var(--brand-1);
        background-color: var(--brand-3);
        border: 2px solid transparent;
      }

      &:disabled {
        background-color: transparent;
        border: 2px solid var(--interface);
        color: var(--interface);
        pointer-events: none;
      }
    `};

  ${(props) =>
    props.property === ButtonProperty.SECONDARY &&
    css`
      color: var(--brand-3);
      background-color: var(--brand-1);
      border: 2px solid transparent;

      &:hover {
        background-color: var(--brand-1-dark);
      }

      &:disabled {
        background-color: var(--inactive);
        color: var(--interface);
        pointer-events: none;
      }
    `};

  ${(props) =>
    props.property === ButtonProperty.HIGHLIGHTED &&
    css`
      color: var(--brand-1);
      background-color: var(--brand-3);
      border: 2px solid var(--brand-1);

      &:hover {
        color: var(--brand-1-dark);
        border: 2px solid var(--brand-1-dark);
      }

      &:disabled {
        color: var(--interface);
        background-color: var(--inactive);
        border: 2px solid transparent;
        pointer-events: none;
      }
    `};

  &:active {
    transform: scale(0.98);
  }

  &:focus-visible {
    border: 2px solid var(--interface-dark);
  }
`;

export {Button};
