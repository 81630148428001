import styled from "styled-components";
import { ButtonIcon as ButtonIconField } from "../../atom";

const FieldInputCode = styled.div`
  width: 100%;
  max-width: 415px;
  position: relative;
`;

const ButtonIcon = styled(ButtonIconField)`
  position: absolute;
  top: 50%;
  right: 24px;
  transform: translateY(-50%);

  &:active {
    transform: translateY(-50%) scale(0.95);
  }
`;

export { FieldInputCode, ButtonIcon };
