import React, { FC } from "react";
import * as s from "./Buttons-tags-styles";
import { Animation, ButtonTag, ButtonTagType } from "../../atom";

type ButtonsTagsType = {
  className?: string;
  error?: string;
  buttonsTags: Array<ButtonTagType>;
};

export const ButtonsTags: FC<ButtonsTagsType> = ({
  className,
  error,
  buttonsTags,
}) => {
  return (
    <s.ButtonsTags className={className}>
      <s.List>
        {buttonsTags.map((tag, index) => (
          <s.Item key={index}>
            <ButtonTag
              property={tag.property}
              isActive={tag.isActive}
              text={tag.text}
              type={tag.type}
              icon={tag.icon}
              onClickButtonTag={tag.onClickButtonTag}
            />
          </s.Item>
        ))}
      </s.List>

      <Animation isShow={!!error}>
        <s.Error>{error}</s.Error>
      </Animation>
    </s.ButtonsTags>
  );
};
