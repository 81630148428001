import React, { FC, ReactNode } from "react";
import * as s from "./Button-upload-styles";

export type ButtonUploadType = {
  className?: string;
  text: string;
  type: "button" | "reset" | "submit";
  disabled?: boolean;
  icon: ReactNode;
  onClickButtonUpload: () => void;
};

export const ButtonUpload: FC<ButtonUploadType> = ({
  className,
  text,
  type,
  disabled,
  icon,
  onClickButtonUpload,
}) => {
  return (
    <s.ButtonUpload
      className={className}
      type={type}
      disabled={disabled}
      onClick={disabled ? () => {} : onClickButtonUpload}
    >
      {icon} {text}
    </s.ButtonUpload>
  );
};
