import styled from "styled-components";
import { ButtonUpload, IconCatShape } from "../../atom";
import { breakpoints } from "../../../config";

const CardTargetAction = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 40px;
  padding: 52px;
  background-color: var(--pink);
  border-radius: 36px;
  max-width: 395px;
  min-height: 400px;
  overflow: hidden;
  z-index: 1;

  @media (max-width: ${breakpoints.XL}) {
    padding: 36px 36px 58px;
    max-width: 340px;
    min-height: 353px;
  }

  @media (max-width: ${breakpoints.LG}) {
    align-items: center;
    padding: 40px 69px;
    max-width: none;
    width: 100%;
    min-height: 247px;
  }

  @media (max-width: ${breakpoints.MD}) {
    align-items: flex-start;
    padding: 24px 24px 32px;
    gap: 32px;
  }
`;

const BgIcon = styled(IconCatShape)`
  position: absolute;
  right: -40px;
  bottom: -55px;
  transform: rotate(-18.549deg);
  width: 309px;
  color: var(--pink-for-svg);
  z-index: -1;

  @media (max-width: ${breakpoints.XL}) {
    right: -65px;
    bottom: -25px;
    width: 230px;
  }

  @media (max-width: ${breakpoints.LG}) {
    bottom: -40px;
  }

  @media (max-width: ${breakpoints.MD}) {
    display: none;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  z-index: 2;

  @media (max-width: ${breakpoints.LG}) {
    align-items: center;
  }

  @media (max-width: ${breakpoints.MD}) {
    align-items: flex-start;
    width: 82%;
  }
`;

const Title = styled.h2`
  font: var(--font-3);
  color: var(--brand-1);
  letter-spacing: -1.68px;

  @media (max-width: ${breakpoints.XL}) {
    letter-spacing: -1.36px;
  }
`;

const Text = styled.p`
  position: relative;
  font: var(--font-12);
  max-width: 241px;
  color: var(--text);

  @media (max-width: ${breakpoints.XXL}) {
    max-width: 214px;
  }

  @media (max-width: ${breakpoints.LG}) {
    max-width: none;
  }

  & svg {
    position: absolute;
    bottom: -5px;

    @media (max-width: ${breakpoints.LG}) {
      right: -35px;
    }

    @media (max-width: ${breakpoints.MD}) {
      right: unset;
    }
  }
`;

const Button = styled(ButtonUpload)`
  @media (max-width: ${breakpoints.XL}) {
    max-width: 210px;
  }

  @media (max-width: ${breakpoints.LG}) {
    max-width: 340px;
  }

  @media (max-width: ${breakpoints.MD}) {
    max-width: none;
  }
`;

export { CardTargetAction, BgIcon, Content, Title, Text, Button };
