import styled from "styled-components";
import { breakpoints } from "../../../config";

const ButtonInvite = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 18px 24px;
  font: var(--font-12);
  color: var(--text);
  border: 2px solid var(--interface);
  border-radius: 8px;
  will-change: transform;
  transition: transform 300ms ease, color 300ms ease, border 300ms ease;

  @media (max-width: ${breakpoints.XL}) {
    padding: 14px 24px;
  }

  @media (max-width: ${breakpoints.LG}) {
    justify-content: center;
    padding: 18px 24px;
  }

  @media (max-width: ${breakpoints.MD}) {
    padding: 14px 24px;
  }

  &:active {
    transform: scale(0.95);
  }

  &:hover {
    border: 2px solid var(--brand-2);
  }

  &:disabled {
    pointer-events: none;
    border: 2px solid var(--interface);
    color: var(--interface);
  }

  &:focus-visible {
    outline: 2px solid var(--brand-2);
  }
`;

export {ButtonInvite};
