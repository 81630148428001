import styled from "styled-components";
import timelineShapeIcon1440 from "../../../config/assets/images/additional-bonus-shape-1440.svg";
import timelineShapeIcon768 from "../../../config/assets/images/additional-bonus-shape-768.svg";
import timelineShapeIcon375 from "../../../config/assets/images/additional-bonus-shape-375.svg";
import { breakpoints } from "../../../config";
import { MiniRoyalsCard } from "../../atom";

const TimelineRoyals = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
  padding: 57px 78px 65px 58px;
  border-radius: 36px;
  background-color: var(--brand-1);
  background-image: url(${timelineShapeIcon1440});
  background-repeat: no-repeat;
  background-position: 100px;
  background-size: cover;

  @media (max-width: ${breakpoints.XXL}) {
    padding: 57px 68px 67px 58px;
  }

  @media (max-width: ${breakpoints.XL}) {
    padding: 36px 28px 61px 36px;
    background-position: -89% 8%;
  }

  @media (max-width: ${breakpoints.LG}) {
    padding: 36px 20px 50px;
    background-image: url(${timelineShapeIcon768});
    background-position: 200px;
  }

  @media (max-width: ${breakpoints.MD}) {
    position: relative;
    justify-content: unset;
    padding: 24px 24px 38px;
    background-image: url(${timelineShapeIcon375});
    background-position: right bottom;
    background-size: contain;
    overflow: auto;
  }
`;

const Title = styled.h3`
  font: var(--font-3);
  letter-spacing: -1.68px;
  color: var(--brand-3);
  max-width: 490px;

  @media (max-width: ${breakpoints.XL}) {
    max-width: 390px;
  }

  @media (max-width: ${breakpoints.LG}) {
    max-width: 400px;
    margin: 0 auto;
    text-align: center;
  }

  @media (max-width: ${breakpoints.MD}) {
    position: sticky;
    top: 0;
    left: 0;
    text-align: left;
  }
`;

const ListTimeline = styled.ol`
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  @media (max-width: ${breakpoints.LG}) {
    margin-right: 36px;
  }

  @media (max-width: ${breakpoints.MD}) {
    min-width: 497px;
    margin-right: 0;
  }

  &:before {
    content: "";
    position: absolute;
    bottom: 49px;
    left: 7px;
    width: 96%;
    height: 10px;
    background: var(--gradient-line);
    border-radius: 12px;

    @media (max-width: ${breakpoints.XXL}) {
      width: 98%;
    }

    @media (max-width: ${breakpoints.XXL}) {
      width: 96%;
    }
`;

const ItemTimeline = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const RoyalsCardMini = styled(MiniRoyalsCard)`
  @media (max-width: ${breakpoints.XXL}) {
    margin-bottom: 10px;
  }
`;

const FirstIcon = styled.span`
  margin-bottom: 10px;
  z-index: 1;

  & svg {
    width: 60px;
    color: var(--brand-3);
  }
`;

const AdditionalText = styled.p`
  font: var(--font-12);
  color: var(--brand-3);
`;

export {
  TimelineRoyals,
  Title,
  ListTimeline,
  ItemTimeline,
  RoyalsCardMini,
  FirstIcon,
  AdditionalText,
};
