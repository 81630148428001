import React, {FC} from "react";
import * as s from "./Form-user-styles";
import {FormikProps} from "formik";
import {InitialValuesFormikRegistrationType} from "../../../pages";

type FormUserType = {
  className?: string;
  formik: FormikProps<InitialValuesFormikRegistrationType>;
};

export const FormUser: FC<FormUserType> = ({className, formik}) => {
  return (
    <s.FormUser className={className} onSubmit={formik.handleSubmit}>
      <s.SubTitle>Введите данные</s.SubTitle>

      <s.List>
        <s.Item>
          <s.InputText
            type={"text"}
            label={"Имя"}
            {...formik.getFieldProps("first_name")}
            {...formik.getFieldMeta("first_name")}
            {...formik.getFieldHelpers("first_name")}
          />
        </s.Item>
        <s.Item>
          <s.InputText
            type={"text"}
            label={"Фамилия"}
            {...formik.getFieldProps("last_name")}
            {...formik.getFieldMeta("last_name")}
            {...formik.getFieldHelpers("last_name")}
          />
        </s.Item>
        <s.Item>
          <s.InputPhone
            label={"Телефон"}
            {...formik.getFieldProps("phone")}
            {...formik.getFieldMeta("phone")}
            {...formik.getFieldHelpers("phone")}
          />
        </s.Item>
        <s.Item>
          <s.WrapInput>
            <s.InputText
              type={"text"}
              label={"E-mail"}
              {...formik.getFieldProps("email")}
              {...formik.getFieldMeta("email")}
              {...formik.getFieldHelpers("email")}
              // errorResponse={errorEmailResponse}
              // onHandelBlurInput={newOnBlur}
            />

            <s.Text>
              Проверьте введенный E-mail - он&nbsp;потребуется для
              восстановления данных
            </s.Text>
          </s.WrapInput>
        </s.Item>
      </s.List>
    </s.FormUser>
  );
};
