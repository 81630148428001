import React, { FC } from "react";
import * as s from "./Auth-styles";
import { Outlet, useMatch, useNavigate } from "react-router-dom";
import { Header } from "../../../widgets";
import { IconDoc, IconExit } from "../../../shared";
import { useIsMobile } from "../../../shared/lib";
import { breakpointsTs, pages } from "../../../shared/config";

type AuthType = {};

export const Auth: FC<AuthType> = ({}) => {
  const isAuthSignIn = useMatch("Auth/Sign-in/*");
  const [isMobile] = useIsMobile(breakpointsTs.LG);
  const navigate = useNavigate();

  const onRedirect = () => {
    navigate(pages.signIn.link);
  };

  return (
    <s.Auth>
      <Header
        isMobile={false}
        listLeft={[<s.LinkRouter to={pages.main.link}>Главная</s.LinkRouter>]}
        listRight={[
          <s.LinkDefault
            href={
              "https://xn----ctbfggjf0anaeki8a4dxd.xn--p1ai/media/rules/Правила_акции_Здоровый_питомец__2024.pdf"
            }
            target={"_blank"}
          >
            <IconDoc width={24} height={24} />
            {isMobile && "Полные правила акции"}
          </s.LinkDefault>,

          !isAuthSignIn && (
            <s.Button type={"button"} onClick={onRedirect}>
              <IconExit width={24} height={24} /> {isMobile && "Войти"}
            </s.Button>
          ),
        ]}
      />

      <s.AuthContainer>
        <s.Content>
          <Outlet />
        </s.Content>
      </s.AuthContainer>
    </s.Auth>
  );
};
