import React, { FC } from "react";

export const IconRoyalsCard: FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox={"0 0 120 120"}
      {...props}
  >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M17.973 40.032a8.5 8.5 0 0 0-8.5 8.5v39.5a8.5 8.5 0 0 0 8.5 8.5h79.86a8.5 8.5 0 0 0 8.499-8.5v-39.5a8.5 8.5 0 0 0-8.5-8.5h-79.86Zm77.495 17c2.87 0 5.196-2.463 5.196-5.5 0-3.038-2.326-5.5-5.196-5.5-2.87 0-5.196 2.462-5.196 5.5 0 3.037 2.327 5.5 5.196 5.5Z"
        clipRule="evenodd"
      />
      <path
        fill="#FFE1EE"
        d="M33.85 77.234c8.32-11.805 16.47-.019 19.506 7.346l.408 1.116c.983-4.118 2.851-8.048 6.707-9.977 4.127-2.06 7.877-.64 10.354 1.312-.253-6.51 1.171-12.54 6.401-14.25 6.873-2.24 13.548 4.808 18.04 11.895-.223-9.088 1.595-18.467 8.932-19.517a6.745 6.745 0 0 1 2.134.041V89c0 .783-1.333 8-9.833 7.5h-64.5s-6.468-7.46 1.851-19.266Z"
      />
      <path
        fill="#444"
        d="M78.762 30.032c-5.491.098-9.726-1.219-10.305-3.203l-1 .294c.735 2.517 5.177 4.066 11.33 3.954 7.492-.135 10.532 5.492 12.23 9.182l1.111.045c-1.89-4.065-5.352-10.413-13.36-10.265l-.006-.007Z"
      />
      <path
        fill="#444"
        d="M83.606 22.83c-4.627-2.808-9.04-3.343-12.109-1.461-4.01 2.449-4.41 4.48-4.038 5.748l1-.294c-.38-1.301.891-2.926 3.578-4.573 2.685-1.642 6.808-1.095 11.023 1.466 6.362 3.863 12.903 12.388 12.238 21.989a.525.525 0 0 0 .483.558.53.53 0 0 0 .444-.194.502.502 0 0 0 .115-.289c.696-10.034-6.114-18.927-12.74-22.951l.007.001Z"
      />
  </svg>
);
