import styled from "styled-components";
import { ButtonsDropdown, FieldInput } from "../../molecules";

const SelectSearch = styled.div`
  position: relative;
  max-width: 100%;
`;

const InputSearch = styled(FieldInput)`
  position: relative;
  z-index: 1;
`;

const ButtonBg = styled.button`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  background-color: var(--dark-30);
`;

const Buttons = styled(ButtonsDropdown)`
  position: relative;
  z-index: 1;
  margin-top: 12px;
`;

export { SelectSearch, InputSearch, ButtonBg, Buttons };
