import React, {FC, ReactNode} from "react";
import * as s from "./Popup-body-styles";
import {IconClose} from "../../atom";

type PopupBodyType = {
  className?: string;
  children: ReactNode;
  onClickButtonBg: () => void;
  onClickButtonIcon: () => void;
};

export const PopupBody: FC<PopupBodyType> = (
  {
    className,
    children,
    onClickButtonBg,
    onClickButtonIcon,
  }) => {
  const variants = {
    initial: {opacity: 0, scale: 0.8, transformOrigin: "bottom center"},
    animate: {opacity: 1, scale: 1},
    exit: {opacity: 0, scale: 0.8},
  };
  return (
    <s.PopupBody className={className}>
      <s.ButtonBg type={"button"} onClick={onClickButtonBg}/>

      <s.BodyAnimation isShow={true} delay={0.2} variants={variants}>
        <s.ButtonIconBody
          type={"button"}
          icon={<IconClose width={24} height={24}/>}
          onClickButtonIcon={onClickButtonIcon}
        />

        {children}
      </s.BodyAnimation>
    </s.PopupBody>
  );
};
