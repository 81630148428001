import React from "react";
import { Navigate, type RouteObject } from "react-router-dom";
import { PersonalArea } from "../../ui/Personal-area";
import { pages } from "../../../../shared/config";

export const router = (
  isPage: boolean,
  children: Array<RouteObject>
): RouteObject => {
  return {
    path: pages.personalArea.router,
    element: isPage ? <PersonalArea /> : <Navigate to={pages.main.link} />,
    children,
  };
};
