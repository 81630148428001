import styled from "styled-components";

const ButtonsTags = styled.div``;

const List = styled.ul`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const Item = styled.li``;

const Error = styled.p`
  padding: 4px 0 0 16px;
  font: var(--font-18);
  color: var(--error);
`;

export { ButtonsTags, List, Item, Error };
