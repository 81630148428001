import { StatusProperty } from "../../../../shared/config";

export type AssignmentType = "young" | "adult" | "grown_up" | "partner";

export type PromocodeType = {
  assignment: AssignmentType;
  title: string;
  description: { [key: string]: string | [] };
};

export type PromoCode = {
  created: string;
  modified: string;
  code: string;
  promocode_type: PromocodeType;
  user_Id: number;
  purchase_date: string;
  expired_date: string;
  is_over: boolean;
};

export type PromoCodeDescriptionType = {
  [key: string]: Array<string> | string;
};

export type PromoCodeType = {
  id: number;
  assignment: AssignmentType;
  title: string;
  price: number;
  image: string;
  available: boolean;
  index: number | null;
  description: PromoCodeDescriptionType;
};

export type HistoryRoyalsType = {
  is_over: boolean;
  is_spented: boolean;
  plus_or_minus: string;
  royals_type: string;
  spent: number;
  title: string | null;
  user: number;
  volume: number;
};

export type ReceiptType = {
  api_uuid: string;
  royals: number | null;
  is_approved: boolean;
  approved_date: string;
  dispatch_date: string;
  user_id: number;
  status: string;
  receipt_status: StatusProperty;
};

export type DiscountStateType = {
  promoCodes: Array<PromoCode>;
  promoCodesTypes: Array<PromoCodeType>;
  promoCodesTypesYoung: Array<PromoCodeType>;
  promoCodesTypesAdult: Array<PromoCodeType>;
  promoCodesTypesPartner: Array<PromoCodeType>;
  isReceiptsCurrentDate: boolean;
  historyRoyals: Array<HistoryRoyalsType>;
  receipts: Array<ReceiptType>;
  api_uuid: string | null;
};

export const initialStateDiscount: DiscountStateType = {
  promoCodes: [],
  promoCodesTypes: [],
  promoCodesTypesYoung: [],
  promoCodesTypesAdult: [],
  promoCodesTypesPartner: [],
  isReceiptsCurrentDate: true,
  historyRoyals: [],
  receipts: [],
  api_uuid: null,
};
