import styled from "styled-components";

const Span = styled.span`
  display: block;
  will-change: transform;
  transition: transform 300ms ease;
`;

const ButtonDropdown = styled.button`
  display: block;
  width: 100%;
  padding: 16px 20px;
  font: var(--font-24);
  color: var(--hyperlink);
  text-align: left;
  background-color: var(--brand-4);
  transition: background-color 300ms ease;

  &:hover {
    background-color: var(--inactive);
  }

  &:active {
    ${Span} {
      transform: scale(0.95);
    }
  }
`;

export { ButtonDropdown, Span };
