import styled from "styled-components";

const FieldCheckbox = styled.div``;

const Error = styled.p`
  font: var(--font-21);
  color: var(--brand-1);
  text-align: left;
`;

export { FieldCheckbox, Error };
