import styled, { css } from "styled-components";
import { ButtonIcon as ButtonIconField, Input } from "../../atom";
import { breakpoints } from "../../../config";

type FieldInputType = {
  $isButton?: boolean;
  $isActivePlaceholderButton?: boolean;
  $isDisabled?: boolean;
};

type LabelType = {
  $isError?: boolean;
  $isFocus?: boolean;
};

type InputFieldType = {
  $isLable: boolean;
};

const Label = styled.label<LabelType>`
  position: absolute;
  top: 50%;
  left: 20px;
  z-index: 1;
  transform: translateY(-50%);
  font: var(--font-16);
  color: var(--brand-2);
  pointer-events: none;
  transition: top 100ms ease, font-size 100ms ease;

  ${(props) =>
    props.$isError &&
    css`
      color: var(--error);
    `};

  ${(props) =>
    props.$isFocus &&
    css`
      top: 10px;
      transform: translateY(0);
      font: var(--font-21);
    `};
`;

const InputField = styled(Input)<InputFieldType>`
  ${(props) =>
    props.$isLable &&
    css`
      &:focus {
        padding: 32px 56px 10px 20px;

        @media (max-width: ${breakpoints.XL}) {
          padding: 28px 56px 8px 20px;
        }
      }

      &:focus + ${Label} {
        top: 10px;
        transform: translateY(0);
        font: var(--font-21);
      }
    `}
`;

const FieldInput = styled.div<FieldInputType>`
  width: 100%;
  max-width: 100%;
  will-change: transform;
  transition: transform 300ms ease;

  ${(props) =>
    props.$isButton &&
    css`
      &:active {
        transform: scale(0.97);
      }
    `}

  ${(props) =>
    props.$isActivePlaceholderButton &&
    css`
      ${InputField} {
        color: var(--brand-2);
      }
    `}


  ${(props) =>
    props.$isDisabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}
`;

const Wrap = styled.div`
  position: relative;
`;

const Error = styled.p`
  padding: 4px 0 0 16px;
  font: var(--font-18);
  color: var(--error);
`;

const ButtonIcon = styled(ButtonIconField)`
  position: absolute;
  height: 24px;
  top: 50%;
  right: 20px;
  color: var(--iconography);
  transform: translateY(-50%);

  &:active {
    transform: translateY(-50%) scale(0.95);
  }
`;

export {FieldInput, Wrap, InputField, Label, ButtonIcon, Error};
