import styled from "styled-components";
import bgIcon from "../../../config/assets/images/footer-shape.svg";
import bgIcon768 from "../../../config/assets/images/footer-shape768.svg";
import bgIcon375 from "../../../config/assets/images/footer-shape-375.svg";
import { breakpoints } from "../../../config";
import { Button } from "../../atom";

const Footer = styled.footer`
  position: relative;
  display: flex;
  justify-content: space-between;
  min-height: 289px;
  width: 100%;
  max-width: 1320px;
  padding: 50px 80px 39px 50px;
  background-color: var(--inactive);
  border-radius: 50px;
  background-image: url(${bgIcon});
  background-repeat: no-repeat;
  background-position: -5px;
  overflow: hidden;
  z-index: 1000;

  @media (max-width: ${breakpoints.XXL}) {
    padding-right: 60px;
  }

  @media (max-width: ${breakpoints.XL}) {
    padding: 40px 50px 50px 40px;
    background-position: -86px;
  }

  @media (max-width: ${breakpoints.LG}) {
    gap: 32px;
    padding: 40px 40px 20px;
    background-image: url(${bgIcon768});
    background-position: left;
    background-size: cover;
  }

  @media (max-width: ${breakpoints.MD}) {
    flex-direction: column;
    padding: 40px 24px 50px;
    background-image: url(${bgIcon375});
    background-position: top left;
  }
`;

const SupportForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 268px;

  @media (max-width: ${breakpoints.LG}) {
    justify-content: start;
    gap: 32px;
    max-width: 41%;
  }

  @media (max-width: ${breakpoints.MD}) {
    gap: 24px;
    max-width: none;
  }
`;

const HeadText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Title = styled.h2`
  font: var(--font-6);
  letter-spacing: -0.56px;
  color: var(--brand-1);
`;

const Text = styled.p`
  font: var(--font-19);
  color: var(--text);

  @media (max-width: ${breakpoints.MD}) {
    font: var(--font-26);
  }
`;

const ButtonText = styled(Button)`
  height: 60px;
  padding: 0 40px;

  @media (max-width: ${breakpoints.XL}) {
    height: 64px;
  }

  @media (max-width: ${breakpoints.LG}) {
    max-width: none;
  }
`;

const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 0 0 49%;

  @media (max-width: ${breakpoints.XL}) {
    flex: 0 0 58%;
  }

  @media (max-width: ${breakpoints.LG}) {
    flex: none;
    max-width: 46%;
    gap: 34px;
  }

  @media (max-width: ${breakpoints.MD}) {
    max-width: none;
  }
`;

const FaqWrap = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: ${breakpoints.LG}) {
    flex-direction: column;
    gap: 24px;
  }
`;

const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-height: 138px;
`;

const LinksTitle = styled.h3`
  font: var(--font-15);
  color: var(--interface-dark);
`;

const LinksList = styled.ul`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 4px;
  column-gap: 20px;
  max-height: 110px;
`;

const LinkItem = styled.li``;

const LinkDefault = styled.a`
  font: var(--font-16);
  color: var(--text);
  transition: color 300ms ease;

  &:hover {
    color: var(--hyperlink);
  }
`;

const Copyright = styled.p`
  font: var(--font-19);
  color: var(--text);
  max-width: 360px;

  @media (max-width: ${breakpoints.MD}) {
    font: var(--font-26);
    max-width: none;
  }
`;

const Picture = styled.picture`
  display: contents;
`;

const Image = styled.img`
  position: absolute;
  bottom: 0;
  left: 384px;
  width: 221px;
  height: 266px;

  @media (max-width: ${breakpoints.XXL}) {
    left: 334px;
  }

  @media (max-width: ${breakpoints.XL}) {
    right: 0;
    bottom: -21px;
    left: auto;
    width: 162px;
    height: auto;
  }

  @media (max-width: ${breakpoints.LG}) {
    display: none;
  }
`;

export {
  Footer,
  SupportForm,
  HeadText,
  Title,
  Text,
  ButtonText,
  LinksWrapper,
  InfoBlock,
  FaqWrap,
  LinksTitle,
  LinksList,
  LinkItem,
  LinkDefault,
  Copyright,
  Picture,
  Image,
};
