export type QuestionType = {
  phone: string;
  email: string;
  text: string;
  category: string;
};

export type SmsType = {
  phone: string;
};

export type SmsCheckType = SmsType & {
  code: number;
};

export type InviteType = {
  email: string;
};

export type NotificationType = {
  text: string;
  id: string;
  buttonText?: string;
};

export type CheckEmailType = {
  email: string;
};

export type CheckEmailResponseType = {
  availible: boolean | null;
};

export type SharedStateType = {
  error: string | null;
  loading: boolean;
  valueCheckEmail: boolean | null;
  notifications: Array<NotificationType>;
};

export const initialStateShared: SharedStateType = {
  error: null,
  loading: false,
  valueCheckEmail: null,
  notifications: [],
};
