import styled from "styled-components";
import { breakpoints } from "../../../config";
import { Banner } from "../../atom";
import { BonusMechanics } from "../../organisms/bonus-mechanics/Bonus-mechanics";

const Hero = styled.section`
  position: relative;
  min-height: 707px;

  @media (max-width: ${breakpoints.XL}) {
    min-height: 630px;
  }

  @media (max-width: ${breakpoints.LG}) {
    min-height: 817px;
  }

  @media (max-width: ${breakpoints.MD}) {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
`;

const BannerStyles = styled(Banner)``;

const BonusMechanicsStyles = styled(BonusMechanics)`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;

  @media (max-width: ${breakpoints.MD}) {
    position: static;
    transform: none;
  }
`;

export { Hero, BannerStyles, BonusMechanicsStyles };
