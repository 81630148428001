import React, { FC, ReactNode } from "react";
import * as s from "./Modal-feeding-types-styles";

export type ModalFeedingTypesType = {
  className?: string;
  footnote: string | ReactNode;
  icon: ReactNode;
  onShowModal: () => void;
  list: Array<{ text: ReactNode }>;
};

export const ModalFeedingTypes: FC<ModalFeedingTypesType> = ({
  className,
  footnote,
  icon,
  onShowModal,
  list,
}) => {
  return (
    <s.ModalFeedingTypes className={className}>
      <s.Modal onClickButtonBg={onShowModal} onClickButtonIcon={onShowModal}>
        <s.List>
          {list.map((item, index) => (
            <s.Item key={index}>
              <s.Paragraph>{item.text}</s.Paragraph>
            </s.Item>
          ))}
        </s.List>

        <s.Footnote>{footnote}</s.Footnote>

        <s.Icon>{icon}</s.Icon>
      </s.Modal>
    </s.ModalFeedingTypes>
  );
};
