const validateText = (text: string) => {
  const validates = {
    statusLength: false,
    statusNumber: false,
    statusStr1: false,
    statusStr2: false,
  };

  // Проверка на длину более 11 символов
  if (text.length >= 11) {
    validates["statusLength"] = true;
  }

  // Проверка на наличие хотя бы одной цифры
  if (/\d/.test(text)) {
    validates["statusNumber"] = true;
  }

  // Проверка на наличие хотя бы одной строчной буквы
  if (/[а-яёa-z]/.test(text)) {
    validates["statusStr1"] = true;
  }

  // Проверка на наличие хотя бы одной прописной буквы
  if (/[А-ЯЁA-Z]/.test(text)) {
    validates["statusStr2"] = true;
  }

  return validates;
};

export {validateText};
