import styled from "styled-components";

const ChecksValidations = styled.div``;

const Title = styled.h2`
  margin-bottom: 9px;
  font: var(--font-20);
  color: var(--text);
`;

const List = styled.ul`
  display: grid;
  grid-auto-rows: var(--minmax);
  gap: 2px;
`;

const Item = styled.li``;

export { ChecksValidations, Title, List, Item };
