import styled from "styled-components";
import { stylesScrollbarMixin } from "../../../config";

const ButtonsDropdown = styled.div`
  padding: 10px 4px 10px 0;
  border-radius: 8px;
  background-color: var(--brand-4);
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.08), 0 2px 2px 0 rgba(0, 0, 0, 0.07),
    0 4px 3px 0 rgba(0, 0, 0, 0.04), 0 8px 3px 0 rgba(0, 0, 0, 0.01),
    0 12px 3px 0 rgba(0, 0, 0, 0);
  overflow: hidden;
`;

const List = styled.ul`
  max-height: 330px;
  overflow: auto;
  ${stylesScrollbarMixin("4px")};
`;

const Item = styled.li``;

const Text = styled.p`
  padding: 16px;
  font: var(--font-15);
  color: var(--text);
`;

export { ButtonsDropdown, List, Item, Text };
