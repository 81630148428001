import React, { FC } from "react";

export const IconEye: FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox={"0 0 24 24"}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.998 9.17A2.826 2.826 0 0 0 9.168 12a2.826 2.826 0 0 0 2.83 2.83 2.826 2.826 0 0 0 2.83-2.83 2.826 2.826 0 0 0-2.83-2.83ZM7.668 12a4.326 4.326 0 0 1 4.33-4.33 4.326 4.326 0 0 1 4.33 4.33 4.326 4.326 0 0 1-4.33 4.33A4.326 4.326 0 0 1 7.668 12Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2.257 8.997C4.648 5.239 8.152 2.97 12 2.97c3.848 0 7.352 2.269 9.743 6.027.545.855.792 1.95.792 2.998 0 1.049-.247 2.143-.792 2.998M2.257 8.996c-.545.854-.792 1.949-.792 2.998 0 1.049.247 2.143.792 2.998C4.647 18.75 8.152 21.02 12 21.02c3.848 0 7.351-2.269 9.742-6.027M12 4.47c-3.212 0-6.288 1.891-8.477 5.333h-.001c-.355.556-.557 1.351-.557 2.192 0 .84.202 1.636.557 2.192C5.713 17.63 8.789 19.52 12 19.52c3.212 0 6.288-1.891 8.477-5.332v-.002c.355-.555.558-1.35.558-2.191 0-.84-.203-1.636-.557-2.191l-.001-.001C18.288 6.36 15.212 4.47 12 4.47Z"
      clipRule="evenodd"
    />
  </svg>
);
