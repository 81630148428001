import React, { FC } from "react";

export const IconVk: FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox={"0 0 40 40"}
    {...props}
  >
    <path d="M8.701 10h-3.5c-1 0-1.201.47-1.201.99 0 .927 1.187 5.526 5.526 11.609 2.893 4.154 6.969 6.405 10.678 6.405 2.225 0 2.5-.5 2.5-1.361v-3.14c0-1 .211-1.2.916-1.2.52 0 1.41.26 3.486 2.263 2.374 2.374 2.765 3.438 4.1 3.438h3.501c1 0 1.5-.5 1.212-1.487-.316-.983-1.449-2.41-2.953-4.102-.816-.964-2.04-2.003-2.41-2.522-.52-.668-.372-.965 0-1.558l.062-.088c.527-.75 4.234-6.066 4.648-7.96.222-.742 0-1.287-1.059-1.287h-3.5c-.89 0-1.301.47-1.524.99 0 0-1.78 4.34-4.302 7.158-.816.816-1.187 1.076-1.632 1.076-.223 0-.545-.26-.545-1.002v-6.935c0-.89-.258-1.287-1-1.287h-5.501c-.556 0-.891.413-.891.805 0 .844 1.261 1.038 1.391 3.412v5.155c0 1.13-.204 1.335-.65 1.335-1.186 0-4.073-4.358-5.785-9.346C9.932 10.391 9.596 10 8.7 10Z" />
  </svg>
);
