import styled from "styled-components";
import LineDashed from "../../../config/assets/images/line-dashed.svg";
import { breakpoints } from "../../../config";

const StepsRoyals = styled.div``;

const ListSteps = styled.ul`
  position: relative;
  display: flex;
  gap: 25px;

  @media (max-width: ${breakpoints.XXL}) {
    gap: 18px;
  }

  @media (max-width: ${breakpoints.XL}) {
    gap: 8px;
  }

  @media (max-width: ${breakpoints.LG}) {
    gap: 20px;
    max-width: 714px;
  }

  @media (max-width: ${breakpoints.MD}) {
    flex-direction: column;
    gap: 16px;
  }
`;

const ItemSteps = styled.li`
  position: relative;
  background-color: var(--background-form);

  @media (max-width: ${breakpoints.MD}) {
    background-color: var(--brand-3);
  }

  &:nth-child(1) {
    min-width: 167px;

    @media (max-width: ${breakpoints.XL}) {
      min-width: 140px;
    }
  }

  &:nth-child(2) {
    min-width: 160px;

    @media (max-width: ${breakpoints.XL}) {
      min-width: 130px;
    }

    @media (max-width: ${breakpoints.LG}) {
      min-width: 120px;
    }
  }

  &:nth-child(3) {
    min-width: 142px;

    @media (max-width: ${breakpoints.XL}) {
      min-width: 130px;
    }

    @media (max-width: ${breakpoints.LG}) {
      min-width: 120px;
    }
  }

  &:nth-child(4) {
    min-width: 188px;

    @media (max-width: ${breakpoints.XL}) {
      min-width: 130px;
    }

    @media (max-width: ${breakpoints.LG}) {
      min-width: 120px;
    }
  }

  &:nth-child(5) {
    min-width: 122px;

    @media (max-width: ${breakpoints.XL}) {
      min-width: 110px;
    }

    @media (max-width: ${breakpoints.LG}) {
      min-width: 95px;
    }
  }

  &:not(:first-child) {
    padding-left: 15px;

    @media (max-width: ${breakpoints.XXL}) {
      padding-left: 10px;
    }

    @media (max-width: ${breakpoints.LG}) {
      padding-left: 0;
    }
  }

  &:not(:last-child)::before {
    content: "";
    position: absolute;
    top: 34px;
    left: 98px;
    width: 60%;
    height: 2px;
    background-image: url(${LineDashed});
    background-repeat: no-repeat;

    @media (max-width: ${breakpoints.XL}) {
      top: 29px;
      left: 81px;
    }

    @media (max-width: ${breakpoints.LG}) {
      left: 60px;
    }

    @media (max-width: ${breakpoints.MD}) {
      display: none;
    }
  }
`;

export { StepsRoyals, ListSteps, ItemSteps };
