export enum breakpoints {
  XXL = "1439px",
  XL = "1279px",
  LG = "1023px",
  MD = "767px",
  SM = "575px",
  XS = "375px",
}

export enum breakpointsTs {
  XXL = 1440,
  XL = 1280,
  LG = 1024,
  MD = 768,
  SM = 575,
  XS = 375,
}
