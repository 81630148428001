import styled from "styled-components";
import { breakpoints } from "./breakpoints-variables";

const Container = styled.div`
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 0 clamp(40px, 12.63vw + -120px, 60px);

  @media (max-width: ${breakpoints.XXL}) {
    padding: 0 clamp(40px, 10.63vw + -120px, 60px);
  }

  @media (max-width: ${breakpoints.MD}) {
    padding: 0 clamp(12px, 7.12vw + -14.72px, 40px);
  }
`;

export { Container };
