import React, { FC } from "react";

export const IconFemale: FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox={"0 0 24 24"}
    {...props}
  >
    <path
      d="M18 9.98694C17.9959 8.82094 17.6531 7.68131 17.0133 6.70705C16.3735 5.73279 15.4644 4.966 14.3969 4.5002C13.3293 4.03441 12.1495 3.88973 11.0013 4.08382C9.85302 4.27791 8.78599 4.80238 7.93032 5.59326C7.07465 6.38414 6.46731 7.40726 6.18238 8.53783C5.89745 9.66839 5.94724 10.8576 6.32568 11.9603C6.70411 13.063 7.39484 14.0316 8.31362 14.748C9.2324 15.4644 10.3395 15.8976 11.5 15.9948V17.4967H10.5C10.3674 17.4967 10.2402 17.5495 10.1464 17.6434C10.0526 17.7373 9.99996 17.8646 9.99996 17.9974C9.99996 18.1302 10.0526 18.2575 10.1464 18.3514C10.2402 18.4453 10.3674 18.498 10.5 18.498H11.5V19.4993C11.5 19.6321 11.5526 19.7595 11.6464 19.8534C11.7402 19.9473 11.8674 20 12 20C12.1326 20 12.2597 19.9473 12.3535 19.8534C12.4473 19.7595 12.5 19.6321 12.5 19.4993V18.498H13.5C13.6326 18.498 13.7597 18.4453 13.8535 18.3514C13.9473 18.2575 14 18.1302 14 17.9974C14 17.8646 13.9473 17.7373 13.8535 17.6434C13.7597 17.5495 13.6326 17.4967 13.5 17.4967H12.5V15.9948C14.0031 15.8689 15.4037 15.1808 16.4229 14.0675C17.4422 12.9541 18.0052 11.4973 18 9.98694ZM6.99996 9.98694C6.99996 8.99674 7.29321 8.02878 7.84262 7.20546C8.39202 6.38214 9.17292 5.74044 10.0865 5.36151C11.0002 4.98258 12.0055 4.88343 12.9754 5.07661C13.9453 5.26979 14.8362 5.74661 15.5355 6.44679C16.2348 7.14696 16.711 8.03904 16.9039 9.01021C17.0968 9.98139 16.9978 10.988 16.6194 11.9029C16.2409 12.8177 15.6001 13.5996 14.7778 14.1497C13.9556 14.6998 12.9889 14.9935 12 14.9935C10.6739 14.9935 9.40211 14.466 8.46443 13.5271C7.52675 12.5882 6.99996 11.3148 6.99996 9.98694Z"
      fill="currentColor"
    />
  </svg>
);
