import React, { FC, ReactNode } from "react";
import * as s from "./Card-target-action-styles";
import { IconRoyals } from "../../atom";

type CardTargetActionType = {
  className?: string;
  title: string;
  text: string;
  textButton: string;
  typeButton: "button" | "reset" | "submit";
  iconButton: ReactNode;
  disabledButton?: boolean;
  onClickButtonUpload: () => void;
};

export const CardTargetAction: FC<CardTargetActionType> = ({
  className,
  title,
  text,
  textButton,
  typeButton,
  iconButton,
  disabledButton,
  onClickButtonUpload,
}) => {
  return (
    <s.CardTargetAction className={className}>
      <s.BgIcon />
      <s.Content>
        <s.Title>{title}</s.Title>
        <s.Text>
          {text}
          <IconRoyals width={32} height={32} />
        </s.Text>
      </s.Content>

      <s.Button
        text={textButton}
        type={typeButton}
        icon={iconButton}
        onClickButtonUpload={onClickButtonUpload}
        disabled={disabledButton}
      />
    </s.CardTargetAction>
  );
};
