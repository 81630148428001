import {
  type ActionReducerMapBuilder,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { type NoInfer } from "react-redux";
import {
  AnimalResponseType,
  animalStateType,
  initialStateAnimal as initialState,
} from "../type/animal-type";
import { getAnimalsThunk } from "../thunk/animal-thunk";

const animalSlice = createSlice({
  name: "animal",
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<NoInfer<any>>) => {
    builder.addCase(
      getAnimalsThunk.fulfilled.type,
      (
        state: animalStateType,
        action: PayloadAction<Array<AnimalResponseType>>
      ) => {
        const getAnimalsCatAndDog = (breed_class: "cat" | "dog") => {
          return action.payload
            .filter(
              (animal) =>
                animal.breed_class === breed_class &&
                animal.title.toLowerCase() !== "Беспородная".toLowerCase() &&
                animal.title.toLowerCase() !== "Другая порода".toLowerCase()
            )
            .map((animal) => {
              return {
                id: String(animal.id),
                value: animal.title,
              };
            })
            ?.sort((a, b) =>
              a.value.localeCompare(b.value, "ru", { sensitivity: "accent" })
            );
        };

        const getCatAndDogMix = (breed_class: "cat" | "dog") => {
          return action.payload
            .filter(
              (animal) =>
                animal.breed_class === breed_class &&
                (animal.title.toLowerCase() === "Беспородная".toLowerCase() ||
                  animal.title.toLowerCase() === "Другая порода".toLowerCase())
            )
            .map((animal) => {
              return {
                id: String(animal.id),
                value: animal.title,
              };
            });
        };

        state.animals = action.payload;

        state.cat = [...getCatAndDogMix("cat"), ...getAnimalsCatAndDog("cat")];

        state.dog = [...getCatAndDogMix("dog"), ...getAnimalsCatAndDog("dog")];
      }
    );
  },

  selectors: {
    selectStateAnimals: (state) => state,
    selectAnimals: (state) => state.animals,
    selectCat: (state) => state.cat,
    selectDog: (state) => state.dog,
  },
});

export const { reducer } = animalSlice;
export const animalReducer = reducer;
export const { selectStateAnimals, selectAnimals, selectCat, selectDog } =
  animalSlice.selectors;
