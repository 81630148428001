import styled from "styled-components";
import {ButtonUpload, IconZoom, ModalBody, PopupBody,} from "../../../../shared";
import {breakpoints} from "../../../../shared/config";
import modalShapeBg from "../../../../shared/config/assets/images/modal-example-shape-bg.png";
import {BodyAnimation} from "../../../../shared/ui/molecules/modal-body/Modal-body-styles";
import {BodyAnimation as PopupBodyAnimation} from "../../../../shared/ui/molecules/popup-body/Popup-body-styles";

const ModalReceipt = styled.div``;

const Modal = styled(ModalBody)`
  ${BodyAnimation} {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 39%;
    max-width: 1080px;
    padding: 0;
    margin: auto;
    overflow: auto;
  }

  @media (max-width: ${breakpoints.XL}) {
    ${BodyAnimation} {
      grid-template-columns: 1fr 37%;
      max-width: 938px;
    }
  }

  @media (max-width: ${breakpoints.LG}) {
    z-index: 10000;

    ${BodyAnimation} {
      display: flex;
      flex-direction: column;
      max-width: none;
      width: 100%;
      height: 100vh;
      padding: 40px 56px 100px;
    }
  }

  @media (max-width: ${breakpoints.MD}) {
    ${BodyAnimation} {
      padding: 60px 12px 60px 12px;
    }
  }
`;

const ReceiptInfo = styled.div`
  padding: 50px 44px 34px 50px;

  @media (max-width: ${breakpoints.LG}) {
    width: 100%;
    padding: 0 0 32px 0;
    border-bottom: 1px solid var(--brand-2);
  }

  @media (max-width: ${breakpoints.MD}) {
    padding: 0;
    text-align: center;
  }
`;

const Title = styled.h2`
  font: var(--font-3);
  letter-spacing: -1.68px;
  color: var(--brand-1);
  margin-bottom: 36px;

  @media (max-width: ${breakpoints.XL}) {
    margin-bottom: 32px;
  }

  @media (max-width: ${breakpoints.LG}) {
    margin-bottom: 24px;
  }

  @media (max-width: ${breakpoints.MD}) {
    margin-bottom: 27px;
  }
`;

const ReceiptExamples = styled.div``;

const ReceiptExampleBar = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 16px;

  @media (max-width: ${breakpoints.XL}) {
    margin-bottom: 31px;
  }

  @media (max-width: ${breakpoints.LG}) {
    margin-bottom: 24px;
  }

  @media (max-width: ${breakpoints.MD}) {
    justify-content: center;
  }
`;

const ReceiptExampleBarTitle = styled.p`
  font: var(--font-8);
  letter-spacing: -0.48px;
  color: var(--interface-dark);
  margin-right: 32px;

  @media (max-width: ${breakpoints.XL}) {
    margin-right: 36px;
  }

  @media (max-width: ${breakpoints.LG}) {
    margin-right: 31px;
  }

  @media (max-width: ${breakpoints.MD}) {
    display: none;
  }
`;

const ReceiptExampleBarItem = styled.nav`
  font: var(--font-18);
  letter-spacing: -0.64px;
  padding-bottom: 4px;
  color: var(--text);
  border-bottom: 1px solid transparent;
  cursor: pointer;

  &.receipt {
    margin-right: 22px;

    @media (max-width: ${breakpoints.XL}) {
      margin-right: 24px;
    }
  }

  &.active {
    color: var(--brand-1);
    border-bottom: 1px solid var(--brand-1);
  }
`;

const ReceiptExampleInfo = styled.div`
  display: flex;

  @media (max-width: ${breakpoints.MD}) {
    flex-direction: column-reverse;
    margin-bottom: 32px;
  }
`;

const ReceiptExampleInfoImageContainer = styled.div`
  position: relative;
  cursor: pointer;

  @media (max-width: ${breakpoints.MD}) {
    margin-top: 20px;
  }
`;

const ReceiptExampleInfoImage = styled.img`
  width: 180px;
  height: 365px;

  @media (max-width: ${breakpoints.MD}) {
    width: 100%;
    height: 140px;
    object-fit: cover;
  }
`;

const ReceiptExampleInfoImageZoom = styled(IconZoom)`
  position: absolute;
  right: 4px;
  bottom: 4px;
  padding: 4px;
  color: var(--brand-3);
  background-color: var(--brand-1);
  border-radius: 100%;
  cursor: pointer;

  @media (max-width: ${breakpoints.XL}) {
    right: 12px;
    bottom: 12px;
  }
`;

const ReceiptExampleDescription = styled.div`
  color: var(--text);
  margin-left: 28px;

  @media (max-width: ${breakpoints.XL}) {
    margin-left: 23px;
  }

  @media (max-width: ${breakpoints.MD}) {
    margin-left: 0;
  }

  @media (max-width: ${breakpoints.MD}) {
    text-align: left;
  }
`;

const ReceiptExampleDescriptionTitle = styled.p`
  font: var(--font-18);
  letter-spacing: -0.64px;
  margin-bottom: 16px;
`;

const ReceiptExampleDescriptionList = styled.ul`
  font: var(--font-19);
  margin-left: 16px;

  & li {
    list-style: disc;
    margin-bottom: 12px;

    &::marker {
      color: var(--brand-1);
    }
  }
`;

const UploadReceipt = styled.div`
  padding: 116px 28px 50px 50px;
  background-image: url(${modalShapeBg});
  background-repeat: no-repeat;
  background-position: right top;
  background-color: var(--brand-4);
  border-radius: 44px 0 0 44px;

  @media (max-width: ${breakpoints.XL}) {
    padding: 104px 45px;
  }

  @media (max-width: ${breakpoints.LG}) {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    padding: 32px 0 0 0;
    background-image: none;
    background-color: var(--brand-3);
  }
`;

const ButtonUploadReceipt = styled(ButtonUpload)`
  margin-bottom: 32px;

  @media (max-width: ${breakpoints.XL}) {
    margin-top: 32px;
    margin-bottom: unset;
  }
`;

const ModalButton = styled(PopupBody)`
  ${PopupBodyAnimation} {
    max-width: 580px;
  }

  @media (max-width: ${breakpoints.LG}) {
    padding: 0 20px;
    ${PopupBodyAnimation} {
      margin: auto;
      border-radius: 40px;

      &::before {
        content: unset;
      }
    }
  }
`;

const ModalButtonTitle = styled.h2`
  margin-bottom: 16px;
  font: var(--font-3);
  color: var(--interface-dark);
`;

const ModalButtonText = styled.p`
  font: var(--font-11);
  color: var(--brand-2);
`;

const UploadListDescription = styled.ul`
  font: var(--font-19);
  color: var(--text);

  li {
    margin-bottom: 11px;
  }

  @media (max-width: ${breakpoints.LG}) {
    margin-left: 16px;

    & li {
      list-style: disc;
      margin-bottom: 12px;

      &::marker {
        color: var(--brand-1);
      }
    }
  }
`;

const ZoomImgModal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  padding: 16px;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;

  & picture {
    height: 100%;
    margin: auto;
  }
`;

const ZoomImgReceipt = styled.img`
  max-width: 100%;
  height: 100%;

  @media (max-width: ${breakpoints.LG}) {
    object-fit: contain;
  }
`;

const ZoomImg = styled.img`
  max-width: 100%;
  height: 100%;

  @media (max-width: ${breakpoints.LG}) {
    object-fit: contain;
  }
`;

export {
  ModalReceipt,
  Modal,
  ReceiptInfo,
  Title,
  ReceiptExamples,
  ReceiptExampleBar,
  ReceiptExampleBarTitle,
  ReceiptExampleBarItem,
  ReceiptExampleInfo,
  ReceiptExampleInfoImageContainer,
  ReceiptExampleInfoImage,
  ReceiptExampleInfoImageZoom,
  ReceiptExampleDescription,
  ReceiptExampleDescriptionTitle,
  ReceiptExampleDescriptionList,
  UploadReceipt,
  ButtonUploadReceipt,
  UploadListDescription,
  ZoomImgModal,
  ZoomImgReceipt,
  ZoomImg,
  ModalButton,
  ModalButtonTitle,
  ModalButtonText,
};
