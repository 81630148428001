import * as React from "react";
import { FC } from "react";

export const IconUser: FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox={"0 0 32 32"}
    fill="currentColor"
    {...props}
  >
    <path
      fill="currentColor"
      d="M22.668 17.44a9.705 9.705 0 0 0-4.014-2.351 5.499 5.499 0 1 0-6.056 0A9.203 9.203 0 0 0 6 23.7v1.237A2.062 2.062 0 0 0 8.062 27h15.121a2.062 2.062 0 0 0 2.062-2.062v-1.505a8.358 8.358 0 0 0-2.577-5.994Zm-11.17-6.936a4.124 4.124 0 1 1 8.25 0 4.124 4.124 0 0 1-8.25 0Zm12.373 14.434a.687.687 0 0 1-.688.687H8.062a.687.687 0 0 1-.687-.687v-1.237c0-4.172 3.629-7.623 8.09-7.698a8.678 8.678 0 0 1 6.24 2.412 7.024 7.024 0 0 1 2.166 5.018v1.505Z"
    />
  </svg>
);
