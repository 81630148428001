export type AnimalResponseType<T extends "cat" | "dog" = "cat" | "dog"> = {
  adult_age_from: number;
  breed_class: T;
  id: number;
  title: string;
};

export type AnimalPetType<T extends string | number = string> = {
  name: string;
  date_of_birth: string;
  breed: T;
  gender: string;
};

export type AnimalCatAndDogType = {
  id: string;
  value: string;
};

export type animalStateType = {
  animals: Array<AnimalResponseType>;
  cat: Array<AnimalCatAndDogType>;
  dog: Array<AnimalCatAndDogType>;
};

export const initialStateAnimal: animalStateType = {
  animals: [],
  cat: [],
  dog: [],
};
