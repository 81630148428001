import React, { FC, ReactNode } from "react";
import * as s from "./Menu-desktop-styles";

type MenuDesktopType = {
  className?: string;
  listLeft: Array<ReactNode>;
};

export const MenuDesktop: FC<MenuDesktopType> = ({ className, listLeft }) => {
  return (
    <s.MenuDesktop className={className}>
      <s.ListLeft>
        {listLeft.map((item, index) => (
          <s.ItemLeft key={index}>{item}</s.ItemLeft>
        ))}
      </s.ListLeft>
    </s.MenuDesktop>
  );
};
