import React, { FC, ReactNode } from "react";
import * as s from "../timeline-royals/Timeline-royals-styles";
import { MiniRoyalsCardType } from "../../atom";

type TimelineRoyalsType = {
  className?: string;
  title: string;
  firstStepIcon: ReactNode;
  cards: Array<MiniRoyalsCardType>;
};

export const TimelineRoyals: FC<TimelineRoyalsType> = ({
  className,
  title,
  firstStepIcon,
  cards,
}) => {
  const getComponentIsAmountNull = (amount: null | number): ReactNode => {
    return amount !== null ? (
      <s.RoyalsCardMini amount={amount} />
    ) : (
      <s.FirstIcon data-testid={"first-icon-test"}>{firstStepIcon}</s.FirstIcon>
    );
  };

  return (
    <s.TimelineRoyals className={className}>
      <s.Title>{title}</s.Title>

      <s.ListTimeline data-testid={"list-cards-test"}>
        {cards.map((card, index) => (
          <s.ItemTimeline key={index}>
            {getComponentIsAmountNull(card.amount)}
            <s.AdditionalText>{card.month}</s.AdditionalText>
          </s.ItemTimeline>
        ))}
      </s.ListTimeline>
    </s.TimelineRoyals>
  );
};
