import React, { FC } from "react";

export const IconsCardsRoyals: FC<
  React.SVGProps<SVGSVGElement> & { status: 1 | 2 | 3 | 4 }
> = ({ status, ...props }) => (
  <>
    {/* Icon Heart */}
    {status === 1 && (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 250 287"
        {...props}
      >
        <path d="M-110.986 129.768C-209.794 266.669 73.963 342.89 165.535 377.362l49.706-115.956C271.4 123.511 254.498 46.681 199.294 15.077c-49.256-28.2-125.396-28.882-157.5 106.808-25.025-22.845-103.382-60.558-152.78 7.883Z" />
      </svg>
    )}

    {/* Icon Wing */}
    {status === 2 && (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 250 287"
        {...props}
      >
        <path
          fill="currentColor"
          d="M127.376-74.846S-109.265-10.213-9.565 155.396c80.889 134.342 246.122-3.464 233.324-41.495-5.171-15.29-74.519-1.271-74.519-1.271s48.328-47.076 36.804-53.096c-11.525-6.02-43.548 5.19-43.548 5.19s43.64-58.794 19.116-65.726C137.089-7.934 104.498 24.4 104.498 24.4s63.664-112.568 22.878-99.246Z"
        />
      </svg>
    )}

    {/* Icon Star */}
    {status === 3 && (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 250 287"
        {...props}
      >
        <path
          fill="currentColor"
          d="m11.37 176.528-15.14-45.632 131.67 6.959L72.392 27.583l31.967-20.26 57.456 131.603L155.418 0l27.114 3.306 7.635 126.165L259.77 13.273l34.885 30.867-87.664 111.283 115.095-28.607 25.41 51.828-140.125 2.692 37.277 87.569-35.741 11.842-29.877-90.032-72.064 100.58-30.783-22.643 75.319-94.817-140.131 2.693Z"
        />
      </svg>
    )}

    {/* Icon Shape */}
    {status === 4 && (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 250 287"
        {...props}
      >
        <path
          fill="currentColor"
          d="M124.377 158.946c-2.118 1.159-3.749 1.936-5.268 2.897-9.243 5.831-19.17 10.192-29.756 12.699-12.375 2.935-24.79 3.101-36.884-1.778-20.811-8.403-32.173-27.51-30.198-50.955.982-11.654 4.477-22.638 9.132-33.3.74-1.694 1.447-3.396 2.365-5.564-1.621-.823-3.023-1.612-4.482-2.273C8.211 71.156-2.416 51.842.822 28.936 2.782 15.048 9.032 3.06 17.523-7.847c13.62-17.485 31.168-29.986 51.316-38.774 35.169-15.341 71.93-19.234 109.704-13.825 7.146 1.026 14.256 3.098 21.047 5.59 22.193 8.152 35.754 24.49 42.03 46.795 11.508 40.895 8.831 81.52-3.913 121.693-8.519 26.849-21.958 51.034-41.194 71.811-8.945 9.658-19.863 15.673-33.258 16.511-20.066 1.257-34.142-9.917-37.406-29.757-.662-4.022-.917-8.112-1.478-13.249l.006-.002Z"
        />
      </svg>
    )}
  </>
);
