import React from "react";
import { type RouteObject } from "react-router-dom";
import { sd } from "../../../../shared";
import { RecoverPasswordRestored } from "../../ui/Recover-password-restored";
import {pages} from "../../../../shared/config";

export const router: RouteObject = {
  path: pages.recoverPasswordRestored.router,
  element: <RecoverPasswordRestored />,
};
