import styled from "styled-components";
import { breakpoints } from "../../../shared/config";
import { FormSignInUser } from "../../../widgets/form-signin";

const SignIn = styled.section`
  position: relative;
  padding-bottom: 169px;

  @media (max-width: ${breakpoints.XL}) {
    padding-bottom: 110px;
  }

  @media (max-width: ${breakpoints.LG}) {
    width: 400px;
    padding-bottom: 100px;
  }

  @media (max-width: ${breakpoints.MD}) {
    width: 480px;
    padding-bottom: 80px;
  }

  @media (max-width: ${breakpoints.SM}) {
    width: 100%;
    padding-bottom: 60px;
  }
`;

const ImageBg = styled.img`
  position: absolute;
  top: 50%;
  right: 0;
  width: 773px;
  height: 529px;
  z-index: -1;
  transform: translateY(-50%);

  @media (max-width: ${breakpoints.XXL}) {
    width: 632px;
    height: 433px;
    transform: translateY(-50%);
  }

  @media (max-width: ${breakpoints.XL}) {
    width: 521px;
    height: 356px;
    transform: translateY(-50%);
  }

  @media (max-width: ${breakpoints.LG}) {
    display: none;
  }
`;

const Title = styled.h2`
  margin-bottom: 40px;
  font: var(--font-3);
  color: var(--brand-1);

  @media (max-width: ${breakpoints.MD}) {
    margin-bottom: 32px;
  }
`;

const FormSignIn = styled(FormSignInUser)`
  max-width: 450px;
  margin-bottom: 24px;

  @media (max-width: ${breakpoints.XL}) {
    max-width: 400px;
  }

  @media (max-width: ${breakpoints.LG}) {
    max-width: 380px;
  }

  @media (max-width: ${breakpoints.SM}) {
    margin-bottom: 20px;
  }
`;

const ForgottenPassword = styled.a`
  display: block;
  font: var(--font-19);
  color: var(--text);
  width: max-content;
  margin-bottom: 16px;
`;

const ToRegistration = styled.a`
  font: var(--font-19);
  color: var(--brand-1);

  & span {
    font: var(--font-17);
  }
`;

export {
  SignIn,
  ImageBg,
  Title,
  FormSignIn,
  ForgottenPassword,
  ToRegistration,
};
