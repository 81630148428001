import React, { FC } from "react";

export const IconArrowRow: FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox={"0 0 40 40"}
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M25.658 8.342a1.168 1.168 0 0 0-1.652 0l-9.055 9.056a3.926 3.926 0 0 0 0 5.54l9.055 9.056a1.168 1.168 0 0 0 1.652-1.652l-9.056-9.055a1.59 1.59 0 0 1 0-2.238l9.056-9.055a1.168 1.168 0 0 0 0-1.652Z"
      clipRule="evenodd"
    />
  </svg>
);
