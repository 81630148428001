import styled, { css } from "styled-components";
import { IconEye, IconEyeClosed } from "../../../shared";

type IconType = {
  $isError: boolean;
};

const FieldPasswordFormik = styled.div``;

const IconOpenedEye = styled(IconEye)<IconType>`
  display: flex;
  width: 24px;
  height: 24px;
  color: var(--text);

  ${(props) =>
    props.$isError &&
    css`
      color: var(--error);
    `}
`;

const IconClosedEye = styled(IconEyeClosed)<IconType>`
  display: flex;
  width: 24px;
  height: 24px;
  color: var(--text);

  ${(props) =>
    props.$isError &&
    css`
      color: var(--error);
    `}
`;

export { FieldPasswordFormik, IconOpenedEye, IconClosedEye };
