import styled from "styled-components";
import { breakpoints } from "../../../config";

const ButtonUpload = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
  height: 64px;
  padding: 16px 24px;
  font: var(--font-7);
  color: var(--brand-3);
  background-color: var(--brand-1);
  text-transform: uppercase;
  will-change: transform;
  transition: transform 300ms ease, background-color 300ms ease,
    color 300ms ease;

  @media (max-width: ${breakpoints.XL}) {
    height: 56px;
  }

  & svg {
    width: 24px;
    height: 24px;

    @media (max-width: ${breakpoints.XL}) {
      width: 20px;
      height: 20px;
    }
  }

  &:hover {
    background-color: var(--pink);
    color: var(--brand-1);
  }

  &:disabled {
    pointer-events: none;
    background-color: var(--brand-3);
    color: var(--inactive);
  }

  &:active {
    transform: scale(0.95);
  }

  &:focus-visible {
    outline: 2px solid var(--brand-1);
    background-color: var(--pink);
    color: var(--brand-1);
  }
`;

export {ButtonUpload};
