import * as React from "react";
import { FC } from "react";

export const IconSetting: FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox={"0 0 24 24"}
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M7.478 10.96H8.87c1.392 0 2.087-.696 2.087-2.088V7.481c0-1.392-.696-2.088-2.087-2.088H7.478c-1.392 0-2.087.696-2.087 2.088v1.391c0 1.392.695 2.088 2.087 2.088ZM15.83 10.96h1.391c1.392 0 2.088-.696 2.088-2.088V7.481c0-1.392-.696-2.088-2.088-2.088H15.83c-1.392 0-2.088.696-2.088 2.088v1.391c0 1.392.696 2.088 2.088 2.088ZM15.83 19.31h1.391c1.392 0 2.088-.696 2.088-2.088v-1.391c0-1.392-.696-2.088-2.088-2.088H15.83c-1.392 0-2.088.696-2.088 2.088v1.391c0 1.392.696 2.088 2.088 2.088ZM7.478 19.31H8.87c1.392 0 2.087-.696 2.087-2.088v-1.391c0-1.392-.696-2.088-2.087-2.088H7.478c-1.392 0-2.087.696-2.087 2.088v1.391c0 1.392.695 2.088 2.087 2.088Z"
    />
  </svg>
);
