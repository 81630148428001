import React, { FC } from "react";

export const IconUpload: FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox={"0 0 17 16"}
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M5.343 7.804c.267.267.7.267.967 0l1.848-1.849 1.85 1.85a.683.683 0 0 0 .966-.967L8.642 4.506a.683.683 0 0 0-.967 0L5.343 6.838a.683.683 0 0 0 0 .966Z"
      clipRule="evenodd"
    />
    <path
      fillRule="evenodd"
      d="M8.16 15a.683.683 0 0 0 .682-.683V5.053a.683.683 0 0 0-1.366 0v9.264c0 .377.306.683.683.683Z"
      clipRule="evenodd"
    />
    <path
      fillRule="evenodd"
      d="M1.683 8.684a.683.683 0 0 0 .683-.683c0-1.59.539-2.995 1.49-3.997C4.803 3.008 6.198 2.367 8 2.367s3.197.64 4.144 1.637c.951 1.002 1.49 2.406 1.49 3.997A.683.683 0 0 0 15 8c0-1.9-.646-3.654-1.866-4.938C11.91 1.773 10.146 1.001 8 1.001c-2.146 0-3.91.772-5.134 2.062C1.646 4.347 1 6.102 1 8.001c0 .377.306.683.683.683Z"
      clipRule="evenodd"
    />
  </svg>
);
