import styled from "styled-components";
import { CounterRoyals, ModalBodyRight } from "../../../../shared";
import { breakpoints, stylesScrollbarMixin } from "../../../../shared/config";
import { BodyAnimation } from "../../../../shared/ui/molecules/modal-body-right/Modal-body-right-styles";

const ModalHistoryOperations = styled.div``;

const Modal = styled(ModalBodyRight)`
  ${BodyAnimation} {
    padding: 80px 10px 80px 64px;
  }

  @media (max-width: ${breakpoints.XXL}) {
    ${BodyAnimation} {
      padding: 60px 10px 60px 70px;
    }
  }

  @media (max-width: ${breakpoints.XL}) {
    ${BodyAnimation} {
      padding: 40px 10px 40px 60px;
    }
  }

  @media (max-width: ${breakpoints.MD}) {
    ${BodyAnimation} {
      padding: 65px 10px 30px 20px;
    }
  }
`;

const Title = styled.h2`
  margin-bottom: 40px;
  font: var(--font-8);
  color: var(--interface-dark);

  @media (max-width: ${breakpoints.XL}) {
    margin-bottom: 32px;
  }
`;

const List = styled.ul`
  display: grid;
  grid-auto-rows: var(--minmax);
  gap: 24px;
  max-height: 75vh;
  margin-bottom: 32px;
  padding-right: 34px;
  overflow: auto;

  ${stylesScrollbarMixin("4px", "4px")}
`;

const Item = styled.li`
  position: relative;

  &::before {
    content: "";
    position: absolute;
    bottom: -12px;
    width: 100%;
    height: 1px;
    background-color: var(--inactive);
  }
`;

const Wrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`;

const Text = styled.p`
  font: var(--font-15);
  color: var(--text);
`;

const Royals = styled(CounterRoyals)``;

const Button = styled.button`
  font: var(--font-19);
  color: var(--text);
`;

export {
  ModalHistoryOperations,
  Modal,
  Title,
  List,
  Item,
  Button,
  Wrap,
  Text,
  Royals,
};
