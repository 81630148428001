import {useEffect} from "react";

export const useScrollToTop = () => {
  useEffect(() => {
    const idTimeout = setTimeout(() => {
      window.scrollTo({
        top: 0,
      });
    }, 0);
    return () => clearTimeout(idTimeout);
  }, []);
};