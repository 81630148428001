import styled, { css } from "styled-components";
import { breakpoints, Container } from "../../../shared/config";
import { Link } from "react-router-dom";

const Auth = styled.section``;

const AuthContainer = styled(Container)``;

const LinkCss = css`
  font: var(--font-16);
  color: var(--text);
  will-change: transform;
  transition: color 300ms ease, transform 300ms ease;

  &:hover {
    color: var(--hyperlink);
  }

  &:active {
    transform: scale(0.98);
  }
`;

const LinkRouter = styled(Link)`
  display: block;
  ${LinkCss};
`;

const LinkDefault = styled.a`
  display: flex;
  align-items: center;
  gap: 4px;
  ${LinkCss};
`;

const LinkDefaultFooter = styled.a``;

const Button = styled.button`
  display: flex;
  align-items: center;
  gap: 4px;
  ${LinkCss};
`;

const Content = styled.div`
  flex: 1 1 auto;

  @media (max-width: ${breakpoints.LG}) {
    margin-top: 4px;
  }

  @media (max-width: ${breakpoints.MD}) {
    margin-top: 20px;
  }
`;

export {
  Auth,
  AuthContainer,
  LinkRouter,
  LinkDefault,
  LinkDefaultFooter,
  Button,
  Content,
};
