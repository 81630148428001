import React, { FC } from "react";
import * as s from "./Button-time-styles";

type ButtonTimeType = {
  className?: string;
  text: string;
  type: "button" | "reset" | "submit";
  isActive: boolean;
  onClickButtonTime: () => void;
};

export const ButtonTime: FC<ButtonTimeType> = ({
  className,
  text,
  type,
  isActive,
  onClickButtonTime,
}) => {
  return (
    <s.ButtonTime
      className={className}
      type={type}
      disabled={isActive}
      $isActive={isActive}
      onClick={isActive ? () => {} : onClickButtonTime}
    >
      {text}
    </s.ButtonTime>
  );
};
