import React, {FC, ReactNode} from 'react';
import {IconArrowRow, IconClose} from '../../atom';
import * as s from './Modal-body-styles';

type ModalBodyType = {
  className?: string;
  children: ReactNode;
  buttonRedirectText: string | null;
  onClickButtonBg: () => void;
  onClickButtonIcon: () => void;
};

export const ModalBody: FC<ModalBodyType> = (
  {
    className,
    children,
    buttonRedirectText,
    onClickButtonBg,
    onClickButtonIcon,
  }) => {
  const variants = {
    initial: {opacity: 0, scale: 0.8, transformOrigin: "bottom center"},
    animate: {opacity: 1, scale: 1},
    exit: {opacity: 0, scale: 0.8},
  };
  return (
    <s.ModalBody className={className}>
      <s.ButtonBg type={'button'} onClick={onClickButtonBg}/>

      <s.BodyAnimation isShow={true} delay={0.2} variants={variants}>
        <s.ButtonIconBody
          type={'button'}
          icon={<IconClose width={24} height={24}/>}
          onClickButtonIcon={onClickButtonIcon}
        />

        {buttonRedirectText ? <s.ButtonRedirect type={'button'} onClick={onClickButtonIcon}>
          <IconArrowRow width={24} height={24}/> {buttonRedirectText}
        </s.ButtonRedirect> : null}

        {children}
      </s.BodyAnimation>
    </s.ModalBody>
  );
};
