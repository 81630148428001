import * as React from "react";
import { FC } from "react";

export const IconUserAdd: FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox={"0 0 32 32"}
    fill="currentColor"
    {...props}
  >
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.3}
      d="M24.818 13.702h-1.453v-1.52a.332.332 0 1 0-.663 0v1.52h-1.52a.332.332 0 0 0 0 .663h1.52v1.453a.332.332 0 0 0 .663 0v-1.453h1.453a.332.332 0 0 0 0-.663Z"
    />
    <path
      fill="currentColor"
      d="M19.5 9.5a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0ZM23 27a.988.988 0 0 0 .993-1c-.025-1.482-.142-3.777-.678-4.777a8.448 8.448 0 0 0-1.951-2.745 9.06 9.06 0 0 0-2.92-1.834A9.5 9.5 0 0 0 15 16a9.5 9.5 0 0 0-3.444.644 9.058 9.058 0 0 0-2.92 1.834 8.448 8.448 0 0 0-1.95 2.745c-.534 1.21-.652 3.359-.678 4.777-.01.553.44 1 .992 1h16Z"
    />
  </svg>
);
