import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { petApi } from "../../api";
import { BreedRequestType } from "../type/pet-type";

export const getPetsThunk = createAsyncThunk(
  "pet/get-pets",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await petApi.get_pets();
      return response.data;
    } catch (e) {
      const error = e as AxiosError<any>;
      return rejectWithValue(error.response?.data.error);
    }
  }
);

export const createPetThunk = createAsyncThunk(
  "pet/create-pets",
  async (body: BreedRequestType, { rejectWithValue, dispatch }) => {
    try {
      await petApi.create_pet(body);
      dispatch(getPetsThunk());
    } catch (e) {
      const error = e as AxiosError<any>;
      return rejectWithValue(error.response?.data.error);
    }
  }
);

export const updatePetsThunk = createAsyncThunk(
  "pet/update-pets",
  async (data: { body: any; id: number }, { rejectWithValue, dispatch }) => {
    try {
      const response = await petApi.update_pet(data);
      return response.data;
    } catch (e) {
      const error = e as AxiosError<any>;
      return rejectWithValue(error.response?.data.error);
    }
  }
);
